import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-show-more-button",
  templateUrl: "./show-more-button.component.html",
  styleUrls: ["./show-more-button.component.scss"]
})
export class ShowMoreButtonComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() showMoreStatus: "less" | "more" = "more";
  @Input() showMoreLabel: string = "Show me more";
  @Input() showLessLabel: string = "Show me less";

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}

  removeBlur($event) {
    $event.target.parentElement.parentElement.blur();
  }
}
