import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  ContentChildren,
  QueryList
} from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { CollapseLinkComponent } from "../collapse-link/collapse-link.component";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
  host: { class: "app-accordion" }
})
export class AccordionComponent implements OnInit, AfterContentInit {
  constructor() {}
  @ContentChildren(CollapseLinkComponent) topLevelPanes!: QueryList<
    CollapseLinkComponent
  >;

  @ViewChild("accordion", { static: true }) accordion: MatAccordion;

  @Input() multi: boolean;

  ngOnInit() {}

  ngAfterContentInit() {
    //we must tell to the mat expansion panels which is their parent accordion
    //as they are not its direct childs

    this.topLevelPanes.forEach((item: CollapseLinkComponent) => {
      item.accordion = this.accordion;
      item.topLevelPanes = this.topLevelPanes;
    });
  }
}
