import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypenscheinService {

  private calls = new Subject();

  constructor(private http: HttpClient) { }

  sendTypenscheinRequest(agreementId: string, requestBody: any) {
    this.calls.next(true);
    const url: string = environment.restBase + '/actions/' + agreementId + '/car-certificate/request';
    return this.http
      .post<any>(url, requestBody)
      .pipe(
        takeUntil(this.calls),
        catchError(err => {
          return throwError(() => err);
        })
      );
  }
}
