import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-registration-help",
  templateUrl: "./registration-help.component.html",
  styleUrls: ["./registration-help.component.scss"]
})
export class RegistrationHelpComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() title: string;
  @Input() text: string;
  @Input() videoUrl: string;

  ngOnInit() {}
}
