import { Component, OnInit, Input } from "@angular/core";

export interface LabelValue {
  label: string;
  value: string;
  mention?: string;
  appTest?: string;
}
export interface EmphasisData {
  amountList: LabelValue[];
  total: LabelValue;
}

@Component({
  selector: "app-emphasis-list",
  templateUrl: "./emphasis-list.component.html",
  styleUrls: ["./emphasis-list.component.scss"]
})
export class EmphasisListComponent implements OnInit {
  constructor() {}

  @Input() amountList: LabelValue[];
  @Input() total: LabelValue;

  @Input() emphasisData: EmphasisData;
  /* emphasisData example :  {
    amountList: [
      { label: "Arrears/overpayments", value: "123,50 €" },
      { label: "Termination figure", value: "11.540,51 €" }
    ],
    total: {
      label: "Total amount to pay",
      value: "11.739,01 €",
      mention:
        "Der angegebene Wert enthält nur Bewegungen bis zum Zeitpunkt des Abrufs. Etwaige spätere Zahlungen, Lastschriften retour, Rechnungen etc. können hier nicht berücksichtigt werden. Irrtum vorbehalten!"
    }
  };*/

  ngOnInit() {
    if (this.emphasisData) {
      this.amountList = this.emphasisData.amountList;
      this.total = this.emphasisData.total;
    }
  }
}
