/* eslint-disable max-len */
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DeviceService } from "../../../services/device.service";
import { MatFormFieldControl } from "@angular/material/form-field";
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: "app-textfield",
  templateUrl: "./textfield.component.html",
  styleUrls: ["./textfield.component.scss"],
  providers: [{ provide: MatFormFieldControl, useExisting: TextfieldComponent }]
})
export class TextfieldComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() pattern: string;
  @Input() patternErrorMessage: string;
  @Input() required: boolean;
  @Input() requiredErrorMessage: string;

  @Input() value?: string;
  @Input() label = 'Label';
  @Input() placeholder ? = '';
  @Input() withClear ? = true; // false for search input
  @Input() hint?: string;
  @Input() hasError?: boolean; // the error state is dsplayed if there's an error message as a parameter or hasError = true
  @Input() error?: string; // the error state is dsplayed if there's an error message as a parameter or id  hasError = true
  @Input() isPassword ? = false;
  @Input() isMail ? = false;
  @Input() hidePassword ? = true;
  @Input() type?: string;
  @Input() filter?: boolean;

  @Input() disabled?: boolean;
  @Input() prefilled?: boolean;
  @Input() uploadFile ? = false;
  @Input() appTest?: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearEmitter: EventEmitter<any> = new EventEmitter<any>();

  public hasLostFocus = false;

  isMobile: boolean;

  constructor(private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile;
  }

  clear() {
    this.parentForm.patchValue({[this.controlName]: ''});
    this.value = '';
    this.parentForm.controls[this.controlName].markAsDirty({ onlySelf: true });
    this.parentForm.markAsDirty({ onlySelf: true });
  }

  ngOnInit() {
    // init form if not recieved in input
    if ( !this.parentForm ) {
      this.controlName = 'field';
      this.parentForm = new FormGroup({
        field: new FormControl(this.value)
      });
    }

    if (!this.disabled && !this.uploadFile && this.parentForm.controls[this.controlName].value) {
      this.onFocusOut();
    }

    // if you fill an error message, you don't have to add has Error = true
    // set hasError=true when there's no message
    if (this.error != null) {
      this.hasError = true;
    }
    // set hasError=true when there's no message
    if (this.errorDetected()) {
      this.hasError = true;
    }
  }

  onFocusOut() {
    this.parentForm.controls[this.controlName].markAsDirty({ onlySelf: true }); // needed to trigger the requiredErrorMessage visualization
    this.hasLostFocus = true;
  }

  errorDetected(): boolean {
    if ( this.parentForm.controls[this.controlName].errors &&
         (this.parentForm.controls[this.controlName].dirty || this.parentForm.controls[this.controlName].value !== '') ) {
      return true;
    } else {
      return false;
    }
  }

  onChange(event) {
    if(this.isMail) this.value=event.toLowerCase()
    this.onModelChange.emit(event)
  }

}
