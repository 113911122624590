<span app-test="claims-page">
  <div [editSlug]="slug">
    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">

          <h2 class="form-title">{{fields.claims_page_title}}</h2>
          <br/>
          <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(fields.claims_page_content)"></p>
          <br/>
          <ul app-test="claims-documents">
            <li *ngIf="fields.document_1_label">
              <a [href]="fields.document_1_url" target="_blank"><b>{{fields.document_1_label}}</b></a>
            </li>
            <br/>
            <li *ngIf="fields.document_2_label">
              <a [href]="fields.document_2_url" target="_blank"><b>{{fields.document_2_label}}</b></a>
            </li>
          </ul>
          <br/>

        </div>
      </div>
    </div>
  </div>
</span>