import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { ConfigurablePage } from '../configurable-page';
import { LayoutService } from 'src/app/design-system/services/layout.service';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { TabData } from 'src/app/design-system/components/structure/pwa-tab/pwa-tab.component';
import { AlertsService } from 'src/app/services/alerts/alerts.service';
import { NotificationItem } from 'src/app/design-system/components/modals/feed-notification/feed-notification.component';
import { AbstractComponent } from '../abstract-component';
import { InterpolatePipe } from 'src/app/pipes/interpolate.pipe';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-logged-page',
  templateUrl: './logged-page.component.html',
  styleUrls: ['./logged-page.component.sass'],
  providers: [InterpolatePipe, CurrencyFormatPipe, DateFormatPipe]
})
export class LoggedPageComponent extends AbstractComponent implements OnInit {

  constructor(private layoutService: LayoutService, private customerService: CustomerService,
              private alertService: AlertsService, private interpolate: InterpolatePipe,
              private dataFormat: DateFormatPipe, private pageLayoutConfigService: PageLayoutConfigService) {
    super("page-layout");
  }

  public menu: MenuItems[];
  public footerLinks: MenuItems[];
  public alertsTitle: string;
  public footerCopyright: string;
  public tabBarData: TabData[];
  public tabBarBurgerLabel: string;
  public displayName: string;
  public welcomeText: string;
  public notificationItems: NotificationItem[] = [];
  public pageConfig: any = {};
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  ngOnInit() {

    this.menu = this.pageLayoutConfigService.menu;
    this.footerLinks = this.pageLayoutConfigService.footerLinks;
    this.tabBarData = this.pageLayoutConfigService.tabMenu;
    this.tabBarBurgerLabel = this.pageLayoutConfigService.pwaBurgerButtonLabel;
    this.alertsTitle = this.pageLayoutConfigService.alertsTitle;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.welcomeText = this.pageLayoutConfigService.headerWelcome;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.contactBlocksData;
    this.customerService.self().toPromise().then(customer =>
      this.displayName = customer.person.displayName);
    this.alertService.list().toPromise().then(alerts =>
      this.notificationItems = this.buildNotificationItems(alerts));

    this.pageConfig = {
      isHome: true,
      isSubPage: false,
      backToLabel: this.fields.back_button_label
    };
  }

  private buildNotificationItems(alerts: any[]): NotificationItem[] {
    return alerts.map(a => this.buildNotificationItem(a));
  }

  private  buildNotificationItem(alert: any): NotificationItem {
    const vehicle = alert.agreement.vehicle;
    const vehicleModel = vehicle.model ? vehicle.model.label : '';
    const message = this.interpolate.transform(this.fields.alert_payment_message_template, {
      date: this.dataFormat.transform(alert.created),
      vehicle: vehicleModel + " " + vehicle.plate
    });
    return {title: this.fields.alert_payment_title, text: message , id: null, buttonLabel: null};
  }

  updatePageConfig(page: ConfigurablePage) {

    this.pageConfig = {
      isHome: true,
      isSubPage: false,
      backToLabel: this.fields.back_button_label
    };

    if ( page.getConfig ) {
      this.pageConfig = Object.assign(this.pageConfig, page.getConfig());
    }

    this.layoutService.updateMasonry(true);
  }
}
