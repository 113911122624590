import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from '../../cms-manager/cms-manager.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginPageComponent } from './login-page/login-page.component';

const routes: Routes = [
  { path: 'login',
    component: LoginPageComponent,
    children: [
      { path: '',
        component: LoginFormComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    LoginFormComponent,
    LoginPageComponent
  ]
})
export class LoginModule { }
