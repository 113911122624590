import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { StatementService } from 'src/app/services/statement/statement.service';

@Component({
  selector: 'app-statement-sent',
  templateUrl: './statement-sent.component.html',
  styleUrls: ['./statement-sent.component.sass']
})

export class StatementSentComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public requestSent: boolean = undefined;

  constructor(private route: ActivatedRoute,
              private router: Router) {
      super('agreement-details-page');
    }

  ngOnInit() {
    this.requestSent = this.route.snapshot.data.requestSent;
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.params.id,
      isSubPage : true,
      isHome : false
    };
  }

  backToAgreement() {
    this.router.navigate(['/home/agreements/' + this.route.snapshot.parent.params.id]);
  }
}
