<div class="container--nested" [editSlug]="slug" app-test="public-contact-us-page">
  <div class="content-box content-box--form">
    <div class="content-box-inner">
      <div class="layout-narrow">

        <h2 class="form-title">{{fields.contact_us_send_us_message_label}}</h2>
        <p>{{fields.mandatory_label}}</p>

        <form class="ng-untouched ng-pristine ng-valid" [formGroup]="publicContactUsForm" app-test="contact-us-form">

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_agreement_number_label" [parentForm]="publicContactUsForm"
              controlName="agreementNumber" [required]="false"
              [requiredErrorMessage]="fields.required_error_txt" [patternErrorMessage]="fields.invalid_error_txt" appTest="Agreement Number">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_customer_name_label" [parentForm]="publicContactUsForm"
              controlName="customerName" [required]="true" [pattern]="patterns.name.pattern"
              [requiredErrorMessage]="fields.required_error_txt" [patternErrorMessage]="fields.invalid_error_txt"
              appTest="Customer Name">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-select [label]="fields.contact_us_type_label" [parentForm]="publicContactUsForm"
              controlName="type" [options]="messageTypeSelectOptions"
              (selectionChange)="setSelectedMessageType($event)"
              appTest="Message Type"
              [required]="true">
            </app-select>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_email_address_label" [parentForm]="publicContactUsForm"
              controlName="email" [required]="true" [pattern]="patterns.email.pattern"
              [requiredErrorMessage]="fields.required_error_txt" [patternErrorMessage]="fields.invalid_error_txt" 
              appTest="Email Address">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_telephone_number_label" [parentForm]="publicContactUsForm"
              controlName="phone" [required]="false" [pattern]="patterns.phone.pattern"
              [requiredErrorMessage]="fields.required_error_txt" [patternErrorMessage]="fields.invalid_error_txt"
              appTest="Telephone Number">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textarea [label]="fields.contact_us_message_label" [parentForm]="publicContactUsForm" controlName="message" [required]="true"
              [requiredErrorMessage]="fields.required_error_txt" maxLength="5000" minRows="5" [displayCharCount]="true" appTest="Message">
            </app-textarea>
          </div>

          <ngx-recaptcha2
            [siteKey]="env.captchaKey"
            useGlobalDomain="false"
            size="Normal"
            hl="de"
            theme="Light"
            type="Image"
            [required]="env.captchaFormControl"
            formControlName="recaptcha">
          </ngx-recaptcha2>
          <br/>
        </form>
        
        <app-form-buttons>
          <div>
            <app-button 
            [buttonLabel]="fields.confirm_button_label" 
            (buttonClick)="send($event)"
            [disabled]="publicContactUsForm.invalid" 
            app-test="confirm-btn"
            >
            </app-button>
          </div>
        </app-form-buttons>

      </div>
    </div>
  </div>
</div>
