import { Component, OnInit, Input } from "@angular/core";
import { DialogService } from "src/app/design-system/services/dialog.service";

@Component({
  selector: "app-close-button",
  templateUrl: "./close-button.component.html",
  styleUrls: ["./close-button.component.scss"]
})
export class CloseButtonComponent implements OnInit {
  constructor(private dialogService: DialogService) {}

  @Input() colorClass?: string = "color-primary";

  @Input() closeAction: Function;

  closeDialog() {
    if (this.closeAction != null) {
      this.closeAction();
    } else {
      this.dialogService.close();
    }
  }

  ngOnInit() {}
}
