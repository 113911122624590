import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-push-link",
  templateUrl: "./push-link.component.html",
  styleUrls: ["./push-link.component.scss"]
})
export class PushLinkComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() title: string = "Title";

  ngOnInit() {}
}
