<div class="content-box content-box--form">
  <div class="content-box-inner">
    <div class="layout-large">
      <form app-test="vehicle-form">
        <h2 *ngIf="!isPWA" class="form-title">{{ title }}</h2>
        <a class="link-body-text up-corner-link" [href]="goToLink" target="_blank">{{ goToLabel }}</a>
        <div class="rwd-row">
          <div class="rwd-col-50">
            <h3 class="form-heading">{{ heading }}</h3>
            <p class="mention">{{ imageAccepted }}</p>
            <div class="bg-image-holder">
              <app-bg-image
              [bgImage]="image ? image : defaultImage"
              app-test="vehicle-detail-image"></app-bg-image>
              <div class="upload-image">
                <div>
                  <app-icon-button
                    icon="icon-photo-circle"
                    iconWidth="57px"
                    iconHeight="57px"
                    colorClass="color-white"
                    class="hover-white-strong change-photo"
                    (click)="browseImage()"
                    app-test="load-image-btn">
                  </app-icon-button>
                </div>                
                <input #uploadImage [hidden]="true" type="file" accept="image/jpeg,image/png" (change)="updateImage()" />
                <app-alert-message class="error-msg" *ngIf="!isNullOrEmpty(errorFileMessage)">
                  <span class="text-bold" app-test="image-file-error">{{errorFileMessage}}</span>
                </app-alert-message>
              </div>
            </div>
          </div>

          <div class="rwd-col-50">
            <div class="form-group">
              <app-textfield
                [label]="brandLabel"
                [value]="brand"
                [disabled]="true"
                [prefilled]="true"
                appTest="Maker brand"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="modelLabel"
                [value]="model"
                [disabled]="true"
                [prefilled]="true"
                appTest="Model"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="licensePlateNumberLabel"
                [value]="licensePlateNumber"
                [disabled]="true"
                [prefilled]="true"
                appTest="Plate"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="vinLabel"
                [value]="vin"
                [disabled]="true"
                [prefilled]="true"
                appTest="Vin"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="vehiculeNameLabel"
                [placeholder]="vehicleNamePlaceholderLabel"
                [value]="vehiculeName"
                (onModelChange)="updateName($event)"
                appTest="Vehicle name"
              ></app-textfield>
            </div>
          </div>
        </div>

        <div class="layout-narrow">
          <app-form-buttons>
            <app-button
              buttonType="secondary"
              [buttonLabel]="cancelLabel"
              appTest="cancel-btn"
              (buttonClick)="cancel.emit($event)"
            ></app-button
            ><app-button (buttonClick)="save($event)" [buttonLabel]="confirmLabel" app-test="confirm-btn" [disabled]="!enableSave"></app-button>
          </app-form-buttons>
        </div>
      </form>
    </div>
  </div>
</div>
