import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule, Routes } from '@angular/router';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from '../../cms-manager/cms-manager.module';
import { UpdateEmailPageComponent } from './update-email-page/update-email-page.component';
import { UpdateEmailConfirmComponent } from './update-email-confirm/update-email-confirm.component';
import { UsersService } from 'src/app/services/users/users.service';


const routes: Routes = [
  { path: 'username-update',
    component: UpdateEmailPageComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'confirm',
        component: UpdateEmailConfirmComponent,
        resolve: {
          updateResult: UsersService
        }
      }
    ]
  }
];


@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    UpdateEmailPageComponent,
    UpdateEmailConfirmComponent
  ]
})

export class UpdateEmailModule { }
