import { WordpressService } from '../../cms-manager/wordpress/wordpress.service';
import { StaticResourceService } from '../../services/static-resource/static-resource.service';
import { environment } from 'src/environments/environment';

export class AbstractComponent {

    public fields: any = {};
    public patterns: any = {};
    public env: any;

    constructor(public slug?: string) {
        this.env = environment;

        if (this.slug) {
            this.fields = WordpressService.getPageFields(this.slug);
        } else {
            this.fields = WordpressService.getPageFields();
        }
        this.patterns = StaticResourceService.getPatterns();
    }
}