<div [editSlug]="slug">
  <div class="content-box-inner ng-star-inserted">
   
    <div class="layout-narrow">
      <h2 class="form-title">{{fields.form_title}}</h2>
      <p>{{fields.form_instruction}}</p>
      <p>{{fields.mandatory_label}}</p>
      <p app-test="message-registration-validated" *ngIf="fromValidation">{{fields.validation_success_message}}</p>
      <div class="layout-xsmall">

        <form class="ng-untouched ng-pristine ng-valid"
        [formGroup]="identifyForm"
        app-test="identify-form">

          <div class="form-group">
            <app-textfield
            [label]="fields.customer_id_label"
            controlName="customerId"
            [parentForm]="identifyForm"
            [required]="true"
            [requiredErrorMessage]="fields.required_error_txt"
            [pattern]="patterns.customerId.pattern"
            [patternErrorMessage]="fields.customer_id_pattern_error_message"
            app-test="customer-id-txt"
            [isMail]="true">
            </app-textfield>
          </div>
          <div class="form-group">
            <app-textfield
            [label]="fields.secret_label"
            [isPassword]="true"
            controlName="secret"
            [parentForm]="identifyForm"
            [required]="true"
            [requiredErrorMessage]="fields.required_error_txt"
            app-test="secret-txt">
            </app-textfield>
          </div>

          <ngx-recaptcha2
            [siteKey]="env.captchaKey"
            useGlobalDomain="false"
            size="Normal"
            hl="de"
            theme="Light"
            type="Image"
            [required]="env.captchaFormControl"
            formControlName="recaptcha">
          </ngx-recaptcha2>
          <br/>
        
          <app-form-buttons>
            <div>
              <app-button
              [buttonLabel]="fields.confirm_button_label"
              (buttonClick)="confirm()"
              [disabled]="!identifyForm.valid"
              app-test="confirm-btn">
              </app-button>
            </div>
          </app-form-buttons>
        </form>

      </div>
    </div>

  </div>
</div>