<button
  class="mat-button icon-button"
  disableRipple="true"
  (click)="buttonClick.emit($event)"
  [ngStyle]="{ width: iconWidth, height: iconHeight }"
  [disabled]="disabled"
  type="button"
  tabindex="-1"
>
  <app-icon
    [icon]="icon"
    [ngClass]="'icon-' + icon"
    [iconWidth]="iconWidth"
    [iconHeight]="iconHeight"
    [colorClass]="colorClass"
    [ngStyle]="{ width: iconWidth, height: iconHeight }"
    [description]="description"
  ></app-icon>
</button>
