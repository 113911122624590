import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfigurablePage } from '../../commons/configurable-page';
import { UsersService } from 'src/app/services/users/users.service';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.sass']
})
export class EditPasswordComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public passwordForm: FormGroup;
  public errorOnRequest: any;

  private parentLink = '/customer';
  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private usersService: UsersService,
              private deviceService: DeviceService) {
      super('customer-page');
  }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    this.setErrorObject(false, false, false);

    this.passwordForm = this.fb.group({
      oldPassword: [''],
      newPassword: [''],
      confirmationPassword: ['']
    });

  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_my_profile_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title: this.fields.edit_password_label
    };
  }

  passwordsMatch(): boolean {
    if (!this.passwordForm.controls.confirmationPassword.dirty) {
      return true;
    }

    if (this.passwordForm.value.newPassword === this.passwordForm.value.confirmationPassword) {
      return true;
    } else {
      return false;
    }
  }

  confirm() {
    this.setErrorObject(false, false, false);
    this.usersService.userPasswordUpdated = false;
    this.usersService.editPassword(this.passwordForm.value.oldPassword, this.passwordForm.value.newPassword).subscribe(
      data => {
        this.usersService.userPasswordUpdated = true;
        this.router.navigate(['./success'], { relativeTo: this.route });
      },
      err => {
        if (err.status === 400) {
          this.setErrorObject(false, false, true);
        } else if (err.status === 403) {
          this.setErrorObject(false, true, false);
        } else if (err.status === 500) {
          this.setErrorObject(true, false, false);
        } else {
          this.setErrorObject(true, false, false);
        }
      }
    );
  }

  cancel() {
    this.router.navigate([this.parentLink]);
  }

  private setErrorObject(serverErrorBool: boolean, forbiddenErrorBool: boolean, badRequestBool: boolean) {
    this.errorOnRequest = {
        serverError: serverErrorBool,
        forbiddenError: forbiddenErrorBool,
        badRequest: badRequestBool
    };
  }

}
