<div app-test="contact-us-outcome-page" [editSlug]="slug">

  <app-message-block 
    *ngIf="successRequest"
    [title]="fields.success_title" 
    [message]="fields.contact_us_send_success_message"
    [button]="{
      label : fields.back_to + ' ' + fields.contact_us_page_title
    }"
    (buttonEmitter)="backToContactUs()"
    appTest="contact-us-success">
  </app-message-block>


  <app-message-block 
  *ngIf="successRequest==false"
  [icon]="'icon-circle-close'"
  [message]="fields.contact_us_send_failure_message"
  [button]="{
    label : fields.back_to + ' ' + fields.contact_us_page_title
  }"
  (buttonEmitter)="backToContactUs()"
  appTest="contact-us-failure">
</app-message-block>


</div>