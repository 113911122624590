import { Component, OnInit, Input, HostListener } from "@angular/core";
import { SwitchItem } from "../switch-container/switch-container.component";
import { DeviceService } from "src/app/design-system/services/device.service";

@Component({
  selector: "app-switch-tabs",
  templateUrl: "./switch-tabs.component.html",
  styleUrls: ["./switch-tabs.component.scss"]
})
export class SwitchTabsComponent implements OnInit {
  constructor(private deviceService: DeviceService) {
    this.isPWA = this.deviceService.isPWA;
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }

  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  @Input() isPWA: boolean;

  @Input() selectedItemIndex: number;

  @Input() switchItems: SwitchItem[];

  ngOnInit() {
    this.updatePWA();
  }
}
