import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { MatCheckbox } from "@angular/material/checkbox";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent implements OnInit {
  @Input() appTest: string;

  @ViewChild("matCheckbox", { static: true })
  matCheckbox: MatCheckbox;

  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() labelPosition: "before" | "after";
  @Input() name: string | null;
  @Input() value: string;
  @Input() required: boolean;
  @Input() disabled: any = false;
  @Input() indeterminate: boolean = false;
  @Input() colorClass: "color-white";
  //@Input() tooltipMessage: string;

  @Input() ariaLabel: string;
  @Input() ariaLabelledby: string;

  inputId: string;

  constructor() {}

  ngOnInit() {}

  onChange() {
    //this.checked = this.parentForm.controls[this.controlName].value;
    this.checked = this.matCheckbox.checked;
  }
}
