import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { StepData } from 'src/app/design-system/components/navigation/stepper/stepper.component';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-update-customer-form',
  templateUrl: './update-customer-form.component.html',
  styleUrls: ['./update-customer-form.component.sass']
})
export class UpdateCustomerFormComponent extends AbstractComponent implements OnInit {

  public registrationSteps: StepData[];
  public readonly stepIndex = 1;

  public personalInformationsForm: FormGroup;

  private phone: any;
  private address: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private signupService: SignupService) {
              super('registration-page');
  }

  ngOnInit() {
    if (!this.signupService.initialized) { this.router.navigate(['/login']); }

    if (this.signupService.signupCreated) {
      this.address = this.signupService.customer.addresses.filter((i: { type: string; }) => i.type === 'home')[0]
      || { type: 'home' };
      this.phone = this.signupService.customer.phones.filter((i: { type: string; }) => i.type === 'home')[0]
      || this.signupService.customer.phones.filter((i: { type: string; }) => i.type === 'mobile')[0]
      || { type: 'home' };

      this.personalInformationsForm = this.fb.group({
        zipcode: [this.address.postCode || ''],
        city: [this.address.city || ''],
        address: [this.address.street || ''],
        phone: [this.phone.value || '']
      });
    }

    this.registrationSteps = [
      {
        label: this.fields.step_1_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_2_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_3_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      }
    ];

  }

  back() {
    this.router.navigate(['/registration/check-customer',{referrer:"update-customer"}]);
  }

  continue() {
    this.phone.value = this.personalInformationsForm.value.phone;
    this.address.city = this.personalInformationsForm.value.city;
    this.address.postCode = this.personalInformationsForm.value.zipcode;
    this.address.street = this.personalInformationsForm.value.address;
    this.signupService.updateSignup(this.address, this.phone);
    this.router.navigate(['/registration/update-user']);
  }

}
