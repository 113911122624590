<app-pop-in
[title]="fields.warning_section_title"
icon="icon-circle-warning"
[closingButtonLabel]="fields.cancel_button_label"
[actionButtonLabel]="fields.confirm_button_label"
actionButtonAppTest="edit-payment-modal-action-app-test"
closingButtonAppTest="edit-payment-modal-close-app-test"
app-test="edit-payment-method-modal"
(actionEmitter)="updatePaymentMethod($event)">

  <div [editSlug]="slug">
    <p *ngIf="paymentMethodType == 'directDebit'">{{ fields.warning_section_instruction_direct_debit }}</p>
    <p *ngIf="paymentMethodType == 'paymentStrip'">{{ fields.warning_section_instruction_payment_strip }}</p>
    <ul class="list-with-hyphens" *ngFor="let agreementLabel of agreementLabels">
      <li>{{ agreementLabel }}</li>
    </ul>
  </div>

  <br/>

  <app-error-message
  *ngIf="errorOnRequest.serverError"
  [message]="fields.message_server_error"
  app-test="message-server-error">
  </app-error-message>

  <app-error-message
  *ngIf="errorOnRequest.badRequest"
  [message]="fields.message_bad_request_error"
  app-test="message-bad-request">
  </app-error-message>

</app-pop-in>