import { Component, OnInit, Input } from "@angular/core";

export interface SyntheseItem {
  label: string;
  value: string;
  isLink: boolean;
  link: string;
}

@Component({
  selector: "app-synthese-list",
  templateUrl: "./synthese-list.component.html",
  styleUrls: ["./synthese-list.component.scss"]
})
export class SyntheseListComponent implements OnInit {
  constructor() {}

  @Input() syntheseList: SyntheseItem[];

  ngOnInit() {
    this.syntheseList.forEach( (item, index) => {
      if (!item.label || !item.value) {
        this.syntheseList.splice(index, 1);
      }
    });
  }
}
