import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-body-link",
  templateUrl: "./body-link.component.html",
  styleUrls: ["./body-link.component.scss"]
})
export class BodyLinkComponent implements OnInit {
  constructor() {}

  @Input() href: string;

  ngOnInit() {}
}
