<div [ngClass]="[commercialCards && commercialCards.length > 0 ? 'grid-item grid-item--verybig' : 'grid-item grid-item--fullsize']">
  <div class="content-box" app-test="vehicle-detail-page">

    <div [editSlug]="slug">
            
      <app-vehicule-edition
      [brand]="vehicle.brand.label"
      [model]="vehicle.model.label"
      [registrationDate]="dateFormatter.transform(vehicle.registrationDate)"
      [vin]="vehicle.vin"
      [vehiculeName]="vehicle.name"
      
      [licensePlateNumber]="vehicle.plate"
      
      [image]="vehicle.image"
      [fileMaxSize]="512000"
      [errorMessageNotAllowed]="fields.file_error_not_allowed"
      [errorMessageTooBig]="fields.file_error_too_big"
      
      [title]="fields.detail_card_title"

      [brandLabel]="fields.brand_label"
      [modelLabel]="fields.model_label"
      [registrationDateLabel]="fields.registration_date_label"
      [licensePlateNumberLabel]="fields.licence_plate_label"
      [vinLabel]="fields.vin_label"
      [vehiculeNameLabel]="fields.vehicle_name_label"

      [heading]="fields.image_instruction_label"
      [imageAccepted]="fields.acceptable_image_formats_label"
      [registrationCardLabel]="fields.registration_card_label"

      [confirmLabel]="fields.confirm_button_label"
      [cancelLabel]="fields.cancel_button_label"
      [browseBtnLabel]="fields.browse_button_label"

      (cancel)="back()"
      (saveChanges)="save($event)"
      app-test="vehicle-detail-card">
      </app-vehicule-edition>
      
    </div>

  </div>
</div>
