import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-block',
  templateUrl: './message-block.component.html',
  styleUrls: ['./message-block.component.sass']
})
export class MessageBlockComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() button: any;
  @Input() secondaryButton: any;
  @Input() icon: any;
  @Input() appTest: any;

  @Output() buttonEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondaryButtonEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
