import { Component, OnInit, Input, AfterViewInit} from "@angular/core";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceService } from "../../../services/device.service";

@Component({
  selector: "app-back-to",
  templateUrl: "./back-to.component.html",
  styleUrls: ["./back-to.component.scss"],
  host: { class: "back-to-button" }
})
export class BackToComponent implements OnInit, AfterViewInit {
  @Input() appTest: string;

  @Input() label: string = "Back to login";
  @Input() link: string;

  isIE: boolean;

  constructor(private deviceService: DeviceService,
              private router: Router,
              private location: Location) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.isIE = this.deviceService.isIE;
  }

  public back() {
    if ( this.link !== undefined ) {
      this.router.navigate([this.link]);
    } else {
      this.location.back();
    }
  }
}
