import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-block-contracts",
  templateUrl: "./block-contracts.component.html",
  styleUrls: ["./block-contracts.component.scss"]
})
export class BlockContractsComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() showMoreStatus: "more" | "less";
  @Input() withShowMoreButton: boolean;
  @Input() showMoreLabel: string;
  @Input() showLessLabel: string;

  @Output() showMoreEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() blockContractsData: any[];
  @Output() cardActionEvent: EventEmitter<any> = new EventEmitter<any>();

  cardAction($event, id) {
    if ($event.preventDefault != null) {
      $event.preventDefault();
    }
    console.log($event, "Card clicked", id);
    this.cardActionEvent.emit(id);
  }

  ngOnInit() {}
}
