<span app-test="data-protection-page">
  <div [editSlug]="slug">
    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">

          <h2 class="form-title">{{fields.data_protection_page_title}}</h2>
          <br/>
          <p [innerHtml] = "pageContent1"></p>
          <p id="cookies" app-test="cookies-infos" [innerHtml] = "pageContent2"></p>
          <p [innerHtml] = "pageContent3"></p>

        </div>
      </div>
    </div>
  </div>
</span>