<span app-test="edit-personal-details-page">
  <div [editSlug]="slug">

    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

            <h2 *ngIf="!isPWA" class="form-title">{{fields.edit_personal_details_label}}</h2>
            <p>{{fields.mandatory_label}}</p>

            <form class="ng-untouched ng-pristine ng-valid"
            [formGroup]="customerDetailsForm"
            app-test="customer-details-form">

              <div class="form-group">
                <app-textfield
                [label]="fields.address_textfield_label"
                [parentForm]="customerDetailsForm"
                controlName="street"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [pattern]="patterns.street.pattern"
                [patternErrorMessage]="fields.invalid_error_txt"
                appTest="Address"
                app-test="Address field">
                </app-textfield>
              </div>
              
              <div class="rwd-row">
                <div class="rwd-col-50">
                  <div class="form-group">
                    <app-textfield
                    [label]="fields.city_textfield_label"
                    [parentForm]="customerDetailsForm"
                    controlName="city"
                    [required]="true"
                    [requiredErrorMessage]="fields.required_error_txt"
                    [pattern]="patterns.city.pattern"
                    [patternErrorMessage]="fields.invalid_error_txt"
                    appTest="City"
                    app-test="City field">
                    </app-textfield>
                  </div>
                </div>
                <div class="rwd-col-50">
                  <div class="form-group">
                    <app-textfield
                    [label]="fields.zipcode_textfield_label"
                    [parentForm]="customerDetailsForm"
                    controlName="postcode"
                    [required]="true"
                    [requiredErrorMessage]="fields.required_error_txt"
                    [pattern]="patterns.zipCode.pattern"
                    [patternErrorMessage]="fields.invalid_error_txt"                      
                    appTest="Zipcode"
                    app-test="Zipcode field">
                    </app-textfield>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <app-textfield
                [label]="fields.country_textfield_label"
                [parentForm]="customerDetailsForm"
                controlName="country"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [pattern]="patterns.city.pattern"
                [patternErrorMessage]="fields.invalid_error_txt"
                [disabled]="isPrefilledCountry()"
                [prefilled]="isPrefilledCountry()"
                appTest="Country">
                </app-textfield>
              </div>
              
              <div class="form-group">
                <app-textfield
                [label]="fields.phone_textfield_label"
                [parentForm]="customerDetailsForm"
                controlName="phone"
                [pattern]="patterns.phone.pattern"
                [patternErrorMessage]="fields.invalid_error_phone_label"
                appTest="Phone Number"
                app-test="Phone number field">
                </app-textfield>
              </div>
            </form>

            <app-form-buttons>
              <div>
                <app-button
                buttonType="secondary"
                [buttonLabel]="fields.cancel_button_label"
                (buttonClick)="cancel()"
                app-test="back-btn">
                </app-button>
                <app-button
                [buttonLabel]="fields.confirm_button_label"
                (buttonClick)="confirm()"
                [disabled]="customerDetailsForm.invalid || !customerDetailsForm.dirty"
                app-test="confirm-btn">
                </app-button>
              </div>
            </app-form-buttons>

          </div>
        </div>
      </div>
    </div> 


  </div>
</span>
