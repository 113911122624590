import { Injectable } from "@angular/core";
import { PageLayoutComponent } from "./components/structure/page-layout/page-layout.component";
import { LoginLayoutComponent } from "./components/structure/login-layout/login-layout.component";

@Injectable()
export class Globals {
  currentPageLayout: PageLayoutComponent;
  isLoginPage: boolean;
  updateMasonry() {
    if (this.currentPageLayout) {
      this.currentPageLayout.updateMasonry();
    }
  }
}
