import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from "src/app/features/commons/abstract-component";
import { WordpressService } from "src/app/cms-manager/wordpress/wordpress.service";
import { FaqData } from "src/app/design-system/components/blocks/faq/faq.component";
import { SubjectData } from "src/app/design-system/components/lists/faq-list/faq-list.component";
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component( {
    selector: 'app-faq-content',
    templateUrl: './faq-content.component.html',
    styleUrls: ['./faq-content.component.scss']
} )
export class FaqContentComponent extends AbstractComponent implements OnInit {

    private faqDataList: FaqData[];

    public displayFaqDataList: FaqData[];

    constructor(private layoutService: LayoutService) {
        super("faq-page");
    }

    ngOnInit() {
        this.layoutService.updateMasonry(true);

        const faqDataByCategory = {};
        WordpressService.faqsCategories.forEach( cat => faqDataByCategory[cat.id] = { subject: cat.name, subjectDataList: [] } );
        WordpressService.faqs.forEach( faq => {
            const faqData = faqDataByCategory[faq.faqs_categories[0]];
            faqData.subjectDataList.push( { question: faq.title.rendered, answer: faq.content.rendered });
            }
        );

        this.faqDataList = [];
        for (let i in faqDataByCategory) {
            this.faqDataList.push(faqDataByCategory[i]);
        }
        this.displayFaqDataList = this.faqDataList;
    }

    filter(searchString: string) {
        searchString = searchString.trim().toLowerCase();
        if (!searchString || searchString.length === 0) {
            this.displayFaqDataList = this.faqDataList;
        }
        this.displayFaqDataList = [];
        for (let i in this.faqDataList) {
            let fd = this.faqDataList[i];
            let sdList = fd.subjectDataList.filter(sd=>this.match(sd,searchString));
            this.displayFaqDataList.push({subject:fd.subject, subjectDataList:sdList});            
        }
    }

    // return true if faq contains all tokens of searchString
    private match(sd: SubjectData, searchString: string): boolean {
        const tokens = searchString.split(" ");
        for (let i in tokens) {
            const token = tokens[i];
            if (token.length === 0) {
                continue;
            }
            if (sd.question.toLowerCase().indexOf(token)<0 && sd.answer.toLowerCase().indexOf(token)<0) {
                return false;
            }
        }
        return true;
    }

}
