<mat-list-item
class="link-item"
[ngClass]="{ decorated: decorated }"
(click)="navigate()">
  <span class="link-text" app-test="link-item-text"><ng-content></ng-content></span>
  <div class="link-icons">
    <app-icon
      *ngIf="alert"
      icon="icon-alert-accent"
      iconWidth="30px"
      iconHeight="30px"
    ></app-icon>
    <span *ngIf="arrowed && link" class="chevron"></span>
  </div>
</mat-list-item>
