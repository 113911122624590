<app-contact-button
  *ngIf="!isPWA"
  [isPWA]="false"
  [buttonLabel]="data.closeButtonLabel"
  status="closing"
></app-contact-button>
<app-close-button *ngIf="isPWA"></app-close-button>
<app-title-bar *ngIf="isPWA" [title]="title"></app-title-bar>
<mat-dialog-content>
  <div class="contact-blocks-container">
    <app-contact-block *ngFor="let contackBlock of data.contactBlocksData"
      [appTest]="contackBlock.appTest"
      [icon]="contackBlock.icon"
      [mention]="contackBlock.mention"
      [title]="contackBlock.title"
      [subtitle]="contackBlock.subtitle"
      [buttonLabel]="contackBlock.buttonLabel"
      [buttonLink]="contackBlock.buttonLink"
      [buttonType]="contackBlock.buttonType"
      [disableOnDesktop]="contackBlock.disableOnDesktop"
      [nativeLink]="contackBlock.nativeLink"
      (buttonClick)="data.close()"
    >
      <span [innerHTML]="contackBlock.htmlContent"></span>
    </app-contact-block>
  </div>
</mat-dialog-content>
