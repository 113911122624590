<h2 class="form-title">{{fields.success_title}}</h2>

<app-icon-feedback
icon="icon-circle-message"
colorClass="default-icon-color">
</app-icon-feedback>

<p app-test="message-mail-sent">{{fields.success_text}}</p>

<div class="layout-xsmall">
  <app-form-buttons>
    <div>
      <app-button
      buttonType="secondary"
      [buttonLabel]="fields.login_button_label"
      (buttonClick)="back()"
      app-test="login-btn">
      </app-button>
    </div>
  </app-form-buttons>
</div>