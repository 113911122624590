import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

export interface BlockAction {
  label: string;
  name: string;
  appTest: string;
}

@Component({
  selector: "app-block-actions",
  templateUrl: "./block-actions.component.html",
  styleUrls: ["./block-actions.component.scss"]
})
export class BlockActionsComponent implements OnInit {
  constructor() {}

  @Input() actionList: BlockAction[];

  @Output() actionEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}

  handleActionClick(action: any) {
    //console.log(action);
    this.actionEmitter.emit(action);
  }
}
