<nav mat-tab-nav-bar #matTabNav color="accent" [disableRipple]="true">
  <app-pwa-tab
    *ngFor="let tab of tabBarData"
    [tabData]="tab"
    (tabClickEmitter)="closePwaMenu()"
  ></app-pwa-tab>
  <app-pwa-tab
    *ngIf="menuItems"
    [tabData]="{ icon: 'icon-pwa-more', label:  tabBarMoreLabel}"
    (tabClickEmitter)="togglePwaMenu()"
  ></app-pwa-tab>
</nav>
