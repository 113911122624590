import { Injectable } from "@angular/core";

import Masonry from "masonry-layout";
import { DeviceService } from "./device.service";
import { PageLayoutComponent } from "../components/structure/page-layout/page-layout.component";

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  constructor(private deviceService: DeviceService) {
    this.isIE11 = deviceService.isIE11;
    this.isIE = deviceService.isIE;
  }

  isIE11: boolean;
  isIE: boolean;
  isLoginPage: boolean;

  //MASONRY
  private static _MASONRY_ALL: Masonry;
  private static _MASONRY_NESTED: Masonry;

  masonryAll: Masonry;
  masonryNested: Masonry;
  createMasonry(
    isNested: boolean = false,
    nestedType: "contract" | "vehicule" = "contract"
  ) {
    const suffix: string = isNested ? "--" + nestedType : "";
    const gridSelector: string = ".grid" + suffix;
    const itemSelector: string = ".grid-item" + suffix;
    const sizerSelector: string = ".grid-sizer" + suffix;

    const grid = document.querySelector(gridSelector);

    const _masonry: Masonry = new Masonry(grid, {
      itemSelector: itemSelector,
      columnWidth: sizerSelector,
      percentPosition: true,
      transitionDuration: ".3s"
    });
    //_masonry.once is not fired -> to do: fix it if necessary
    _masonry.once("layoutComplete", () => {
      grid.classList.add("load");
    });

    return _masonry;
  }

  initMasonry(
    nested: boolean = false,
    nestedType: "contract" | "vehicule" = "contract"
  ) {
    this.masonryAll = this.createMasonry();
    LayoutService._MASONRY_ALL = this.masonryAll;
    if (nested) {
      this.masonryNested = this.createMasonry(true, nestedType);
      LayoutService._MASONRY_NESTED = this.masonryNested;
    } else {
      LayoutService._MASONRY_NESTED = null;
    }
  }
  addItems(elements: Array<HTMLElement>, nested: boolean = true) {
    const msnry = nested ? this.masonryNested : this.masonryAll;
    msnry.addItems(elements);
    this.updateMasonry(true);
  }

  updateMasonry(
    nested: boolean = false,
    nestedType: "contract" | "vehicule" = "contract"
  ) {
    //console.log("masonry", this.masonryAll);
    try {
      if ( !this.masonryAll ) {
        this.masonryAll = LayoutService._MASONRY_ALL;
      }
      if ( this.masonryAll && this.masonryAll.destroy ) {
        this.masonryAll.destroy();
      }
      if (nested) {
        if ( !this.masonryNested ) {
          this.masonryNested = LayoutService._MASONRY_NESTED;
        }
        if ( this.masonryNested && this.masonryNested.destroy ) {
          this.masonryNested.destroy();
        }
      }
      this.initMasonry(nested, nestedType);
    } catch (error) {
      console.warn("masonry error: ", error);
    }

    /*if (nested) {
      this.masonryAll.destroy();
      if (!this.masonryNested)
        this.masonryNested = LayoutService._MASONRY_NESTED;
      this.masonryNested.destroy();
      this.initMasonry(nested, nestedType);
    } else {
      setTimeout(this.triggerResize, 500);
    }*/
  }

  reloadItems(nested: boolean = false) {
    this.masonryAll.reloadItems();
    if (nested) {
      this.masonryNested.reloadItems();
    }
  }

  //RESIZE - SCROLL

  private static _FOOTER: Element | HTMLElement;
  private static _HEADER: Element | HTMLElement;
  public static CONTACT_BUTTON: HTMLElement;

  //to  resize the login content on desktop-tablet : the content must heighten enough to push the footer on bottom
  //dont add any other ".content-box" div in the login-signin pages.
  resizeLoginPage(
    header: Element = LayoutService._HEADER,
    footer: Element = LayoutService._FOOTER
  ) {
    if (!footer || !header) {
      return false;
    }
    const height: number = this.getPagesContentHeight(header, footer);
    if (!height) return false;
    const domList: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".login-content .grid  .content-box"
    ) as NodeListOf<HTMLElement>;

    /*domList.forEach(elt => {
      elt.style.minHeight = height + "px";
    });*/

    Array.prototype.forEach.call(domList, function(domElt: HTMLElement) {
      domElt.style.minHeight = height + "px";
    });
  }
  resizePages(
    header: Element = LayoutService._HEADER,
    footer: Element = LayoutService._FOOTER
  ) {
    if (!footer || !header) {
      return false;
    }
    const height: number = this.getPagesContentHeight(header, footer);
    if (!height) return false;
    let elt: HTMLElement = document.querySelector(".page-content > .container");
    if (!elt) return false;
    elt.style.minHeight = height + "px";
    elt = document.querySelector(".page-content > .login-container > .grid");
    if (!elt) return false;
    elt.style.minHeight = height + "px";
  }
  resizeDesktopPages(navMenu: HTMLElement) {
    if (!navMenu) return false;
    const box = navMenu.getBoundingClientRect();
    const height: number = box.bottom;
    if (!height) return false;
    let elt: HTMLElement = document.querySelector(".page-content");
    elt.style.minHeight = height + "px";
  }
  getPagesContentHeight(
    header: Element | HTMLElement = LayoutService._HEADER,
    footer: Element | HTMLElement = LayoutService._FOOTER
  ): number {
    if (!header) return null;
    if (!LayoutService._HEADER) {
      LayoutService._HEADER = header;
      LayoutService._FOOTER = footer;
    }
    const headerHeight: number = header.clientHeight;
    const footerHeight: number = footer.clientHeight;
    return window.innerHeight - headerHeight - footerHeight;
  }

  setContactButtonPosition(footer: Element = LayoutService._FOOTER) {
    if (!LayoutService.CONTACT_BUTTON) return false;
    if (!LayoutService._FOOTER) {
      if (!footer) {
        return false;
      }
      LayoutService._FOOTER = footer;
    }
    const rectTop: number = footer.getBoundingClientRect().top;
    const windowHeight: number = window.innerHeight;
    if (rectTop > windowHeight) {
      LayoutService.CONTACT_BUTTON.style.position = "fixed";
      LayoutService.CONTACT_BUTTON.style.bottom = "0";
      LayoutService.CONTACT_BUTTON.style.top = "auto";
    } else {
      LayoutService.CONTACT_BUTTON.style.position = "absolute";
      LayoutService.CONTACT_BUTTON.style.bottom = "auto";
      LayoutService.CONTACT_BUTTON.style.top = "-38px";
    }

    //contactButton.style.top = Math.min(rectTop, windowHeight) - 38 + "px";
  }

  getDeviceOnResize() {
    const w: number = window.innerWidth;
    let device: string;
    if (w < 768) {
      device = "mobile";
    } else if (w < 1120) {
      device = "tablet";
    } else {
      device = "desktop";
    }
    return device;
  }

  triggerResize() {
    if (this.isIE) {
      const evt = document.createEvent('Events');
      evt.initEvent('resize', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('resize'));
    }
  }

  animate(animFunc, duration, ease = "linear", endFunc = null) {
    const animationDuration = duration ? duration : 500,
      animationEffect = ease || "easeInOut",
      startTime = new Date().getTime();

    let requestId, timeSpent, delta;

    start();
    function _animate() {
      timeSpent = new Date().getTime() - startTime;
      if (animationEffect === "linear") {
        delta = timeSpent / animationDuration;
      } else if (animationEffect === "easeInOut") {
        delta =
          1 / 2 +
          Math.cos(
            (Math.PI * (animationDuration - timeSpent)) / animationDuration
          ) /
            2;
      } else if (animationEffect === "easeOut") {
        delta = 1 - Math.cos(((Math.PI / 2) * timeSpent) / animationDuration);
      } else if (animationEffect === "easeIn") {
        delta = Math.sin(((Math.PI / 2) * timeSpent) / animationDuration);
      }

      if (timeSpent < animationDuration) {
        animFunc(delta);
        requestId = window.requestAnimationFrame(_animate);
      } else {
        // animation end
        animFunc(1);
        stop();
        if (endFunc != null) endFunc();
      }
    }
    function start() {
      requestId = window.requestAnimationFrame(_animate);
    }
    function stop() {
      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }
    }
  }

  scrollToTop(event) {
    if (event.type !== 'scrollTop') {
      event.preventDefault();
    }
    this.scrollToPos(0, 500);
  }

  scrollToPos(scrollEndY, duration, endFunc = null) {
    const scrollInitY = window.scrollY || window.pageYOffset,
      scrollHeight = scrollEndY - scrollInitY,
      x = window.scrollX;

    this.animate(
      function(delta) {
        window.scrollTo(x, scrollInitY + scrollHeight * delta);
      },
      duration,
      "linear",
      endFunc
    );
  }
  scrollToElement(
    element: HTMLElement,
    duration: number = 500,
    endFunc: Function = null
  ) {
    const scrollEndY = element.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
    this.scrollToPos(scrollEndY, duration, endFunc);
  }
}
