<div class="block-help">
  <app-icon
    class="icon"
    [icon]="icon"
    colorClass="color-primary"
    iconWidth="47px"
    iconHeight="47px"
  ></app-icon>

  <h3 class="title">{{ title }}</h3>
  <h4 class="heading">{{ heading }}</h4>
  <p class="telephone">{{ telephone }}</p>
  <p><ng-content></ng-content></p>
</div>
