import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { DeviceService } from "../../../services/device.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-contact-block",
  templateUrl: "./contact-block.component.html",
  styleUrls: ["./contact-block.component.scss"]
})
export class ContactBlockComponent implements OnInit {
  constructor(private deviceService: DeviceService,
              private router: Router) {}
  @Input() appTest: string;

  @Input() icon: string;
  @Input() mention?: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonLabel: string;
  @Input() buttonLink: string;
  @Input() buttonType: string;
  @Input() disableOnDesktop: boolean;
  @Input() nativeLink: string;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("anchor", { static: false }) anchor: ElementRef;

  isMobile: boolean;

  ngOnInit() {
    this.buttonType = this.buttonType || "mat-primary";
    this.isMobile = this.deviceService.isMobile;
  }

  public clickHandler($event) {
    if ( this.buttonLink ) {
      this.router.navigate([this.buttonLink] );
    }
    if ( this.anchor ) {
      this.anchor.nativeElement.click();
    }
    this.buttonClick.emit($event);
  }
}
