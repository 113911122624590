<div class="top-menu-header">
  <app-icon-button
    icon="logo-RCI-connect"
    class="header-logo"
  ></app-icon-button>
  <app-icon-button
    icon="icon-menu-close"
    iconWidth="22px"
    iconHeight="22px"
    colorClass="color-accent"
    class="header-close"
    (buttonClick)="closeMenu()"
  ></app-icon-button>
</div>
<mat-dialog-content>
  <div class="menu-user-name">
    <app-user-name [userName]="userName" [welcomeText]="welcomeText"></app-user-name>
  </div>
  <app-nav-menu [menuItems]="menuItems" (onClick)="closeMenu()">
  </app-nav-menu>
</mat-dialog-content>
