import {
  Component,
  OnInit,
  HostListener,
  Input
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DialogService } from "../../../services/dialog.service";
import { NavMenuMobileComponent } from "../nav-menu-mobile/nav-menu-mobile.component";
import { DeviceService } from "../../../services/device.service";
import { MenuItems } from '../nav-menu/nav-menu.component';
import { NotificationItem } from '../../modals/feed-notification/feed-notification.component';

@Component({
  selector: "app-header-connected",
  templateUrl: "./header-connected.component.html",
  styleUrls: ["./header-connected.component.scss"]
})
export class HeaderConnectedComponent implements OnInit {

  @Input() appTest: string;
  @Input() userName: string;
  @Input() welcomeText: string;
  @Input() menuItems: MenuItems[];
  @Input() alertsTitle: string;
  @Input() notificationItems: NotificationItem[] = [];
  @Input() showInputSearch: boolean = false;

  iconMessage: string = "icon-circle-message";
  iconAlert: string = "icon-circle-bell";
  iconBurger: string;

  // We must switch the icons on mobile - tablet - desktop
  iconMessages: object = {
    mobile: "icon-mobile-message",
    tablet: "icon-tablet-message",
    desktop: "icon-circle-message"
  };
  iconAlerts: object = {
    mobile: "icon-mobile-bell",
    tablet: "icon-tablet-bell",
    desktop: "icon-circle-bell"
  };
  iconBurgers: object = {
    // mobile: "icon-mobile-burger",
    tablet: "icon-tablet-burger"
  };

  header: HTMLElement;
  footer: HTMLElement;

  @Input() isPWA: boolean;

  @Input() notificationCount: number = 0;
  @Input() messageCount: number = 0;

  constructor(
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private deviceService: DeviceService
  ) {
    this.isPWA = this.deviceService.isPWA || this.deviceService.isMobileSize();
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updateIcons(this.layoutService.getDeviceOnResize());
    if (window.innerWidth > 1119) { this.dialogService.close("menu"); }
    this.dialogService.close("feed");
    this.layoutService.resizePages(this.header, this.footer);
    this.updatePWA();
  }

  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  updateIcons(device) {
    this.iconMessage = this.iconMessages[device];
    this.iconBurger = this.iconBurgers[device];
    this.iconAlert = this.iconAlerts[device];
  }

  openMobileMenu() {
    this.dialogService.open(
      "menu",
      NavMenuMobileComponent,
      // DialogComponent,
      {
        ...DialogService.DIALOG_CONFIG.menu,
        data: {
          userName: this.userName,
          menuItems: this.menuItems,
          welcomeText: this.welcomeText
        }
      }
    );
  }

  openNotifications($event) {
    if (this.deviceService.isIE) {
      const evt = document.createEvent('Event');
      evt.initEvent('show-alert', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('show-alert'));
    }
  }

  ngOnInit() {
      this.header = document.querySelector( ".app-header" );
      this.footer = document.querySelector( ".app-footer" );
      this.layoutService.triggerResize();
  }

}

