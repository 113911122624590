<app-nested-masonry-layout
  nestedType="vehicule"
  (showMoreEmitter)="showMoreEmitter.emit($event)"
  [withShowMoreButton]="withShowMoreButton"
  [showMoreStatus]="showMoreStatus"
  [showMoreLabel]="showMoreLabel"
  [showLessLabel]="showLessLabel"
>
  <div
    attr.app-test="vehicle-card-{{vehiculeData.id}}"
    class="grid-item--vehicule"
    *ngFor="let vehiculeData of blockVehiculesData"
  >
    <app-block-vehicule
      [title]="vehiculeData.title"
      [subtitle]="vehiculeData.subtitle"
      [image]="vehiculeData.image"
      [vehiculeInfos]="vehiculeData.vehiculeInfos"
      [commercialText]="vehiculeData.commercialText"
      [link]="vehiculeData.link"
    >
    </app-block-vehicule>
  </div>
</app-nested-masonry-layout>
