<span *ngIf="showSuccessMessage">
  <h2 class="form-title">{{fields.thank_you_title}}</h2>
  <app-icon-feedback
  icon="icon-circle-valid"
  colorClass="default-icon-color">
  </app-icon-feedback>
  <span app-test="message-email-confirmed">
    <p >{{fields.email_changed_successfully_label}}</p>
    <br/>
    <p >{{fields.email_changed_login_instruction_label}}</p>
  </span>
</span>

<span *ngIf="!showSuccessMessage">
  <app-icon-feedback
  icon="icon-circle-close"
  colorClass="default-icon-color">
  </app-icon-feedback>
  <span app-test="message-email-error">
    <p >{{fields.email_changed_successfully_label}}</p>
    <br/>
    <p >{{fields.email_changed_login_instruction_label}}</p>  
  </span>
</span>

<app-form-buttons>
  <div>
    <app-button
    [buttonLabel]="fields.login_button_label"
    (buttonClick)="backToLogin()"
    app-test="back-to-login-btn">
    </app-button>
  </div>
</app-form-buttons>
