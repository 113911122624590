import { Component, OnInit, Input } from "@angular/core";
import { MAT_DATE_FORMATS, DateAdapter } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "./date.adaptater";
import { DeviceService } from "../../../services/device.service";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class DatepickerComponent implements OnInit {
  @Input() appTest: string;

  @Input() value: any;
  @Input() label: string = "Label";
  @Input() placeholder: string = "";
  @Input() hint: string = "Format: DD/MM/YYYY";
  @Input() hasError: boolean; // the error state is dsplayed if there's an error message as a parameter or hasError = true
  @Input() error: string; // the error state is dsplayed if there's an error message as a parameter or id  hasError = true
  // @Input() withClear = true; // false for search input

  //for the controls / to set the date in the form components page
  @Input() name: string;
  @Input() formControlName: any;

  @Input() colorClass: string;

  @Input() minDate: any; //The min date proposed in the calendar.
  @Input() maxDate: any; //The max dateproposed  in the calendar.
  @Input() startAt: any; //The date to open the calendar to initially.
  @Input() disabled: boolean; //Whether the datepicker pop-up should be disabled.
  @Input() opened: boolean; //Whether the calendar is open.
  @Input() startView: "month" | "year" | "multi-year" = "month"; // The view that the calendar should start in.

  isMobile: boolean;

  constructor(private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile;
  }

  formatDate() {
    if (this.value) {
      this.value = new Date(this.value);
    }
  }

  ngOnInit() {
    //if you fill an error message, you don't have to add has Error = true
    //set hasError=true when there's no message
    if (this.error != null) this.hasError = true;
    this.formatDate();
    //you can set the min / max dat in the calendar as a parameter.
    this.minDate = new Date("01/01/2000");
    this.maxDate = new Date("01/01/2030");
  }
}
