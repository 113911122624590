import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

@Component({
  selector: 'app-agreements-content',
  templateUrl: './agreements-content.component.html',
  styleUrls: ['./agreements-content.component.sass']
})
export class AgreementsContentComponent extends AbstractComponent implements OnInit {

  public blockContracts: any[];
  private agreements: any[];
  private alerts: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super('agreements-page');
  }

  readonly AgreementTypeField = {
    CREDIT: 'type_credit',
    LEASING: 'type_leasing',
    BATTERY: 'type_battery_only',
    BALLOON: 'type_credit_residual_value',
    SPECIAL_CREDIT: 'type_special_credit'
  };

  ngOnInit() {
    this.blockContracts = [];

    this.agreements = this.route.snapshot.data.agreements;
    this.alerts = this.route.snapshot.data.alerts;

    this.agreements.forEach((agreement: any) => {
      // Check alerts for LEGAL_DPT and DAMAGE agreements
      agreement.alert = false;
      this.alerts.forEach((alert: any) => {
        if (alert.referenceType === 'agreement' && alert.referenceId === agreement.id ) {
          agreement.alert = true;
        }
      });

      this.blockContracts.push({
        id: agreement.id,
        title: this.getTranslatedAgreementsLabel(this.AgreementTypeField[agreement.type]),
        subtitle: !agreement.vehicle.model ?
          agreement.vehicle.plate :
          agreement.vehicle.model.label,
        agreementNumber: this.getTranslatedAgreementsLabel('number') + ': ' + agreement.label,
        icon: 'icon-circle-car',
        expired: this.isDisabledCard(agreement) ? true : false,
        info: agreement.status === 'CLOSED' ?
              this.getTranslatedAgreementsLabel('closed_message') :
              agreement.status === 'LEGAL_DPT' ?
              this.getTranslatedAgreementsLabel('legal_department_message') :
              agreement.status === 'DAMAGE' ?
              this.getTranslatedAgreementsLabel('damage_message') :
              agreement.type == 'SPECIAL_CREDIT' ?
              this.getTranslatedAgreementsLabel('special_credit_message') :
              agreement.alert ?
              this.getTranslatedAgreementsLabel('alert_message') :
              null,
        alert: this.isDisabledAlert(agreement) ? false : agreement.alert,
        appTest: agreement.label
      });

    });
  }

  isDisabledCard(agreement: any): boolean {
    return ( agreement.status === 'LEGAL_DPT' || 
             agreement.status === 'DAMAGE' || 
             agreement.status === 'CLOSED' || 
             agreement.type === 'SPECIAL_CREDIT' ); //buisness request: disable special credit agreements
  }

  isDisabledAlert(agreement: any): boolean {
    return ( agreement.status === 'LEGAL_DPT' || 
             agreement.status === 'DAMAGE' || 
             agreement.status === 'CLOSED' );
  }

  openDetails(id: string) {
    this.agreements.forEach((agreement: any) => {
      if (this.isDisabledCard(agreement)) {
        return;
      } else if (agreement.id === id) {
        this.router.navigate(['home/agreements/' + id]);
      }
    });
    console.log(id);
  }

  private getTranslatedAgreementsLabel(code: string): string {
    switch (code) {
      case 'title':
        return this.fields.agreement_title;
      case 'number':
        return this.fields.agreement_number;
      case 'status':
        return this.fields.agreement_status;
      case 'start':
        return this.fields.agreement_start_date;
      case 'end':
        return this.fields.agreement_end_date;
      case 'type_credit':
        return this.fields.agreement_type_credit;
      case 'type_leasing':
        return this.fields.agreement_type_leasing;
      case 'type_battery_only':
        return this.fields.agreement_type_battery_only;
      case 'type_credit_residual_value':
        return this.fields.agreement_type_credit_residual_value;
      case 'type_special_credit':
        return this.fields.agreement_type_special_credit;
      case 'status_active':
        return this.fields.agreement_status_active;
      case 'status_closed':
        return this.fields.agreement_status_closed;
      case 'status_suspended':
        return this.fields.agreement_status_suspended;
      case 'closed_message':
        return this.fields.agreement_closed_message;
      case 'legal_department_message':
        return this.fields.agreement_legal_department_message;
      case 'damage_message':
          return this.fields.agreement_damage_message;
      case 'alert_message':
          return this.fields.agreement_alert_message;
      case 'special_credit_message':
          return this.fields.agreement_special_credit_message;
      default:
        return code;
    }
  }

}
