import { Component, OnInit, Injectable } from '@angular/core';
import { SwitchItem } from 'src/app/design-system/components/navigation/switch-container/switch-container.component';
import { AbstractComponent } from '../../commons/abstract-component';

@Component({
  selector: 'app-help-tab',
  templateUrl: './help-tab.component.html',
  styleUrls: ['./help-tab.component.sass']
})
@Injectable({
  providedIn: 'root'
})
export class HelpTabComponent extends AbstractComponent implements OnInit {

  public tabItems: SwitchItem[];

  constructor() {
    super('common-static-fields');
  }

  ngOnInit() {
    this.tabItems = [
      {
        itemTitle: this.fields.help_section_faq_tab_label,
        routerLink: '/help/faq',
        appTest: 'tab-faq-btn'
      },
      {
        itemTitle: this.fields.help_section_contact_us_tab_label,
        routerLink: '/help/contact-us',
        appTest: 'tab-contact-us-btn'
      },
      {
        itemTitle: this.fields.help_section_contact_infos_tab_label,
        routerLink: '/help/contact-infos',
        appTest: 'tab-contact-infos-btn'
      }
    ];
  }
}
