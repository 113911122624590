<div class="feed-container" #feedContainer [ngClass]="kind">
  <div class="feed-header">
    <div class="arrow" #arrow></div>
    <app-close-button
      [colorClass]="isPWA ? 'color-grey-90' : 'color-white'"
    ></app-close-button>
    <h3 class="feed-title">{{ feedTitle }}</h3>
  </div>
  <mat-dialog-content>
    <div class="mat-dialog-content-inner">
      <ul *ngIf="kind === 'notification'">
        <li *ngFor="let itemData of feedItemsData">
          <app-feed-notification
            [title]="itemData.title"
            [text]="itemData.text"
            [buttonLabel]="itemData.buttonLabel"
          >
          </app-feed-notification>
        </li>
      </ul>
      <ul *ngIf="kind === 'message'">
        <li *ngFor="let itemData of feedItemsData">
          <app-feed-message
            [id]="itemData.id"
            [title]="itemData.title"
            [text]="itemData.text"
            [date]="itemData.date"
            (clickEmitter)="messageClickHandler($event)"
          >
          </app-feed-message>
        </li>
      </ul>
      <app-message-tool
        #messageTool
        *ngIf="kind === 'message'"
        [noMessage]="!feedItemsData"
      ></app-message-tool>
    </div>
  </mat-dialog-content>
</div>
