import { Injectable } from '@angular/core';
import { Resource } from '../resource';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticResourceService  {

  constructor(private http: HttpClient) { }

  private static patterns: any;

  public static getPatterns() {
    return StaticResourceService.patterns;
  }

  public init() {
    return new Observable<any>(observer => {
        this.http.get(environment.restBase + '/static/patterns')
          .subscribe(patterns => {
            StaticResourceService.patterns = patterns;
            observer.next(StaticResourceService.patterns);
            observer.complete();
          },
          error => {
            StaticResourceService.patterns = {};
            observer.next(StaticResourceService.patterns);
            observer.complete();
          });
    }).toPromise();
  }
}
