import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-block-help",
  templateUrl: "./block-help.component.html",
  styleUrls: ["./block-help.component.scss"]
})
export class BlockHelpComponent implements OnInit {
  constructor() {}

  @Input() title: string = "Need help? ";
  @Input() heading: string = "Contact us?";
  @Input() telephone: string = "01 02 03 04 05";
  @Input() icon: string = "icon-circle-call";

  ngOnInit() {}
}
