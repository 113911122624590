<div class="app-header">
  <div class="content-box">
    <div class="content-box-inner">
      <div class="effective-container">
        <h1>
          <app-icon-button
            icon="logo-RCI-connect"
            class="header-logo"
            routerLink="/home"
          ></app-icon-button>
          <span>RCI CONNECT</span>
        </h1>
        <app-user-name [userName]="userName" [welcomeText]="welcomeText"></app-user-name>
        <app-input-search class="input-search" *ngIf="showInputSearch"></app-input-search>
      </div>
      <ul class="right-buttons">
        <li>
          <app-notification-button
            type="alert"
            [count]="notificationItems.length"
            [icon]="iconAlert"
            (notificationClickEmitter)="openNotifications($event)"
          ></app-notification-button>
        </li>
        <li *ngIf="iconBurger && !isPWA">
          <app-icon-button
            [icon]="iconBurger"
            class="header-burger"
            (buttonClick)="openMobileMenu()"
          ></app-icon-button>
        </li>
      </ul>
    </div>
  </div>
</div>
