/* eslint-disable prefer-arrow/prefer-arrow-functions, @typescript-eslint/dot-notation, prefer-const, curly, no-var, prefer-arrow/prefer-arrow-functions */

import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, Injectable } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PageLayoutComponent } from "../../structure/page-layout/page-layout.component";
import { TimelineData } from "../../lists/timeline-item/timeline-item.component";
import { Globals } from "src/app/design-system/globals";
import { SelectionModel } from "@angular/cdk/collections";

@Injectable()
export class AppMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
  }
}

export interface AgreementRow {
  alert: { value: boolean };
  agreement: { value: string; text: string };
  type: { value: string; text: string };
  agreementNumber: { value: string; text: string };
  date: { value: string; text: string };
  collapsible: {
    value: string;
    text: string;
    timelineData: TimelineData[];
  };
}
@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: AppMatPaginatorIntl
    }
  ],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class TableComponent implements OnInit, AfterViewInit {
  constructor(private globals: Globals) {}

  //set fullsize at true in tables without right and left padding
  //example pages : disaster claims abd messages, tables after switchContainer (or tabs in PWA)
  @Input() fullsize: boolean;

  //paginator labels translation
  @Input() ofLabel: string = "of";
  @Input() nextPageLabel: string = "Next page";
  @Input() previousPageLabel: string = "Previous page";

  @Input() allText = "all";
  @Input() selectText = "Select";
  @Input() unselectText = "Unselect";

  //messagery table
  @Input() isMessagery: boolean;

  //title of the table
  @Input() caption?: string;

  //the table data
  @Input() columnsData: any[];
  @Input() tableData: any[];
  @Input() dataSource: MatTableDataSource<any>;

  //set as true if you need sorting
  @Input() sort: boolean = false;

  //set as true if you need a pagination
  @Input() usePagination: boolean = false;

  //automatically set as true if there are more rows then the pageSizeOptions
  @Input() hasPagination: boolean = false; // pagination by default

  //if the all the rows of the table are clickable
  @Input() clickableRows: boolean;

  //if you need checkboxes to select rows
  @Input() selectableRows: boolean = false;

  //used in the html to build the table as string[] are needed by mat-table
  columnsLabels: string[];
  columnsIds: string[];

  @Input() expandable: boolean;

  expandedRow: AgreementRow | null;

  //Used to emit event outside the table-component
  @Output() rowActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickEmitter: EventEmitter<any> = new EventEmitter<any>();

  // TABLE PAGINATION
  @ViewChild("paginator", { static: false }) paginator: MatPaginator;
  @Input() pageSizeOptions = [8];

  //SPECIAL TABLE WITH 2 COLUMNS WITH ACTIONS OR NOT (3COL BUT ONE INVISBLE)
  @Input() table2col: boolean;
  //SPECIAL TABLE IBAN WITH 3 COLUMNS WITH ACTIONS OR NOT (4COL BUT ONE INVISIBLE)
  //AND CREDIT CARD TABLES WITH 4 COLUMNS
  @Input() iban3col: boolean;

  //ALIGN THE SECOND COLUMN OF ANY TABLE
  //IT'S ALIGNED WITH IBAN WITH 4 COLUMN
  @Input() secondColAligned: boolean;


  //CUSTOM TABLE ALIGNMENT
  @Input() has3Columns: boolean;
  @Input() has4Columns: boolean;


  //SORT
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  //SELECT

  selection = new SelectionModel<any>(true, []);

  ngAfterViewInit() {
    //To translate or change the paginator labels
    if (this.paginator) {
      const intl = this.paginator._intl;
      intl.nextPageLabel = this.nextPageLabel;
      intl.previousPageLabel = this.previousPageLabel;
      intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
          return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
      };
    }
  }
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.columnsLabels = this.columnsData.map(obj => obj["label"]);
    this.columnsIds = this.columnsData.map(obj => obj["id"]);

    // Add Sorting by default and by objects data values. Don't add if withSort=false
    if (this.sort) {
      this.dataSource.sortingDataAccessor = (
        data: any,
        sortHeaderId: string
      ) => {
        //No time left to change this, but the status column in tables without cards has a different name
        //but in a way it's better to have custom values for each col
        //to do, work to avoid hard coded string names of columns in the tables
        //But this is huge ...
        const rowData = data[sortHeaderId] || data["status"];
        const sortValue =
          typeof rowData === "string"
            ? rowData
            : typeof rowData === "object" && (rowData.value || rowData.text);
        return sortValue;
      };
      this.dataSource.sort = this.matSort;
    }

    this.dataSource.filterPredicate = (data: Element, filter: string) => {
      if (filter === "ok" || filter === "ko") {
        return data["status"].value === filter;
      } else {
        //default filterPredicate action adjusted to data as object
        var dataStr = Object.keys(data)
          .reduce(function(currentTerm, key) {
            // Use an obscure Unicode character to delimit the words in the concatenated string.
            // This avoids matches where the values of two columns combined will match the user's query
            // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
            // that has a very low chance of being typed in by somebody in a text field. This one in
            // particular is "White up-pointing triangle with dot" from
            // https://en.wikipedia.org/wiki/List_of_Unicode_characters
            const str =
              typeof data[key] === "string"
                ? data[key]
                : data[key]["value"] + " " + data[key]["text"];
            return currentTerm + /** @type {?} */ str + "◬";
          }, "")
          .toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        // eslint-disable-next-line jsdoc/no-types
        /** @type {?} */
        var transformedFilter = filter.trim().toLowerCase();

        return dataStr.indexOf(transformedFilter) !== -1;
      }
    };

    // Add Paginator if more than 8 rows (To discuss)
    if (this.dataSource.filteredData.length > this.pageSizeOptions[0]) {
      this.hasPagination = true;
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 0);
    }
  }

  //FUNCTIONS USED IN THE HTML TO DISPLAY CELL DATA

  getCellText(element: any, col: any) {
    if (!element[col.id]) return element[col];
    if (!element[col.id].text) return null;
    if (!element[col.id].text.length) return null;
    return element[col.id].text;
  }
  getCellValue(element: any, col: any) {
    if (!element[col.id]) return null;
    return element[col.id].value;
  }
  getCellAction(element, col) {
    if (!element[col.id].actions) return null;
    return element[col.id].actions;
  }
  //isActionLink is used detects the peculiar grey rows with arrow
  isActionLink(row) {
    return row.action && row.action.actions === "arrow";
  }

  // ACTIONS ON CLICK

  //the click on the icon button app-action-group
  //returns the id of the action : edit, delete or download and the rows data
  rowAction($event, row) {
    //console.log($event, row);
    this.rowActionEmitter.emit(row);
  }

  //the click on the rows of clickable rows tables
  handleRowClick(row) {
    if (this.expandable) {
      this.expandedRow = this.expandedRow === row ? null : row;
      this.globals.currentPageLayout.updateMasonry();
      return true;
    }

    if (!this.isActionLink(row) && !this.clickableRows) return false;

    //console.log(row);
    this.rowClickEmitter.emit(row);
  }

  //for testing or any use
  handleCellClick(a, b) {
    //console.log(a, b);
  }

  //on page change we must update masonry as the dimension of the block changes
  onTablePageChange($event) {
    this.globals.currentPageLayout.updateMasonry();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  noneSelected() {
    return this.selection.selected.length === 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => {
          this.selection.select(row);
        });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? this.unselectText : this.selectText} ${
        this.allText
      } `;
    }
    return `${
      this.selection.isSelected(row) ? this.unselectText : this.selectText
    } row ${row.position + 1}`;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
