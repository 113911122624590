import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';

@Component({
  selector: 'app-update-email-confirm',
  templateUrl: './update-email-confirm.component.html',
  styleUrls: ['./update-email-confirm.component.sass']
})
export class UpdateEmailConfirmComponent extends AbstractComponent implements OnInit {

  showSuccessMessage: boolean;

  constructor(private route: ActivatedRoute, private router: Router) {
    super('customer-page');
  }

  ngOnInit() {
    console.log(this.fields);
    console.log(this.showSuccessMessage);
    this.showSuccessMessage = this.route.snapshot.data.updateResult;
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

}
