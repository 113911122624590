import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurablePage } from '../../commons/configurable-page';
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.sass']
})
export class ClaimsComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  constructor(public sanitizer: DomSanitizer, private layoutService: LayoutService) {
    super('claims-page');
  }

  ngOnInit() {
      this.layoutService.updateMasonry(true);
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_button_label,
      backToLink : '/',
      isSubPage : false,
      isHome : false
    };
    
  }
}
