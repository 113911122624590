<app-page-layout 
  [userName]="displayName"
  [welcomeText]="welcomeText"
  [menuItems]="menu"
  [tabBarData]="tabBarData"
  [tabBarMoreLabel]="tabBarBurgerLabel"
  [footerLinks]="footerLinks"
  [alertsTitle]="alertsTitle"
  [copyright]="footerCopyright"
  [backToLabel]="pageConfig.backToLabel"
  [backToLink]="pageConfig.backToLink"
  [title]="pageConfig.title"
  [isHome]="pageConfig.isHome"
  [isSubPage]="pageConfig.isSubPage"
  [notificationItems]="notificationItems"
  [contactBlocksData]="contactBlocksData"
  [contactBlocksButton]="contactBlocksButton">
  <ng-template #pageContentRef>
    <router-outlet (activate)="updatePageConfig($event)"></router-outlet>
    <router-outlet name="extras"></router-outlet>
  </ng-template>
</app-page-layout>