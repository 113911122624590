<div class="container--nested" [editSlug]="slug" app-test="contact-infos-page">
  <div class="block-section" app-test="general-infos-block">
    <app-block-title [title]="fields.general_informations_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.address_line_1_value"
        [label]="fields.address_textfield_label"
        [multiline]="true"
        appTest="General address">
          {{fields.address_line_1_value}}
          <br/>
          {{fields.address_line_2_value}}
          <br/>
          {{fields.address_line_3_value}}
      </app-block-row>
      <app-block-row
        *ngIf="fields.opening_hours_line_1_value"
        [label]="fields.opening_hours_textfield_label"
        [multiline]="true"
        appTest="General opening hours">
          {{fields.opening_hours_line_1_value}}
          <br/>
          {{fields.opening_hours_line_2_value}}
      </app-block-row>
    </div>
  </div>
  
  <br/>

  <div class="block-section" app-test="customer-service-block">
    <app-block-title [title]="fields.customer_service_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.customer_service_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.customer_service_email_value"
        appTest="Customer service email">
      </app-block-row>
      <app-block-row
        *ngIf="fields.customer_service_phone_value"
        [label]="fields.phone_textfield_label"
        [value]="fields.customer_service_phone_value"
        appTest="Customer service phone">
      </app-block-row>
    </div>
  </div>
  
  <br/>

  <div class="block-section" app-test="customer-service-battery-agreements-block">
    <app-block-title [title]="fields.customer_service_battery_agreements_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.customer_service_battery_agreements_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.customer_service_battery_agreements_email_value"
        appTest="Customer service battery agreement email">
      </app-block-row>
      <app-block-row
        *ngIf="fields.customer_service_battery_agreements_phone_value"
        [label]="fields.phone_textfield_label"
        [value]="fields.customer_service_battery_agreements_phone_value"
        appTest="Customer service battery agreement phone">
      </app-block-row>
    </div>
  </div>
  
  <br/>
  
  <div class="block-section" app-test="exhortation-block">
    <app-block-title [title]="fields.exhortation_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.exhortation_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.exhortation_email_value"
        appTest="Exhortation email">
      </app-block-row>
      <app-block-row
        *ngIf="fields.exhortation_phone_value"
        [label]="fields.phone_textfield_label"
        [value]="fields.exhortation_phone_value"
        appTest="Exhortation phone">
      </app-block-row>
    </div>
  </div>
  
  <br/>
  
  <div class="block-section" app-test="insurance-or-claims-block">
    <app-block-title [title]="fields.insurance_or_claims_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.insurance_or_claims_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.insurance_or_claims_email_value"
        appTest="Insurance or claims email">
      </app-block-row>
      <app-block-row
        *ngIf="fields.insurance_or_claims_phone_value"
        [label]="fields.phone_textfield_label"
        [value]="fields.insurance_or_claims_phone_value"
        appTest="Insurance or claims phone">
      </app-block-row>
    </div>
  </div>
  
  <br/>
  
  <div class="block-section" app-test="legal-department-block">
    <app-block-title [title]="fields.legal_department_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.legal_department_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.legal_department_email_value"
        appTest="Legal department email">
      </app-block-row>
      <app-block-row
        *ngIf="fields.legal_department_phone_value"
        [label]="fields.phone_textfield_label"
        [value]="fields.legal_department_phone_value"
        appTest="Legal department phone">
      </app-block-row>
    </div>
  </div>
  
  <br/>
  
  <div class="block-section" app-test="data-protection-block">
    <app-block-title [title]="fields.data_protection_label"></app-block-title>
    <div class="block-row-container">
      <app-block-row
        *ngIf="fields.data_protection_email_value"
        [label]="fields.email_textfield_label"
        [value]="fields.data_protection_email_value"
        appTest="Data protection email">
      </app-block-row>
    </div>
  </div>  
</div>