import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SignupService } from 'src/app/services/signup/signup.service';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { RegistrationSideHelpComponent } from './registration-side-help/registration-side-help.component';
import { TermOfUseModalComponent } from './term-of-use-modal/term-of-use-modal.component';
import { CheckCustomerFormComponent } from './check-customer-form/check-customer-form.component';
import { UpdateCustomerFormComponent } from './update-customer-form/update-customer-form.component';
import { UpdateUserFormComponent } from './update-user-form/update-user-form.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { RegistrationValidationComponent } from './registration-validation/registration-validation.component';
import { IdentifyCustomerFormComponent } from './identify-customer-form/identify-customer-form.component';
import { NgxCaptchaModule } from 'ngx-captcha';

const routes: Routes = [
  {
    path: 'registration',
    component: RegistrationPageComponent,
    children: [
      {
        path: '',
        component: RegistrationSideHelpComponent,
        outlet: 'sideContent',
        data : {
          bgImage : environment.contentUploads + '/2022/02/img_help_login.jpg'
        }
      },
      {
        path: 'identify-customer',
        component: IdentifyCustomerFormComponent
      },
      {
        path: 'check-customer',
        component: CheckCustomerFormComponent,
        resolve: {
          signupRes: SignupService
        }
      },
      {
        path: 'update-customer',
        component: UpdateCustomerFormComponent
      },
      {
        path: 'update-user',
        component: UpdateUserFormComponent
      },
      {
        path: 'success',
        component: RegistrationSuccessComponent
      },
      {
        path: 'validation',
        component: RegistrationValidationComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    SignupService
  ],
  declarations: [
    RegistrationPageComponent,
    RegistrationSideHelpComponent,
    TermOfUseModalComponent,
    CheckCustomerFormComponent,
    UpdateCustomerFormComponent,
    UpdateUserFormComponent,
    RegistrationSuccessComponent,
    RegistrationValidationComponent,
    IdentifyCustomerFormComponent
  ],
  entryComponents: [
    TermOfUseModalComponent
  ]
})
export class RegistrationModule { }
