<div class="contact-block">
  <app-icon iconHeight="40px" iconWidth="40px" [icon]="icon"></app-icon>
  <h3 class="small-block-title" [attr.app-test]="appTest + '-title'">{{ title }}</h3>
  <h4 *ngIf="subtitle" class="small-block-subtitle">{{ subtitle }}</h4>
  <h4 *ngIf="!subtitle" class="small-block-subtitle">
    <ng-content></ng-content>
  </h4>
  <p *ngIf="mention" class="mention">{{ mention }}</p>
  <app-button
    [buttonType]="buttonType"
    [fullWidth]="true"
    [disabled]="disableOnDesktop && !isMobile"
    [buttonLabel]="buttonLabel"
    [appTest]="appTest + '-btn'"
    (buttonClick)="clickHandler($event)"
    > 
  </app-button>
  <a [attr.href]="nativeLink" style="display: none" #anchor *ngIf="nativeLink"></a>
</div>
