import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '../commons/common-components.module';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';
import { EditDirectDebitComponent } from './edit-direct-debit/edit-direct-debit.component';
import { PaymentMethodContentComponent } from './payment-method-content/payment-method-content.component';
import { EditPaymentMethodSuccessComponent } from './edit-payment-method-success/edit-payment-method-success.component';
import { EditPaymentMethodModalComponent } from './edit-payment-method-modal/edit-payment-method-modal.component';

const routes: Routes = [
  { path: 'payment-method',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PaymentMethodContentComponent,
        resolve: {
          bankDetails : BankDetailsService,
          agreements : AgreementsService
        }
      },
      {
        path: 'direct-debit/edit',
        component: EditDirectDebitComponent,
        resolve: {
          agreements : AgreementsService
        }
      },
      {
        path: 'direct-debit/edit/success',
        component: EditPaymentMethodSuccessComponent
      },
      {
        path: 'payment-slip/edit/success',
        component: EditPaymentMethodSuccessComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    PaymentMethodContentComponent,
    EditDirectDebitComponent,
    EditPaymentMethodModalComponent,
    EditPaymentMethodSuccessComponent
  ]
})
export class PaymentMethodModule { }
