import { Component, OnInit, Input } from "@angular/core";

export interface SubjectData {
  question: string;
  answer: string;
}

@Component({
  selector: "app-faq-list",
  templateUrl: "./faq-list.component.html",
  styleUrls: ["./faq-list.component.scss"],
  host: { class: "faq-list" }
})
export class FaqListComponent implements OnInit {
  constructor() {}
  @Input() formHeading: string = "Was this information helpful?";
  @Input() subjectDataList: SubjectData[] = [
    { question: "Question 1", answer: "Answer 1" },
    { question: "Question 2", answer: "Answer 2" },
    { question: "Question 3", answer: "Answer 3" },
    { question: "Question 4", answer: "Answer 4" }
  ];

  @Input() enableFeedback: boolean = false;
  
  @Input() emptyMessage: string = "Sorry, we didn’t find any result to your question. Please contact our customer service ";
  
  ngOnInit() {}
}
