import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-pwa-form-back-link",
  templateUrl: "./pwa-form-back-link.component.html",
  styleUrls: ["./pwa-form-back-link.component.scss"]
})
export class PwaFormBackLinkComponent implements OnInit {
  constructor() {}

  @Input() backToLink: string;

  ngOnInit() {
    console.log("backToLink", this.backToLink);
  }
}
