import { Component, OnInit } from '@angular/core';
import { SwitchItem } from 'src/app/design-system/components/navigation/switch-container/switch-container.component';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-public-help-tab',
  templateUrl: './public-help-tab.component.html',
  styleUrls: ['./public-help-tab.component.sass']
})
export class PublicHelpTabComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public tabItems: SwitchItem[];

  constructor(private layoutService: LayoutService,) {
    super('common-static-fields');
  }

  ngOnInit() {
    this.layoutService.updateMasonry(true);

    this.tabItems = [
      {
        itemTitle: this.fields.help_section_faq_tab_label,
        routerLink: '/public/help/faq',
        appTest: 'tab-faq-btn'
      },
      {
        itemTitle: this.fields.help_section_contact_us_tab_label,
        routerLink: '/public/help/contact-us',
        appTest: 'tab-contact-us-btn'
      },
      {
        itemTitle: this.fields.help_section_contact_infos_tab_label,
        routerLink: '/public/help/contact-infos',
        appTest: 'tab-contact-infos-btn'
      }
    ];
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_button_label,
      backToLink : '/',
      isSubPage : false,
      isHome : false
    };
  }
}
