import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-fab-button",
  templateUrl: "./fab-button.component.html",
  styleUrls: ["./fab-button.component.scss"],
  host: { class: "app-fab-button" }
})
export class FabButtonComponent implements OnInit {
  constructor() {}

  //default as PWA Contact Button

  @Input() buttonWidth: string = "45px";
  @Input() buttonHeight: string = "45px";
  @Input() icon: string = "icon-pwa-contact";
  @Input() iconWidth: string = "35px";
  @Input() iconHeight: string = "35px";
  //color of the icon
  @Input() colorClass:
    | "color-white"
    | "color-accent"
    | "color-primary"
    | "color-grey-70"
    | "color-grey-90" = "color-white";
  //color of the chip
  @Input() themeColor: "primary" | "accent" | "warn" = "accent";

  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}
}
