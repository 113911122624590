<h2 class="form-title">{{fields.form_title}}</h2>
<p>{{fields.mandatory_label}}</p>
<p>{{fields.form_instruction}}</p>

<div class="layout-xsmall">
  <form class="ng-untouched ng-pristine ng-valid"
  [formGroup]="passwordForgottenForm"
  app-test="password-forgotten-form">

    <div class="form-group">
      <app-textfield
      [label]="fields.email_textfield_label"
      controlName="email"
      [parentForm]="passwordForgottenForm"
      [required]="true"
      [requiredErrorMessage]="fields.required_error_txt"
      [pattern]="patterns.email.pattern"
      [patternErrorMessage]="fields.email_pattern_error_message"
      app-test="email-txt"
      [isMail]="true">
      </app-textfield>
    </div>
    <p>{{fields.email_textfield_hint}}</p>

    <app-error-message *ngIf="error" [message]="fields.message_invalid_user" app-test="message-invalid-username"></app-error-message>
    <br *ngIf="error"/>

    <ngx-recaptcha2
      [siteKey]="env.captchaKey"
      useGlobalDomain="false"
      size="Normal"
      hl="de"
      theme="Light"
      type="Image"
      [required]="env.captchaFormControl"
      formControlName="recaptcha">
    </ngx-recaptcha2>

    <app-form-buttons>
      <div>
        <app-button
        buttonType="secondary"
        [buttonLabel]="fields.back_button_label"
        (buttonClick)="back()">
        </app-button>
        <app-button
        [buttonLabel]="fields.confirm_button_label"
        (buttonClick)="confirm()"
        [disabled]="passwordForgottenForm.invalid"
        app-test="reset-password-request-btn">
        </app-button>
      </div>
    </app-form-buttons>

  </form>
</div>