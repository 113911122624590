import { Component, OnInit, Input, HostListener, EventEmitter, Output } from "@angular/core";
import { DeviceService } from "../../../services/device.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  constructor(private deviceService: DeviceService) {
    this.isPWA = deviceService.isPWA || deviceService.isMobileSize();
  }
  @Input() appTest: string;

  @Input() backToLink: string;
  @Input() isPWA: boolean;

  @Input() cookiesAlert = {
    cookiesAccepted: true,
    message: undefined,
    buttonLabel: undefined,
    linkLabel: undefined,
    linkUrl: undefined,
    editUrl: undefined
  };
  @Output() cookiesClickEmitter: EventEmitter<any> = new EventEmitter<any>();

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }
  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnInit() {}

  acceptCookies($event: any) {
    this.cookiesClickEmitter.emit($event);
    this.cookiesAlert.cookiesAccepted = true;
  }

}
