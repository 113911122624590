import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { EditPaymentMethodModalComponent } from '../edit-payment-method-modal/edit-payment-method-modal.component';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { RoutingStateService } from 'src/app/services/routing-state.service';
import { DeviceService } from 'src/app/design-system/services/device.service';

/* eslint-disable max-len */

@Component({
  selector: 'app-edit-direct-debit',
  templateUrl: './edit-direct-debit.component.html',
  styleUrls: ['./edit-direct-debit.component.sass']
})
export class EditDirectDebitComponent extends AbstractComponent implements OnInit {

  private parentLink: string;
  private backToLabel: string;

  public agreements: any;
  public activeAgreements: any;
  public preselectedAgreements: any;
  public bankAccount: any;

  public editDirectDebitForm: FormGroup;
  public agreementCheckboxesForm: FormGroup;
  public checkboxes = [];

  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routingState: RoutingStateService,
              private fb: FormBuilder,
              private bankDetailsService: BankDetailsService,
              private customerService: CustomerService,
              private dialogService: DialogService,
              private deviceService: DeviceService) {
          super('payment-method-page');
        }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    console.log(this.fields);
    console.log(this.patterns);

    this.agreements = this.route.snapshot.data.agreements;
    this.preselectedAgreements = this.bankDetailsService.agreementIdList;
    this.bankAccount = this.bankDetailsService.updatableBankAccount;

    if (this.bankAccount) {
      this.editDirectDebitForm = this.fb.group({
        accountHolder: [this.bankAccount.accountHolder],
        bankName: [this.bankAccount.bankName],
        iban: [this.bankAccount.iban]
      });
    } else {

      this.editDirectDebitForm = this.fb.group({
        accountHolder: [''],
        bankName: [''],
        iban: ['']
      });

      this.customerService.self().subscribe(
        data => {
          console.log(data);
          if (!data.person) {
            this.router.navigate(['/payment-method']);
          }
          const userName = data.person.firstName + ' ' + data.person.lastName;
          this.editDirectDebitForm.controls.accountHolder.setValue(userName);
        },
        err => {
          console.log(err);
          this.router.navigate(['/payment-method']);
        }
      );

    }

    this.createCheckboxes();

    this.agreementCheckboxesForm = this.fb.group({
      checkboxes: new FormArray([], this.minSelectedCheckboxes(1))
    });

    this.addCheckboxes();

    console.log(this.agreements);
    console.log(this.preselectedAgreements);
    console.log(this.bankAccount);
  }

  getConfig() {
    this.setBackTo();
    return {
      backToLabel : this.backToLabel,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title: this.fields.edit_form_title
    };
  }

  setBackTo() {
    const storedUrl = this.routingState.getStoredUrl();
    if ( storedUrl === undefined ) {
      this.routingState.setStoredUrl( { url: "/home/agreements", label: this.fields.back_to_agreements_button_label } );
    }
    this.backToLabel = this.routingState.getStoredUrl().label;
    this.parentLink =  this.routingState.getStoredUrl().url;
  }

  private createCheckboxes() {
    this.agreements.forEach((agreement: any) => {
      const vehicleInfo = agreement.vehicle.model ?
          agreement.vehicle.model.label :
          this.fields.licence_plate_number_label + ' ' + agreement.vehicle.plate;
      agreement.checkboxLabel = this.fields.agreement_number_label.concat(' ' + agreement.label + ' - ' + vehicleInfo);

      let agreementChecked = false;
      if (this.preselectedAgreements) {
        this.preselectedAgreements.forEach((preselectedAgreementId: string) => {
          if (preselectedAgreementId === agreement.id) {
            agreementChecked = true;
            return;
          }
        });
      }

      if (agreement.status === 'ACTIVE') {
        this.checkboxes.push(
          {
            id: agreement.id,
            label: agreement.checkboxLabel,
            initialValue: agreementChecked,
            appTest: 'agreement-' + agreement.id + '-checkbox'
          }
        );
      }

    });
  }

  private addCheckboxes() {
    this.checkboxes.map((checkbox: any, index: number) => {
      const control = new FormControl(checkbox.initialValue);
      (this.agreementCheckboxesForm.controls.checkboxes as FormArray).push(control);
    });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  private getCheckboxSelections(): string[] {
    return this.agreementCheckboxesForm.value.checkboxes
      .map((value: boolean, index: number) => value ? this.checkboxes[index].id : null)
      .filter((value: boolean) => value !== null);
  }

  private getCheckboxSelectionsLabel(): string[] {
    return this.agreementCheckboxesForm.value.checkboxes
      .map((value: boolean, index: number) => value ? this.checkboxes[index].label : null)
      .filter((value: boolean) => value !== null);
  }

  confirm() {
    const newDirectDebit = {
      ids: this.getCheckboxSelections(),
      data: {
        accountHolder: this.editDirectDebitForm.get('accountHolder').value,
        bankName: this.editDirectDebitForm.get('bankName').value,
        iban: this.editDirectDebitForm.get('iban').value,
      }
    };
    this.bankDetailsService.newDDM = newDirectDebit;
    this.bankDetailsService.agreementLabelsNewDDM = this.getCheckboxSelectionsLabel();
    this.bankDetailsService.selectedPaymentMethod = 'directDebit';
    this.dialogService.open('popin', EditPaymentMethodModalComponent, {...DialogService.DIALOG_CONFIG.popin});
  }

  cancel() {
    if (!this.bankDetailsService.agreementIdList) {
      this.router.navigate([this.parentLink]);
    } else if (this.bankAccount) {
      this.bankDetailsService.selectedIban = this.bankAccount.iban;
      this.router.navigate(['/payment-method']);
    } else {
      this.router.navigate(['/payment-method']);
    }
  }

}
