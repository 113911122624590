import { Component, OnInit, Input } from "@angular/core";

interface Star {
  value: number;
  state: string;
}

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"]
})
export class RatingComponent implements OnInit {
  constructor() {
    //
  }
  @Input() appTest: string;

  private _rate: number = 0;
  public get rate(): number {
    return this._rate;
  }
  @Input()
  public set rate(value: number) {
    this._rate = value;
    this.stars.forEach((star: Star, index: number) => {
      star.state = index < this.rate ? "on" : "off";
    });
  }

  @Input() stars: Star[] = Array.from(Array(5)).map(
    (item: any, index: number) => <Star>{ value: index + 1, state: "off" }
  );

  offIcon: string = "icon-circle-star";
  onIcon: string = "icon-circle-star-on";

  doRate({ value }) {
    this.rate = value;
  }

  ngOnInit() {}
}
