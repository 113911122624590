<div
  class="timeline"
  [ngClass]="{ 'with-show-more-button': withShowMoreButton }"
>
  <app-timeline-item
    *ngFor="let itemData of timelineData"
    [itemData]="itemData"
  >
  </app-timeline-item>
</div>
