<span app-test="customer-details-page">
  <div [editSlug]="slug">

      <div class="grid-item grid-item--fullsize">
        <div class="content-box content-box--form">
          <app-profile-intro
            app-test="profile-infos"
            [fullName]="fullName"
            [bornLabel]="fields.born_label"
            [birthDate]="longDateFormatter.transform(customer.person.birthDate)"
            [text]="fields.error_on_customer_details_label">
          </app-profile-intro>
        </div>
      </div>

      <div class="grid-item grid-item--fullsize">
        <div class="content-box">
          <app-tabs
            #appTabs
            [withMore]="false"
            [selectedTabIndex]="0"
            [tabData]="tabData">
          </app-tabs>

          <br/>

          <div class="block-section" id="personalDetails" app-test="personal-details-container">
            <app-block-title
              #personalDetails
              [title]="fields.personal_detail_label"
              [actionData]="{ id: 'EDIT_PERSONAL_DETAILS', icon: 'edit', label: fields.edit_label }"
              (actionEvent)="performAction($event)"
              app-test="edit-personal-details">
            </app-block-title>
            <div class="block-row-container">
              <app-block-row
                *ngIf="address.street"
                [label]="fields.address_textfield_label"
                [value]="address.street"
                appTest="Address">
              </app-block-row>
              <app-block-row
                *ngIf="address.postCode"
                [label]="fields.zipcode_textfield_label"
                [value]="address.postCode"
                appTest="Zipcode">
              </app-block-row>
              <app-block-row
                *ngIf="address.city"
                [label]="fields.city_textfield_label"
                [value]="address.city"
                appTest="City">
              </app-block-row>
              <app-block-row
                *ngIf="address.country"
                [label]="fields.country_textfield_label"
                [value]="address.country"
                appTest="Country">
              </app-block-row>
              <app-block-row
                *ngIf="phone.value"
                [label]="fields.phone_textfield_label"
                [value]="phone.value"
                appTest="Phone">
              </app-block-row>
            </div>
          </div>

          <br/><br/>

          <div class="block-section" id="accountDetails" app-test="account-details-container">
            <app-block-title
              #accountDetails
              [title]="fields.account_label">
            </app-block-title>
            <div class="block-row-container">
              <app-block-row
                [label]="fields.password_textfield_label"
                value="********"
                [actions]="[{ id: 'EDIT_PASSWORD', icon: 'edit', label: fields.edit_label }]"
                (actionEvent)="performAction($event)"
                appTest="Password"
                app-test="edit-password">
              </app-block-row>
              <app-block-row
                [label]="fields.email_textfield_label"
                [value]="userEmail"
                [actions]="[{ id: 'EDIT_EMAIL', icon: 'edit', label: fields.edit_label }]"
                (actionEvent)="performAction($event)"
                appTest="Email"
                app-test="edit-email">
              </app-block-row>
            </div>
          </div>

        </div>
      </div>

  </div>
</span>

