import { Component, OnInit, Input } from "@angular/core";
import { Router } from '@angular/router';

export interface VehiculeInfos {
  id: string;
  text: string;
  alert?: boolean;
  link: string;
  appTest: string;
}

@Component({
  selector: "app-block-vehicule",
  templateUrl: "./block-vehicule.component.html",
  styleUrls: ["./block-vehicule.component.scss"]
})
export class BlockVehiculeComponent implements OnInit {
  constructor(private router: Router) {}


  defaultImage: string = "app/design-system/assets/images/img-clio-rs.png";

  @Input() image: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() commercialText: string;
  @Input() link: string;

  @Input() vehiculeInfos: VehiculeInfos[];

  ngOnInit() {
    if (!this.image) {
      this.image = this.defaultImage;
    }
  }

  showVehicleDetails() {
    this.router.navigate([this.link]);
  }

}
