import { Component, OnInit, Input } from "@angular/core";
import { Router } from '@angular/router';

export interface LinksInfos {
  link: string;
  text: string;
  alert: boolean;
}

@Component({
  selector: "app-link-item",
  templateUrl: "./link-item.component.html",
  styleUrls: ["./link-item.component.scss"]
})
export class LinkItemComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() appTest: string;
  @Input() link: string;
  @Input() alert: boolean = false;
  @Input() arrowed: boolean = true;
  @Input() decorated: boolean = true;
  @Input() routerLink: string;

  ngOnInit() {}

  navigate() {
    if (this.link) {
      this.router.navigate([this.link]);
    }
  }
}
