<div class="tool-container" [ngClass]="{ 'no-message': noMessage }">
  <div *ngIf="noMessage" class="tool-header">
    <app-icon
      icon="icon-circle-message"
      iconWidth="35px"
      iconHeight="35px"
      colorClass="color-grey-90"
    >
    </app-icon>
    <p class="app-heading">{{ NoMessageText }}</p>
  </div>
  <div class="tool-actions">
    <app-button [isLink]="true" (buttonClick)="showAll()">{{
      showAllLabel
    }}</app-button>
    <app-button>{{ writeLabel }}</app-button>
  </div>
</div>
