import { Component, OnInit, Input, EventEmitter, Output, HostBinding } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"]
})
export class SelectComponent implements OnInit {
  @Input() appTest: string;

  @Input() label = "Label";
  @Input() options: SelectOptions[] = [
    { value: null, viewValue: " Which month ?  ", hint: "DefaultHint" },
    {
      value: "value-0",
      viewValue: "October",
      hint: "October hint"
    },
    {
      value: "value-1",
      viewValue: "November",
      hint: "November hint"
    },
    {
      value: "value-2",
      viewValue: "December",
      hint: "December hint"
    }
  ];

  @Input() selectedOption: SelectOptions;
  @Input() multiple = false; // Enable multiple choices
  @Input() hint: string; // Helper text / hint below input
  @Input() selectedHint: string; // Helper text / hint below input
  @Input() tooltipMessage: string; // the info icon is dsplayed only if there's an info as a parameter
  @Input() hasError: boolean; // the error state is dsplayed if there's an error message as a parameter or hasError = true
  @Input() error: string; // the error state is dsplayed if there's an error message as a parameter or id  hasError = true
  @Input() required: boolean;

  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Output() selectionChange: EventEmitter<SelectOptions|SelectOptions[]> = new EventEmitter<SelectOptions|SelectOptions[]>();
  

  constructor() {}

  ngOnInit() {
    if ( !this.parentForm ) {
      this.controlName = 'field';
      this.parentForm = new FormGroup({
        field: new FormControl(this.selectedOption)
      });
    }
    //if you fill an error message, you don't have to add has Error = true
    //set hasError=true when there's no message
    if (this.error != null) this.hasError = true;
  }
  onSelectionChange(event) {
    if ( event.value instanceof Array ) {
      (event.value as []).forEach(option => {
        this.selectedHint += "   - " + option["hint"];
      });
    } else {
      this.selectedHint = event.value["hint"];
    }
    this.selectionChange.emit(event.value);
  }
}

export interface SelectOptions {
  value: string;
  viewValue: string;
  hint?: string;
}
