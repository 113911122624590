<span app-test="edit-email-page">
  <div [editSlug]="slug">

    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

            <h2 *ngIf="!isPWA" class="form-title">{{fields.edit_email_label}}</h2>
            <p>{{fields.mandatory_label}}</p>

            <form class="ng-untouched ng-pristine ng-valid"
            [formGroup]="emailForm"
            app-test="email-form">

              <div class="form-group">
                <app-textfield
                [label]="fields.new_email_textfield_label"
                controlName="email"
                [parentForm]="emailForm"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [pattern]="patterns.email.pattern"
                [patternErrorMessage]="fields.email_pattern_error_message"
                app-test="New username"
                [isMail]="true">
                </app-textfield>
              </div>

              <div class="form-group">
                <app-textfield
                [label]="fields.new_email_confirm_textfield_label"
                controlName="confirmationEmail"
                [parentForm]="emailForm"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [pattern]="patterns.email.pattern"
                [patternErrorMessage]="fields.email_pattern_error_message"
                [hint]="fields.username_textfield_hint"
                app-test="Confirmation username">
                </app-textfield>
                <mat-hint *ngIf="!emailMatch()" class="mat-hint hint-error" style="color: #d20062 !important">
                  <span app-test="validation-unconfirmed-lbl">{{ fields.emails_match_error_message }}</span>
                </mat-hint>
              </div>

              <div class="form-group">
                <app-textfield
                [label]="fields.password_to_change_email_label"
                controlName="password"
                [parentForm]="emailForm"
                [required]="true"
                [isPassword]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                app-test="Password">
                </app-textfield>
              </div>

              <app-error-message
                *ngIf="errorOnRequest.serverError"
                [message]="fields.message_server_error"
                app-test="message-server-error">
              </app-error-message>
            
              <app-error-message
                *ngIf="errorOnRequest.badRequest"
                [message]="fields.message_bad_request_error"
                app-test="message-bad-request">
              </app-error-message>

              <app-error-message
                *ngIf="errorOnRequest.forbiddenError"
                [message]="fields.message_forbidden_error"
                app-test="message-forbidden-error">
              </app-error-message>

            </form>

            <app-form-buttons>
              <div>
                <app-button
                buttonType="secondary"
                [buttonLabel]="fields.cancel_button_label"
                (buttonClick)="cancel()"
                app-test="back-btn">
                </app-button>
                <app-button
                [buttonLabel]="fields.confirm_button_label"
                (buttonClick)="confirm()"
                [disabled]="emailForm.invalid || !emailMatch()"
                app-test="confirm-btn">
                </app-button>
              </div>
            </app-form-buttons>

          </div>
        </div>
      </div>
    </div>

  </div>
</span>
