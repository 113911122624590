<div class="layout-narrow" *ngIf="showError">

  <h2 class="form-title">{{fields.validation_title}}</h2>

  <ng-container>
    <app-error-message 
      [message]="fields.message_server_error" 
      app-test="message-server-error">
    </app-error-message>
  </ng-container>

  <div class="layout-xsmall">
    <app-form-buttons>
      <div>
        <app-button
        buttonType="secondary"
        [buttonLabel]="fields.login_button_label"
        (buttonClick)="back()"
        app-test="login-btn">
        </app-button>
      </div>
    </app-form-buttons>
  </div>

</div>
