import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input
} from "@angular/core";

@Component({
  selector: "app-password-keyboard",
  templateUrl: "./password-keyboard.component.html",
  styleUrls: ["./password-keyboard.component.scss"]
})
export class PasswordKeyboardComponent implements OnInit, AfterViewInit {
  @Input() appTest: string;

  path = "/assets/images/keyboard/";
  bgImage: string = this.path + "keyboard.png";
  images: number[] = new Array();

  @ViewChild("keyboard", { static: true }) keyboard: ElementRef;
  keyboardElement: Element;

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.setKeyboardHeight();
  }

  constructor() {
    let i = 0;
    while (i < 10) {
      //this.images.push("button_number_" + i + "0.png");
      this.images.push(i);
      ++i;
    }
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.setKeyboardHeight();
  }
  setKeyboardHeight() {
    if (!this.keyboardElement)
      this.keyboardElement = this.keyboard.nativeElement;
    const w: number = this.keyboardElement.clientWidth;
    //use the proportions 606x230 of the image to fix the container height on resize
    //change if you have to change the image dimensions
    const h: number = (w * 230) / 606;
    this.keyboardElement["style"]["height"] = h + "px";
  }
}
