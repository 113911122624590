import { Injectable } from '@angular/core';

import {
  HttpSentEvent, HttpHandler, HttpRequest, HttpHeaderResponse, HttpClient,
  HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpEvent, HttpHeaders
} from '@angular/common/http';

import { HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt/jwt.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable()
export class RestInterceptor implements HttpInterceptor {

  constructor(public http: HttpClient, private router: Router, public authService: JwtService, public tokenHelper: JwtHelperService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {

  if ( req.url.startsWith(environment.restBase) ) {
      req = req.clone({ headers : req.headers
        .append('X-BRAND-IDENTIFIER', environment.brand)
        .append('X-API-VERSION', environment.apiVersion)
        .append('x-Gateway-APIKey', environment.gwApiKey)
      });
    }
  return next.handle(req);
  }

}
