import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeAT from '@angular/common/locales/de-AT';
registerLocaleData(localeAT, 'de-AT');

@Pipe({
    name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {

    static readonly DATE_FMT = 'dd.MM.yyyy';

    private datePipe = new DatePipe('de-AT');

    transform(value: string) {
        value = this.datePipe.transform(value, DateFormatPipe.DATE_FMT);
        return value;
    }
}
