<mat-radio-button
  [name]="name"
  [value]="value"
  [disabled]="disabled"
  [disableRipple]="true"
  [labelPosition]="labelPosition"
  (change)="changeEmitter.emit(value)"
  [checked]="checked"
  [aria-label]="ariaLabel"
  [required]="required"
  [ngClass]="[colorClass]"
>
  {{ label }}</mat-radio-button
>
