<span *ngIf="cookiesAlert && !cookiesAlert.cookiesAccepted">
  <div app-test="cookies-alert" class="cookies-alert">
    <span class="message">
      {{ cookiesAlert.message }}
      <a
        class="link"
        [routerLink]="[cookiesAlert.linkUrl]" fragment="cookies"
        app-test="cookies-link">
        {{ cookiesAlert.linkLabel }}
      </a>
    </span>
    <app-button
      [buttonLabel]="cookiesAlert.buttonLabel"
      (buttonClick)="acceptCookies($event)"
      app-test="cookies-agree-btn">
    </app-button>
  </div>
</span>

<div class="app-header">
  <div class="content-box">
    <div class="content-box-inner">
      <!--div class="content-box-inner-section"-->
      <h1>
        <app-icon-button
          icon="logo-RCI-connect"
          class="header-logo"
          routerLink="/home"
        ></app-icon-button>
        <span>RCI CONNECT</span>
      </h1>

      <!--/div-->
    </div>
  </div>
</div>