import { Pipe, PipeTransform } from '@angular/core';
import { registerLocaleData, getCurrencySymbol, formatCurrency } from '@angular/common';
import localeAT from '@angular/common/locales/de-AT';
registerLocaleData(localeAT, 'de-AT');

@Pipe({
    name: 'currencyFormatPipe',
})
export class CurrencyFormatPipe implements PipeTransform {

    static readonly CURRENCY_FMT = '1.2-2';
    static readonly CURRENCY_LOCALE = 'de-AT';
    static readonly CURRENCY_CODE = 'EUR';

    transform(value: number): string | null {
        return formatCurrency(
            value,
            CurrencyFormatPipe.CURRENCY_LOCALE,
            getCurrencySymbol(CurrencyFormatPipe.CURRENCY_CODE, 'wide'),
            CurrencyFormatPipe.CURRENCY_CODE,
            CurrencyFormatPipe.CURRENCY_FMT
        );
    }
}
