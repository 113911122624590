import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { ShowMoreButtonComponent } from "../../navigation/show-more-button/show-more-button.component";

@Component({
  selector: "app-nested-masonry-layout",
  templateUrl: "./nested-masonry-layout.component.html",
  styleUrls: ["./nested-masonry-layout.component.scss"]
})
export class NestedMasonryLayoutComponent implements OnInit {
  constructor(private layoutService: LayoutService) {}

  @ViewChild("showMoreButton", { static: false }) showMoreButton: ShowMoreButtonComponent;

  @Input() appTest: string;

  //set withShowMoreButton as true if there are more cards (or vehicules in vehicules tab)
  @Input() withShowMoreButton: boolean;
  @Input() showMoreStatus: "more" | "less";
  @Input() showMoreLabel: string;
  @Input() showLessLabel: string;

  @Output() showMoreEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() nestedType: "contract" | "vehicule";

  showHideMore($event) {
    this.showMoreEmitter.emit($event);

    setTimeout(() => {
      this.layoutService.updateMasonry(true, this.nestedType);
      //this.layoutService.triggerResize();
    }, 0);
  }

  ngOnInit() {}
}
