import { Component, OnInit, Input } from "@angular/core";
import { SyntheseItem } from "../../lists/synthese-list/synthese-list.component";

@Component({
  selector: "app-agreement-synthese",
  templateUrl: "./agreement-synthese.component.html",
  styleUrls: ["./agreement-synthese.component.scss"]
})
export class AgreementSyntheseComponent implements OnInit {
  constructor() {}

  @Input() syntheseIcon: string;
  @Input() syntheseTitle: string;
  @Input() syntheseHeading?: string;
  @Input() syntheseList: SyntheseItem[];

  ngOnInit() {}
}
