<div class="amount-list">
  <div class="line-item amont-item" *ngFor="let item of amountList">
    <span class="label">
      {{ item.label }}
    </span>
    <span class="value" attr.app-test="{{item.appTest}}">{{ item.value }}</span>
  </div>
</div>
<div class="total-line">
  <div class="line-item total-item">
    <span class="label">
      {{ total.label }}<span *ngIf="total.mention">*</span>
    </span>
    <span class="value" attr.app-test="{{total.appTest}}">{{ total.value }}</span>
  </div>
</div>
<p *ngIf="total.mention">*{{ total.mention }}</p>
