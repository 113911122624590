import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.sass']
})
export class RegistrationSuccessComponent extends AbstractComponent implements OnInit {

  public fields: any;
  public customerEmail: any;

  constructor(private router: Router,
              private signupService: SignupService) {
    super('registration-page');
  }

  ngOnInit() {
    if (this.signupService.signupCreated) {
      this.customerEmail = this.signupService.username;
    } else {
      this.router.navigate(['/login']);
    }
  }

  back() {
    this.router.navigate(['/login']);
  }

}
