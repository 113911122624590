import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interpolate'
})
export class InterpolatePipe implements PipeTransform {

  transform(template: string, context: any): any {
    Object.keys(context).map((key) => {
        template = template.replace("{{" + key + "}}", context[key]);
    });
    return template;
  }

}
