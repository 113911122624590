<ul>
  <li *ngFor="let text of listData">
    <app-icon
      icon="icon-check-green"
      iconWidth="16px"
      iconHeight="16px"
    ></app-icon>
    <span class="text">{{ text }}</span>
  </li>
</ul>
