import { Component, OnInit, Input, HostListener } from "@angular/core";
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: "app-agreement-alert",
  templateUrl: "./agreement-alert.component.html",
  styleUrls: ["./agreement-alert.component.scss"]
})
export class AgreementAlertComponent implements OnInit {
  constructor(private deviceService: DeviceService) {}

  @Input() buttonLabel?: string;
  @Input() message: string;
  @Input() date: string;

  ngOnInit() {}

  @HostListener("click") onClick() {
    if (this.deviceService.isIE) {
      const evt = document.createEvent('Event');
      evt.initEvent('show-alert', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('show-alert'));
    }
  }

}
