<div class="agreement-alert">
  <div class="alert-info">
    <div class="icon-message">
      <app-icon
        icon="icon-alert-accent"
        iconWidth="30px"
        iconHeight="30px"
      ></app-icon>
      <span class="message">{{ message }}</span
      >&nbsp;
    </div>
    <span class="date">{{ date }}</span>
  </div>
  <app-button
    *ngIf="buttonLabel"
    buttonType="mat-accent"
    [buttonLabel]="buttonLabel"
  ></app-button>
</div>
