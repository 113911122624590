import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SelectOptions } from 'src/app/design-system/components/forms/select/select.component';
import { LayoutService } from 'src/app/design-system/services/layout.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TypenscheinService } from 'src/app/services/typenschein/typenschein.service';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { DeviceService } from 'src/app/design-system/services/device.service';

enum State {
  receiver,
  reason
}

@Component({
  selector: 'app-typenschein',
  templateUrl: './typenschein.component.html',
  styleUrls: ['./typenschein.component.sass'],
  providers: [DateFormatPipe]
})
export class TypenscheinComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public StateEnum = State;
  public actualState = this.StateEnum.receiver;

  public agreement: any;
  public customer: any;

  public receiverOptions: SelectOptions[];
  public selectedReceiverOption: SelectOptions;
  public receiverFormMe: FormGroup;
  public receiverFormOther: FormGroup;

  public reasonOptions: SelectOptions[];
  public selectedReasonOption: SelectOptions;
  public otherReasonText = '';
  public phone: any;

  public isPWA: boolean;

  public errorOnRequest: any;
  public confirmButtonEl: any;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private layoutService: LayoutService,
              private typenscheinService: TypenscheinService,
              private deviceService: DeviceService,
              public sanitizer: DomSanitizer,
              public dateFormatter: DateFormatPipe) {
        super('action-typenschein');
}

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    this.agreement = this.route.snapshot.parent.parent.data.agreement;
    this.customer = this.route.snapshot.data.customer;

    this.setErrorObject(false, false);

    this.receiverOptions = [
      { value: 'ME', viewValue: this.fields.receiver_option_value_me },
      { value: 'DEALER', viewValue: this.fields.receiver_option_value_dealer },
      { value: 'INSURANCE', viewValue: this.fields.receiver_option_value_insurance }
    ];

    // uncomment me if you want to see a presetted receiver option
    this.selectedReceiverOption = this.receiverOptions[0];

    this.phone = this.customer.phones
                 ? ( this.customer.phones.filter((i: { type: string; }) => i.type === 'mobile')[0]
                     || this.customer.phones.filter((i: { type: string; }) => i.type === 'home')[0] )
                 : { type: 'mobile', value: '' };

    this.receiverFormMe = this.fb.group({
      phone: [this.phone.value]
    });

    this.receiverFormOther = this.fb.group({
      receiverName: [''],
      street: [''],
      postCode: [''],
      city: ['']
    });

    this.reasonOptions = [
      { value: 'TYPING', viewValue: this.fields.reason_option_value_typing },
      { value: 'ALTERNATE_LICENSE_PLATE', viewValue: this.fields.reason_option_value_alternate_licence_plate },
      { value: 'CHANGE_ADDRESS_OR_NAME', viewValue: this.fields.reason_option_value_change_address_or_name },
      { value: 'OTHER', viewValue: this.fields.reason_option_value_other }
    ];
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.parent.params.id,
      isSubPage : true,
      isHome : false,
      title : this.fields.typenschein_title_label
    };
  }

  onReceiverChange(receiverType: SelectOptions) {
    this.selectedReceiverOption = receiverType;
    console.log(this.selectedReceiverOption);
    this.layoutService.updateMasonry(true);
  }

  isConfirmButtonDisabled(): boolean {
    if (this.actualState === this.StateEnum.receiver) {
      if (this.selectedReceiverOption.value === 'ME') {
        return this.receiverFormMe.invalid;
      } else {
        return this.receiverFormOther.invalid;
      }
    } else if (this.actualState === this.StateEnum.reason) {
      if (this.selectedReasonOption === undefined) {
        return true;
      } else if (this.selectedReasonOption.value !== undefined && this.selectedReasonOption.value === 'OTHER') {
        if ( this.otherReasonText === undefined || this.otherReasonText.length === 0 ) {
          return true;
        }
      }
      return this.selectedReasonOption === undefined;
    }
  }

  onReasonChange(reasonType: SelectOptions) {
    this.selectedReasonOption = reasonType;
    this.layoutService.updateMasonry(true);
  }

  onTextareaChange(otherReason: string) {
    this.otherReasonText = otherReason;
  }

  confirm(event: any) {
    this.setErrorObject(false, false);
    if (this.actualState === this.StateEnum.receiver) {
      // since this is a multiview component, we need to scroll to the top when the view changes
      window.scroll(0, 0);
      this.actualState = this.StateEnum.reason;
      this.confirmButtonEl = event.target.ownerDocument.activeElement;
      this.confirmButtonEl.blur();
    } else if (this.actualState === this.StateEnum.reason) {
      this.confirmButtonEl = event.target.ownerDocument.activeElement;
      this.confirmButtonEl.disabled = true;
      const carCertificateRequest = this.getCarCertificateRequest();
      this.typenscheinService.sendTypenscheinRequest(this.route.snapshot.parent.parent.params.id, carCertificateRequest)
        .subscribe(
          data => {
            console.log(data);
            this.router.navigate(['/home/agreements/' + this.route.snapshot.parent.parent.params.id + '/typenschein/sent']);
          },
          err => {
            this.confirmButtonEl.disabled = false;
            console.log(err);
            if (err.status === 400) {
              this.setErrorObject(false, true);
            } else if (err.status === 500) {
              this.setErrorObject(true, false);
            } else {
              this.setErrorObject(true, false);
            }
          }
      );
    }
  }

  private setErrorObject(serverErrorBool: boolean, badRequestBool: boolean) {
    this.errorOnRequest = {
        serverError: serverErrorBool,
        badRequest: badRequestBool
    };
  }

  resetErrors() {
    this.errorOnRequest.badRequest = false;
    this.errorOnRequest.serverError = false;
  }

  cancel() {
    if (this.actualState === this.StateEnum.receiver) {
      this.router.navigate(['/home/agreements/' + this.route.snapshot.parent.parent.params.id]);
    } else if (this.actualState === this.StateEnum.reason) {
      // since this is a multiview component, we need to scroll to the top when the view changes
      window.scroll(0, 0);
      this.actualState = this.StateEnum.receiver;
    }
    this.layoutService.updateMasonry(true);
    this.resetErrors();
  }

  private getCarCertificateRequest(): any {
    const request = {
      receiver: this.selectedReceiverOption.value,
      receiverName: this.selectedReceiverOption.value === 'ME' ?
        this.customer.person.firstName + ' ' + this.customer.person.lastName :
        this.receiverFormOther.get('receiverName').value,
      address: null,
      phone: this.selectedReceiverOption.value === 'ME' ?
        this.receiverFormMe.get('phone').value :
        null,
      reason: this.selectedReasonOption.value,
      text: this.selectedReasonOption.value === 'OTHER' ?
        this.otherReasonText :
        null
    };

    if (request.phone != null && request.phone.length === 0) {
      request.phone = null;
    }

    if (this.selectedReceiverOption.value !== 'ME') {
      request.address = {
        street: this.receiverFormOther.get('street').value,
        city: this.receiverFormOther.get('city').value,
        postCode: this.receiverFormOther.get('postCode').value
      };
    }

    return request;
  }

}
