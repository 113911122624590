import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-user-name",
  templateUrl: "./user-name.component.html",
  styleUrls: ["./user-name.component.scss"],
  host: { class: "app-user-name" }
})
export class UserNameComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() userName: string;
  @Input() welcomeText: string = "Welcome";

  ngOnInit() {}
}
