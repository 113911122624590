import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";

@Component({
  selector: "app-block-commercial",
  templateUrl: "./block-commercial.component.html",
  styleUrls: ["./block-commercial.component.scss"]
})
export class BlockCommercialComponent implements OnInit {


  @Input() appTest: string;

  @Input() isMajor: boolean;

  @Input() image: string;
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() text: string;

  @Input() isLink = false;
  @Input() buttonLabel: string;
  @Input() link: string;

  @Input() routerLink: any;
  @Input() branding: "renault";
  @Input() edged: boolean;
  @Input() withFlag: boolean;
  @Input() isChecked: boolean;
  @Input() visualLeft: boolean;
  @Input() imageContain: boolean;

  constructor(private router: Router, public sanitizer: DomSanitizer) {}

  ngOnInit() { }

  buttonClickHandler(event) {
    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    } else if (this.link) {
      window.open(this.link, '_blank');
    }
  }

  // the event is fired only if there's a favorite flag in the card
  isFlagCheckedChangeHandler($event) {
    this.isChecked = $event;
    console.log(this.isChecked);
  }
}
