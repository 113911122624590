import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService extends Resource {

  private agreementIds: string[];
  private preselectedIban: string;

  private updatableBA: any;

  private newDirectDebit: any;
  private agreementsLabelForNewDirectDebit: string[];

  private paymentMethod: string;

  constructor(http: HttpClient) {
    super(http, environment.restBase + '/direct-debits');
  }

  set agreementIdList(agreementIdList: string[]) {
    this.agreementIds = agreementIdList;
  }

  get agreementIdList() {
    return this.agreementIds;
  }

  set selectedIban(preselectedIban: string) {
    this.preselectedIban = preselectedIban;
  }

  get selectedIban() {
    return this.preselectedIban;
  }

  set updatableBankAccount(updatableBA: any) {
    this.updatableBA = updatableBA;
  }

  get updatableBankAccount() {
    return this.updatableBA;
  }

  set newDDM(newDirectDebit: any) {
    this.newDirectDebit = newDirectDebit;
  }

  get newDDM() {
    return this.newDirectDebit;
  }

  set agreementLabelsNewDDM(agreementsLabelForNewDirectDebit: any) {
    this.agreementsLabelForNewDirectDebit = agreementsLabelForNewDirectDebit;
  }

  get agreementLabelsNewDDM() {
    return this.agreementsLabelForNewDirectDebit;
  }

  set selectedPaymentMethod(paymentMethod: any) {
    this.paymentMethod = paymentMethod;
  }

  get selectedPaymentMethod() {
    return this.paymentMethod;
  }

  get(id: string): Observable<any> {
    return this.http
      .get<any>(environment.restBase + '/direct-debits', { params: { agreementId: id } })
      .pipe(catchError(this.handleError<any>('get resource', [])));
  }

  // return [] when an error 400 has been detected resolving the GET
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 400) {
        return of(result as T);
      } else {
        return throwError(() => error);
      }
    };
  }

}
