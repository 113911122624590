import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { UsersService } from 'src/app/services/users/users.service';


@Component({
  selector: 'app-edit-email-success',
  templateUrl: './edit-email-success.component.html',
  styleUrls: ['./edit-email-success.component.sass']
})
export class EditEmailSuccessComponent extends AbstractComponent implements OnInit {


    private parentLink = '/customer';

    constructor(private router: Router,
                private usersService: UsersService) {
        super('customer-page');
    }

    ngOnInit() {
      if ( !this.usersService.userEmailUpdated ) {
        this.router.navigate(['/customer/edit-email']);
      }
      console.log(this.fields);
    }

    getConfig() {
      return {
        backToLabel : this.fields.back_to_my_profile_button_label,
        backToLink : this.parentLink,
        isSubPage : true,
        isHome : false
      };
    }

    backToProfile() {
      this.router.navigate([this.parentLink]);
    }

}