<ng-container *ngIf="selectableRows">
  <div class="actions">
    <mat-checkbox
      (change)="$event ? masterToggle() : null"
      [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()"
      [aria-label]="checkboxLabel()"
      >{{ checkboxLabel() }}</mat-checkbox
    >
    <app-action-group
      *ngIf="!noneSelected()"
      [actionData]="{ action: 'delete', actionName: 'Delete' }"
      [iconLeft]="true"
      [displayNameInMobile]="true"
    ></app-action-group>
  </div>
</ng-container>

<table
  mat-table
  matSort
  [matSortDisabled]="sort ? false : true"
  [ngClass]="[
    'app-table',
    fullsize ? 'fullsize' : '',
    selectableRows ? 'selectable' : '',
    table2col ? 'table-2col' : '',
    iban3col ? 'iban-3col' : '',
    secondColAligned ? 'second-col-aligned' : '',
    has3Columns ? 'table-3-col' : '',
    has4Columns ? 'table-4-col' : ''
  ]"
  [dataSource]="dataSource"
  [multiTemplateDataRows]="expandable"
>
  <caption *ngIf="caption" app-test="table-caption-label">
    <span>{{ caption }}</span>
  </caption>

  <ng-container *ngFor="let col of columnsData">
    <ng-container [matColumnDef]="col.id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ col.label }}
      </th>

      <td
        mat-cell
        *matCellDef="let element"
        [attr.data-parent]="col.label"
        [attr.app-test]="col.appTest || col.id"
        [attr.colspan]="
          col.id === 'holder' && element.action.actions === 'arrow' ? '50' : '1'
        "
        [ngClass]="[
          element.action &&
          element.action.actions !== 'arrow' &&
          element.action.actions.length
            ? 'action-icon'
            : ''
        ]"
        (click)="handleCellClick(col.id, element)"
      >
        <div [ngClass]="[col.id + '-container']">
          <app-icon
            *ngIf="col.id === 'cardType'"
            iconWidth="32px"
            iconHeight="20px"
            [icon]="element.cardType.CCIcon"
          >
          </app-icon>
          <span
            [attr.data-parent]="
              col.id === 'from' || col.id === 'subject' ? col.label + ': ' : ''
            "
            *ngIf="getCellText(element, col)"
            class="cell-text"
            [class.financial-data]="getCellText(element, col).includes('€')"
          >
            {{ getCellText(element, col) }}
          </span>
          <app-icon
            ngClass="alert-icon"
            *ngIf="col.id === 'alert' && getCellValue(element, col) === true"
            icon="icon-alert-accent"
            iconWidth="30px"
            iconHeight="30px"
          ></app-icon>
          <span
            class="chevron"
            *ngIf="
              (col.id === 'holder' && isActionLink(element)) ||
              col.id === 'link' ||
              col.id === 'collapsible'
            "
          ></span>
          <div
            class="action-groups"
            *ngIf="
              col.id === 'action' && getCellAction(element, col) !== 'arrow'
            "
          >
            <app-action-group
              *ngFor="let actionData of getCellAction(element, col)"
              [actionData]="actionData"
              (actionEmitter)="rowAction($event, element)"
              [displayNameInMobile]="true"
            ></app-action-group>
          </div>
          <mat-checkbox
            *ngIf="col.id === 'select'"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
            [aria-label]="checkboxLabel(element)"
          >
          </mat-checkbox>
        </div>
      </td>
    </ng-container>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container *ngIf="expandable">
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsIds.length">
        <div
          class="expanded-detail"
          [@detailExpand]="element === expandedRow ? 'expanded' : 'collapsed'"
        >
          <app-timeline
            *ngIf="element.collapsible && element.collapsible.timelineData"
            [timelineData]="element.collapsible.timelineData"
          ></app-timeline>
        </div>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsIds"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnsIds"
    [ngClass]="[
      row.action && row.action.actions === 'arrow' ? 'row-arrow' : '',
      clickableRows ? 'row-link' : '',
      row.collapsible ? 'row-collapsible' : '',
      row.content && row.content.unread ? 'unread' : ''
    ]"
    [class.expanded-row]="expandedRow === row"
    (click)="handleRowClick(row)"
    attr.app-test="{{row.appTest}}"
  ></tr>
  <ng-container *ngIf="expandable">
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="expanded-detail-row"
    ></tr
  ></ng-container>
</table>

<mat-paginator
  #paginator
  *ngIf="usePagination && hasPagination"
  [showFirstLastButtons]="true"
  [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="false"
  (page)="onTablePageChange($event)"
  [ngClass]="['app-table-paginator', isMessagery ? 'messagery' : '']"
></mat-paginator>
