import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentStripService extends Resource {

  constructor(http: HttpClient) {
    super(http, environment.restBase + '/payment-methods/payment-strip');
    this.manageError = false;
  }

}
