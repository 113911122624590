<app-switch-container
  *ngIf="!isPWA"
  [switchItems]="switchItems"
  [selectedItemIndex]="selectedItemIndex"
>
</app-switch-container>
<app-tabs
  *ngIf="isPWA"
  [switchItems]="switchItems"
  [selectedTabIndex]="selectedItemIndex"
>
</app-tabs>
