import { Component, OnInit, Input } from "@angular/core";
import { LinksInfos } from "../link-item/link-item.component";

@Component({
  selector: "app-links-list",
  templateUrl: "./links-list.component.html",
  styleUrls: ["./links-list.component.scss"]
})
export class LinksListComponent implements OnInit {
  constructor() {}

  @Input() linksInfos: LinksInfos[];

  ngOnInit() {}
}
