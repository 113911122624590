<div [editSlug]="slug">
  <div class="grid-item grid-item--fullsize" app-test="settlement-page">
    <div class="content-box content-box--form">
      <div class="content-box-inner">
        <div class="layout-narrow">
          <h2 *ngIf="!isPWA && agreement.type === 'LEASING'" class="form-title" app-test="settlement-leasing-title">{{fields.settlement_title_leasing_label}}</h2>
          <h2 *ngIf="!isPWA && agreement.type === 'CREDIT'" class="form-title" app-test="settlement-credit-title">{{fields.settlement_title_credit_ballon_label}}</h2>
          <h2 *ngIf="!isPWA && agreement.type === 'BALLOON'" class="form-title" app-test="settlement-balloon-title">{{fields.settlement_title_credit_ballon_label}}</h2>

          <span *ngIf="settlement">
            <p>
              {{fields.settlement_first_description_text}} <span app-test="valid-to-date-label">{{settlement.validUntil | dateFormatPipe}}</span> 
              {{fields.settlement_second_description_text}} <span app-test="last-installment-date-label">{{settlement.lastInstallmentDate | dateFormatPipe}}</span>
            </p>
            <span *ngIf="settlement.nextInstallmentDate">
              <h3 class="app-heading">{{fields.settlement_alert_title}}</h3>
              <p>
                {{fields.settlement_first_alert_text}} <span app-test="next-installment-date-label">{{settlement.nextInstallmentDate | dateFormatPipe}}</span>
                <span *ngIf="settlement.nextInstallmentType==='RESIDUAL_VALUE'" app-test="type-residual-value-label"> ({{fields.settlement_next_installment_type_residual_value}})</span>
                <span *ngIf="settlement.nextInstallmentType==='NORMAL'" app-test="type-normal-rate-label"> ({{fields.settlement_next_installment_type_normal}})</span>
              </p>
            </span>
  
            <app-emphasis-list app-test="settlement-amounts-list"
              [emphasisData]="{
                  amountList: [],
                  total:
                    {
                      label: fields.settlement_termination_figure_label,
                      value: currencyFormatter.transform(settlement.terminationFigure),
                      mention: fields.settlement_termination_figure_explanation,
                      appTest: 'Termination Figure'
                    }
                }">
            </app-emphasis-list>
            <p>
              <a [routerLink]="['./payment-info']" app-test="payment-information-lnk" class="link-body-text">{{fields.payment_information_button}}</a>
            </p>
          </span>

          <app-error-message
            *ngIf="!settlement"
            [message]="fields.message_settlement_not_available"
            app-test="message-server-error">
          </app-error-message>
          
          <app-form-buttons>
            <app-button
              buttonType="secondary"
              [buttonLabel]="fields.cancel_button_label"
              [routerLink]="['/home','agreements', agreement.id]">
            </app-button>
            <app-button
              *ngIf="settlement"
              [buttonLabel]="fields.post_button_label"
              [routerLink]="['./sent']"
              app-test="send-settlement-request-btn">
            </app-button>
          </app-form-buttons>
        </div>
      </div>
    </div>
  </div>
</div>