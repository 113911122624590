import {
  Component,
  OnInit,
  Input,
  HostBinding,
  HostListener
} from "@angular/core";
import { DeviceService } from "src/app/design-system/services/device.service";

@Component({
  selector: "app-title-bar",
  templateUrl: "./title-bar.component.html",
  styleUrls: ["./title-bar.component.scss"]
})
export class TitleBarComponent implements OnInit {
  constructor(private deviceService: DeviceService) {
    this.isPWA = this.deviceService.isPWA || this.deviceService.isMobileSize();
  }

  @Input() title: string;
  @Input() backToLink: string;
  @Input() isSubPage: boolean;
  @Input() withPictoOffset: boolean;
  @Input() underlinedTitle: boolean;
  @Input() withFlag: boolean;
  @Input() isChecked: boolean;
  @Input() isPWA: boolean;

  @HostBinding("class.with-back-button") @Input() hasBackButton: boolean;

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    const pwa = this.isPWA;
    this.updatePWA();
    if (this.isPWA != pwa) {
      this.updateBackButton();
    }
  }
  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnInit() {
    this.updateBackButton();
  }
  updateBackButton() {
    this.hasBackButton = this.isPWA && this.isSubPage;
  }
  //the event is fired only if there's a favorite flag in the card
  isFlagCheckedChangeHandler($event) {
    this.isChecked = $event;
    console.log(this.isChecked);
  }
}
