import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeAT from '@angular/common/locales/de-AT';
registerLocaleData(localeAT, 'de-AT');

@Pipe({
    name: 'ibanFormatPipe',
})
export class IbanFormatPipe implements PipeTransform {

    transform(value: string) {
        value = value.split(' ').join('').replace(/(.{4})/g, "$1 ");
        return value;
    }
}
