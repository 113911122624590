<app-button
  class="show-more-button"
  buttonType="default"
  [icon]="
    showMoreStatus === 'less'
      ? 'icon-less-fill-circle'
      : 'icon-more-fill-circle'
  "
  colorClass="color-grey-90"
  (mouseup)="removeBlur($event)"
  (click)="clickEmitter.emit(showMoreStatus)"
  iconHeight="20px"
  iconWidth="20px"
>
  {{ showMoreStatus === "less" ? showLessLabel : showMoreLabel }}
</app-button>
