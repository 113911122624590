import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettlementService implements Resolve<any> {

  constructor(private http: HttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ( route.routeConfig.path === 'sent' ) {
      return this.sendSettlementRequest(route.parent.parent.params.id);
    }
    if ( route.routeConfig.path === 'settlement' ) {
      return this.settlementComputation(route.parent.params.id);
    }

  }

  settlementComputation(agreementId: string): Observable<any> {
    const url: string = environment.restBase + '/actions/' + agreementId + '/settlement';
    return this.http.get<any>(url)
    .pipe(
      map(res => {
        console.log(res);
        return res;
      }),
      catchError(this.handlesettlementComputationError<any>(undefined))
    );
  }

  public handlesettlementComputationError<T>(result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  sendSettlementRequest(agreementId: string) {
    const url: string = environment.restBase + '/actions/' + agreementId + '/settlement/request';
    return this.http.post<any>(url, {}).subscribe(
      data => {
        return true;
      },
      error => {
        return false;
      }
    );
  }

}
