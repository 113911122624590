import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { DeviceService } from 'src/app/design-system/services/device.service';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.sass']
})
export class RegistrationPageComponent extends AbstractComponent implements OnInit {

  public backLink = '/login';
  public isPWA = false;
  public footerLinks: MenuItems[];
  public footerCopyright: string;
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  constructor(
    private deviceService: DeviceService,
    public cookiesService: CookiesService,
    private pageLayoutConfigService: PageLayoutConfigService
    ) {
    super('registration-page');
  }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;
    this.footerLinks = this.pageLayoutConfigService.publicFooterLinks;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.publicContactBlocksData;
  }

}
