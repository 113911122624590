<a
  href="#"
  class="app-card"
  [ngClass]="{
    expired: expired,
    alert: alert,
    pending: pending
  }"
  (click)="cardButtonClick.emit($event)">

  <app-icon
    *ngIf="alert"
    iconWidth="30px"
    iconHeight="30px"
    class="alert-icon"
    [icon]="alertIcon"
    app-test="alert-icon">
  </app-icon>

  <app-icon
    class="icon"
    [icon]="icon"
    colorClass="default-icon-color"
    iconWidth="40px"
    iconHeight="40px">
  </app-icon>

  <h2 class="title" app-test="title">{{ title }}</h2>
  <h3 class="subtitle" app-test="subtitle">{{ subtitle }}</h3>
  <p *ngIf="agreementsCount" class="agreements-count-label">
    <span> {{ agreementsCountLabel }}</span>
    <span *ngIf="agreementsCount" class="agreements-count">
      {{ agreementsCount }}
    </span>
  </p>

  <p *ngIf="displayAgreement" class="agreement-data-text">
    <span *ngIf="agreementNumber"> {{ agreementNumberLabel }} {{ agreementNumber }} </span>
    <span *ngIf="startDate" class="start-date-text"> {{ startDate }} </span>
    <span *ngIf="endDate" class="end-date-text"> {{ endDate }} </span>
  </p>
  <br>
  <p *ngIf="info" [ngClass]="[alert ? 'alert-text' : 'info-text']">
    <span app-test="info"> {{ info }} </span>
  </p>

  <div class="button-container" *ngIf="hasButton">
    <app-button
      buttonLabel="{{ buttonLabel || 'Renew' }}"
      buttonType="mat-accent"
      [isLink]="buttonIsLink"
      (buttonClick)="cardButtonClick.emit($event)">
    </app-button>
  </div>

</a>
