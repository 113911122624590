import { Component, OnInit } from '@angular/core';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { ConfigurablePage } from '../configurable-page';
import { AbstractComponent } from '../abstract-component';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-public-page',
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.sass']
})
export class PublicPageComponent extends AbstractComponent implements OnInit {

  public pageConfig: any = {
    isSubPage: false,
    backToLabel: '',
    backToLink: ''
  };
  public footerLinks: MenuItems[];
  public footerCopyright: string;
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  constructor(private layoutService: LayoutService,
              private pageLayoutConfigService: PageLayoutConfigService,
              public cookiesService: CookiesService) {
    super('page-layout');
  }

  ngOnInit() {
    this.footerLinks = this.pageLayoutConfigService.publicFooterLinks;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.publicContactBlocksData;
  }

  updatePageConfig(page: ConfigurablePage) {
    if ( page.getConfig ) {
      this.pageConfig = Object.assign(this.pageConfig, page.getConfig());
    } else {
      this.pageConfig = {
        isSubPage: false,
        backToLabel: '',
        backToLink: ''
      };
    }

    this.layoutService.updateMasonry(true);
  }

}
