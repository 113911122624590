import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { VehiculeInfos } from 'src/app/design-system/components/blocks/block-vehicule/block-vehicule.component';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

@Component({
  selector: 'app-vehicles-content',
  templateUrl: './vehicles-content.component.html',
  styleUrls: ['./vehicles-content.component.sass']
})
export class VehiclesContentComponent extends AbstractComponent implements OnInit {

  public vehiclesData: object[] = [];

  constructor(private route: ActivatedRoute) {
      super('vehicles-page');
    }

  ngOnInit() {
    this.route.snapshot.data.vehicles.forEach((vehicle: any) => {
      const vehicleData = {
        id: vehicle.id,
        link: '/home/vehicles/' + vehicle.id,
        title: vehicle.name ?
          vehicle.name :
          vehicle.model ?
          vehicle.model.label :
          this.fields.missing_model_info,
        subtitle: vehicle.plate ?
          this.fields.licence_plate_number_label + ' ' + vehicle.plate :
          this.fields.licence_plate_number_wait_label,
        image : vehicle.image,
        vehiculeInfos: this.getAgreementsInfo(vehicle),
        // add text in commercialText, if needed
        // commercialText: ''
      };
      console.log(vehicleData);
      this.vehiclesData.push(vehicleData);

    });
  }

  private getAgreementsInfo(vehicle: any): VehiculeInfos[] {
    const agreementsInfo: VehiculeInfos[] = [];
    if (vehicle.agreements) {
      vehicle.agreements.forEach((agreement: any) => {
        let agreementLink = ( agreement.status === 'LEGAL_DPT' || 
                              agreement.status === 'DAMAGE' || 
                              agreement.status === 'CLOSED' || 
                              agreement.type === 'SPECIAL_CREDIT' ) ? undefined : '/home/agreements/' + agreement.id;
        const agreementInfo = {
          id: agreement.id,
          link: agreementLink,
          text: this.getTranslatedAgreementType(agreement.type) + ' n. ' + agreement.label,
          alert: false,
          appTest: agreement.label
        };
        agreementsInfo.push(agreementInfo);
      });
    }
    return agreementsInfo;
  }

  private getTranslatedAgreementType(type: string): string {
    switch (type) {
      case 'CREDIT':
        return this.fields.financing_agreement_label;
      case 'LEASING':
        return this.fields.leasing_agreement_label;
      case 'BATTERY':
        return this.fields.battery_rental_agreement_label;
      case 'BALLOON':
        return this.fields.credit_residual_value_agreement_label;
      case 'SPECIAL_CREDIT':
          return this.fields.special_credit_agreement_label;
      default:
        return type;
    }
  }

}
