import { Component, OnInit, Input } from "@angular/core";
import { TimelineData } from "../timeline-item/timeline-item.component";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"]
})
export class TimelineComponent implements OnInit {
  constructor() {}

  @Input() timelineData: TimelineData[];
  @Input() withShowMoreButton: boolean;

  ngOnInit() {}
}
