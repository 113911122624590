<div class="switch-container-list-holder" [ngClass]="{ open: open }">
  <ul class="switch-container-list" [ngClass]="{ open: open }">
    <li
      *ngFor="let item of switchItems; let i = index"
      [ngClass]="{ selected: rla.isActive }"
    >
      <a
      (click)="itemClickHandler($event, i)"
      [routerLink]="item.routerLink"
      routerLinkActive
      #rla="routerLinkActive"
      [attr.app-test]="item.appTest">
        <app-switch-container-item
          [title]="item.itemTitle"
          [index]="i"
          [selected]="rla.isActive"
          [ngClass]="{ open: open }"
        >
        </app-switch-container-item>
      </a>
    </li>
  </ul>
</div>
