import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';

@Component({
  selector: 'app-registration-side-help',
  templateUrl: './registration-side-help.component.html',
  styleUrls: ['./registration-side-help.component.sass']
})
export class RegistrationSideHelpComponent extends AbstractComponent implements OnInit {

  public bgImage: string;
  public postContent: SafeHtml;

    constructor(private route: ActivatedRoute,
                private sanitizer: DomSanitizer) { 
                super('registration-page');
              }

    ngOnInit() {
      this.bgImage = this.route.snapshot.data.bgImage;
      this.postContent = this.sanitizer.bypassSecurityTrustHtml(this.fields.side_help_text);
    }

}
