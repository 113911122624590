<div class="agreement-credit">
  <div class="progress-credit">
    <app-progress-credit [progressValue]="progressValue">
      {{ label }}:
      <span class="value">
        <span app-test="current installment number">{{ agreement.currentInstallmentNumber }} </span>
        <span> {{ of }} </span>
        <span app-test="total installments number">{{ agreement.totalInstallmentsNumber }}</span>
      </span>
      <span *ngIf="date">
        <br />
        <span app-test="next installment date">{{ date }}</span>
      </span>
    </app-progress-credit>
  </div>
  <div class="infos">
    <div class="label-value" *ngFor="let info of infos">
      <span class="label">{{ info.label }} </span>
      <span class="value">{{ info.value }}</span>
    </div>
  </div>
</div>