import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-commercial',
  templateUrl: './card-commercial.component.html',
  styleUrls: ['./card-commercial.component.scss']
})
export class CardCommercialComponent implements OnInit {

  @Input() enlarge = false;

  @Input() image: string;
  @Input() title: string;
  @Input() text: string;

  @Input() isLink = false;
  @Input() buttonLabel: string;
  @Input() link: string;

  constructor() { }

  ngOnInit() {
  }

}
