import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfigurablePage } from '../../commons/configurable-page';
import { UsersService } from 'src/app/services/users/users.service';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.sass']
})
export class EditEmailComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public emailForm: FormGroup;
  public errorOnRequest: any;

  private parentLink = '/customer';
  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private usersService: UsersService,
              private deviceService: DeviceService) {
      super('customer-page');
  }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    this.setErrorObject(false, false, false);

    this.emailForm = this.fb.group({
      email: [''],
      confirmationEmail: [''],
      password: ['']
    });

    }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_my_profile_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title: this.fields.edit_email_label
    };
  }

  emailMatch(): boolean {
    if (!this.emailForm.controls.confirmationEmail.dirty) {
      return true;
    }

    if (this.emailForm.value.email === this.emailForm.value.confirmationEmail) {
      return true;
    } else {
      return false;
    }
  }


  confirm() {
    this.setErrorObject(false, false, false);
    this.usersService.userEmailUpdated = false;
    this.usersService.editUsername(this.emailForm.value.password, this.emailForm.value.email).subscribe(
      data => {
        this.usersService.userEmailUpdated = true;
        this.router.navigate(['./success'], { relativeTo: this.route });
      },
      err => {
        if (err.status === 400) {
          this.setErrorObject(false, false, true);
        } else if (err.status === 403) {
          this.setErrorObject(false, true, false);
        } else if (err.status === 500) {
          this.setErrorObject(true, false, false);
        } else {
          this.setErrorObject(true, false, false);
        }
      }
    );
  }

  cancel() {
    this.router.navigate([this.parentLink]);
  }

  private setErrorObject(serverErrorBool: boolean, forbiddenErrorBool: boolean, badRequestBool: boolean) {
    this.errorOnRequest = {
        serverError: serverErrorBool,
        forbiddenError: forbiddenErrorBool,
        badRequest: badRequestBool
    };
  }

}

