import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter
} from "@angular/core";
import { MatTabLink } from "@angular/material/tabs";

export interface TabData {
  icon?: string;
  label: string;
  routerLink?: any;
}

@Component({
  selector: "app-pwa-tab",
  templateUrl: "./pwa-tab.component.html",
  styleUrls: ["./pwa-tab.component.scss"]
})
export class PwaTabComponent implements OnInit {

  constructor() {}

  @ViewChild("tab", { static: true }) tab: MatTabLink;

  @Input() tabData: TabData;

  @Input() active: boolean;

  @Output() tabClickEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}
}
