<div class="item-block">
  <div class="infos">
    <span class="title" *ngIf="itemData.title">{{ itemData.title }}</span>
    <span class="date">{{ itemData.date }}</span>
    <span>{{ itemData.info }}</span
    ><app-button
      *ngIf="itemData.alert"
      [buttonLabel]="buttonLabel"
      buttonType="mat-accent"
    ></app-button>
  </div>
  <app-icon
    class="item-icon"
    [icon]="getIcon(itemData.status)"
    colorClass="color-grey-70"
    iconWidth="30px"
    iconHeight="30px"
    [ngClass]="{ alert: itemData.alert || '' }"
  ></app-icon>
</div>
