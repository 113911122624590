import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  HostListener
} from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import hammer from "hammerjs";
import { DeviceService } from "../../../services/device.service";
import { Router } from "@angular/router";
import { LayoutService } from "../../../services/layout.service";
import { SwitchItem } from "../switch-container/switch-container.component";

export interface tab {
  label: string;
  fragment: string;
  route: string;
}

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
  host: { class: "app-tabs" }
})
export class TabsComponent implements OnInit, AfterViewInit {
  constructor(
    private deviceService: DeviceService,
    private layoutService: LayoutService,
    private router: Router
  ) {
    this.router;
  }

  @Input() withMore: boolean;

  isDesktop: Boolean;

  @ViewChild("tabs", { static: true }) tabs: MatTabGroup;

  @Input() selectedTabIndex: number = 0;

  @Input() tabData: tab[];
  @Input() switchItems: SwitchItem[];

  ngOnInit() {
    this.isDesktop = this.deviceService.isDesktop;
  }
  ngAfterViewInit() {
    if (!this.deviceService.isDesktop) {
      this.addSwipeListeners();
    }
    if (this.tabData) {
      setTimeout(() => {
        const hash: string = window.location.hash;
        const index: number = hash.lastIndexOf("#");
        let anchor;
        if (index > 0) anchor = hash.substr(index + 1);
        if (anchor && anchor.length) {
          const element = document.getElementById(anchor);
          this.layoutService.scrollToElement(element);
        }
      }, 0);
    } else if (this.switchItems && this.selectedTabIndex) {
      this.switchTab(this.selectedTabIndex);
    } else {
        //the selected tab is commanded by the active route
        this.switchItems.forEach((element, index) => {
          if ( this.router.url === element.routerLink ) {
            this.selectedTabIndex = index;
          }
        });
    }
  }
  addSwipeListeners() {
    const ham = new hammer(this.tabs._elementRef.nativeElement);
    const pagination = this.hasPagination;
    const increment = this.incrementTab;
    const decrement = this.decrementTab;

    /*
    Could be used, but isn't, the swipe is sufficient
    ham.add(new hammer.Pan({ direction: hammer.DIRECTION_ALL, threshold: 0 }));
    ham.on("pan", function(event) {
      if (!pagination()) return false;
      if (event.direction === hammer.DIRECTION_LEFT) {
        console.log(event);
      } else if (event.direction === hammer.DIRECTION_RIGHT) {
        console.log(event);
      }
    })*/
    ham.on("swipeleft", function(ev) {
      if (!pagination()) return false;
      increment();
    });
    ham.on("swiperight", function(ev) {
      if (!pagination()) return false;
      decrement();
    });
  }

  hasPagination() {
    return (
      document.querySelector(".mat-tab-header-pagination-controls-enabled") !==
      null
    );
  }
  incrementTab = () => {
    this.selectedTabIndex = this.selectedTabIndex + 1;
  };
  decrementTab = () => {
    this.selectedTabIndex = this.selectedTabIndex - 1;
  };

  /*in src/app/app.routing,module, the options
    anchorScrolling: "enabled",
    onSameUrlNavigation: "reload",
    scrollPositionRestoration: "enabled"
    allow to navigate to anchors in the page
  */

  changeHandler($event) {
    const selectedIndex = $event;
    if (this.tabData) {
      setTimeout(() => {
        this.scrollToAnchor(selectedIndex);
      }, 500);
    } else if (this.switchItems) {
      this.switchTab(selectedIndex);
    }
  }

  scrollToAnchor(selectedIndex) {
    if (this.tabData === null) return false;
    const anchor: string = this.tabData[selectedIndex].fragment;
    const route: string = this.tabData[selectedIndex].route;

    this.layoutService.scrollToElement(
      document.getElementById(anchor),
      500,
      () => {
        this.router.navigate([route], { fragment: anchor });
      }
    );
    this.selectedTabIndex = selectedIndex;
  }
  switchTab(selectedIndex) {
    //this location.href href just for the demo - to be changed - only the content of the switch-container must change!!
    if (this.selectedTabIndex !== selectedIndex) {
      this.selectedTabIndex = selectedIndex;

      // window.location.href = this.switchItems[selectedIndex].routerLink;
      this.router.navigateByUrl(this.switchItems[selectedIndex].routerLink);
    }
  }
}
