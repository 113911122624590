import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AbstractComponent } from '../../commons/abstract-component';

@Component({
  selector: 'app-edit-personal-details-success',
  templateUrl: './edit-personal-details-success.component.html',
  styleUrls: ['./edit-personal-details-success.component.sass']
})
export class EditPersonalDetailsSuccessComponent extends AbstractComponent implements OnInit {

  public customerUpdated: boolean;

  private parentLink = '/customer';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private customerService: CustomerService) {
      super('customer-page');
  }
  ngOnInit() {
    this.customerUpdated = this.customerService.customerUpdated;
    console.log(this.customerUpdated);

  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_my_profile_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false
    };
  }

  backToProfile() {

    this.router.navigate([this.parentLink]);
  }
}
