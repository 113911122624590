/* eslint-disable max-len */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { filter, flatMap, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommercialCardsHelperService } from '../commercial-cards-helper/commercial-cards-helper.service';

const WP_PAGES_URL = environment.contentBase + environment.contentPages;
const WP_FAQS_URL = environment.contentBase + environment.contentFaqs;
const WP_FAQS_CATEGORIES_URL = environment.contentBase + environment.contentFaqsCategories;
const WP_COMMERCIAL_CARDS_URL = environment.contentBase + environment.contentCommercialCards;
const CACHE_PAGES_SIZE = 1;
// Refresch pages cache every half an hour
const CACHE_PAGES_REFRESH_INTERVAL = 1800000;

@Injectable({
  providedIn: 'root'
})
export class WordpressService implements Resolve<any> {

  private static pages: any[];
  public static faqs: any[];
  public static faqsCategories: any[];
  public static commercialCards = [];

  private cachePages$: Observable<any[]>;
  private cacheFaqs$: Observable<any[]>;
  private cacheFaqsCategories$: Observable<any[]>;
  private cacheCommercialCards$: Observable<any[]>;

  public static getPageFields(slug?: string) {
    const commonFields = WordpressService.pages.filter((page: any) => page.slug === 'common-static-fields')[0].acf;
    if (slug) {
      const specificFields = WordpressService.pages.filter((page: any) => page.slug === slug)[0].acf;
      return Object.assign({}, commonFields, specificFields);
    } else {
      return commonFields;
    }
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private cardsHelper: CommercialCardsHelperService) { }

  public initPages() {
    this.cachePages$ = this.http.get<any[]>(WP_PAGES_URL);
    return this.cachePages$.toPromise().then(data => WordpressService.pages = data);
  }

  public initFaqs() {
      this.cacheFaqs$ = this.http.get<any[]>(WP_FAQS_URL);
      return this.cacheFaqs$.toPromise().then(data => WordpressService.faqs = data);
  }

  public initFaqsCategories() {
      this.cacheFaqsCategories$ = this.http.get<any[]>(WP_FAQS_CATEGORIES_URL);
      return this.cacheFaqsCategories$.toPromise().then(data => WordpressService.faqsCategories = data);
  }

  public initCommercialCards() {
    this.cacheCommercialCards$ = this.http.get<any[]>(WP_COMMERCIAL_CARDS_URL);
    return this.cacheCommercialCards$.toPromise().then(data => {
      console.log(data);
      this.cardsHelper.processWPCards(data);
      this.cardsHelper.cardsWithExpiration.forEach((card: any) => WordpressService.commercialCards.push(card));
      this.cardsHelper.cardsWithNoExpiration.forEach((card: any) => WordpressService.commercialCards.push(card));
      console.log(WordpressService.commercialCards);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data.cmsQuery.post) {
      return this.getPostHtml(route.data.cmsQuery.slug);
    } else {
      return this.getFields(route.data.cmsQuery.slug);
    }
  }

  private requestPages() {
    if (this.cachePages$) {
      return this.cachePages$;
    }
    const timer$ = timer(0, CACHE_PAGES_REFRESH_INTERVAL);

    this.cachePages$ = timer$.pipe(
      switchMap(_ => this.http.get<any[]>(WP_PAGES_URL)),
      shareReplay(CACHE_PAGES_SIZE)
    );

    return this.cachePages$;
  }

  getPostHtml(slug: string): Observable<SafeHtml> {
    const postHtml = new Observable<SafeHtml>(observer => {
      this.requestPages()
        .pipe(
          flatMap(data => data),
          filter((content: any) => content.slug === slug)
        )
        .subscribe(page => {
          observer.next(this.sanitizer.bypassSecurityTrustHtml(page.content));
          observer.complete();
        });
    });
    return postHtml;
  }

  getFields(slug: string): Observable<any> {
    const fields = new Observable<any>(observer => {
      this.requestPages()
        .pipe(
          flatMap(data => data),
          filter((content: any) => content.slug === slug)
        )
        .subscribe(page => {
          observer.next(page.acf);
          observer.complete();
        });
    });
    return fields;
  }

  getEditUrl(slug: string): Observable<string> {
    const editUrl = new Observable<string>(observer => {
      this.requestPages()
        .pipe(
          flatMap(data => data),
          filter((content: any) => content.slug === slug)
        )
        .subscribe(page => {
          observer.next(page._links.edit_link[0].href);
          observer.complete();
        });
    });
    return editUrl;
  }
}
