import { Injectable, HostListener } from "@angular/core";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  deviceInfo: DeviceInfo;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isIE11: boolean;
  isIE: boolean;
  isEdge: boolean;
  isPWA: boolean;
  html: HTMLElement;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = deviceService.getDeviceInfo();
    console.log(this.deviceInfo);
    this.isMobile = deviceService.isMobile();
    this.isPWA = deviceService.isMobile();
    this.html = document.querySelector("html");

    if (navigator.userAgent.match(/Windows Phone/i)) {
      this.isMobile = true;
    }
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    if (this.deviceInfo.browser === "IE" && this.deviceInfo.browser_version.substr(0, 2) === "11") {
      this.isIE11 = true;
      this.html.classList.add("ie11");
    } else {
      this.isIE11 = false;
      this.html.classList.add("not-ie11");
    }

    if (this.deviceInfo.browser === "IE") {
      this.isIE = true;
      this.html.classList.add("ie");
    } else {
      this.isIE = false;
      this.html.classList.add("not-ie");
    }

    this.isEdge = this.deviceInfo.browser === "MS-Edge";
  }

  isMobileSize() {
    return window.innerWidth <= 767;
  }
}
