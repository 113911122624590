<span app-test="payment-method-page">
  <div [editSlug]="slug">
    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

              <h2 *ngIf="!isPWA" class="form-title">{{ fields.selection_form_title }}</h2>
              <span *ngFor="let info of agreementsInfo">
                <p class="bg-grey">{{info}}</p>
              </span>
              <br/>
              <p>{{ fields.selection_form_instruction }}</p>
              
              <h3 class="form-subtitle">{{ fields.selection_form_subtitle }}</h3>

              <app-accordion #appAccordion [multi]="false">

                <app-collapse-link
                [title]="fields.payment_strip_label"
                radioName="paymentChoice"
                value="paymentStrip"
                [expanded]="isSelectedPaymentStrip()"
                (radioSelectionEmitter)="updatePaymentMethodType($event)"
                app-test="payment-strip-collapse">

                <form 
                [formGroup]="paymentStripForm"
                app-test="payment-strip-form">

                  <div>
                    <p>{{fields.payment_strip_description}}</p>
                    <div class="form-group">
                      <app-textfield
                      [label]="fields.payment_purpose_textfield_label"
                      controlName="paymentPurpose"
                      [parentForm]="paymentStripForm"
                      [disabled]="true"
                      [prefilled]="true"
                      app-test="payment-purpose-txt"
                      appTest="payment-purpose-value">
                      </app-textfield>
                    </div>
                    <div class="form-group">
                      <app-textfield
                      [label]="fields.rci_iban_textfield_label"
                      controlName="rciIban"
                      [parentForm]="paymentStripForm"
                      [disabled]="true"
                      [prefilled]="true"
                      app-test="rci-iban-txt"
                      appTest="rci-iban-value">
                      </app-textfield>
                    </div>
                    <div class="form-group">
                      <app-textfield
                      [label]="fields.rci_bank_name_textfield_label"
                      controlName="rciBank"
                      [parentForm]="paymentStripForm"
                      [disabled]="true"
                      [prefilled]="true"
                      app-test="rci-bank-txt"
                      appTest="rci-bank-value">
                      </app-textfield>
                    </div>
                  </div>

                </form>
                </app-collapse-link>

                <app-collapse-link
                [title]="fields.direct_debit_label"
                radioName="paymentChoice"
                value="directDebit"
                [expanded]="!isSelectedPaymentStrip()"
                (radioSelectionEmitter)="updatePaymentMethodType($event)"
                app-test="direct_debit-collapse">

                  <p>{{ fields.bank_account_selection_title }}</p>

                  <div class="form-group">
                    <app-radio-group>
                      <span *ngFor="let directDebit of directDebitMandates">
                        <app-radio-button
                        label='{{directDebit.bankAccount.bankName}} {{ibanFormatter.transform(directDebit.bankAccount.iban)}}'
                        [value]="directDebit"
                        [checked]="directDebit.startSelection"
                        (changeEmitter)="ibanSelection($event)"
                        attr.app-test="{{directDebit.bankAccount.iban}}-radio-btn">
                        </app-radio-button>
                      </span>
                    </app-radio-group>
                  </div>

                  <app-button
                  [buttonLabel]="fields.add_new_iban_label"
                  [isLink]=true
                  (buttonClick)="performAction()"
                  app-test="add-new-iban-action">
                  </app-button>
                  <br/>

                </app-collapse-link>

              </app-accordion>

              <app-form-buttons>
                <div>
                  <app-button
                  buttonType="secondary"
                  [buttonLabel]="fields.cancel_button_label"
                  (buttonClick)="cancel()"
                  app-test="back-btn">
                  </app-button>
                  <app-button
                  [buttonLabel]="fields.confirm_button_label"
                  (buttonClick)="confirm()"
                  [disabled]="!enableConfirmButton()"
                  app-test="confirm-btn">
                  </app-button>
                </div>
              </app-form-buttons>

          </div>
        </div>
      </div>
    </div>
  </div>
</span>
