import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertsService implements Resolve<any> {

  private resource: Resource;
  private alerts: any[];

  constructor(http: HttpClient) {
    this.resource = new Resource(http, environment.restBase + '/alerts');
  }

  list(refresh: boolean = false): Observable<any[]> {
    if ( this.alerts === undefined || refresh ) {
      const obs = this.resource.list();
      obs.subscribe(
        alerts => {
        this.alerts = alerts;
        });
      return obs;
    } else {
      return new Observable((observer) => {
        observer.next(this.alerts);
        observer.complete();
      });
    }
  }

  reset() {
    this.alerts = undefined;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.list();
  }

}
