import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DeviceService } from "../../../services/device.service";
import { LayoutService } from "../../../services/layout.service";

@Component({
  selector: "app-vehicule-edition",
  templateUrl: "./vehicule-edition.component.html",
  styleUrls: ["./vehicule-edition.component.scss"]
})
export class VehiculeEditionComponent implements OnInit {
  @ViewChild("uploadImage", { static: true }) uploadImage: ElementRef;

  @Input() appTest: string;

  @Input() title: string;
  @Input() heading: string;
  @Input() goToLabel: string;
  @Input() goToLink: string;

  @Input() image: string;
  @Input() defaultImage: string = "app/design-system/assets/images/img-clio-rs.png";
  @Input() brand: string;
  @Input() model: string;
  @Input() registrationDate: string;
  @Input() licensePlateNumber: string;
  @Input() vin: string;
  @Input() vehiculeName: string;
  @Input() brandLabel: string;
  @Input() modelLabel: string;
  @Input() registrationDateLabel: string;
  @Input() licensePlateNumberLabel: string;
  @Input() vinLabel: string;
  @Input() uploadRegistrationCardLabel: string;
  @Input() registrationCardLabel: string;
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;
  @Input() vehiculeNameLabel: string;
  @Input() vehicleNamePlaceholderLabel ? = '';
  @Input() imageAccepted: string;
  @Input() dropInstructions: string;
  @Input() dropFormatsAccepted: string;
  @Input() fileFormatsInstructions: string;
  @Input() browseBtnLabel: string;
  @Input() fileMaxSize: number;
  @Input() errorMessageNotAllowed: string;
  @Input() errorMessageTooBig: string;

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileError: EventEmitter<string> = new EventEmitter<string>();

  isPWA: boolean;
  public enableSave: boolean;
  public errorFileMessage: string;

  private imageToUpload: File;

  constructor(
    private deviceService: DeviceService,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;
    this.enableSave = false;
  }

  browseImage() {
    console.log(this.uploadImage);
    this.uploadImage.nativeElement.click();
  }

  public updateName(name: any) {
    this.vehiculeName = name;
    this.enableSave = true;
  }

  public updateImage() {
    this.errorFileMessage = null;
    this.imageToUpload = this.uploadImage.nativeElement.files[0];
    if ( !this.isFileValid(this.imageToUpload) ) {
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.image = event.target.result;
      this.enableSave = true;
    });
    reader.readAsDataURL(this.imageToUpload);
  }

  isFileValid(imageToUpload: File) {
    const allowedTypes = "image/jpg,image/png,image/jpeg";
    if ( !(allowedTypes.lastIndexOf(imageToUpload.type) > -1) ) {
      this.errorFileMessage = "'" + imageToUpload.name + "'" + ' - ' + this.errorMessageNotAllowed;
      this.enableSave = false;
      return false;
    } else if ( imageToUpload.size > this.fileMaxSize ) {
      this.errorFileMessage = "'" + imageToUpload.name + "'" + ' - ' + this.errorMessageTooBig;
      this.enableSave = false;
      return false;
    }
    return true;
  }

  public save($event) {
    this.saveChanges.emit({
      imageToUpload: this.imageToUpload,
      name: this.vehiculeName
    });
  }

  isNullOrEmpty(str: string) {
    return !str || !str.trim();
  }

}
