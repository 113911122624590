import { Component, OnInit, Input, HostListener, HostBinding, Optional, Inject } from "@angular/core";
import { AbstractComponent } from "../../commons/abstract-component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ContactService } from "src/app/services/contact/contact.service";
import { ActivatedRoute, Router } from '@angular/router';
import { SelectOptions } from 'src/app/design-system/components/forms/select/select.component';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { LayoutService } from 'src/app/design-system/services/layout.service';

/* eslint-disable max-len */

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"]
})
export class ContactUsComponent extends AbstractComponent implements OnInit {

  public contactUsForm: FormGroup;

  public dropFormatsAccepted: string = "application/pdf,application/msword,text/plain,image/jpg,image/png,image/jpeg";

  public attachment: File;

  public agreementsSelectOptions: Array<SelectOptions> = new Array<SelectOptions>();

  public messageTypeSelectOptions: Array<SelectOptions> = new Array<SelectOptions>();

  public selectedAgreement: SelectOptions;

  public selectedMessageType: SelectOptions;

  public customer: any;

  public errorFileMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private contact: ContactService,
    private jwtService: JwtService,
    private layoutService: LayoutService) {
    super("contact-us-page");
  }

  ngOnInit() {
    this.layoutService.updateMasonry(true);

    this.agreementsSelectOptions = this.getAgreementOptions(this.route.snapshot.data.agreements);
    this.customer = this.route.snapshot.data.customer;
    this.messageTypeSelectOptions = this.getMessageTypeOptions(this.fields.contact_us_type_list);

    this.contactUsForm = this.fb.group({
      agreementNumber: [ this.agreementsSelectOptions.length === 1 ? this.agreementsSelectOptions[0].viewValue : ''],
      customerName: [ this.customer.person.displayName ],
      type: [''],
      email: [ this.customer.emails && this.customer.emails[0] ? this.customer.emails[0].value : this.jwtService.UserStoredInformations.user_name],
      phone: [null],
      message: ['']
    });
  }

  getAgreementOptions(agreements: Array<any>): SelectOptions[] {
    agreements.forEach(agreement => {
      this.agreementsSelectOptions.push({
        value: agreement.label,
        viewValue: agreement.label + " - " + this.getVehicleName(agreement.vehicle),
        hint: "select agreement"
      });
    });
    return this.agreementsSelectOptions;
  }

  private getVehicleName(vehicle: any) {
    return vehicle.name ? vehicle.name :
                    vehicle.model ? vehicle.model.label : vehicle.plate;
  }

  getMessageTypeOptions(messageTypeField: string) {

    messageTypeField.split("-").forEach(element => {
      if (!this.isNullOrEmpty(element)) {
        this.messageTypeSelectOptions.push({
          value: element.replace("\n", "").trim(),
          viewValue: element.replace("\n", "").trim(),
          hint: element.replace("\n", "").trim()
        });
      }
    });
    return this.messageTypeSelectOptions;
  }

  isNullOrEmpty(str: string) {
    return !str || !str.trim();
  }

  setSelectedAgreement(event: SelectOptions) {
    this.selectedAgreement = event;
  }

  setSelectedMessageType(event: SelectOptions) {
    this.selectedMessageType = event;
  }

  updateAttachment(files: File) {
    this.attachment = files[0];
    this.errorFileMessage = null;
  }

  setFileError(event: any) {
    this.errorFileMessage = event;
  }

  send() {
    const request = {
      agreementNumber: this.agreementsSelectOptions.length === 1 ? this.agreementsSelectOptions[0].value : this.selectedAgreement.value,
      customerName: this.contactUsForm.get('customerName').value,
      messageType: this.selectedMessageType.value,
      email: this.contactUsForm.get('email').value,
      phone: this.contactUsForm.get('phone').value,
      message: this.contactUsForm.get('message').value
    };

    this.contact.sendRequest(request, this.attachment).subscribe(
      data => {
        this.router.navigate(['./outcome'], { relativeTo: this.route });
      },
      error => {
        this.router.navigate(['./outcome'], { relativeTo: this.route });
      }
    );
  }
}
