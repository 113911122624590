import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "app-progress-tip",
  templateUrl: "./progress-tip.component.html",
  styleUrls: ["./progress-tip.component.scss"]
})
export class ProgressTipComponent implements OnInit, AfterViewInit, OnChanges {
  constructor() {}

  @ViewChild("arrow", { read: ElementRef, static: true })
  arrow: ElementRef;

  arrowDomElt: HTMLElement;

  @Input() progressValue: number = 0;

  ngOnInit() {}
  ngAfterViewInit() {
    this.arrowDomElt = this.arrow.nativeElement as HTMLElement;
    if (this.arrowDomElt != null) {
      this.updateStyle();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.arrowDomElt == null) return false;
    if (changes.progressValue) {
      if (this.progressValue > 100) {
        this.progressValue = 100;
      }
      this.updateStyle();
    }
  }

  updateStyle() {
    this.arrowDomElt.style.left = this.progressValue + "%";
  }
}
