import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-vehicule-edition-form",
  templateUrl: "./vehicule-edition-form.component.html",
  styleUrls: ["./vehicule-edition-form.component.scss"]
})
export class VehiculeEditionFormComponent implements OnInit {
  constructor() {}
  @ViewChild("uploadImage", { static: true }) uploadImage: ElementRef;

  @Input() appTest: string;

  @Input() title: string = "Personalize my vehicule";
  @Input() heading: string = "Choose your favorite picture";

  @Input() image: string = "/assets/images/img-clio-rs.jpg";
  @Input() brand: string;
  @Input() model: string;
  @Input() registrationDate: string;
  @Input() licensePlateNumber: string;
  @Input() vin: string;
  @Input() vehiculeName: string;
  @Input() brandLabel: string;
  @Input() modelLabel: string;
  @Input() registrationDateLabel: string;
  @Input() licensePlateNumberLabel: string;
  @Input() vinLabel: string;
  @Input() uploadRegistrationCardLabel: string;
  @Input() registrationCardLabel: string;
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;
  @Input() vehiculeNameLabel: string;
  @Input() imageAccepted: string;
  @Input() dropInstructions: string;
  @Input() dropFormatsAccepted: string;
  @Input() fileFormatsInstructions: string;
  @Input() browseBtnLabel: string;

  ngOnInit() {}

  browseImage() {
    console.log(this.uploadImage);
    this.uploadImage.nativeElement.click();
  }

  updateFiles(files) {
    console.log(files);
  }
}
