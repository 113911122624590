<mat-form-field
  class="mat-form-field"
  [ngClass]="{ error: hasError, prefilled: prefilled, filter: filter }"
  [formGroup]="parentForm"
  *ngIf="!disabled || !prefilled || uploadFile"
>
  <mat-label>{{ label }}</mat-label>

  <input
    autocomplete="off"
    matInput
    [type]="
      hidePassword && isPassword
        ? 'password'
        : !hidePassword
        ? 'text'
        : isMobile
        ? type || 'text'
        : 'text'
    "
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="uploadFile"
    [formControlName]="controlName"
    [pattern]="pattern"
    [required]="required"
    (ngModelChange)="onChange($event)"
    (focusout)="onFocusOut()"
    attr.app-test="{{appTest}}"
  />

  <app-icon-button
    *ngIf="isPassword"
    matSuffix
    (buttonClick)="hidePassword = !hidePassword"
    [icon]="hidePassword ? 'icon-password-show' : 'icon-password-hide'"
    [colorClass]="hasError ? 'color-warn' : 'color-grey-70'"
    class="eye-icon"
    iconHeight="16px"
    iconWidth="16px"
    [class.has-value]="parentForm.controls[controlName].value"
  ></app-icon-button>

  <app-icon-button
    matSuffix
    *ngIf="withClear"
    icon="icon-circle-close"
    iconHeight="16px"
    iconWidth="16px"
    class="clear-icon"
    [colorClass]="hasError ? 'color-warn' : 'color-grey-70'"
    (buttonClick)="uploadFile ? clearEmitter.emit($event) : clear()"
    [class.has-value]="parentForm.controls[controlName].value"
  ></app-icon-button>
  <app-icon-button
    matSuffix
    *ngIf="filter"
    icon="icon-circle-filter"
    iconHeight="16px"
    iconWidth="16px"
    class="filter-icon"
    colorClass="color-grey-70"
    description="Search in your messages"
  ></app-icon-button>
</mat-form-field>

<div
  class="mat-form-field disabled-prefilled-form-field"
  *ngIf="disabled && prefilled && !uploadFile"
>
  <label class="disabled-prefilled-label">{{ label }}</label>

  <span class="disabled-prefilled-value" attr.app-test="{{appTest}}">
    {{ parentForm.controls[controlName].value }}
  </span>
</div>

<mat-hint *ngIf="hint" [ngClass]="{ 'hint-error': hasError }" app-test="hint-lbl">{{ hint }}
</mat-hint>

<mat-hint *ngIf="errorDetected()"
[ngClass]="{ 'hint-error': errorDetected() }">
  <span *ngIf="parentForm.controls[controlName].errors.required && hasLostFocus" app-test="validation-required-lbl">{{requiredErrorMessage}}</span>
  <span *ngIf="parentForm.controls[controlName].errors.pattern && hasLostFocus" app-test="validation-pattern-lbl">{{patternErrorMessage}}</span>
  <!--add here other <span> tags for other possible validation errors;
  see 'Validators' from '@angular/forms'-->
</mat-hint>
