import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ContentChild,
  HostListener,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DeviceService } from "../../../services/device.service";
import { Globals } from "../../../globals";
import { MenuItems } from '../nav-menu/nav-menu.component';

@Component({
  selector: "app-page-nolog-layout",
  templateUrl: "./page-nolog-layout.component.html",
  styleUrls: ["./page-nolog-layout.component.scss"]
})
export class PageNologLayoutComponent implements OnInit, AfterViewInit {
  constructor(
    private deviceService: DeviceService,
    private globals: Globals,
    private layoutService: LayoutService
  ) {
    this.globals.isLoginPage = false;
  }

  @Input() cookiesAlert: any;
  @Output() cookiesEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() appTest: string;

  @ContentChild("pageContentRef", {static: true}) pageContentRef;

  @Input() isSubPage: boolean = true;
  @Input() backToLabel: string;
  @Input() backToLink: string;
  @Input() showMore: boolean = false;
  @Input() showMoreLabel: string = "Show me more";

  @Input() footerLinks: MenuItems[];
  @Input() copyright: string;

  @Input() contactBlocksData: [];
  @Input() contactBlocksButton: string;

  @Input() isPWA: boolean = false;

  @ViewChild("header", { read: ElementRef, static: true })
  headerElement: ElementRef;

  @ViewChild("footer", { read: ElementRef, static: true })
  footerElement: ElementRef;

  footer: Element;
  header: Element;

  @HostListener("window:scrollTop", ["$event"])
  scrollTop(event) {
    this.layoutService.scrollToTop(event);
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    const pwa = this.isPWA;
    this.updatePWA();
    if (this.isPWA != pwa) {
      this.updateLayout();
    }
    if (this.footer && this.header) {
      this.layoutService.resizePages(this.header, this.footer);
    }
  }

  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  updateLayout() {
    if (this.isPWA) {
      document.querySelector("html").classList.add("pwa");
    } else {
      document.querySelector("html").classList.remove("pwa");
    }
  }

  ngOnInit() {
    this.updatePWA();
    this.updateLayout();
  }

  // button showmore if needed
  showHideMore = () => {
    this.showMore = !this.showMore;
    this.showMoreLabel = this.showMore ? "show me less" : "show me more";
  };

  ngAfterViewInit() {
    if (!this.footerElement || !this.headerElement) {
      return false;
    }
    this.header = this.headerElement.nativeElement;
    this.footer = this.footerElement.nativeElement;

    this.layoutService.resizePages(this.header, this.footer);
  }
}
