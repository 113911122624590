import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { BlockAction } from 'src/app/design-system/components/navigation/block-actions/block-actions.component';
import { IbanFormatPipe } from 'src/app/pipes/iban-format.pipe';

/* eslint-disable max-len */

@Component({
  selector: 'app-bank-details-content',
  templateUrl: './bank-details-content.component.html',
  styleUrls: ['./bank-details-content.component.sass'],
  providers: [IbanFormatPipe]
})
export class BankDetailsContentComponent extends AbstractComponent implements OnInit {

  public actions: BlockAction[];

  public bankDetailsColumns: any[];

  public rciTableData: any[];
  public bankDetailsTableData: any[];
  public haveActiveAgreements = false;
  public havePersonalBankDetails = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public ibanFormatter: IbanFormatPipe,
              private bankDetailsService: BankDetailsService) {
          super('bank-details-page');
        }

  ngOnInit() {
    console.log(this.fields);
    console.log(this.route.snapshot.data.bankDetails);
    console.log(this.route.snapshot.data.agreements);

    const agreements = this.route.snapshot.data.agreements;
    agreements.forEach((agreement: any) => {
      if (agreement.status === 'ACTIVE') {
        this.haveActiveAgreements = true;
      }
    });

    console.log(this.haveActiveAgreements);

    this.actions = [
      {
        label: this.fields.add_new_iban_label,
        name: 'addNewIban',
        appTest: 'add-new-iban-action'
      }
    ];

    this.bankDetailsColumns = [
      { id: 'holder', label: this.fields.account_holder_label },
      { id: 'bank', label: this.fields.bank_name_label },
      { id: 'iban', label: this.fields.iban_label },
      { id: 'action', label: '' }
    ];

    this.rciTableData = [
      {
        holder: { value: '', text: this.fields.account_holder_rci_value},
        bank: { value: '', text: this.fields.bank_name_rci_value },
        iban: { value: '', text: this.ibanFormatter.transform(this.fields.iban_rci_value) },
        action: { value: '', actions: '', actionName: '' },
        appTest: 'row-rci-values'
      }
    ];

    const bankDetails = this.route.snapshot.data.bankDetails;

    if (bankDetails && bankDetails.length > 0) {
      this.havePersonalBankDetails = true;

      this.bankDetailsTableData = [];

      bankDetails.forEach((bankDetail: any) => {
        const directDebitInfo = {
          holder: { value: '', text: bankDetail.bankAccount.accountHolder },
          bank: { value: '', text: bankDetail.bankAccount.bankName },
          iban: { value: '', text: this.ibanFormatter.transform(bankDetail.bankAccount.iban) },
          action: { value: '', actions: [{ action: 'edit', actionName: this.fields.see_label.concat('/' + this.fields.edit_label) }] },
          appTest: 'row-bank-details-'.concat(bankDetail.bankAccount.iban)
        };

        const description = bankDetail.vehicleDescription ?
                            this.getTranslatedAgreementType(bankDetail.agreementDescription).concat(' - ' + bankDetail.vehicleDescription) :
                            this.getTranslatedAgreementType(bankDetail.agreementDescription);
        const agreementInfo = {
          holder: { value: '', text: description },
          bank: '',
          iban: '',
          action: { value: '', actions: 'arrow' },
          agreementId: bankDetail.agreementId,
          appTest: 'row-'.concat(bankDetail.bankAccount.iban + '-agreement-' + bankDetail.agreementId)
        };

        let detailAlreadyAdded = false;
        let position: number;
        this.bankDetailsTableData.forEach((detailsLine: any, index: number) => {
          if (this.ibanFormatter.transform(bankDetail.bankAccount.iban) === detailsLine.iban.text) {
            detailAlreadyAdded = true;
            position = index;
          }
        });

        if (!detailAlreadyAdded) {
          this.bankDetailsTableData.push(directDebitInfo);
          this.bankDetailsTableData.push(agreementInfo);
        } else {
          this.bankDetailsTableData.splice(position + 1, 0, agreementInfo);
        }

      });
    }

    console.log(this.havePersonalBankDetails);
    //console.log(this.bankDetailsTableData);

  }

  private getTranslatedAgreementType(type: string): string {
    switch (type) {
      case 'CREDIT':
        return this.fields.financing_agreement_label;
      case 'LEASING':
        return this.fields.leasing_agreement_label;
      case 'BATTERY':
        return this.fields.battery_rental_agreement_label;
      case 'BALLOON':
        return this.fields.credit_residual_value_agreement_label;
      default:
        return type;
    }
  }

  private getAgreementIdList(iban: string): string[] {
    const agreementIdList = [];
    const bankDetails = this.route.snapshot.data.bankDetails;
    bankDetails.forEach((bankDetail: any) => {
      if (this.ibanFormatter.transform(bankDetail.bankAccount.iban) === iban) {
        agreementIdList.push(bankDetail.agreementId);
      }
    });
    return agreementIdList;
  }

  public editBankDetails(data: any) {
    console.log(data.iban.text);
    const agreementIdList = this.getAgreementIdList(data.iban.text);
    this.bankDetailsService.agreementIdList = agreementIdList;
    this.bankDetailsService.selectedIban = data.iban.text.split(' ').join('');
    console.log(this.bankDetailsService.agreementIdList, this.bankDetailsService.selectedIban);
    this.router.navigate(['/payment-method']);
  }

  public goToAgreementDetails(data: any) {
    //console.log(data);
    this.router.navigate(['/home/agreements/' + data.agreementId]);
  }

  public performAction(action: any) {
    //console.log(action);
    if (action.name === 'addNewIban') {
      this.bankDetailsService.agreementIdList = undefined;
      this.bankDetailsService.updatableBankAccount = undefined;
      this.router.navigate(['/payment-method/direct-debit/edit']);
    }
  }

}
