import { Component, OnInit, Input } from "@angular/core";
import { SubjectData } from "../../lists/faq-list/faq-list.component";

export interface FaqData {
  subject: string;
  subjectDataList: SubjectData[];
}

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
  host: { class: "faq" }
})
export class FaqComponent implements OnInit {
  constructor() {}

  @Input() faqText: string = "FAQ";
  @Input() introText: string = "Any question you have about";
  @Input() selectedIndex: number = 0;
  @Input() enableFeedback: boolean = false;
  @Input() emptyMessage: string;

  @Input() faqData: FaqData[] = [
    {
      subject: "My agreements",
      subjectDataList: [
        {
          question:
            "All my login identifiers are correct but I still cannot log in, why is that?",
          answer: `
        <p>Please double check that the identifying codes you have entered are those provided to the contract holder, as referenced in all Renault Finance statements or messages.</p>
        <p>You can contact us directly on 04 78 17 67 07 for further help to connection.</p>
        <p>N.B. This site is currently open to all clients who own a personal vehicle.</p>`
        },
        { question: "Question 1_2", answer: "Answer 1_2" },
        { question: "Question 1_3", answer: "Answer 1_3" },
        { question: "Question 1_4", answer: "Answer 1_4" }
      ]
    },
    {
      subject: "My insurances and DIAC services",
      subjectDataList: [
        { question: "Question 2_1", answer: "Answer 2_1" },
        { question: "Question 2_2", answer: "Answer 2_2" },
        { question: "Question 2_3", answer: "Answer 2_3" },
        { question: "Question 2_4", answer: "Answer 2_4" }
      ]
    },
    {
      subject: "subject 3",
      subjectDataList: [
        { question: "Question 3_1", answer: "Answer 3_1" },
        { question: "Question 3_2", answer: "Answer 3_2" },
        { question: "Question 3_3", answer: "Answer 3_3" },
        { question: "Question 3_4", answer: "Answer 3_4" }
      ]
    },
    {
      subject: "subject 4",
      subjectDataList: [
        { question: "Question 4_1", answer: "Answer 4_1" },
        { question: "Question 4_2", answer: "Answer 4_2" },
        { question: "Question 4_3", answer: "Answer 4_3" },
        { question: "Question 4_4", answer: "Answer 4_4" }
      ]
    },
    {
      subject: "subject 5",
      subjectDataList: [
        { question: "Question 5_1", answer: "Answer 5_1" },
        { question: "Question 5_2", answer: "Answer 5_2" },
        { question: "Question 5_3", answer: "Answer 5_3" },
        { question: "Question 5_4", answer: "Answer 5_4" }
      ]
    }
  ];

  ngOnInit() {}
}
