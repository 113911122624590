import { Component, OnInit, Input } from "@angular/core";
import { Filter } from "../../lists/filter-list/filter-list.component";

@Component({
  selector: "app-projects-filter",
  templateUrl: "./projects-filter.component.html",
  styleUrls: ["./projects-filter.component.scss"]
})
export class ProjectsFilterComponent implements OnInit {
  constructor() {}

  @Input() title = "Your projects";

  @Input() allText: string = "all";

  @Input() projects: Filter[] = [
    { label: "Car purchase", name: "", value: "" },
    { label: "Savings", name: "", value: "" },
    { label: "Insurance", name: "", value: "" },
    { label: "Face hardship", name: "", value: "" }
  ];

  ngOnInit() {}
}
