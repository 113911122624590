import { Component, OnInit, Input, ElementRef, Renderer2 } from "@angular/core";

export interface Settings {
  label: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
}

@Component({
  selector: "app-cookie-banner",
  templateUrl: "./cookie-banner.component.html",
  styleUrls: ["./cookie-banner.component.scss"],
  host: { class: "cookie-banner" }
})
export class CookieBannerComponent implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @Input() explanationText: string;
  @Input() explanationLink: string;
  @Input() link: string;
  @Input() buttonLabel: string;
  @Input() settingsData: Settings[];

  cookiePrefAction() {
    //send data
    //then remove component
    this.elementRef.nativeElement.remove();
  }
  ngOnInit() {}
}
