<div class="desktop-faq">
  <div class="desktop-faq-subjects">
    <ul>
      <li
        *ngFor="let faq of faqData; let i = index"
        (click)="selectedIndex = i"
        [ngClass]="selectedIndex === i ? 'selected' : ''"
        [attr.app-test]="faq.subject"
      >
        {{ faq.subject }}
      </li>
    </ul>
  </div>
  <div class="desktop-faq-list">
    <div class="intro-block">
      <span>
        {{ introText }} <span>{{ faqData[selectedIndex].subject }}</span>
      </span>
    </div>
    <app-faq-list [subjectDataList]="faqData[selectedIndex].subjectDataList" [emptyMessage]="emptyMessage"
      [enableFeedback]="enableFeedback" >
    </app-faq-list>
  </div>
</div>

<div class="mobile-faq">
  <app-collapse-link *ngFor="let faq of faqData" [title]="faq.subject">
    <div class="intro-block">
      <span>
        {{ introText }} <span>{{ faq.subject }}</span>
      </span>
    </div>
    <app-faq-list [subjectDataList]="faq.subjectDataList"  [enableFeedback]="enableFeedback" > </app-faq-list>
  </app-collapse-link>
</div>
