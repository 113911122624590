import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanService extends Resource {

  constructor(http: HttpClient) {
    super(http, environment.restBase + '/agreements');
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(route.parent.params.id);
    return this.paymentPlan(route.parent.params.id);
  }

  public paymentPlan(agreementId: string) {
    return this.http
    .get<any>(environment.restBase + '/agreements/' + agreementId + '/paymentPlan')
    .pipe(catchError(super.handleError<any>('get resource', [])));
  }
}
