<app-page-nolog-layout
  [cookiesAlert]="cookiesService.cookiesBannerConfig"
  (cookiesEmitter)="cookiesService.storeCookieFromPublicPage()"
  [footerLinks]="footerLinks"
  [copyright]="footerCopyright"
  [backToLabel]="pageConfig.backToLabel"
  [backToLink]="pageConfig.backToLink"
  [isSubPage]="pageConfig.isSubPage"
  [contactBlocksData]="contactBlocksData"
  [contactBlocksButton]="contactBlocksButton">
  <ng-template #pageContentRef>
    <router-outlet (activate)="updatePageConfig($event)"></router-outlet>
  </ng-template>
</app-page-nolog-layout>