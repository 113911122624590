<ngx-file-drop
  #fileDrop
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)"
  [showBrowseBtn]="true"
  [browseBtnLabel]="browseBtnLabel"
  [multiple]="multiple"
  [ngClass]="{ 'mobile-tablet': !isDesktop }"
  [accept]="dropFormatsAccepted">
  <span *ngIf="isDesktop">{{ dropInstructions }}</span>
</ngx-file-drop>

<div class="form-group" *ngFor="let file of filesList">
  <app-textfield
    [label]="fileLabel"
    [value]="file.name"
    [uploadFile]="true"
    [prefilled]="true"
    [disabled]="true"
    (clearEmitter)="removeUploadedFile(file)"
  ></app-textfield>
</div>
