<span app-test="edit-personal-details-success-page">
  <div [editSlug]="slug">

    <app-message-block 
      *ngIf="customerUpdated"
      [title]="fields.thank_you_title" 
      [message]="fields.profile_details_successfully_changed_label" 
      [secondaryButton]="{
          label : fields.back_to_my_profile_button_label
      }"
      (secondaryButtonEmitter)="backToProfile()"
      appTest="customer-update-sent-success">
    </app-message-block>


    <app-message-block 
      *ngIf="!customerUpdated"
      [title]="" 
      [icon]="'icon-circle-close'"
      [message]="fields.message_server_error"
      [secondaryButton]="{
          label : fields.back_to_my_profile_button_label
      }"
      (secondaryButtonEmitter)="backToProfile()"
      appTest="customer-update-sent-failed">
    </app-message-block>

  </div>
</span>