import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

export interface MessageItem {
  id: string;
  title: string;
  text: string;
  date: string;
}

@Component({
  selector: "app-feed-message",
  templateUrl: "./feed-message.component.html",
  styleUrls: ["./feed-message.component.scss"]
})
export class FeedMessageComponent implements OnInit {
  constructor() {}

  @Input() appTest: string;

  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  @Input() date: string;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter<any>();

  messageClickHanler(event, id) {
    if (event && event.preventDefault !== null) {
      event.preventDefault();
    }
    this.clickEmitter.emit(id);
  }

  ngOnInit() {}
}
