<span app-test="edit-direct-debit-page">
    <div [editSlug]="slug">
      <div class="grid-item grid-item--fullsize">
        <div class="content-box content-box--form">
          <div class="content-box-inner">
            <div class="layout-narrow">
  
                <h2 *ngIf="!isPWA" class="form-title">{{ fields.edit_form_title }}</h2>
                <p>{{ fields.edit_form_instruction_1 }}</p>
                <p>{{ fields.edit_form_instruction_2 }}</p>
                
                <h3 class="form-subtitle">{{ fields.edit_form_subtitle }}</h3>
                <form class="ng-untouched ng-pristine ng-valid"
                [formGroup]="editDirectDebitForm"
                app-test="edit-direct-debit-form">

                  <div class="form-group">
                    <app-textfield
                    [label]="fields.account_holder_textfield_label"
                    controlName="accountHolder"
                    [parentForm]="editDirectDebitForm"
                    [disabled]="true"
                    [prefilled]="true"
                    appTest="account-holder-txt">
                    </app-textfield>
                  </div>
                  <p>{{ fields.account_holder_message }}</p>
                  <p>{{fields.mandatory_label}}</p>

                  <div class="form-group">
                    <app-textfield
                    [label]="fields.bank_name_textfield_label"
                    controlName="bankName"
                    [parentForm]="editDirectDebitForm"
                    [required]="true"
                    [requiredErrorMessage]="fields.required_error_txt"
                    appTest="bank-name-txt">
                    </app-textfield>
                  </div>

                  <div class="form-group">
                    <app-textfield
                    [label]="fields.iban_textfield_label"
                    controlName="iban"
                    [parentForm]="editDirectDebitForm"
                    [required]="true"
                    [requiredErrorMessage]="fields.required_error_txt"
                    [pattern]="patterns.iban.pattern"
                    [patternErrorMessage]="fields.iban_pattern_error_message"
                    appTest="iban-txt">
                    </app-textfield>
                  </div>

                </form>

                <br/>
                <h3 class="form-subtitle">{{ fields.agreement_selection_subtitle }}</h3>
                <form class="ng-untouched ng-pristine ng-valid"
                [formGroup]="agreementCheckboxesForm"
                app-test="agreement-chechboxes-form">

                  <span
                  formArrayName="checkboxes"
                  *ngFor="let checkbox of agreementCheckboxesForm['controls'].checkboxes['controls']; let i = index">
                    <app-checkbox
                    [label]="checkboxes[i].label"
                    [controlName]="i"
                    [parentForm]="agreementCheckboxesForm['controls'].checkboxes"
                    appTest="{{checkboxes[i].appTest}}">
                    </app-checkbox>
                  </span>

                </form>

                <app-form-buttons>
                  <div>
                    <app-button
                    buttonType="secondary"
                    [buttonLabel]="fields.cancel_button_label"
                    (buttonClick)="cancel()"
                    app-test="back-btn">
                    </app-button>
                    <app-button
                    [buttonLabel]="fields.confirm_button_label"
                    (buttonClick)="confirm()"
                    [disabled]="editDirectDebitForm.invalid || agreementCheckboxesForm.invalid"
                    app-test="confirm-btn">
                    </app-button>
                  </div>
                </app-form-buttons>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
  