import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { VehiclesService } from '../vehicles/vehicles.service';
import { AlertsService } from '../alerts/alerts.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementsService extends Resource {

  constructor(http: HttpClient,
              private vehiclesService: VehiclesService,
              private alertService: AlertsService) {
    super(http, environment.restBase + '/agreements');
  }

  public paymentPlan(agreementId: string) {
    return this.http
    .get<any>(environment.restBase + '/agreements/' + agreementId + '/paymentPlan')
    .pipe(catchError(super.handleError<any>('get resource', [])));
  }

  get(id: string): Observable<any> {
    return new Observable((observer) => {
      this.http.get<any>(this.url + '/' + id)
        .pipe(catchError(this.handleError<any>('get resource', [])))
        .subscribe(agreement => {
          this.alertService.list().subscribe(alerts => {
            agreement.alert = alerts.find(alert => {
              return alert.referenceType === 'agreement' && alert.referenceId === agreement.id;
            });
            observer.next(agreement);
            observer.complete();
          });
        });
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.endsWith('/contact-us') === true ) {
      return new Observable<any>(observer => {
        this.list().subscribe( agreements => {
          agreements.forEach( agreementEl => {
            this.vehiclesService.get(agreementEl.vehicle.id).subscribe( vehicleEl => {
              vehicleEl.title = this.getVehicleName(vehicleEl);
              agreementEl.vehicle = vehicleEl;
            });
            observer.next(agreements);
            observer.complete();
          });
        });
      });
    } else {
      if ( route.params.id != null ) {
        return new Observable<any>(observer => {
          this.list().subscribe(agreements => {
            this.get(route.params.id).subscribe(agreement => {
              agreement.vehicle = agreements.find(summaryAgreement => {
                return summaryAgreement.id === route.params.id;
              }).vehicle;
              observer.next(agreement);
              observer.complete();
            });
          });
        });
      } else {
        return this.http
          .get<any[]>(environment.restBase + '/agreements')
          .pipe(catchError(this.handleListError<any[]>('list resource', [])));
      }
    }
  }

  getVehicleName(vehicle: any) {
    return vehicle.name ? vehicle.name :
                    vehicle.model ? vehicle.model.label : vehicle.plate;
  }


  private handleListError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return throwError(() => error);
    };
  }

}
