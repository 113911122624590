import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { LayoutService } from "../services/layout.service";

@Directive({
  selector: "[appUpdatePageSizeOnChange]"
})
export class UpdatePageSizeOnChangeDirective implements OnChanges {
  @Input() changingValue: any;
  // not used yet - must be fixed
  constructor(private el: ElementRef, private layoutService: LayoutService) {}

  ngOnChanges(changes) {
    if (changes.changingValue) {
      this.layoutService.resizeLoginPage();
      this.layoutService.setContactButtonPosition();
    }
  }
}
