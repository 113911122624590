<h2 class="form-title">{{fields.form_title}}</h2>
<p>{{fields.mandatory_label}}</p>
<p app-test="password-update-instruction-message" *ngIf="!showSuccessMessage">{{fields.form_instruction_password_update}}</p>

<span *ngIf="showSuccessMessage">
  <app-icon-feedback
  icon="icon-circle-valid"
  colorClass="default-icon-color">
  </app-icon-feedback>

  <p app-test="message-password-updated">{{fields.message_password_updated}}</p>
</span>

<div class="layout-xsmall" *ngIf="!showSuccessMessage">

  <form class="ng-untouched ng-pristine ng-valid"
  [formGroup]="passwordForm"
  app-test="change-password-form">
    <div class="form-group">
      <app-textfield
      [label]="fields.password_textfield_label"
      [isPassword]="true"
      controlName="password"
      [parentForm]="passwordForm"
      [required]="true"
      [requiredErrorMessage]="fields.required_error_txt"
      [pattern]="patterns.password.pattern"
      [patternErrorMessage]="fields.invalid_error_txt"
      [hint]="fields.password_textfield_hint"
      app-test="New password"
      [disabled]="showSuccessMessage"
      [prefilled]="showSuccessMessage">
      </app-textfield>
    </div>
    <div class="form-group">
      <app-textfield
      [label]="fields.password_confirm_textfield_label"
      [isPassword]="true"
      controlName="confirmationPassword"
      [parentForm]="passwordForm"
      [required]="true"
      [requiredErrorMessage]="fields.required_error_txt"
      app-test="Confirmation password"
      [disabled]="showSuccessMessage"
      [prefilled]="showSuccessMessage">
      </app-textfield>
      <mat-hint *ngIf="!passwordsMatch()" class="mat-hint hint-error" style="color: #d20062 !important">
        <span app-test="validation-unconfirmed-lbl">{{ fields.password_confirm_match_error_message }}</span>
      </mat-hint>
    </div>
    
    <app-error-message *ngIf="showErrorMessage" [message]="fields.message_server_error" app-test="message-server-error"></app-error-message>

    <app-form-buttons>
      <div>
        <app-button
        [buttonLabel]="fields.confirm_button_label"
        (buttonClick)="submit()"
        [disabled]="passwordForm.invalid || !passwordsMatch() || showSuccessMessage"
        app-test="confirm-reset-password-btn">
        </app-button>
      </div>
    </app-form-buttons>
  </form>
</div>