import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

export interface CookiesAlert {
  cookiesAccepted: boolean;
  message: string;
  buttonLabel: string;
  linkLabel: string;
  linkUrl: string;
  editUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class CookiesService extends AbstractComponent {

  public cookiesBannerConfig: CookiesAlert;
  private myCookie = 'cookie_banner_disabled';

  constructor(private cookieService: CookieService, private wpService: WordpressService) {
    super('cookies-page');
    this.cookiesBannerConfig = {
      cookiesAccepted: this.isStored(),
      message: this.fields.cookie_message,
      buttonLabel: this.fields.cookie_button_label,
      linkLabel: this.fields.cookie_link_label,
      linkUrl: '/public/help/data-protection',
      editUrl: ''
    };
    this.wpService.getEditUrl(this.slug).subscribe(editUrl => {
      this.cookiesBannerConfig.editUrl = editUrl;
    });
    console.log(this.cookiesBannerConfig);
  }

  public storeCookieFromPublicPage() {
    const millisecExpirationIncrement = 1000 * 60 * 60 * 24 * 365 / 2; // six months
    const actualDate = Date.now();
    const expiryDate = new Date(actualDate + millisecExpirationIncrement);
    this.cookieService.set(
        this.myCookie,
        actualDate.toString(),
        expiryDate
      );
    console.log('Cookies accepted in date: ', this.cookieService.get(this.myCookie));
  }

  public isStored(): boolean {
    return this.cookieService.check(this.myCookie);
  }
}
