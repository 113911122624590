<app-dialog>
  <h2 class="form-title">{{ title }}</h2>

  <mat-dialog-content>
    <app-icon-feedback
      *ngIf="icon"
      [icon]="icon"
      [colorClass]="colorClass"
    ></app-icon-feedback>

    <div class="popin-content" *ngIf="text">
      <p>{{ text }}</p>
    </div>
    <ng-content></ng-content>
  </mat-dialog-content>
  <app-form-buttons class="centered-buttons">
    <app-button
      *ngIf="closingButtonLabel"
      buttonType="secondary"
      [buttonLabel]="closingButtonLabel"
      (buttonClick)="closeDialog()"
        attr.app-test="{{closingButtonAppTest}}"
    ></app-button>
    <app-button
      *ngIf="actionButtonLabel"
      buttonType="mat-primary"
      [buttonLabel]="actionButtonLabel"
      (buttonClick)="handleActionClick($event)"
       attr.app-test="{{actionButtonAppTest}}"
    ></app-button>
  </app-form-buttons>
</app-dialog>
