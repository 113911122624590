<div class="container--nested" [editSlug]="slug" app-test="faq-list">
	<app-faq-form
		app-test="faq-form"
		[searchPlaceholder]="fields.search_placeholder"
		[buttonLabel]="fields.button_label" (onModelChange)="filter($event)"
		[subtitle]="fields.form_subtitle" [searchHint]="fields.search_hint">
		{{fields.form_title}}
	</app-faq-form>
	<app-faq
		[faqData]="displayFaqDataList"
		[enableFeedback]="false"
		[emptyMessage]="fields.empty_message"
		[introText]="fields.any_question_you_have_about">
	</app-faq>
</div>