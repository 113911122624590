/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Injectable({
  providedIn: 'root'
})
export class CommercialCardsHelperService {

  private expirationCards = [];
  private noExpirationCards = [];

  constructor(private deviceService: DeviceService) { }

  public get cardsWithExpiration(): any[] {
    return this.expirationCards;
  }

  public get cardsWithNoExpiration(): any[] {
    return this.noExpirationCards;
  }

  public processWPCards(data: any)  {
    const actualMillis = new Date().getTime();

    // console.log(actualMillis);

    data.forEach((wpCard: any) => {
      const editLink = wpCard.link.substring(0, wpCard.link.indexOf('commercial-card')) + 'wp-admin/post.php?post=' + wpCard.id + '&action=edit';
      const tempCard = wpCard.acf;

      // formatting the incoming wordpress date string to ensure browser compatibility
      const formattedStartDate = tempCard.validity_start_date ? tempCard.validity_start_date.replace(' ', 'T') : '';
      const formattedEndDate = tempCard.validity_end_date ? tempCard.validity_end_date.replace(' ', 'T') : '';

      // validity start and end date are cosidered provided in UTC format
      const startMillis = tempCard.validity_start_date ? this.getElapsedMillisecUTC(new Date(formattedStartDate)) : undefined;
      const endMillis = this.getElapsedMillisecUTC(new Date(formattedEndDate));

      /*
      console.log('Debugging card ' + wpCard.id + ' - ' +  tempCard.commercial_card_title);
      console.log('Start: ' + startMillis + ' - ' + new Date(startMillis) + ' <-- Unformatted: ' + tempCard.validity_start_date + ', Formatted: ' + formattedStartDate);
      console.log('End: ' + endMillis + ' - ' + new Date(endMillis) + ' <-- Unformatted: ' + tempCard.validity_end_date + ', Formatted: ' + formattedEndDate);
      console.log('Now: ' + actualMillis + ' - ' + new Date(actualMillis));
      */

      if (tempCard.is_active && startMillis) {
        if (startMillis <= actualMillis && (!endMillis || actualMillis <= endMillis)) {
          if (endMillis) {
            this.expirationCards.push(this.getCardObject(tempCard, editLink, startMillis, endMillis));
          } else if (!endMillis) {
            this.noExpirationCards.push(this.getCardObject(tempCard, editLink, startMillis, endMillis));
          }
        }
      }
    });

    // console.log(cardsWithExpiration);
    // console.log(cardsWithNoExpiration);

    // sorting the cards with expiration according to the nearest end date
    this.expirationCards.sort((a, b) => {
      if ( a.endDateMillis < b.endDateMillis ) { return -1; }
      if ( a.endDateMillis > b.endDateMillis ) { return 1; }
      return 0;
    });

    // sorting the cards without expiration by according to the nearest start date
    this.noExpirationCards.sort((a, b) => {
      if ( a.startDateMillis < b.startDateMillis ) { return 1; }
      if ( a.startDateMillis > b.startDateMillis ) { return -1; }
      return 0;
    });
  }

  private getElapsedMillisecUTC(date: Date): number {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  }

  private getCardObject(tempCard: any, editLink: string, startMillis: number, endMillis: number): any {

    let cardContent = this.filterOutHTMLTags(tempCard.commercial_card_content, ['a', 'script']);
    cardContent = cardContent.replace(new RegExp('(http).*?(uploads)', 'g'), environment.contentUploads);
    const cardImg = environment.contentUploads +
                    tempCard.commercial_card_image.substring(tempCard.commercial_card_image.indexOf('uploads') + 7);

    return {
      slug: editLink,
      startDate: tempCard.validity_start_date,
      startDateMillis: startMillis,
      endDate: tempCard.validity_end_date,
      endDateMillis: endMillis,
      imageUrl: cardImg,
      title: tempCard.commercial_card_title,
      text: cardContent,
      externalLink: tempCard.have_external_link ? tempCard.external_link_url : undefined,
      externalLinkLabel: tempCard.have_external_link ? tempCard.external_link_card_label : undefined,
      position: tempCard.position_priority.toUpperCase() === 'RIGHT' ? 'RIGHT' : 'BOTTOM',
      appTest: this.normalizeCardTitle(tempCard.commercial_card_title)
    };
  }

  private normalizeCardTitle(title: string): string {
    return title.toLowerCase().replace(/ /g, '-');
  }

  private filterOutHTMLTags(content: string, tags: string[]): string {
    // console.log(content);
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    tags.forEach((tag: string) => {
      const tagElementsInContent = doc.documentElement.querySelectorAll(tag);
      try {
        tagElementsInContent.forEach((el: any) => {
          el.remove();
        });
      } catch (error) {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        Array.prototype.forEach.call(tagElementsInContent, function(el: any) {
          el.parentNode.removeChild(el);
        });
      }
    });
    // console.log(doc.body.innerHTML);
    return doc.body.innerHTML;
  }

}
