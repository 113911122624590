<span app-test="edit-payment-method-success-page">
  <div [editSlug]="slug">
    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

            <h2 class="form-title">{{ fields.thank_you_title }}</h2>

            <app-icon-feedback
            icon="icon-circle-valid"
            colorClass="default-icon-color">
            </app-icon-feedback>
          
            <p *ngIf="updatedPaymentMethod == 'directDebit'" app-test="message-bank-account-update">
              {{ fields.bank_account_update_message }}
            </p>
            <p app-test="message-mail-sent">
              {{ fields.email_confirmation_message }}
            </p>
          
            <div class="layout-xsmall">
              <app-form-buttons>
                <div>
                  <app-button
                  buttonType="secondary"
                  [buttonLabel]="getBackToLabel()"
                  (buttonClick)="back()">
                  </app-button>
                </div>
              </app-form-buttons>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</span>
