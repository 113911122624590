import { NgModule } from '@angular/core';
import { LoginModule } from './login/login.module';
import { RegistrationModule } from './registration/registration.module';
import { ForgottenPasswordModule } from './forgotten-password/forgotten-password.module';
import { AgreementsModule } from './agreements/agreements.module';
import { CustomerModule } from './customer/customer.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { BankDetailsModule } from './bank-details/bank-details.module';
import { PaymentMethodModule } from './payment-method/payment-method.module';
import { HelpModule } from 'src/app/features/help/help.module';
import { UpdateEmailModule } from './update-email/update-email.module';
import { ErrorMessageModule } from './error-message/error-message.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    RegistrationModule,
    ForgottenPasswordModule,
    AgreementsModule,
    CustomerModule,
    VehiclesModule,
    BankDetailsModule,
    PaymentMethodModule,
    HelpModule,
    UpdateEmailModule,
    ErrorMessageModule
  ],
  declarations: []
})
export class FeaturesModule {}
