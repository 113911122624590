<mat-expansion-panel
  #expansionPanel
  (opened)="openHandler()"
  (closed)="closeHandler()"
  [expanded]="expanded"
  [ngClass]="[
    radioName !== null ? 'has-radio' : '',
    radioName !== null && expanded ? 'radio-checked' : ''
  ]"
>
  <mat-expansion-panel-header>
    <app-radio-button
      *ngIf="radioName"
      [name]="radioName"
      [value]="value"
      #radioButton
    ></app-radio-button>
    <mat-panel-title>
      <span [attr.app-test]="appTest"> {{ title }} </span>
    </mat-panel-title>
    <mat-panel-description> </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="collapse-link--panel">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
