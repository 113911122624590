import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
  host: { class: "app-radio-button" }
})
export class RadioButtonComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() colorClass: string = "";

  @Input() label: string;

  /* Whether this radio button is checked. */
  @Input() checked: boolean;

  /* Whether the radio button is disabled. */
  @Input() disabled: boolean;

  /* Whether the label should appear after or before the radio button. Defaults to 'after' */
  @Input() labelPosition: "before" | "after";

  /* Analog to HTML 'name' attribute used to group radios for unique selection. */
  @Input() name: string;

  /* Whether the radio button is required. */
  @Input() required: boolean;

  /* The value of this radio button. */
  @Input() value: any;

  /* The unique ID for the radio button. */
  @Input() id: string;

  /* Event emitted when the checked state of this radio button changes. Change events are only emitted when the value changes due to user interaction with the radio button (the same behavior as <input type-"radio">). */
  @Output() changeEmitter: EventEmitter<any> = new EventEmitter<any>();

  /* ID of the native input element inside <mat-radio-button> */
  inputId: string;

  /*The 'aria-describedby' attribute is read after the element's label and field type. */
  @Input("aria-describedby") ariaDescribedby: string;

  /* Used to set the 'aria-label' attribute on the underlying input element. */
  @Input("aria-label") ariaLabel: string;

  /* The 'aria-labelledby' attribute takes precedence as the element's text alternative. */
  @Input("aria-labelledby") ariaLabelledby: string;

  ngOnInit() {}
}
