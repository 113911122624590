<div class="content-box">
  <app-block-commercial
    [image]="image"  
    [title]="title"
    [text]="text"

    [isLink]="isLink"
    [buttonLabel]="buttonLabel"
    [link]="link"

    [isMajor]="enlarge">
  </app-block-commercial>
</div>