<div class="block-text-container negative content-box-inner">
  <div class="block-text">
    <h3 class="form-subtitle">{{ title }}</h3>
    <p *ngIf="text">{{ text }}</p>
    <ng-content></ng-content>
  </div>
</div>
<app-bg-image
  [withOverlay]="withOverlay"
  [overlayOpacity]="overlayOpacity"
  [overlayColor]="overlayColor"
  [bgImage]="bgImage"
></app-bg-image>
