import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActionData } from "../../navigation/action-group/action-group.component";

@Component({
  selector: "app-block-title",
  templateUrl: "./block-title.component.html",
  styleUrls: ["./block-title.component.scss"]
})
export class BlockTitleComponent implements OnInit {
  constructor() {}

  @Input() title: string = "Title";
  @Input() actionData?: ActionData;
  @Output() actionEvent: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {}
}
