import { Component, OnInit } from '@angular/core';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';
import { AbstractComponent } from '../abstract-component';

@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.sass']
})
export class HomeTabComponent extends AbstractComponent implements OnInit {

  public commercialCards: any[];
  public tabItems: object[];

  constructor() {
    super('common-static-fields');
  }

  ngOnInit() {
    this.commercialCards = WordpressService.commercialCards;
    this.tabItems = [
      {
        itemTitle: this.fields.agreements_tab_label,
        routerLink: '/home/agreements',
        appTest: 'tab-agreements-btn'
      },
      {
        itemTitle: this.fields.vehicles_tab_label,
        routerLink: '/home/vehicles',
        appTest: 'tab-vehicles-btn'
      }];
  }

}
