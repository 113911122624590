import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

@Injectable({
  providedIn: 'root'
})
export class VehiclesService extends Resource {

  constructor(http: HttpClient) {
    super(http, environment.restBase + '/vehicles');
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable<any|any[]>(observer => {
      super.resolve(route, state).subscribe(response => {
        if ( response instanceof Array ) {

          const _response: any[] = [];
          const promises: Promise<any>[] = [];
          response.forEach((vehicle: any) => {
            const promise = this.getVehicleImage(vehicle).then(dataImage => {
              vehicle.image = dataImage;
              _response.push(vehicle);
            });
            promises.push(promise);
          });

          Promise.all(promises).then(res => {
            observer.next(_response);
            observer.complete();
          });
        } else {
          this.getVehicleImage(response).then(dataImage => {
            response.image = dataImage;
            observer.next(response);
            observer.complete();
          });
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });

  }

  public getVehicleImage(vehicle: any) {
    return new Promise<string>((resolve, reject) => {
      if ( vehicle.links.filter((link: any) => link.rel === 'image').length === 1 ) {
        const image = JSON.parse(localStorage.getItem(vehicle.id));
        const headers = {
          'Accept' : 'image/png'
        };
        const url = environment.restBase + '/vehicles/' + vehicle.id;
        if ( image && image.mime && image.etag && image.data ) {
          headers['Accept'] = image.mime;
          headers['If-None-Match'] = image.etag;
        }
        this.http.get(url, { headers, observe: 'response', responseType: 'blob' }).toPromise().then(
          response => {
            const reader = new FileReader();
            reader.readAsDataURL(response.body);
            reader.onloadend = () => {
              const tmpImg = {
                etag : response.headers.get('ETag'),
                data : reader.result.toString(),
                mime : response.body.type
              };
              try {
                if (tmpImg.etag !== null || undefined) {
                  localStorage.setItem(vehicle.id, JSON.stringify(tmpImg));
                }
              } catch (error) {
                localStorage.clear();
              }
              resolve(tmpImg.data);
            };
          },
          error => {
            if ( error.status === 304 ) {
              resolve(image.data);
            } else {
              console.error(error);
              resolve(undefined);
            }
          });
      } else {
        resolve(undefined);
      }
    });
  }

  public saveImage(id: string, imageToUpload: File) {
    const uploadData = new FormData();
    uploadData.append('image', imageToUpload, imageToUpload.name);
    return this.http.post(environment.restBase + '/vehicles/' + id, uploadData);
  }
}
