import { Component, OnInit, Input, Inject, EventEmitter, Output, HostListener } from "@angular/core";
import { MenuItems } from "../nav-menu/nav-menu.component";
import { DialogService } from "src/app/design-system/services/dialog.service";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: "app-pwa-menu-more",
  templateUrl: "./pwa-menu-more.component.html",
  styleUrls: ["./pwa-menu-more.component.scss"]
})
export class PwaMenuMoreComponent implements OnInit {
  constructor(private deviceService: DeviceService,
              private dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.isPWA = this.deviceService.isPWA || this.deviceService.isMobileSize();
  }

  @Input() selectedItemIndex: number;
  @Input() menuItems: MenuItems[];
  @Input() footerLinks: MenuItems[];
  @Input() copyright: string;
  @Output() scrollTop: EventEmitter<any> = new EventEmitter<any>();

  @Input() isPWA: boolean;

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }
  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnInit() {
    if (this.data) {
      this.menuItems = this.data.menuItems;
      this.footerLinks = this.data.footerLinks;
      this.copyright = this.data.copyright;
    }
  }

  closePwaMenu() {
    this.dialogService.close();
    if (this.deviceService.isIE) {
      const evt = document.createEvent('Event');
      evt.initEvent('scrollTop', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('scrollTop'));
    }
  }

}
