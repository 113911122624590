import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, Observable } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public userPasswordUpdated: boolean;
  public userEmailUpdated: boolean;


  private passwordResetRequestOutcome: any;
  constructor(private httpClient: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(state.url.toString());
    if (state.url.startsWith('/username-update/confirm') === true) {
      const username = route.paramMap.get('username');
      const token = route.paramMap.get('token');
      console.log(username);
      return this.commitChange(username, token, '')
        .pipe(
          map(res => {
            console.log(res);
            return true;
          }),
          catchError(this.handleUsernameUpdateError<any>(false))
        );
    }
  }

  private handleUsernameUpdateError<T>(result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  list() {
    throw new Error('Method not implemented.');
  }

  get(id: any) {
    throw new Error('Method not implemented.');
  }

  public passwordResetRequest(email: string, captcha: string) {
    const url = environment.restBase + '/users/' + email + '/resets?captcha=' + captcha;
    const promise = this.httpClient.put<void>(url, undefined).toPromise();
    promise.then(response => {
      this.passwordResetRequestOutcome = { status: 'success', username: email };
      return this.passwordResetRequestOutcome;
    },
      error => {
        this.passwordResetRequestOutcome = { status: 'error' };
        return this.passwordResetRequestOutcome;
      });
    return promise;
  }

  public getPasswordResetRequestOutcome() {
    return this.passwordResetRequestOutcome;
  }

  public commitChange(email: string, token: string, body: string) {
    return this.httpClient.post<void>(environment.restBase + '/users/' + email + '/commit/' + token, body)
    .pipe(catchError( err => {
        console.log(err);
        return throwError(() => err);
      })
    );
  }

  public editPassword(oldPassword: string, newPassword: string) {
    return this.httpClient
    .put<void>(environment.restBase + '/users/self/password',{oldPassword:oldPassword , newPassword:newPassword })
    .pipe(
      map(res => {
        console.log(res);
        return res;
      }),
      catchError(err => {
        console.log(err);
        return throwError(() => err);
      })
    );
  }

  public editUsername(password: string, newUsername: string) {
    return this.httpClient
    .put<void>(environment.restBase + '/users/self/username', {newUsername:newUsername, password:password})
    .pipe(
      map(res => {
        console.log(res);
        return res;
      }),
      catchError(err => {
        console.log(err);
        return throwError(() => err);
      })
    );
  }

}
