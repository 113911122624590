import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  TemplateRef,
  ElementRef
} from "@angular/core";
import { Globals } from "../../../globals";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {
  constructor(private globals: Globals, private elementRef: ElementRef) {}

  @Input() videoUrl: string;

  closeWelcome() {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.parentElement.parentElement.remove();
    this.globals.updateMasonry();
  }

  ngOnInit() {}
}
