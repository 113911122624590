import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule, Routes } from '@angular/router';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from '../../cms-manager/cms-manager.module';
import { ForgottenPasswordPageComponent } from './forgotten-password-page/forgotten-password-page.component';
import { ForgottenPasswordFormComponent } from './forgotten-password-form/forgotten-password-form.component';
import { ForgottenPasswordSuccessComponent } from './forgotten-password-success/forgotten-password-success.component';
import { ForgottenPasswordChangeComponent } from './forgotten-password-change/forgotten-password-change.component';

const routes: Routes = [
  {
    path: 'forgotten-password',
    component: ForgottenPasswordPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'request',
        pathMatch: 'full'
      },
      {
        path: 'request',
        component: ForgottenPasswordFormComponent
      },
      {
        path: 'sent',
        component: ForgottenPasswordSuccessComponent
      },
      {
        path: 'change',
        component: ForgottenPasswordChangeComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    ForgottenPasswordPageComponent,
    ForgottenPasswordFormComponent,
    ForgottenPasswordSuccessComponent,
    ForgottenPasswordChangeComponent
  ]
})
export class ForgottenPasswordModule { }
