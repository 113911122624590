
import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/design-system/services/device.service';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-update-email-page',
  templateUrl: './update-email-page.component.html',
  styleUrls: ['./update-email-page.component.sass']
})
export class UpdateEmailPageComponent extends AbstractComponent implements OnInit {

  public bgImage: string;
  public isPWA = false;
  public footerLinks: MenuItems[];
  public footerCopyright: string;
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  constructor(public cookiesService: CookiesService,
              private deviceService: DeviceService,
              private pageLayoutConfigService: PageLayoutConfigService) {
                super('customer-page');
              }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;
    this.bgImage = environment.contentUploads + '/2022/02/img_help_login.jpg';
    this.footerLinks = this.pageLayoutConfigService.publicFooterLinks;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.publicContactBlocksData;
  }


}
