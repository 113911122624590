<app-collapse-link [title]="question" [appTest]="question">
  <p #answer class="answer"></p>
  <form *ngIf="enableFeedback">
    <h3 class="form-heading">{{ formHeading }}</h3>
    <app-form-buttons>
      <app-button>Yes</app-button>
      <app-button buttonType="secondary">No</app-button>
    </app-form-buttons>
  </form>
</app-collapse-link>
