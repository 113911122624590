import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-forgotten-password-success',
  templateUrl: './forgotten-password-success.component.html',
  styleUrls: ['./forgotten-password-success.component.sass']
})
export class ForgottenPasswordSuccessComponent extends AbstractComponent implements OnInit {

  public fields: any;

  constructor(private router: Router, private usersService: UsersService) {
    super('forgotten-password-page');
  }

  ngOnInit() {
    if ( !this.usersService.getPasswordResetRequestOutcome() ) {
      this.router.navigate(['/login']);
    }
  }

  back() {
    this.router.navigate(['/login']);
  }

}
