<div [editSlug]="slug">
  <div class="content-box-inner ng-star-inserted">
   
    <div class="layout-narrow">
      <h2 class="form-title">{{fields.form_title}}</h2>
      <p>{{fields.mandatory_label}}</p>
      <p app-test="message-registration-validated" *ngIf="fromValidation">{{fields.validation_success_message}}</p>
      <div class="layout-xsmall">

        <form class="ng-untouched ng-pristine ng-valid"
        [formGroup]="loginForm"
        app-test="login-form">

          <div class="form-group">
            <app-textfield
            [label]="fields.email_textfield_label"
            controlName="username"
            [parentForm]="loginForm"
            [required]="true"
            [requiredErrorMessage]="fields.required_error_txt"
            [pattern]="patterns.email.pattern + '|' + patterns.customerId.pattern"
            [patternErrorMessage]="fields.login_pattern_error_message"
            app-test="username-txt"
            [isMail]="true">
            </app-textfield>
            <app-checkbox
            [label]="fields.email_checkbox_label"
            controlName="usernameCheckbox"
            [parentForm]="loginForm">
            </app-checkbox>
          </div>
          <div class="form-group">
            <app-textfield
            [label]="fields.password_textfield_label"
            [isPassword]="true"
            controlName="password"
            [parentForm]="loginForm"
            [required]="true"
            [requiredErrorMessage]="fields.required_error_txt"
            app-test="password-txt"
            (keypress)="eventKeyHandler($event)">
            </app-textfield>
            <a class="link" routerLink="/forgotten-password/request" app-test="forgotten-password-link" tabindex="-1">
              {{fields.forgot_password_label}}
            </a>
          </div>

          <ngx-recaptcha2
            *ngIf="captchaRequired()"
            [siteKey]="env.captchaKey"
            useGlobalDomain="false"
            size="Normal"
            hl="de"
            theme="Light"
            type="Image"
            [required]="env.captchaFormControl"
            formControlName="recaptcha">
          </ngx-recaptcha2>
          <br/>

          <app-error-message *ngIf="loginErrors.invalidCredential && loginErrors.remainingAttempts > 0" [message]="fields.message_unsuccessful_login" app-test="message-invalid-credential"></app-error-message>
          <app-error-message *ngIf="loginErrors.invalidCredential && loginErrors.remainingAttempts == 1" [message]="fields.last_attempt_warning" app-test="message-last-attempt"></app-error-message>
          <app-error-message *ngIf="loginErrors.invalidCredential && loginErrors.remainingAttempts <= 0" [message]="fields.too_many_attempts_blocked" app-test="message-username-locked-too-many-attempts"></app-error-message>
          <app-error-message *ngIf="loginErrors.serverError" [message]="fields.message_server_error" app-test="message-server-error"></app-error-message>
          <app-error-message *ngIf="loginErrors.accessDenided" app-test="no-agreement-error">
            <span [innerHTML]="fields.message_no_agreement_error | safe: 'html'"></span>
          </app-error-message>
          <app-error-message *ngIf="loginErrors.accountLocked" [message]="fields.message_account_locked" app-test="message-account-locked"></app-error-message>

          <app-form-buttons>
            <div>
              <app-button
              [buttonLabel]="fields.login_button_label"
              (buttonClick)="login()"
              [disabled]="!loginForm.valid"
              app-test="confirm-btn">
              </app-button>
            </div>
          </app-form-buttons>
        </form>

      </div>
    </div>
    <div class="separator"></div>
  </div>

  <div class="content-box-inner">
    <div class="layout-narrow">
      <h2 class="form-subtitle">{{fields.identify_customer_label}}</h2>
      <app-form-buttons>
        <div>
          <app-button
                  [buttonLabel]="fields.identify_customer_button"
                  (buttonClick)="signup()"
                  app-test="identify-customer-button">
          </app-button>
        </div>
      </app-form-buttons>
    </div>
  </div>
</div>




