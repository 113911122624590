import { Component, OnInit, Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-input-search",
  templateUrl: "./input-search.component.html",
  styleUrls: ["./input-search.component.scss"]
})
export class InputSearchComponent implements OnInit {
  @Input() appTest: string;

  @Input() value: string;
  @Input() placeholder ? = '';
  @Input() hint ? = '';
  @Output() onModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
