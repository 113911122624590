import { Component, OnInit, Input } from "@angular/core";

//change RouterLink and RouterLinkActive type
export interface SwitchItem {
  itemTitle: string;
  routerLink?: string;
  appTest: string;
}

@Component({
  selector: "app-switch-container",
  templateUrl: "./switch-container.component.html",
  styleUrls: ["./switch-container.component.scss"]
})
export class SwitchContainerComponent implements OnInit {
  constructor() {}

  @Input() selectedItemIndex: number;
  @Input() open: boolean;
  @Input() switchItems: SwitchItem[];


  itemClickHandler($event, i) {
    this.open = !this.open;
    this.selectedItemIndex = i;
  }

  ngOnInit() {}
}
