import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-forgotten-password-change',
  templateUrl: './forgotten-password-change.component.html',
  styleUrls: ['./forgotten-password-change.component.sass']
})
export class ForgottenPasswordChangeComponent extends AbstractComponent implements OnInit {

  private username: string;
  private token: string;
  public showErrorMessage = false;
  public showSuccessMessage = false;
  public passwordForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private usersService: UsersService,
  ) {
    super('forgotten-password-page');
  }

  ngOnInit(): void {
    this.username = this.activatedRoute.snapshot.paramMap.get('username');
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    if (!this.username || !this.token) 
    { //FIXME should show a message
      this.router.navigate(['/login']);
    }

    this.passwordForm = this.fb.group({
      password: [''],
      confirmationPassword: ['']
    });

  }


  passwordsMatch(): boolean {
    if (!this.passwordForm.controls.confirmationPassword.dirty) {
      return true;
    }

    if (this.passwordForm.value.password === this.passwordForm.value.confirmationPassword) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
    this.usersService.commitChange(
      this.username,
      this.token,
      this.passwordForm.value.password
    ).subscribe(
      data => {
        this.showSuccessMessage = true;
      },
      error => {
        this.showErrorMessage = true;
      }
    );


  }


}
