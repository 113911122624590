<mat-form-field
  class="mat-form-field datepicker {{ hasError ? 'error' : '' }} {{
    isMobile ? 'mobile' : ''
  }}"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    autocomplete="off"
    [type]="isMobile ? 'date' : 'text'"
    [(ngModel)]="value"
    [min]="minDate"
    [max]="maxDate"
    (change)="formatDate()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker">
    <app-icon
      matDatepickerToggleIcon
      icon="icon-circle-calendar"
      iconWidth="30px"
      iconHeight="30px"
      [colorClass]="hasError ? 'color-warn' : 'color-grey-70'"
    >
    </app-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startAt]="startAt"
    [disabled]="disabled"
    [opened]="opened"
    [startView]="startView"
  ></mat-datepicker>
</mat-form-field>

<mat-hint *ngIf="hint" [ngClass]="{ 'hint-error': hasError }"
  >{{ selectedHint || hint }}
</mat-hint>

<mat-hint *ngIf="error" [ngClass]="{ 'hint-error': hasError }"
  >{{ error }}
</mat-hint>
