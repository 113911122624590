import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-cookie-details",
  templateUrl: "./cookie-details.component.html",
  styleUrls: ["./cookie-details.component.scss"]
})
export class CookieDetailsComponent implements OnInit {
  @Input() purposeLabel: string = "Purpose";
  @Input() retentionPeriodLabel: string = "Retention period";
  @Input() descriptionLabel: string = "Description";
  @Input() usedNamesLabel: string = "Used names";
  @Input() sharingLabel: string = "Sharing";
  @Input() privacyStatementLabel: string = "Privacy statement";

  @Input() caption: string = "Google Analytics";
  @Input() purpose: string = "gathering analytical information";
  @Input() period: string = "1 year";
  @Input() description: string =
    "GWith Google analytics we gather information about the usage of our website";
  @Input() usednames: string =
    "_ga, _gid, _gat, _gaexp, _utm, __utmc, UTMD_, __utmv, __utmz, _gat_gtag_UA_ID, gadwp_wg_default_view, gadwp_wg_default_swmetric, gadwp_wg_default_metric, gadwp_wg_default_dimension, gadwp_ir_default_dimension, gadwp_ir_default_swmetric, gadwp_ir_default_metric";
  @Input() sharingurl: string = "https://policies.google.com/privacy";

  constructor() {}

  ngOnInit() {}
}
