import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

export interface ActionData {
  id: string;
  icon: "edit" | "download" | "delete";
  label: string;
}
@Component({
  selector: "app-action-group",
  templateUrl: "./action-group.component.html",
  styleUrls: ["./action-group.component.scss"]
})
export class ActionGroupComponent implements OnInit {
  constructor() {}

  @Input() actionData: ActionData;
  @Input() iconLeft: boolean;
  @Input() displayNameInMobile: boolean;
  @Output() actionEmitter: EventEmitter<string> = new EventEmitter<string>();

  //actions available : download | delete | edit

  getIcon() {
    let icon = "";
    switch (this.actionData.icon) {
      case "download":
        icon = "icon-circle-download";
        break;
      case "delete":
        icon = "icon-circle-trash";
        break;
      case "edit":
      default:
        icon = "icon-circle-edit";
    }
    return icon;
  }

  ngOnInit() {}
}
