<div class="app-select space-between">
  <mat-form-field class="mat-form-field {{ hasError ? 'error' : '' }}" 
    [formGroup]="parentForm">
    <mat-label>{{ label }}</mat-label>

    <mat-select
      [(value)]="selectedOption"
      [multiple]="multiple"
      [disableRipple]="true"
      [formControlName]="controlName"
      (selectionChange)="onSelectionChange($event)"
      [required]="required"
      [attr.app-test]="appTest"
    >
      <mat-option *ngFor="let option of options; let i = index" [value]="option" [attr.app-test]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--app-info-icon
    *ngIf="tooltipMessage"
    [tooltipMessage]="tooltipMessage"
  ></app-info-icon-->
</div>

<mat-hint
  *ngIf="hint"
  [ngClass]="{ 'hint-error': hasError, narrowed: tooltipMessage }"
  >{{ selectedHint || hint }}
</mat-hint>

<mat-hint
  *ngIf="error"
  [ngClass]="{ 'hint-error': hasError, narrowed: tooltipMessage }"
  >{{ error }}
</mat-hint>
