import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  HostListener
} from "@angular/core";

@Component({
  selector: "app-form-buttons",
  templateUrl: "./form-buttons.component.html",
  styleUrls: ["./form-buttons.component.scss"],
  host: { class: "form-buttons" }
})
export class FormButtonsComponent implements OnInit, AfterViewInit {
  @Input() appTest: string;

  @ViewChild("buttons", { read: ElementRef, static: true })
  buttons: ElementRef;

  constructor() {}

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updateButtonSize(this.nodeList);
  }

  nodeList: HTMLCollection;

  ngOnInit() {}

  ngAfterViewInit() {
    this.InitButtonSize();
    setTimeout(() => {
      this.updateButtonSize(this.nodeList);
    }, 0);
  }
  InitButtonSize() {
    const buttonsDomElt: HTMLElement = this.buttons
      .nativeElement as HTMLElement;
    this.nodeList = buttonsDomElt.children;

    if (!this.nodeList) {
      return false;
    }

    if (this.nodeList.length < 2) {
      buttonsDomElt.parentElement.classList.add("single-button");
      return true;
    }

    let stringLength = 0;

    Array.prototype.forEach.call(this.nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      //we test if the child is a button as there are spamming testing comment nodes in angular
      if (button) {
        const innerText = button.innerText || "";
        stringLength = Math.max(stringLength, innerText.length);
      }
    });
    if (stringLength > 12)
      buttonsDomElt.parentElement.classList.add("long-button");
  }

  resetButtons(nodeList) {
    if (!this.nodeList) {
      return false;
    }
    Array.prototype.forEach.call(nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      if (button) {
        button.style.width = "auto";
        button.style.height = "auto";
      }
    });
  }

  updateButtonSize(nodeList) {
    if (!this.nodeList) {
      return false;
    }
    if (this.nodeList.length < 2) {
      return false;
    }
    this.resetButtons(nodeList);
    let buttonWidth = 0;
    let buttonHeight = 0;
    Array.prototype.forEach.call(nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      if (button) {
        buttonWidth = Math.max(buttonWidth, Math.ceil(button.clientWidth)) + 1;
      }
    });
    Array.prototype.forEach.call(nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      if (button) {
        button.style.width = buttonWidth + "px";
      }
    });

    Array.prototype.forEach.call(nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      if (button) {
        buttonHeight = Math.max(buttonHeight, button.clientHeight);
      }
    });
    Array.prototype.forEach.call(nodeList, function(element: Element) {
      const button: HTMLElement = element.firstChild as HTMLElement;
      if (button) {
        button.style.height = buttonHeight + "px";
      }
    });
  }
}
