<div class="agreement-synthese">
  <div class="icon-container">
    <app-icon
      [icon]="syntheseIcon"
      iconWidth="60px"
      iconHeight="60px"
    ></app-icon>
  </div>
  <div class="synthese-content">
    <!--<h2 class="synthese-title">{{ syntheseTitle }}</h2>-->
    <h3 *ngIf="syntheseHeading" class="synthese-heading">
      {{ syntheseHeading }}
    </h3>
    <app-synthese-list [syntheseList]="syntheseList"> </app-synthese-list>
    <ng-content></ng-content>
  </div>
</div>
