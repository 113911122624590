<div
  app-test="bg-image"
  class="bg-image"
  [ngClass]="{ contain: contain }"
  [ngStyle]="{ 'background-image': 'url(' + path + bgImage + ')' }"
></div>
<div
  class="overlay"
  *ngIf="withOverlay"
  [ngStyle]="{ 'background-color': overlayColor, opacity: overlayOpacity }"
></div>
