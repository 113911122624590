<div class="container--nested" [editSlug]="slug" app-test="contact-us-page">
  <div class="content-box content-box--form">
    <div class="content-box-inner">
      <div class="layout-narrow">

        <h2 class="form-title">{{fields.contact_us_send_us_message_label}}</h2>
        <p>{{fields.mandatory_label}}</p>

        <form class="ng-untouched ng-pristine ng-valid" [formGroup]="contactUsForm" app-test="contact-us-form">

          <div class="form-group" *ngIf="agreementsSelectOptions.length > 1">
            <app-select [label]="fields.contact_us_agreement_number_label" [parentForm]="contactUsForm"
              controlName="agreementNumber" [options]="agreementsSelectOptions"
              (selectionChange)="setSelectedAgreement($event)"
              appTest="Agreement Number"
              [required]="true" 
              >                  
            </app-select>
          </div>

          <div class="form-group" *ngIf="agreementsSelectOptions.length == 1">
            <app-textfield [label]="fields.contact_us_agreement_number_label" [parentForm]="contactUsForm"
              controlName="agreementNumber" [disabled]="true" [prefilled]="true"
              appTest="Agreement Name">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_customer_name_label" [parentForm]="contactUsForm"
              controlName="customerName" [disabled]="true" [prefilled]="true"
              appTest="Customer Name">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-select [label]="fields.contact_us_type_label" [parentForm]="contactUsForm"
              controlName="type" [options]="messageTypeSelectOptions"
              (selectionChange)="setSelectedMessageType($event)"
              appTest="Message Type"
              [required]="true">
            </app-select>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_email_address_label" [parentForm]="contactUsForm"
              controlName="email" [disabled]="true" [prefilled]="true"
              appTest="Email Address">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textfield [label]="fields.contact_us_telephone_number_label" [parentForm]="contactUsForm"
              controlName="phone" [required]="false" [pattern]="patterns.phone.pattern"
              [requiredErrorMessage]="fields.required_error_txt" [patternErrorMessage]="fields.invalid_error_txt"
              appTest="Telephone Number">
            </app-textfield>
          </div>

          <div class="form-group">
            <app-textarea [label]="fields.contact_us_message_label" [parentForm]="contactUsForm" controlName="message" [required]="true"
              [requiredErrorMessage]="fields.required_error_txt" maxLength="5000" minRows="5" [displayCharCount]="true" appTest="Message">
            </app-textarea>
          </div>

          <div class="form-group">
            <label class="upload-licence-label">{{ fields.contact_us_attached_document_label }}</label>
            <app-file-upload 
            [dropFormatsAccepted]="dropFormatsAccepted" 
            [dropInstructions]="fields.contact_us_upload_label"
            [fileLabel]="fields.contact_us_attached_document_label"
            [multiple]="false"
            [browseBtnLabel]="fields.browse_button_label"
            (onFileListUpdate)="updateAttachment($event)"
            [fileMaxSize]="5120000"
            [errorMessageNotAllowed]="fields.contact_us_file_error_not_allowed"
            [errorMessageTooBig]="fields.contact_us_file_error_too_big"
            (fileError)="setFileError($event)"
            >
            </app-file-upload>
            <app-alert-message *ngIf="!isNullOrEmpty(errorFileMessage)">
              <span class="text-bold" app-test="contact-us-file-error">{{errorFileMessage}}</span>
            </app-alert-message>
          </div>
        </form>

        <app-form-buttons>
          <div>
            <app-button 
            [buttonLabel]="fields.confirm_button_label" 
            (buttonClick)="send()"
            [disabled]="contactUsForm.invalid" 
            app-test="confirm-btn"
            >
            </app-button>
          </div>
        </app-form-buttons>
      </div>
    </div>
  </div>
</div>