import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"]
})
export class StepperComponent implements OnInit, AfterViewInit {
  constructor() {}
  @Input() appTest: string;

  @ViewChild(MatStepper, { static: true }) matStepper: MatStepper;
  @Input() id = "stepper1";

  @Input() stepData: StepData[] = [
    {
      label: "Identification",
      formGroup: "",
      completed: false,
      editable: false
    },
    {
      label: "Email",
      formGroup: "",
      completed: false,
      editable: false
    },
    {
      label: "Mot de passe",
      formGroup: "",
      completed: false,
      editable: false
    }
  ];

  @Input() selectedIndex: number;
  @Input() linear: boolean = true;
  @Input() editable: boolean = false;

  ngAfterViewInit() {
    this.updateStepper(this.selectedIndex);
  }
  ngOnInit() {}

  onStepChange(event: any) {
    const selectedIndex = event.selectedIndex;
    this.updateStepper(selectedIndex);
  }
  updateStepper(selectedIndex: number) {
    //in the demo page we have 3 steppers in one page, so I added an id to differentiaite the steppers.
    //this to style the completed step
    //as there's no class to identifiate if a step is completed in the default stepper.
    const domList: any = window.document.querySelectorAll(
      "#" + this.id + " .mat-step-header"
    );
    if (!domList) return false;

    Array.prototype.forEach.call(domList, function(domElt, index) {
      if (index < selectedIndex) {
        domElt.classList.add("done");
      } else {
        domElt.classList.remove("done");
      }
    });

    if (!this.matStepper || !this.matStepper.steps) return false;

    this.matStepper.steps.forEach((matStep, index) => {
      if (index < selectedIndex) {
        matStep.completed = true;
        //if(this.editable) matStep.editable = true;
      } else {
        matStep.completed = false;
        // if(this.editable)  matStep.editable = false;
      }
    });
  }
}

export interface StepData {
  label: string;
  formGroup?: string;
  completed?: boolean;
  editable?: boolean;
}
