import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { EmbedVideoService } from "ngx-embed-video";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"]
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() appTest: string;

  @ViewChild("div", { static: true })
  divElement: ElementRef;

  @ViewChild("iframe", { static: false })
  iframeElement: ElementRef;

  div: HTMLElement;

  iframe: HTMLElement;

  video_iframe: any;

  private _videoUrl: string;
  public get videoUrl(): string {
    return this._videoUrl;
  }
  @Input()
  public set videoUrl(value: string) {
    this._videoUrl = value;

    this.video_iframe = this.embedService.embed(this.videoUrl);

    this.updateVideoIframeSize();
  }

  constructor(private embedService: EmbedVideoService) {}

  ngAfterViewInit() {
    this.updateVideoIframeSize();
  }

  updateVideoIframeSize() {
    if (!this.video_iframe) return false;
    const iframe: HTMLElement = this.divElement.nativeElement
      .firstChild as HTMLElement;
    if (!iframe) return null;
    iframe.style.width = "100%";
    iframe.style.height = (iframe.clientWidth * 1080) / 1920 + "px";
  }
  ngOnInit() {}
}
