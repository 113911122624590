<app-nested-masonry-layout
  nestedType="contract"
  (showMoreEmitter)="showMoreEmitter.emit($event)"
  [withShowMoreButton]="withShowMoreButton"
  [showMoreStatus]="showMoreStatus"
  [showMoreLabel]="showMoreLabel"
  [showLessLabel]="showLessLabel"
>
  <div
    attr.app-test="agreement-card-{{contractData.appTest}}"
    class="grid-item--contract"
    *ngFor="let contractData of blockContractsData"
  >
    <div class="content-box">
      <app-card
        [id]="contractData.id"
        [title]="contractData.title"
        [subtitle]="contractData.subtitle"
        [icon]="contractData.icon"
        [agreementNumberLabel]="contractData.agreementNumberLabel"
        [agreementNumber]="contractData.agreementNumber"
        [hasButton]="contractData.hasButton"
        [buttonIsLink]="contractData.buttonIsLink"
        [buttonLabel]="contractData.buttonLabel"
        [alert]="contractData.alert"
        [expired]="contractData.expired"
        [info]="contractData.info"
        (cardButtonClick)="cardAction($event, contractData.id)"
      ></app-card>
    </div>
  </div>
</app-nested-masonry-layout>
