/* eslint-disable @typescript-eslint/quotes */

import { Component, OnInit, Inject, Input, Output, EventEmitter, SecurityContext } from "@angular/core";
import { DialogService } from "src/app/design-system/services/dialog.service";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-pop-in",
  templateUrl: "./pop-in.component.html",
  styleUrls: ["./pop-in.component.scss"]
})
export class PopInComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;
  @Input() htmlContent: string;
  @Input() title: string;
  @Input() closingButtonLabel: string;
  @Input() actionButtonLabel: string;
  @Input() closingButtonAppTest: string;
  @Input() actionButtonAppTest: string;

  colorClass = "color-grey-90";

  @Output() actionEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer
  ) {}

  closeDialog() {
    this.dialogService.close();
  }

  ngOnInit() {
    if (this.data.title || this.data.icon) {
      this.title = this.data.title;
      this.icon = this.data.icon;
      this.closingButtonLabel = this.data.closingButtonLabel;
      this.actionButtonLabel = this.data.actionButtonLabel;
      if (this.data.htmlContent) {
        this.htmlContent = this.data.htmlContent;
      } else {
        this.text = this.data.text;
      }
    }
    if (this.htmlContent) {
      this.htmlContent = this.domSanitizer.sanitize(
        SecurityContext.HTML,
        this.htmlContent
      );
    }
    if (this.icon === "icon-circle-warning") {
      this.colorClass = "color-accent";
    }
  }

  handleActionClick(action: any) {
    //console.log(action);
    this.actionEmitter.emit(action);
  }
}
