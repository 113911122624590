<span app-test="error-message-page">
  <div [editSlug]="slug">
    <app-message-block 
      *ngIf="successRequest==false"
      [icon]="'icon-circle-close'"
      [message]="fields.generic_error_message"
      [button]="{
        label : fields.generic_error_page_back
      }"
      (buttonEmitter)="backToHome()"
      appTest="error-message">
      </app-message-block>
  </div>
</span>