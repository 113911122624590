import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { Globals } from "src/app/design-system/globals";

@Component({
  selector: "app-offline-alert",
  templateUrl: "./offline-alert.component.html",
  styleUrls: ["./offline-alert.component.scss"],
  host: { class: "offline-alert" }
})
export class OfflineAlertComponent implements OnInit {
  @Input() appTest: string;

  @Input() messageTitle: string = "No internet connexion";
  @Input() message: string = "Some content may not be available offline";

  constructor(private elementRef: ElementRef) {}

  @Input() videoUrl: string;

  closeAction = () => {
    this.elementRef.nativeElement.remove();
  };

  ngOnInit() {}
}
