<app-dialog>
  <h2 class="title-underlined-centered-accent" mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content>
    <p *ngIf="text">{{ text }}</p>
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-button buttonType="secondary" (buttonClick)="closeDialog()">{{ buttonLabel }}</app-button>
  </mat-dialog-actions>

</app-dialog>
