import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { AppRoutingModule } from './core/app-routing.module';
import { AppComponent } from './app.component';
import { DesignSystemModule } from './design-system/modules/design-system.module';
import { SvgModule } from './design-system/modules/svg.module';
import { CmsManagerModule } from './cms-manager/cms-manager.module';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth-guard';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { FeaturesModule } from './features/features.module';
import { WordpressService } from './cms-manager/wordpress/wordpress.service';
import { StaticResourceService } from './services/static-resource/static-resource.service';
import { RestInterceptor } from './interceptors/rest-interceptor';
import { PipesModule } from './pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PageLayoutConfigService } from './services/page-layout-config/page-layout-config.service';
import { CookieService } from 'ngx-cookie-service';

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    DesignSystemModule,
    SvgModule,
    CmsManagerModule,
    FeaturesModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        disallowedRoutes: ['/rest/oauth/token?grant_type=password', environment.contentBase],
        headerName: 'Authorization',
        skipWhenExpired: true
      }
    }),
    NgxUiLoaderModule.forRoot({
      bgsColor: '#ff7900',
      bgsType: SPINNER.pulse, // background spinner type
      fgsColor: '#ff7900',
      fgsType: SPINNER.pulse, // foreground spinner type
      pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
      pbThickness: 5, // progress bar thickness
      pbColor: '#ff7900'
    }),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      excludeRegexp: ['^((?!\/rest\/).)*$']
    }),
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    CookieService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (wordpressService: WordpressService,
                   staticResourceService: StaticResourceService,
                   pageLayoutConfigService: PageLayoutConfigService) =>
        (): Promise<any> =>
          new Promise<void>((resolve) => {
            Promise.all([
              wordpressService.initPages(),
              wordpressService.initFaqsCategories(),
              wordpressService.initFaqs(),
              wordpressService.initCommercialCards(),
              staticResourceService.init()])
              .then(() => {
                pageLayoutConfigService.init();
                resolve();
              });
          }),
      deps: [WordpressService, StaticResourceService, PageLayoutConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
