import { Component, OnInit, Input } from "@angular/core";

export interface NotificationItem {
  id: string;
  title: string;
  text: string;
  buttonLabel: string;
}

@Component({
  selector: "app-feed-notification",
  templateUrl: "./feed-notification.component.html",
  styleUrls: ["./feed-notification.component.scss"]
})
export class FeedNotificationComponent implements OnInit {
  constructor() {}

  @Input() appTest: string;

  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  @Input() buttonLabel: string;

  action() {
    console.log(this.id);
  }
  ngOnInit() {}
}
