<a
  mat-tab-link
  #tab
  [routerLink]="tabData.routerLink"
  routerLinkActive
  #rla="routerLinkActive"
  [active]="(rla.isActive && tabData.routerLink) || active"
  [disableRipple]="true"
  (click)="tabClickEmitter.emit($event)"
>
  <app-icon
    iconWidth="24px"
    iconHeight="24px"
    [icon]="tabData.icon"
    colorClass="color-grey-90"
  >
  </app-icon>
  <span>{{tabData.label}}</span>
</a>
