import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ContentChild,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DeviceService } from "../../../services/device.service";
import { Globals } from "../../../globals";
import { MenuItems } from "../nav-menu/nav-menu.component";
import { TabData } from '../pwa-tab/pwa-tab.component';
import { NotificationItem } from '../../modals/feed-notification/feed-notification.component';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { FeedComponent } from '../../modals/feed/feed.component';

@Component({
  selector: "app-page-layout",
  templateUrl: "./page-layout.component.html",
  styleUrls: ["./page-layout.component.scss"]
})
export class PageLayoutComponent implements OnInit, AfterViewInit {
  //
  public static SELECTED_NAV_ITEM_INDEX: number;
  //
  constructor(
    private layoutService: LayoutService,
    private deviceService: DeviceService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private globals: Globals
  ) {
    this.globals.isLoginPage = false;
  }
  @Input() appTest: string;

  @ContentChild("pageContentRef", {static: true}) pageContentRef;

  @Input() isHome: boolean = false;
  @Input() isSubPage: boolean = false;
  @Input() backToLabel: string;
  @Input() backToLink: string;
  @Input() showMore: boolean = false;
  @Input() showMoreLabel: string = "Show me more";
  @Input() selectedNavItemIndex: number;
  @Input() selectedTabBarIndex: number;
  @Input() title: string;

  @Input() isMenuMore: boolean;

  @Input() designSystem: boolean;
  @Input() isOffline: boolean = true;

  nestedMasonry: boolean = false;
  @Input() nestedType: "contract" | "vehicule";

  @Input() footerLinks: MenuItems[];
  @Input() menuItems: MenuItems[];
  @Input() userName: string;
  @Input() welcomeText: string;

  @Input() isPWA: boolean = false;

  @Input() tabBarData: TabData[];

  @Input() tabBarMoreLabel: string;

  @Input() alertsTitle: string;
  @Input() copyright: string;

  @Input() notificationItems: NotificationItem[] = [];

  @Input() contactBlocksData: [];
  @Input() contactBlocksButton: string;

  @HostListener("window:scrollTop", ["$event"])
  scrollTop(event) {
    this.layoutService.scrollToTop(event);
  }

  @HostListener("window:resize", ["$event"])
  sizeChange() {
    const pwa = this.isPWA;
    this.updatePWA();
    if (this.isPWA !== pwa) {
      this.updateLayout();
    }
  }

  @HostListener('window:show-alert', ['$event'])
  openNotificationDialog($event) {
    const header = document.querySelector( ".app-header" );
    if (!this.notificationItems || this.notificationItems.length < 1) {
      return false;
    }
    this.dialogService.open("feed", FeedComponent, {
      ...DialogService.DIALOG_CONFIG.feed,
      data: {
        kind: "notification",
        feedTitle: this.alertsTitle,
        feedItemsData: this.notificationItems,
        offsetTop: header ? header.getBoundingClientRect().height : 0
      }
    });
  }

  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  updateLayout() {
    if (this.isPWA) {
      document.querySelector("html").classList.add("pwa");
      // set the active pwa tab bar tab
      if (this.selectedNavItemIndex === 0) {
        this.selectedTabBarIndex = 0;
      } else if (this.selectedNavItemIndex === 1) {
        this.selectedTabBarIndex = 2;
      } else if (this.selectedNavItemIndex === 2) {
        this.selectedTabBarIndex = 1;
      }
      this.changeDetectorRef.detectChanges();
    } else {
      document.querySelector("html").classList.remove("pwa");
    }

    setTimeout(() => {
      this.updateMasonry();
    }, 0);
  }

  ngOnInit() {
    this.globals.currentPageLayout = this;

    PageLayoutComponent.SELECTED_NAV_ITEM_INDEX = this.selectedNavItemIndex;

    this.updatePWA();
    this.updateLayout();
  }

  ngAfterViewInit() {
    if (this.nestedType) { this.nestedMasonry = true; }
    setTimeout(() => {
      this.layoutService.initMasonry(this.nestedMasonry, this.nestedType);
    }, 0);
  }
  // button showmore in the home page tabs wuth nested masonry
  showHideMore = () => {
    this.showMore = !this.showMore;
    this.showMoreLabel = this.showMore ? "show me less" : "show me more";

    setTimeout(() => {
      this.layoutService.updateMasonry(this.nestedMasonry);
    }, 0);
  }

  // update not nested  - collapse link - history show more - PWA / desktop switch
  // we are in the page layout, it's the same instance which created masonry
  updateMasonry() {
    setTimeout(() => {
      this.layoutService.updateMasonry();
    }, 0);
  }
}
