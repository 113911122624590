import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '../commons/common-components.module';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { FaqContentComponent } from 'src/app/features/help/faq-content/faq-content.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactUsOutcomeComponent } from './contact-us-outcome/contact-us-outcome.component';
import { ContactInfosComponent } from './contact-infos/contact-infos.component';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { HelpTabComponent } from './help-tab/help-tab.component';
import { PublicPageComponent } from '../commons/public-page/public-page.component';
import { PublicHelpTabComponent } from './public-help-tab/public-help-tab.component';
import { ClaimsComponent } from './claims/claims.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { PublicContactUsComponent } from './public-contact-us/public-contact-us.component';
import { NgxCaptchaModule } from 'ngx-captcha';

const routes: Routes = [
  {
    path: 'help',
    redirectTo: 'help/faq',
    pathMatch: 'full'
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    component: LoggedPageComponent,
    children: [
      {
        path: '',
        component: HelpTabComponent,
        children: [
          {
            path: 'faq',
            component: FaqContentComponent
          },
          {
            path: 'contact-us',
            component: ContactUsComponent,
            resolve: {
              agreements: AgreementsService,
              customer: CustomerService
            }
          },
          {
            path: 'contact-infos',
            component: ContactInfosComponent
          }
        ]
      },
      {
        path: 'contact-us/outcome',
        component: ContactUsOutcomeComponent
      },
      {
        path: 'impressum',
        component: ImpressumComponent
      },
      {
        path: 'data-protection',
        component: DataProtectionComponent
      },
      {
        path: 'claims',
        component: ClaimsComponent
      }
    ]
  },
  {
    path: 'public/help',
    redirectTo: 'public/help/faq',
    pathMatch: 'full'
  },
  {
    path: 'public/help',
    component: PublicPageComponent,
    children: [
      {
        path: '',
        component: PublicHelpTabComponent,
        children: [
          {
            path: 'faq',
            component: FaqContentComponent
          },
          {
            path: 'contact-us',
            component: PublicContactUsComponent
          },
          {
            path: 'contact-infos',
            component: ContactInfosComponent
          }
        ]
      },
      {
        path: 'contact-us/outcome',
        component: ContactUsOutcomeComponent
      },
      {
        path: 'impressum',
        component: ImpressumComponent
      },
      {
        path: 'data-protection',
        component: DataProtectionComponent
      },
      {
        path: 'claims',
        component: ClaimsComponent
      }
    ]
  }
];


@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule,
    NgxCaptchaModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    FaqContentComponent,
    ContactUsComponent,
    ContactUsOutcomeComponent,
    ContactInfosComponent,
    HelpTabComponent,
    PublicHelpTabComponent,
    ImpressumComponent,
    DataProtectionComponent,
    ClaimsComponent,
    PublicContactUsComponent
  ]
})

export class HelpModule {
}
