<div class="layout-narrow">
  <h2 class="form-title">{{fields.form_title}}</h2>
  <p>{{fields.form_instruction}}</p>

  <div class="form-group">
    <app-stepper
    [selectedIndex]="stepIndex"
    [stepData]="registrationSteps"
    app-test="registration-stepper-2">
    </app-stepper>
  </div>

  <div class="layout-xsmall">
    <h2 class="form-subtitle">{{fields.step_3_title}}</h2>
    <p class="mention">{{fields.step_3_instruction}}</p>
    <form class="ng-untouched ng-pristine ng-valid"
    [formGroup]="passwordForm"
    app-test="user-informations-form">
      <div class="form-group">
        <app-textfield
        [label]="fields.password_textfield_label"
        [isPassword]="true"
        controlName="password"
        [parentForm]="passwordForm"
        [required]="true"
        [requiredErrorMessage]="fields.required_error_txt"
        [pattern]="patterns.password.pattern"
        [patternErrorMessage]="fields.invalid_error_txt"
        [hint]="fields.password_textfield_hint"
        app-test="New password">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.password_confirm_textfield_label"
        [isPassword]="true"
        controlName="confirmationPassword"
        [parentForm]="passwordForm"
        [required]="true"
        [requiredErrorMessage]="fields.required_error_txt"
        app-test="Confirmation password">
        </app-textfield>
        <mat-hint *ngIf="!passwordsMatch()" class="mat-hint hint-error" style="color: #d20062 !important">
          <span app-test="validation-unconfirmed-lbl">{{ fields.password_confirm_match_error_message }}</span>
        </mat-hint>
      </div>

      <app-error-message *ngIf="!isSuccess" [message]="fields.message_server_error" app-test="message-server-error"></app-error-message>

      <app-form-buttons>
        <div>
          <app-button
          buttonType="secondary"
          [buttonLabel]="fields.back_button_label"
          (buttonClick)="back()"
          app-test="back-btn">
          </app-button>
          <app-button
          [buttonLabel]="fields.confirm_button_label"
          (buttonClick)="continue()"
          [disabled]="passwordForm.invalid || !passwordsMatch()"
          app-test="confirm-user-data-btn">
          </app-button>
        </div>
      </app-form-buttons>
    </form>
  </div>

</div>
