<app-login-layout
  app-test="registration-page"
  [backToLink]="backLink"
  [cookiesAlert]="cookiesService.cookiesBannerConfig"
  (cookiesEmitter)="cookiesService.storeCookieFromPublicPage()"
  [footerLinks]="footerLinks"
  [copyright]="footerCopyright"
  [contactBlocksButton]="contactBlocksButton"
  [contactBlocksData]="contactBlocksData">

  <ng-template #formContentRef>
    <div [editSlug]="slug">
      <app-back-to
      [label]="fields.navigation_button_label"
      [link]="backLink">
      </app-back-to>
      <div class="content-box-inner ng-star-inserted">
        <div class="layout-narrow">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #sideContentRef>
    <router-outlet name="sideContent"></router-outlet>
  </ng-template>

</app-login-layout>