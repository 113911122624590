import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Globals } from "src/app/design-system/globals";

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @Input() maxLength ? = '10000';
  @Input() minRows ? = '2';
  @Input() maxRows ? = '100';
  @Input() placeholder ? = '';
  @Input() label = 'Label';
  @Input() value ? = '';
  @Input() displayCharCount ? = false;

  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() required: boolean;
  @Input() requiredErrorMessage: string;
  @Input() appTest: string;

  @Output() onModelChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  constructor(private layoutService: LayoutService, private globals: Globals) {}

  ngOnInit() {
    //init form if not recieved in input
    if ( !this.parentForm ) {
      this.controlName = 'field';
      this.parentForm = new FormGroup({
        field: new FormControl(this.value)
      });
    }
    if (this.required) {
      this.label = this.label + '*';
    }
  }

  errorDetected(): boolean {
    if (this.parentForm.controls[this.controlName].errors
      && (this.parentForm.controls[this.controlName].dirty || this.parentForm.controls[this.controlName].value !== '')) {
      return true;
    } else {
      return false;
    }
  }

  updateLayout($event) {
    if (this.globals.isLoginPage) {
      this.layoutService.resizeLoginPage();
      this.layoutService.setContactButtonPosition();
    } else {
      this.layoutService.updateMasonry();
    }
  }
}
