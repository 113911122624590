import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { ErrorMessageGenericComponent } from './error-message-generic/error-message-generic.component';
import { CommonComponentsModule } from '../commons/common-components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  { path: 'generic-error',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ErrorMessageGenericComponent,
      }
    ]
  }
];

@NgModule({
  declarations: [
    ErrorMessageGenericComponent
  ],
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ]
})
export class ErrorMessageModule { }
