import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-notification-button",
  templateUrl: "./notification-button.component.html",
  styleUrls: ["./notification-button.component.scss"]
})
export class NotificationButtonComponent implements OnInit {
  @Input() appTest: string;

  @Input() type: string;
  @Input() count: number;
  @Input() icon: string;
  @Input() disabled: boolean;

  @Output() notificationClickEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
