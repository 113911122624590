<div class="grid-item grid-item--fullsize" attr.app-test="{{appTest}}">
  <div class="content-box content-box--form">
    <div class="content-box-inner">
      <div class="layout-narrow">

        <h2 class="form-title" app-test="title">{{title}}</h2>

        <app-icon-feedback
        [icon]="icon || 'icon-circle-valid'"
        colorClass="default-icon-color">
        </app-icon-feedback>
      
        <span *ngIf="message" app-test="message">
          {{message}}
        </span>
        
        <ng-content></ng-content>
      
        <app-form-buttons *ngIf="button || secondaryButton">
          <app-button *ngIf="secondaryButton"
            buttonType="secondary"
            [buttonLabel]="secondaryButton.label"
            (buttonClick)="secondaryButtonEmitter.emit($event)"
            app-test="secondaryButton"
            ></app-button>
          <app-button *ngIf="button"
            [buttonLabel]="button.label"
            (buttonClick)="buttonEmitter.emit($event)"
            app-test="button"
          ></app-button>
        </app-form-buttons>
        
      </div>
    </div>
  </div>
</div>
