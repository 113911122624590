import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener
} from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { ContactBlocksComponent } from "../../modals/contact-blocks/contact-blocks.component";
import { DeviceService } from "src/app/design-system/services/device.service";

@Component({
  selector: "app-contact-button",
  templateUrl: "./contact-button.component.html",
  styleUrls: ["./contact-button.component.scss"]
})
export class ContactButtonComponent implements OnInit, OnDestroy {
  constructor(
    private dialogService: DialogService,
    private deviceService: DeviceService
  ) {
    this.isPWA = deviceService.isPWA || deviceService.isMobileSize();
  }
  @Input() appTest: string;

  @Input() buttonLabel: string;
  @Input() icon: string;
  @Input() status: string;

  @Output() contactButtonEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() isPWA: boolean;

  @Input() contactBlocksData: [];

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }
  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnInit() {
    if (this.status === "closing") {
      this.icon = "icon-menu-close";
      this.contactButtonEmitter.subscribe(() => this.dialogService.close());
    } else {
      this.contactButtonEmitter.subscribe(() =>
        this.dialogService.open("contact", ContactBlocksComponent, {
          ...DialogService.DIALOG_CONFIG.contact,
          data: {
            contactBlocksData : this.contactBlocksData,
            closeButtonLabel: this.buttonLabel,
            close: this.dialogService.close
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.contactButtonEmitter.unsubscribe();
    this.dialogService.close();
  }
}
