<div class="input-search">
  <input
    autocomplete="off"
    matInput
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
     (ngModelChange)="onModelChange.emit($event)"
  />
  <app-icon-button icon="search_icon" iconWidth="20px" iconHeight="20px">
  </app-icon-button>
</div>
<span class="hint" *ngIf="hint">{{ hint }}</span>
