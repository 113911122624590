import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from "src/app/design-system/services/dialog.service";

@Component({
  selector: "app-message-tool",
  templateUrl: "./message-tool.component.html",
  styleUrls: ["./message-tool.component.scss"]
})
export class MessageToolComponent implements OnInit {
  constructor(private router: Router, private dialogService: DialogService) {}

  @Input() noMessage: boolean = false;
  @Input() NoMessageText: string = "No new message";
  @Input() showAllLabel: string = "Show all my messages";
  @Input() writeLabel: string = "Write a message";

  showAll() {
    this.router.navigateByUrl("/my-messages");
    this.dialogService.close();
  }

  ngOnInit() {}
}
