<div>


<div *ngIf="subjectDataList.length==0" class="empty-message">
  	{{ emptyMessage }}
  </div>
  
  
  <app-faq-list-item 
    *ngFor="let subjectData of subjectDataList"
    [formHeading]="formHeading"
    [question]="subjectData.question"
    [answer]="subjectData.answer"
    [enableFeedback]="enableFeedback"
  ></app-faq-list-item>
  
  
</div>
