<div
  class="push-promo-container"
  [ngClass]="[pushPromoLarge ? 'push-promo-large' : 'push-promo']"
>
  <div class="content">
    <app-icon
      class="icon"
      [icon]="icon"
      iconWidth="100%"
      iconHeight="100%"
      colorClass="color-primary"
    >
    </app-icon>
    <div class="text">
      <h3 class="title">{{ title }}</h3>
      <span class="from">{{ fromText }}</span>
      <span class="price">{{ price }}</span>
    </div>
  </div>
  <app-form-buttons>
    <app-button
      *ngIf="pushPromoLarge"
      class="secondary-button"
      buttonType="secondary"
      [buttonLabel]="secondaryButtonLabel"
      (buttonClick)="contactEmitter.emit(contactLink)"
    ></app-button>
    <app-button
      class="primary-button"
      [buttonLabel]="primaryButtonLabel"
      (buttonClick)="purchaseEmitter.emit(purchaseLink)"
    ></app-button>
  </app-form-buttons>
</div>
