<span app-test="payment-plan-page">
  <div [editSlug]="slug">
    
    <div class="grid-item grid-item--fullsize">
      <div class="content-box">
          <div class="block-section" id="requests">
            <app-table [usePagination]="true" [ofLabel]="fields.of"
              [columnsData]="[
                { 
                  id: 'dueDate', 
                  label: fields.payment_plan_due_date,
                  appTest: 'due date'
                },
                { 
                  id: 'amount', 
                  label: fields.payment_plan_amount
                },
                { 
                  id: 'status', 
                  label: fields.payment_plan_status
                }
              ]"
              [tableData]="paymentPlanTableData" [caption]="paymentPlanTableCaption"></app-table>
          </div>
      </div>
    </div>

  </div>

  <div class="grid-item grid-item--fullsize">
    <div class="content-box content-box--form">
      <div class="content-box-inner">
        <div class="layout-narrow">
          <p>{{fields.tva_label}}</p>
        </div>
      </div>
    </div>
  </div>
</span>
