import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-quick-access',
  templateUrl: './card-quick-access.component.html',
  styleUrls: ['./card-quick-access.component.scss']
})
export class CardQuickAccessComponent implements OnInit {

  @Input() title: string;
  @Input() link: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit() {
  }

}
