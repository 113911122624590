<ul class="filter-list">
  <li>
    <mat-checkbox
      (change)="masterToggle()"
      [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()"
      >{{ allText }}</mat-checkbox
    >
  </li>
  <li *ngFor="let filter of filters">
    <mat-checkbox
      (change)="selection.toggle(filter)"
      [checked]="selection.isSelected(filter)"
      >{{ filter.label }}</mat-checkbox
    >
  </li>
</ul>
