<div class="synthese-list">
  <div class="synthese-item" *ngFor="let item of syntheseList">
    <span class="label">
      {{ item.label }}
    </span>
    <span class="value" attr.app-test="{{item.label.toLowerCase()}}" *ngIf="!item.isLink">{{ item.value }}</span>
    <app-body-link class="value" *ngIf="item.isLink">{{
      item.value
    }}</app-body-link>
  </div>
</div>
