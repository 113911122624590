import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild
} from "@angular/core";
import { FormGroup } from '@angular/forms';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropComponent, NgxFileDropEntry } from "ngx-file-drop";
import { DeviceService } from "src/app/design-system/services/device.service";
import { LayoutService } from "src/app/design-system/services/layout.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent implements OnInit {

  @ViewChild("fileDrop", { static: true }) fileDrop: NgxFileDropComponent;

  @Input() parentForm: FormGroup;
  @Input() controlName: string;

  @Input() dropInstructions: string;
  @Input() dropFormatsAccepted: string;
  @Input() fileFormatsInstructions: string;
  @Input() browseBtnLabel: string;
  @Input() fileLabel: string;
  @Input() multiple: boolean = true;
  @Input() fileMaxSize: number;
  @Input() errorMessageTooBig: string;
  @Input() errorMessageNotAllowed: string;
  @Input() errorNotAllowed: boolean;
  @Input() errorTooBig: boolean;

  @Output() onFileListUpdate: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() fileError: EventEmitter<string> = new EventEmitter<string>();

  public filesList: File[] = [];

  isDesktop: boolean;

  constructor(
    private deviceService: DeviceService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.isDesktop = this.deviceService.isDesktop;
  }

  // module ngx-file-drop@6.0.1 installed
  // https://www.npmjs.com/package/ngx-file-drop/v/6.0.1
  public dropped(files: NgxFileDropEntry[]) {
    const promises: Promise<File>[] = [];
    const allowedTypes = this.dropFormatsAccepted.split(",");
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const promise = new Promise<File>( (resolve, reject) => {
          fileEntry.file((file: File) => {
            // Here you can access the real file
            if ( !(allowedTypes.lastIndexOf(file.type) > -1) ) {
              this.fileError.emit("'" + file.name + "'" + ' - ' + this.errorMessageNotAllowed);
              reject(this.errorMessageNotAllowed);
            } else if (file.size > this.fileMaxSize) {
              // Error handle
              this.fileError.emit(file.name + ' ' + this.errorMessageTooBig);
              reject(this.errorMessageTooBig);
            } else {
              resolve(file);
            }
          });
        });
        promises.push(promise);
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
    Promise.all(promises).then(filesToUpload => {
      if ( this.multiple ) {
        filesToUpload.forEach(file => {
          this.filesList.push(file);
        });
      } else {
        if ( filesToUpload[0] ) {
          this.filesList = [filesToUpload[0]];
        }
      }
      setTimeout(this.layoutService.updateMasonry, 0);
      this.onFileListUpdate.emit(this.filesList);
    });
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  removeUploadedFile(toRemove: File) {
    this.filesList = this.filesList.filter(file => {
      return toRemove !== file;
    });
    setTimeout(this.layoutService.updateMasonry, 0);
    this.onFileListUpdate.emit(this.filesList);
  }
}
