import { Component, OnInit, Input, HostBinding } from "@angular/core";
import { Globals } from "src/app/design-system/globals";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"]
})
export class ImageComponent implements OnInit {
  constructor(private globals: Globals) {}

  //the src of the img element
  @Input() src: string;
  //default value for the top image of offer detailed pages
  @Input() width?: string = "100%";
  @Input() height?: string = "auto";
  @Input() title?: string;
  @Input() kind?: "top-image";

  //@HostBinding("class.top-image")

  imageLoadHandler() {
    this.globals.updateMasonry();
  }

  ngOnInit() {}
}
