<div
  class="action-group" 
  (click)="actionEmitter.emit(actionData.id)"
  [ngClass]="{
    'icon-left': iconLeft,
    'display-name-in-mobile': displayNameInMobile
  }"
>
  <span>{{ actionData.label }}</span
  ><app-icon-button
    [icon]="getIcon()"
    iconWidth="35px"
    iconHeight="35px"
    colorClass="color-grey-70"
  ></app-icon-button>
</div>
