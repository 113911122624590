import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { BlockAction } from 'src/app/design-system/components/navigation/block-actions/block-actions.component';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { EditPaymentMethodModalComponent } from '../edit-payment-method-modal/edit-payment-method-modal.component';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { IbanFormatPipe } from 'src/app/pipes/iban-format.pipe';
import { RoutingStateService } from 'src/app/services/routing-state.service';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: 'app-payment-method-content',
  templateUrl: './payment-method-content.component.html',
  styleUrls: ['./payment-method-content.component.scss'],
  providers: [IbanFormatPipe]
})
export class PaymentMethodContentComponent extends AbstractComponent implements OnInit {

  private parentLink: string;
  private backToLabel: string;

  public agreements: any;
  public directDebitMandates = [];
  public preselectedAgreements: any;
  public agreementsInfo = [];
  public actions: BlockAction[];

  public paymentMethodType: any;
  public paymentStripForm: FormGroup;
  public paymentDirectDebitForm: FormGroup;

  private selectedDDM: any;

  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routingState: RoutingStateService,
              public ibanFormatter: IbanFormatPipe,
              private fb: FormBuilder,
              private jwtService: JwtService,
              private bankDetailsService: BankDetailsService,
              private dialogService: DialogService,
              private deviceService: DeviceService) {
          super('payment-method-page');
        }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    console.log(this.fields);

    if (!this.bankDetailsService.agreementIdList) {
      this.router.navigate([this.parentLink]);
    }
    this.preselectedAgreements = this.bankDetailsService.agreementIdList;
    console.log(this.preselectedAgreements);

    if (this.bankDetailsService.selectedIban) {
      this.paymentMethodType = 'directDebit';
    } else {
      this.paymentMethodType = 'paymentStrip';
    }

    this.agreements = this.route.snapshot.data.agreements;
    console.log(this.agreements);

    const directDebitMandatesTemp = this.route.snapshot.data.bankDetails;
    console.log(directDebitMandatesTemp);

    if ( directDebitMandatesTemp.length > 0 ) {
      const preselectedIban = this.bankDetailsService.selectedIban ?
                              this.bankDetailsService.selectedIban : directDebitMandatesTemp[0].bankAccount.iban;

      directDebitMandatesTemp.forEach((directDebitMandateTemp: any) => {
        let ddmAlreadyAdded = false;
        this.directDebitMandates.forEach((ddm: any ) => {
          if (directDebitMandateTemp.bankAccount.iban === ddm.bankAccount.iban) {
            ddmAlreadyAdded = true;
          }
        });
        if (!ddmAlreadyAdded) {
          directDebitMandateTemp.startSelection = directDebitMandateTemp.bankAccount.iban === preselectedIban;
          if (directDebitMandateTemp.startSelection) {
            this.selectedDDM = directDebitMandateTemp;
          }
          this.directDebitMandates.push(directDebitMandateTemp);
        }
      });
      console.log(this.directDebitMandates);
    }

    this.paymentStripForm = this.fb.group({
      paymentPurpose: [this.jwtService.UserStoredInformations.customerId],
      rciIban: [this.ibanFormatter.transform(this.fields.iban_rci_value)],
      rciBank: [this.fields.bank_name_rci_value]
    });

    this.paymentDirectDebitForm = this.fb.group({

    });

    this.createAgreementsInfo();

  }

  getConfig() {
    this.setBackTo(this.routingState.getPreviousUrl());
    return {
      backToLabel : this.backToLabel,
      backToLink :  this.parentLink,
      isSubPage : true,
      isHome : false,
      title: this.fields.selection_form_title
    };
  }

  setBackTo(previousUrl: string) {
    if (previousUrl.search("/bank-details") !== -1) {
      this.routingState.setStoredUrl( { url: previousUrl, label: this.fields.back_to_bank_details_button_label } );

    } else if ( previousUrl.search(/\/home\/agreements\/.*\S/gi) !== -1 ) {
      this.routingState.setStoredUrl( { url: previousUrl, label: this.fields.back_to_agreement_details_button_label } );

    } else if ( this.routingState.getStoredUrl() === undefined ) {
      this.routingState.setStoredUrl( { url: "/home/agreements", label: this.fields.back_to_agreements_button_label } );
    }
    this.backToLabel = this.routingState.getStoredUrl().label;
    this.parentLink = this.routingState.getStoredUrl().url;
  }

  private createAgreementsInfo() {
    this.agreements.forEach((agreement: any) => {
      this.preselectedAgreements.forEach((preselectedAgreementId: string) => {
        if (agreement.id === preselectedAgreementId) {
          const vehicleInfo = agreement.vehicle.model ?
            agreement.vehicle.model.label :
            this.fields.licence_plate_number_label + ' ' + agreement.vehicle.plate;
          const label = this.fields.agreement_number_label.concat(' ' + agreement.label + ' - ' + vehicleInfo);
          this.agreementsInfo.push(label);
        }
      });
    });
    console.log(this.agreementsInfo);
  }

  updatePaymentMethodType(radioValue: any) {
    this.paymentMethodType = radioValue;
    // console.log(this.paymentMethodType);
  }

  isSelectedPaymentStrip(): boolean {
    return this.paymentMethodType === 'paymentStrip';
  }

  cancel() {
    this.router.navigate([this.parentLink]);
  }

  enableConfirmButton(): boolean {
    if (this.paymentMethodType === 'directDebit' && this.directDebitMandates.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  confirm() {
    switch (this.paymentMethodType) {

      case 'directDebit':
        this.bankDetailsService.updatableBankAccount = this.selectedDDM.bankAccount;
        this.router.navigate(['/payment-method/direct-debit/edit']);
        break;

      case 'paymentStrip':
        this.bankDetailsService.agreementLabelsNewDDM = this.agreementsInfo;
        this.bankDetailsService.selectedPaymentMethod = this.paymentMethodType;
        this.dialogService.open('popin', EditPaymentMethodModalComponent, {...DialogService.DIALOG_CONFIG.popin});
        break;

      default:
        break;

    }
  }

  public performAction() {
    this.bankDetailsService.updatableBankAccount = undefined;
    this.router.navigate(['/payment-method/direct-debit/edit']);
  }

  public ibanSelection(data: any) {
    this.selectedDDM = data;
  }

}
