<ng-container *ngIf="haveCards()">

  <div class="grid-item grid-item--small" [editSlug]="slug">
    <app-card-quick-access
    [title]="fields.customer_card_title"
    link="/customer"
    icon="icon-circle-customer-database"
    app-test="quick-access-card-customer">
    </app-card-quick-access>
  </div>

  <ng-container *ngFor="let card of commercialCards">
    <ng-container *ngIf="card.position === 'BOTTOM'">
      <div class="grid-item grid-item--big" [editSlug]="card.slug">
        <app-card-commercial
        [image]="card.imageUrl"
        [title]="card.title"
        [text]="card.text"
        [isLink]="haveLink(card)"
        [buttonLabel]="card.externalLinkLabel"
        [link]="card.externalLink"
        attr.app-test="commercial-card-bottom-{{card.appTest}}"
        [enlarge]="true">
        </app-card-commercial>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let card of commercialCards">
    <ng-container *ngIf="card.position === 'RIGHT'">
      <div class="grid-item grid-item--small" [editSlug]="card.slug">
        <app-card-commercial
        [image]="card.imageUrl"
        [title]="card.title"
        [text]="card.text"
        [isLink]="haveLink(card)"
        [buttonLabel]="card.externalLinkLabel"
        [link]="card.externalLink"
        attr.app-test="commercial-card-right-{{card.appTest}}">
        </app-card-commercial>
      </div>
    </ng-container>
  </ng-container>
  
</ng-container>
