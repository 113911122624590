import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { AbstractComponent } from '../../commons/abstract-component';

@Component({
  selector: 'app-identify-customer-form',
  templateUrl: './identify-customer-form.component.html',
  styleUrls: ['./identify-customer-form.component.sass']
})
export class IdentifyCustomerFormComponent extends AbstractComponent implements OnInit {

  public identifyForm: FormGroup;
  public fromValidation: boolean = false;
  public customerId: string;
  public secret: string;

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder,
    private signupService: SignupService) {
    super('registration-page')
  }

  ngOnInit() {
    this.identifyForm = this.fb.group({
      customerId: [''],
      secret: [''],
      recaptcha: ['']
    });

  }

  confirm() {
    this.signupService.init(this.identifyForm.value.customerId, this.identifyForm.value.secret, this.identifyForm.value.recaptcha);
    this.router.navigate(['/registration/check-customer']);
  }
}
