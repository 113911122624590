import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';

@Component({
  selector: 'app-typenschein-sent',
  templateUrl: './typenschein-sent.component.html',
  styleUrls: ['./typenschein-sent.component.sass']
})
export class TypenscheinSentComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  constructor(private route: ActivatedRoute, private router: Router) {
      super('action-typenschein');
    }

  ngOnInit() {
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.parent.params.id,
      isSubPage : true,
      isHome : false
    };
  }

  backToAgreement() {
    this.router.navigate(['/home/agreements/' + this.route.snapshot.parent.parent.params.id]);
  }

}
