import { Directive, ElementRef, Input, Renderer2, HostListener, OnChanges, OnInit  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WordpressService } from './wordpress/wordpress.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[editSlug]'
})
export class EditLinkDirective implements OnInit, OnChanges {

  @Input('editSlug') slug: string;
  private editUrl: string;

  a = this.renderer.createElement('a');
  i = this.renderer.createElement('i');
  text = this.renderer.createText('link');

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private wpService: WordpressService
  ) { }

  ngOnInit() {
    if (this.slug.endsWith('&action=edit')) {
      this.editUrl = this.slug;
      this.enrichElement();
    } else {
      this.wpService.getEditUrl(this.slug).subscribe(editUrl => {
        this.editUrl = editUrl;
        this.enrichElement();
      });
    }
  }

  ngOnChanges() {
    this.renderer.setAttribute(this.a, 'href', this.editUrl);
    this.renderer.setAttribute(this.a, 'target', '_blank');
  }

  @HostListener('mouseenter') onMouseenter() {
    if (environment.cmsLink) {
      this.mouseCreateLink();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    setTimeout(() => {
      this.renderer.removeChild(this.el.nativeElement, this.a);
    }, 4000);
  }

  private mouseCreateLink() {
    this.renderer.appendChild(this.i, this.text);
    this.renderer.appendChild(this.a, this.i);
    this.renderer.appendChild(this.el.nativeElement, this.a);
  }

  private enrichElement() {
    this.renderer.addClass(this.el.nativeElement, 'position-relative');
    this.renderer.setAttribute(this.a, 'href', this.editUrl);
    this.renderer.addClass(this.a, 'link-edit');
    this.renderer.addClass(this.i, 'link-edit-icons');
    this.renderer.setStyle(this.i, 'color', 'lightskyblue');
  }
}
