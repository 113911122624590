import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { VehiculeInfos } from "../block-vehicule/block-vehicule.component";

@Component({
  selector: "app-block-vehicules",
  templateUrl: "./block-vehicules.component.html",
  styleUrls: ["./block-vehicules.component.scss"]
})
export class BlockVehiculesComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() withShowMoreButton: boolean;
  @Input() showMoreStatus: "more" | "less";
  @Input() showMoreLabel: string;
  @Input() showLessLabel: string;

  @Output() showMoreEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() blockVehiculesData: any[];

  showMoreClickHandler($event) {
    this.showMoreEmitter.emit($event);
  }

  ngOnInit() {}
}
