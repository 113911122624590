import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { Router } from '@angular/router';
import { RoutingStateService } from 'src/app/services/routing-state.service';

@Component({
  selector: 'app-edit-payment-method-success',
  templateUrl: './edit-payment-method-success.component.html',
  styleUrls: ['./edit-payment-method-success.component.sass']
})
export class EditPaymentMethodSuccessComponent extends AbstractComponent implements OnInit {

  private backToLabel: string;
  private parentLink: string;

  public updatedPaymentMethod: string;

  constructor(private routingState: RoutingStateService,
              private bankDetailsService: BankDetailsService,
              private router: Router) {
          super('payment-method-page');
        }

  getBackToLabel() {
    return this.backToLabel;
  }

  ngOnInit() {
    console.log(this.fields);
    if (this.bankDetailsService.selectedPaymentMethod) {
      this.updatedPaymentMethod = this.bankDetailsService.selectedPaymentMethod;
    } else {
      this.router.navigate([this.parentLink]);
    }
  }

  getConfig() {
    this.setBackTo();
    return {
      backToLabel : this.backToLabel,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false
    };
  }

  setBackTo() {
    const storedUrl = this.routingState.getStoredUrl();
    if ( storedUrl === undefined ) {
      this.routingState.setStoredUrl( { url: "/home/agreements", label: this.fields.back_to_agreements_button_label } );
    }
    this.backToLabel = this.routingState.getStoredUrl().label;
    this.parentLink =  this.routingState.getStoredUrl().url;
  }

  back() {
    this.router.navigate([this.parentLink]);
  }

}
