import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  HostListener
} from "@angular/core";
import { DialogService } from "src/app/design-system/services/dialog.service";
import { NotificationItem } from "../feed-notification/feed-notification.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageItem } from "../feed-message/feed-message.component";
import { MessageToolComponent } from "../message-tool/message-tool.component";
import { DeviceService } from "src/app/design-system/services/device.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrls: ["./feed.component.scss"]
})
export class FeedComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isPWA = deviceService.isPWA || deviceService.isMobileSize();
  }

  @ViewChild("feedContainer", { static: true }) feedContainer: ElementRef;
  @ViewChild("messageTool", { static: false }) messageTool: MessageToolComponent;

  @Input() kind: "notification" | "message";

  @Input() feedTitle: string;
  @Input() feedItemsData: NotificationItem[] | MessageItem[];

  offsetTop: string;
  overlay: HTMLElement;
  @Input() isPWA: boolean;

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }

  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnDestroy() {
    this.dialogService.close();
  }

  ngAfterViewInit() {
    //display message tool with noMessage=true if no messages
    if (
      this.kind === "message" &&
      (!this.feedItemsData || !this.feedItemsData.length)
    ) {
      this.messageTool.noMessage = true;
    }
  }
  messageClickHandler(messageId) {
    console.log("message " + messageId);
    this.router.navigateByUrl("/message-detail");
    this.dialogService.close();
  }

  ngOnInit() {
    this.overlay = document.querySelector(
      ".cdk-overlay-container"
    ) as HTMLElement;

    if (this.data.kind != null) {
      this.kind = this.data.kind;
      this.feedTitle = this.data.feedTitle;
      this.feedItemsData = this.data.feedItemsData;
      this.offsetTop = this.data.offsetTop;
    }

    if (this.overlay) {
      this.overlay.style.top = this.offsetTop + "px";
    }
  }
}
