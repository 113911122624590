import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format.pipe';
import { LongDateFormatPipe } from './long-date-format.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { IbanFormatPipe } from './iban-format.pipe';
import { IntegerFormatPipe } from './integer-format.pipe';
import { SafePipe } from './safe.pipe';
import { InterpolatePipe } from './interpolate.pipe';

@NgModule({
  imports: [
  ],
  exports: [
    DateFormatPipe,
    LongDateFormatPipe,
    CurrencyFormatPipe,
    IbanFormatPipe,
    IntegerFormatPipe,
    SafePipe,
    InterpolatePipe
  ],
  declarations: [
    DateFormatPipe,
    LongDateFormatPipe,
    CurrencyFormatPipe,
    IbanFormatPipe,
    IntegerFormatPipe,
    SafePipe,
    InterpolatePipe
  ]
})
export class PipesModule { }
