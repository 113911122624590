/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { TabData } from 'src/app/design-system/components/structure/pwa-tab/pwa-tab.component';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageLayoutConfigService {

  constructor(private sanitizer: DomSanitizer) { }

  public menu: MenuItems[] = [
    {
      itemTitle: 'menu_home',
      routerLink: '/home',
      appTest: 'menu-home-btn'
    },
    {
      itemTitle: 'menu_my_profile',
      routerLink: '/customer',
      appTest: 'menu-customer-btn'
    },
    {
      itemTitle: 'menu_bank_details',
      routerLink: '/bank-details',
      appTest: 'menu-bank-details-btn'
    },
    {
      itemTitle: 'menu_help',
      routerLink: '/help',
      appTest: 'menu-help-btn'
    },
    {
      itemTitle: 'menu_log_out',
      routerLink: '/login',
      appTest: 'menu-logout-btn'
    }
  ];

  public tabMenu: TabData[] = [
    {
      icon: 'icon-pwa-car',
      label: 'menu_home',
      routerLink: '/home'
    },
    {
      icon: 'icon-pwa-profil',
      label: 'menu_my_profile',
      routerLink: '/customer'
    },
    {
      icon: 'icon-pwa-contact',
      label: 'menu_help',
      routerLink: '/help'
    }
  ];

  public footerLinks: MenuItems[] = [
    { itemTitle: 'footer_faq', routerLink: '/help/faq', appTest: 'footer-link-faq-app-test' },
    { itemTitle: 'footer_impressum', routerLink: '/help/impressum', appTest: 'footer-link-impressum-app-test' },
    { itemTitle: 'footer_data_protection', routerLink: '/help/data-protection', appTest: 'footer-link-data-protection-app-test' },
    { itemTitle: 'footer_claims', routerLink: '/help/claims', appTest: 'footer-link-claims-app-test' }
  ];

  public publicFooterLinks: MenuItems[] = [
    { itemTitle: 'footer_faq', routerLink: '/public/help/faq', appTest: 'public-footer-link-faq-app-test' },
    { itemTitle: 'footer_impressum', routerLink: '/public/help/impressum', appTest: 'public-footer-link-impressum-app-test' },
    { itemTitle: 'footer_data_protection', routerLink: '/public/help/data-protection', appTest: 'public-footer-link-data-protection-app-test' },
    { itemTitle: 'footer_claims', routerLink: '/public/help/claims', appTest: 'public-footer-link-claims-app-test' }
  ];

  public alertsTitle: string = 'alerts_title';

  public pwaBurgerButtonLabel: string = 'burger_button_label';

  public footerCopyright: string = 'footer_copyright';

  public headerWelcome: string = 'header_welcome';

  public contactBlocksButtonLabel: string = 'contact_blocks_button_label';

  public contactBlocksData: Array<any> = [];

  public publicContactBlocksData: Array<any> = [];

  private initilized: boolean = false;

  public init() {
    if ( !this.initilized ) {
      this.initilized = true;
      const fields = WordpressService.getPageFields('page-layout');

      this.menu.forEach(item => {
        item.itemTitle = fields[item.itemTitle];
      });

      this.footerLinks.forEach(item => {
        item.itemTitle = fields[item.itemTitle];
      });

      this.publicFooterLinks.forEach(item => {
        item.itemTitle = fields[item.itemTitle];
      });

      this.tabMenu.forEach(tabData => {
        tabData.label = fields[tabData.label];
      });

      this.pwaBurgerButtonLabel = fields[this.pwaBurgerButtonLabel];

      this.alertsTitle = fields[this.alertsTitle];

      this.footerCopyright = fields[this.footerCopyright];

      this.headerWelcome = fields[this.headerWelcome];

      this.contactBlocksButtonLabel = fields[this.contactBlocksButtonLabel];

      this.contactBlocksData = [{
          appTest : 'contact-phone',
          icon: 'icon-circle-call',
          title: fields.contact_phone_title,
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(fields.contact_phone_text),
          buttonLabel: fields.contact_phone_button,
          buttonLink: '/help/contact-infos',
          disableOnDesktop: false
        },
        {
          appTest : 'contact-email',
          icon: 'icon-circle-message',
          title: fields.contact_email_title,
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(fields.contact_email_text),
          buttonLabel: fields.contact_email_button,
          buttonLink: '/help/contact-us',
          disableOnDesktop: false
        }
      ];

      this.publicContactBlocksData = [{
          appTest : 'contact-phone',
          icon: 'icon-circle-call',
          title: fields.contact_phone_title,
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(fields.contact_phone_text),
          buttonLabel: fields.contact_phone_button,
          buttonLink: '/public/help/contact-infos',
          disableOnDesktop: false
        },
        {
          appTest : 'contact-email',
          icon: 'icon-circle-message',
          title: fields.ontact_email_title,
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(fields.contact_email_text),
          buttonLabel: fields.contact_email_button,
          buttonLink: '/public/help/contact-us',
          disableOnDesktop: false
        }
      ];
    }
  }

}
