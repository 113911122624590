<div
  class="button-zone" app-test="notification-icon-test"
  (click)="notificationClickEmitter.emit($event)"
  [ngClass]="[!count ? 'disabled' : '', type]"
>
  <app-icon-button
    class="icon-button"
    [icon]="icon"
    [colorClass]="count > 0 ? 'color-accent' : 'color-grey-70'"
    [disabled]="disabled"
  ></app-icon-button>
  <span class="notification-count" app-test="notification-icon-count-test" *ngIf="count > 0">{{ count }}</span>
</div>
