import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StatementService implements Resolve<any> {

  constructor(private http: HttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.sendStatementRequest(route.parent.params.id);
  }


  sendStatementRequest(agreementId: string) {
    const url: string = environment.restBase + '/actions/' + agreementId + '/statement/request';
    return this.http.post<any>(url, {}).subscribe(
      data => {
        return true;
      },
      error => {
        return false;
      }
    );
  }

}
