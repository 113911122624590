<button
  #button
  mat-flat-button
  disableRipple="true"
  (click)="buttonClick.emit($event)"
  [ngClass]="[
    buttonType,
    branding ? branding : '',
    icon ? 'button-with-icon' : '',
    fullWidth ? 'full-width' : ''
  ]"
  [disabled]="disabled"
  type="button"
  [attr.app-test]="appTest"
>
  <app-icon
    *ngIf="icon"
    [icon]="icon"
    [ngClass]="'button-' + icon"
    [iconWidth]="iconWidth"
    [iconHeight]="iconHeight"
    [colorClass]="colorClass"
  ></app-icon>

  <span class="button-label" *ngIf="buttonLabel; else ngContentBlock">{{
    buttonLabel
  }}</span>
  <ng-template #ngContentBlock
    ><span class="button-label"><ng-content></ng-content></span
  ></ng-template>
</button>
