<div class="block-row"
[ngClass]="{ multiline: multiline }">
  <div class="text">

    <span class="label">{{ label }}</span>

    <span *ngIf="value || value == '0'" class="value" attr.app-test="{{appTest}}">{{ value }}</span>

    <span *ngIf="!value" class="value" attr.app-test="{{appTest}}">
      <ng-content></ng-content>
    </span>

    <div class="actions" *ngIf="actions">
      <app-action-group
        *ngFor="let actionData of actions"
        [actionData]="actionData"
        (actionEmitter)="actionEvent.emit($event)">
      </app-action-group>
    </div>
  
  </div>
</div>
