import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { LongDateFormatPipe } from 'src/app/pipes/long-date-format.pipe';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-customer-content',
  templateUrl: './customer-content.component.html',
  styleUrls: ['./customer-content.component.sass'],
  providers: [LongDateFormatPipe]
})
export class CustomerContentComponent extends AbstractComponent implements OnInit {

  public customer: any;
  public fullName: string;
  public address = { type: 'home',  street: '', postCode: '', city: '', country: ''};
  public phone = { type: 'mobile', value: '' };
  public userEmail: any;
  private displayedAddressIndex = -1;
  private displayedPhoneIndex = -1;

  public tabData: any[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private jwtService: JwtService,
              public longDateFormatter: LongDateFormatPipe,
              private customerService: CustomerService) {
                super('customer-page');
            }

  ngOnInit() {
    this.customer = this.route.snapshot.data.customer;

    this.userEmail = this.jwtService.UserStoredInformations.user_name;

    this.fullName = this.customer.person.displayName;

    this.tabData = [
      {
        label: this.fields.personal_detail_label,
        fragment: 'personalDetails'
      },
      {
        label: this.fields.account_label,
        fragment: 'accountDetails'
      }
    ];

    if (this.customer.addresses && this.customer.addresses.length > 0) {
      this.address = this.customer.addresses.filter((i: { type: string; }) => i.type === 'home')[0]
      || { type: 'home',  street: '', postCode: '', city: '', country: ''};
      this.displayedAddressIndex = this.address ===  { type: 'home',  street: '', postCode: '', city: '', country: ''} ?
      -1 : this.customer.addresses.indexOf(this.address);
      console.log(this.displayedAddressIndex);
    }

    if (this.customer.phones && this.customer.phones.length > 0) {
      this.phone = this.customer.phones.filter((i: { type: string; }) => i.type === 'mobile')[0]
      || this.customer.phones.filter((i: { type: string; }) => i.type === 'home')[0]
      || { type: 'mobile', value: '' };
      this.displayedPhoneIndex = this.phone === { type: 'mobile', value: '' } ?
      -1 : this.customer.phones.indexOf(this.phone);
      console.log(this.displayedPhoneIndex);
    }

  }

  performAction(action: string) {
    switch (action) {
      case 'EDIT_PERSONAL_DETAILS':
        this.customerService.customer = this.customer;
        this.customerService.indexEditableAddress = this.displayedAddressIndex;
        this.customerService.indexEditablePhone = this.displayedPhoneIndex;
        this.router.navigate(['./edit-personal-details'], { relativeTo: this.route });
        break;
      case 'EDIT_PASSWORD':
        this.router.navigate(['./edit-password'], { relativeTo: this.route });
        break;
      case 'EDIT_EMAIL':
        this.router.navigate(['./edit-email'], { relativeTo: this.route });
        break;
    }
  }


}
