import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpUrlEncodingCodec, HttpParams } from '@angular/common/http';
import { map, catchError, skipWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AlertsService } from '../alerts/alerts.service';

const headers = new HttpHeaders()
  .set('Authorization', 'Basic b2ZlLWF0OjU1MGJjOWIyLTE1ZWQtNDMwZS05ZjhlLTRmMzllN2Y0YmRkOA==')
  .set('Content-Type', 'application/x-www-form-urlencoded');

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  private readonly basicToken = 'b2ZlLWF0OjU1MGJjOWIyLTE1ZWQtNDMwZS05ZjhlLTRmMzllN2Y0YmRkOA==';
  private readonly encoder = new HttpUrlEncodingCodec();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private helper: JwtHelperService,
    private alertService: AlertsService
    ) { }

  getBasicToken() {
    return this.basicToken;
  }

  getAccessToken() {
    return sessionStorage.getItem('access_token');
  }

  getRefreshToken() {
    return this.helper.decodeToken(sessionStorage.getItem('refresh_token'));
  }

  refreshToken() {
    const params = new HttpParams().set('refresh_token', sessionStorage.getItem('refresh_token'));
    return this.httpClient.post<{ access_token: string, refresh_token: string }>(
      environment.restBase + '/oauth/token?grant_type=refresh_token', {}, { headers, params }
    ).pipe(
      map(res => {
        sessionStorage.setItem('access_token', res.access_token);
        sessionStorage.setItem('refresh_token', res.refresh_token);
        return this.helper.decodeToken(res.access_token);
      }),
      catchError(err => {
        this.logout();
        return throwError(() => err);
      })
    );
  }


  login(username: string, password: string, captcha?: string) {
    // FIXME: search a way to notification the login in order to reset services cache
    this.alertService.reset();
    const captchParam = captcha ? '&captcha=' + captcha : '';
    const body = 'username=' + this.encoder.encodeValue(username) + '&password=' + this.encoder.encodeValue(password);
    return this.httpClient.post<{ access_token: string, refresh_token: string }>(
      environment.restBase + '/oauth/token?grant_type=password' + captchParam, body, { headers }
    )
      .pipe(
        map(res => {
          sessionStorage.setItem('access_token', res.access_token);
          sessionStorage.setItem('refresh_token', res.refresh_token);
          return this.helper.decodeToken(res.access_token);
        }),
        catchError(err => {
          console.log(err);
            const errors: any = {
            invalidCredential: false,
            invalidCaptcha: false,
            accessDenided: false,
            accountLocked: false,
            serverError: false,
            remainingAttempts:err.headers.get("Remaining-Attempts")
          };

          if ( err.status === 400 ) {
            switch ( err.error.error ) {
              case 'invalid_grant':
                if ( err.error.error_description === 'Account is locked' ) {
                  errors.accountLocked = true;
                } else {
                  errors.invalidCredential = true;
                }
                break;
              case 'wrong_captcha':
                errors.invalidCaptcha = true;
                break;
              default :
                errors.serverError = true;
            }
          } else if ( err.status === 403 && err.error.error === 'access_denied' ) {
            errors.accessDenided = true;
          } else {
            errors.serverError = true;
          }
          return throwError(errors);
        })
      );
  }

  logout() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    this.router.navigate(['/login']);
  }

  public get loggedIn(): boolean {
    return sessionStorage.getItem('access_token') !== null;
  }

  public get UserStoredInformations(): any {
    return Object.assign({}, this.helper.decodeToken(sessionStorage.getItem('access_token')));
  }

}
