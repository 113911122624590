<span app-test="edit-password-page">
  <div [editSlug]="slug">

    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

            <h2 *ngIf="!isPWA" class="form-title">{{fields.edit_password_label}}</h2>
            <p>{{fields.mandatory_label}}</p>

            <form class="ng-untouched ng-pristine ng-valid"
            [formGroup]="passwordForm"
            app-test="password-form">

              <div class="form-group">
                <app-textfield
                [label]="fields.old_password_textfield_label"
                [isPassword]="true"
                controlName="oldPassword"
                [parentForm]="passwordForm"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [patternErrorMessage]="fields.invalid_error_txt"
                app-test="Old password">
                </app-textfield>
              </div>

              <div class="form-group">
                <app-textfield
                [label]="fields.new_password_textfield_label"
                [isPassword]="true"
                controlName="newPassword"
                [parentForm]="passwordForm"
                [required]="true"
                [requiredErrorMessage]="fields.required_error_txt"
                [pattern]="patterns.password.pattern"
                [patternErrorMessage]="fields.invalid_error_txt"
                [hint]="fields.password_textfield_hint"
                app-test="New password">
                </app-textfield>
              </div>

              <div class="form-group">
                <app-textfield
                [label]="fields.new_password_confirm_textfield_label"
                [isPassword]="true"
                controlName="confirmationPassword"
                [parentForm]="passwordForm"
                [required]="true"
                
                app-test="Confirmation password">
                </app-textfield>
                <mat-hint *ngIf="!passwordsMatch()" class="mat-hint hint-error" style="color: #d20062 !important">
                  <span app-test="validation-unconfirmed-lbl">{{ fields.password_confirm_match_error_message }}</span>
                </mat-hint>
              </div>

              <app-error-message
                *ngIf="errorOnRequest.serverError"
                [message]="fields.message_server_error"
                app-test="message-server-error">
              </app-error-message>
            
              <app-error-message
                *ngIf="errorOnRequest.badRequest"
                [message]="fields.message_bad_request_error"
                app-test="message-bad-request">
              </app-error-message>

              <app-error-message
                *ngIf="errorOnRequest.forbiddenError"
                [message]="fields.message_forbidden_error"
                app-test="message-forbidden-error">
              </app-error-message>

            </form>

            <app-form-buttons>
              <div>
                <app-button
                buttonType="secondary"
                [buttonLabel]="fields.cancel_button_label"
                (buttonClick)="cancel()"
                app-test="back-btn">
                </app-button>
                <app-button
                [buttonLabel]="fields.confirm_button_label"
                (buttonClick)="confirm()"
                [disabled]="passwordForm.invalid || !passwordsMatch()"
                app-test="confirm-btn">
                </app-button>
              </div>
            </app-form-buttons>

          </div>
        </div>
      </div>
    </div>

  </div>
</span>
