import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-additional-information",
  templateUrl: "./additional-information.component.html",
  styleUrls: ["./additional-information.component.scss"]
})
export class AdditionalInformationComponent implements OnInit {
  constructor() {}

  @Input() info: string = "The amount included the TVA";

  ngOnInit() {}
}
