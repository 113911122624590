import {
  Component,
  OnInit,
  HostListener,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ContentChild,
  Output,
  EventEmitter
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DeviceService } from "../../../services/device.service";
import { MenuItems } from '../nav-menu/nav-menu.component';
import { Globals } from "../../../globals";

@Component({
  selector: "app-login-layout",
  templateUrl: "./login-layout.component.html",
  styleUrls: ["./login-layout.component.scss"]
})
export class LoginLayoutComponent implements OnInit, AfterViewInit {

  @Input() cookiesAlert: any;
  @Output() cookiesEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() appTest: string;

  @ContentChild("formContentRef", {static: true}) formContentRef;
  @ContentChild("sideContentRef", {static: true}) sideContentRef;

  mobile: boolean = false;
  @Input() isPWA: boolean = false;

  @ViewChild("header", { read: ElementRef, static: true })
  headerElement: ElementRef;

  @ViewChild("footer", { read: ElementRef, static: true })
  footerElement: ElementRef;

  footer: Element;
  header: Element;

  @Input() backToLink: string;
  @Input() backToLabel: string;

  @Input() footerLinks: MenuItems[];
  @Input() copyright: string;

  @Input() contactBlocksData: [];
  @Input() contactBlocksButton: string;

  constructor(
    private layoutService: LayoutService,
    private deviceService: DeviceService,
    private globals: Globals
  ) {
    this.isPWA = this.deviceService.isPWA || this.deviceService.isMobileSize();
    this.globals.isLoginPage = true;
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this._checkMobile();
    if (this.header && this.footer) {
      this.layoutService.resizeLoginPage(this.header, this.footer);
    }
  }

  ngOnInit() {
    this._checkMobile();
  }

  private _checkMobile() {
    if (window.innerWidth < 768) {
      this.mobile = true;
      this.isPWA = true;
    } else {
      this.mobile = false;
      this.isPWA = false || this.deviceService.isPWA;
    }
    if (this.isPWA) {
      document.querySelector("html").classList.add("pwa");
    } else {
      document.querySelector("html").classList.remove("pwa");
    }
  }

  ngAfterViewInit() {
    if (!this.footerElement || !this.headerElement) {
      return false;
    }
    this.header = this.headerElement.nativeElement;
    this.footer = this.footerElement.nativeElement;

    this.layoutService.triggerResize();
  }
}
