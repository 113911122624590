import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  Output,
  EventEmitter
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DialogService } from "src/app/design-system/services/dialog.service";
import { MatNavList } from "@angular/material/list";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"]
})
export class NavMenuComponent implements OnInit, AfterViewInit {
  constructor(
    private layoutService: LayoutService,
    private dialogService: DialogService
  ) { }
  @Input() appTest: string;

  @ViewChild("matNNavList", { static: false }) matNNavList: MatNavList;

  @ViewChild("navMenu", { static: true }) navMenuElement: ElementRef;
  navMenu: HTMLElement;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _selectedItemIndex: number = 0;
  public get selectedItemIndex(): number {
    return this._selectedItemIndex;
  }
  @Input()
  public set selectedItemIndex(value: number) {
    this._selectedItemIndex = value;
  }

  @Input() isModal: boolean;
  @Input() isFooterMenu = false;

  @Input() menuItems: MenuItems[];

  sizeChange(event) {
    if (this.navMenu) {
      this.layoutService.resizeDesktopPages(this.navMenu);
    }
  }


  itemClickHandler(i) {
    if (this.selectedItemIndex === i) { return false; }
    this.selectedItemIndex = i;
    if (this.isModal) {
      setTimeout(this.dialogService.close, 200);
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.navMenu = this.navMenuElement.nativeElement;
    this.layoutService.resizeDesktopPages(this.navMenu);
  }
}

export interface MenuItems {
  itemTitle: string;
  routerLink: string;
  isFooterLink?: boolean;
  appTest: string;
}
