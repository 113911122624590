<div class="content-box content-box--form">
  <div class="content-box-inner">
    <div class="layout-large">
      <form>
        <h2 class="form-title">{{ title }}</h2>
        <a class="link-body-text up-corner-link" href="#">Go to my Renault</a>
        <div class="rwd-row">
          <div class="rwd-col-50">
            <h3 class="form-heading">{{ heading }}</h3>
            <p class="mention">{{ imageAccepted }}</p>
            <div class="bg-image-holder">
              <app-bg-image [bgImage]="image"></app-bg-image>
              <div class="upload-image">
                <app-icon-button
                  icon="icon-photo-circle"
                  iconWidth="57px"
                  iconHeight="57px"
                  colorClass="color-white"
                  class="hover-white-strong change-photo"
                  (click)="browseImage()"
                ></app-icon-button>
                <input #uploadImage [hidden]="true" type="file" />
              </div>
            </div>
          </div>
          <div class="rwd-col-50">
            <div class="form-row-table">
              <div class="form-group">
                <app-textfield
                  [label]="brandLabel"
                  [value]="brand"
                  [prefilled]="true"
                  [disabled]="true"
                ></app-textfield>
              </div>
              <div class="form-group">
                <app-textfield
                  [label]="modelLabel"
                  [value]="model"
                  [prefilled]="true"
                  [disabled]="true"
                ></app-textfield>
              </div>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="registrationDateLabel"
                [value]="registrationDate"
                [prefilled]="true"
                [disabled]="true"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="licensePlateNumberLabel"
                [value]="licensePlateNumber"
                [prefilled]="true"
                [disabled]="true"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="vinLabel"
                [value]="vin"
                [prefilled]="true"
                [disabled]="true"
              ></app-textfield>
            </div>
            <div class="form-group">
              <app-textfield
                [label]="vehiculeNameLabel"
                [value]="vehiculeName"
              ></app-textfield>
            </div>
            <div class="form-group">
              <label class="upload-licence-label">{{
                uploadRegistrationCardLabel
              }}</label>
              <div class="form-group">
                <app-file-upload
                  [dropFormatsAccepted]="dropFormatsAccepted"
                  [dropInstructions]="dropInstructions"
                  [dropFormatsAccepted]="dropFormatsAccepted"
                  [browseBtnLabel]="browseBtnLabel"
                  [fileLabel]="registrationCardLabel"
                  (onFileDrop)="updateFiles($event)"
                ></app-file-upload>
              </div>
            </div>
          </div>
        </div>

        <div class="layout-narrow">
          <app-form-buttons>
            <app-button
              buttonType="secondary"
              [buttonLabel]="cancelLabel"
            ></app-button
            ><app-button [buttonLabel]="confirmLabel"></app-button>
          </app-form-buttons>
        </div>
      </form>
    </div>
  </div>
</div>
