<div
  class="app-page page"
  [ngClass]="{ 'home-page': isHome, 'sub-page': isSubPage }"
>
  <app-header-connected
  	#header
  	*ngIf="!(isPWA && (isSubPage || isMenuMore))"
  	[menuItems]="menuItems"
    [userName]="userName"
    [welcomeText]="welcomeText"
    [alertsTitle]="alertsTitle"
    [notificationItems]="notificationItems" >
  </app-header-connected>

  <div class="page-content" [ngClass]="{ 'home-content': isHome }">
    <div class="container">
      <div class="grid">
        <div class="page-left-column">
          <div class="content-box">
            <app-nav-menu [menuItems]="menuItems" app-test="nav-left-side-menu">
            </app-nav-menu>
          </div>
        </div>
        <div class="grid-sizer"></div>

        <div *ngIf="isSubPage && !isPWA" class="grid-item grid-item--fullsize back-header">
          <div class="content-box">
            <app-back-to
              [label]="backToLabel"
              [link]="backToLink">
            </app-back-to>
          </div>
        </div>

        <div *ngIf="isSubPage && isPWA && title" class="grid-item grid-item--fullsize back-header">
          <div class="content-box">
            <app-title-bar
              [title]="title"
              [isSubPage]="true"
              [backToLink]="backToLink"
              [withFlag]="false">
            </app-title-bar>
          </div>
        </div>

        <ng-container>
          <ng-container *ngTemplateOutlet="pageContentRef"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <app-footer
    #footer
    *ngIf="!isPWA"
    [isPWA]="isPWA"
    [footerLinks]="footerLinks"
    [withDownloadLogos]="false"
    [copyright]="copyright"
    [contactBlocksData]="contactBlocksData"
    [contactBlocksButton]="contactBlocksButton"
  ></app-footer>
</div>
<app-pwa-chat-bar *ngIf="isPWA" [isTopPage]="!isSubPage" [contactBlocksData]="contactBlocksData"></app-pwa-chat-bar>
<app-pwa-tab-bar
  *ngIf="!isSubPage && isPWA"
  [tabBarData]="tabBarData"
  [menuItems]="menuItems"
  [footerLinks]="footerLinks"
  [copyright]="copyright"
  [tabBarMoreLabel]="tabBarMoreLabel"
></app-pwa-tab-bar>
