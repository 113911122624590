import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { SettlementService } from 'src/app/services/settlement/settlement.service';

@Component({
  selector: 'app-settlement-sent',
  templateUrl: './settlement-sent.component.html',
  styleUrls: ['./settlement-sent.component.sass']
})
export class SettlementSentComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public requestSent: boolean = undefined;

  constructor(private route: ActivatedRoute,
              private router: Router) {
      super('action-settlement');
    }

  ngOnInit() {
    this.requestSent = this.route.snapshot.data.requestSent;
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.parent.params.id,
      isSubPage : true,
      isHome : false
    };
  }

  backToAgreement() {
    this.router.navigate(['/home/agreements/' + this.route.snapshot.parent.parent.params.id]);
  }
}
