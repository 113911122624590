import { Component, OnInit, Input } from "@angular/core";
import { SyntheseItem } from "../../lists/synthese-list/synthese-list.component";

@Component({
  selector: "app-agreement-credit",
  templateUrl: "./agreement-credit.component.html",
  styleUrls: ["./agreement-credit.component.scss"]
})
export class AgreementCreditComponent implements OnInit {
  constructor() {}

  @Input() label: string;
  @Input() date?: string;
  @Input() infos: SyntheseItem[];
  @Input() agreement: any;
  @Input() of: string;

  public progressValue: number;

  ngOnInit() {
    this.progressValue =  (this.agreement.currentInstallmentNumber * 100) / this.agreement.totalInstallmentsNumber;
  }
}
