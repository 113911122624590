<div class="layout-narrow">
  <h2 class="form-title">{{fields.form_title}}</h2>
  <p>{{fields.form_instruction}}</p>

  <div class="form-group">
    <app-stepper
    [selectedIndex]="stepIndex"
    [stepData]="registrationSteps"
    app-test="registration-stepper-0">
    </app-stepper>
  </div>

  <div class="layout-xsmall">
    <h2 class="form-subtitle">{{fields.step_1_title}}</h2>
    <p class="mention">{{fields.step_1_instruction}}</p>

    <app-error-message *ngIf="identificationErrors.invalidSignupCredential" [message]="fields.message_invalid_signup_credential" app-test="message-invalid-signup-credential"></app-error-message>
    <app-error-message *ngIf="identificationErrors.alredyRegisteredCustomer" [message]="fields.message_already_registered_customer" app-test="message-already-registered-customer"></app-error-message>
    <app-error-message *ngIf="identificationErrors.alreadyExistingUsername" [message]="fields.message_already_existing_username" app-test="message-already-existing-username"></app-error-message>
    <app-error-message *ngIf="identificationErrors.serverError" [message]="fields.message_server_error" app-test="message-server-error"></app-error-message>
    <app-form-buttons *ngIf="haveServiceErrors()">
      <div>
        <app-button
        buttonType="secondary"
        [buttonLabel]="fields.back_button_label"
        (buttonClick)="back()"
        app-test="back-btn">
        </app-button>
      </div>
    </app-form-buttons>

    <form class="ng-untouched ng-pristine ng-valid"
    [formGroup]="identificationForm"
    app-test="identification-form"
    *ngIf="!haveServiceErrors()">
      <div class="form-group">
        <app-textfield
        [label]="fields.name_textfield_label"
        controlName="name"
        [parentForm]="identificationForm"
        [disabled]="true"
        [prefilled]="true"
        app-test="name-txt">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.surname_textfield_label"
        controlName="surname"
        [parentForm]="identificationForm"
        [disabled]="true"
        [prefilled]="true"
        app-test="surname-txt">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.birth_date_textfield_label"
        controlName="birthDate"
        [parentForm]="identificationForm"
        [disabled]="true"
        [prefilled]="true"
        app-test="birth-date-txt">
        </app-textfield>
      </div>
      <app-form-buttons>
        <div>
          <app-button
          buttonType="secondary"
          [buttonLabel]="fields.back_button_label"
          (buttonClick)="back()"
          app-test="back-btn">
          </app-button>
          <app-button
          [buttonLabel]="fields.confirm_button_label"
          (buttonClick)="continue()"
          app-test="confirm-identity-btn">
          </app-button>
        </div>
      </app-form-buttons>
    </form>
  </div>

</div>