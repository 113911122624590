import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef
} from "@angular/core";

@Component({
  selector: "app-faq-list-item",
  templateUrl: "./faq-list-item.component.html",
  styleUrls: ["./faq-list-item.component.scss"],
  host: { class: "faq-list-item" }
})
export class FaqListItemComponent implements OnInit, AfterViewInit {
  constructor() {}

  @ViewChild("answer", { static: true }) answerRef: ElementRef;

  @Input() question: string = "Question";
  @Input() answer: string = "Answer";
  @Input() formHeading: string = "Was this information helpful?";
  @Input() enableFeedback: boolean = false;

  ngOnInit() {}
  ngAfterViewInit() {
   this.answerRef.nativeElement.innerHTML = this.answer;
  }
}
