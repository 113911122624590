<app-message-block 
	*ngIf="requestSent"
    [title]="fields.thank_you_title" 
    [message]="fields.action_statement_success_message" 
    [secondaryButton]="{
        label : fields.back_to_agreement_details_button_label
    }"
    (secondaryButtonEmitter)="backToAgreement()"
    appTest="statement-sent-success">
</app-message-block>


<app-message-block 
    *ngIf="requestSent===false"
    [title]="" 
    [icon]="'icon-circle-close'"
    [message]="fields.message_server_error"
    [secondaryButton]="{
        label : fields.back_to_agreement_details_button_label
    }"
    (secondaryButtonEmitter)="backToAgreement()"
	appTest="statement-sent-failed">
</app-message-block>

