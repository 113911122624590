<h4 class="feed-item-title">
  {{ title }}
</h4>
<p>{{ text }}</p>
<app-button
  *ngIf="buttonLabel"
  buttonType="mat-accent"
  [buttonLabel]="buttonLabel"
  (buttonClick)="action()"
></app-button>
