import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  QueryList,
  Output,
  EventEmitter
} from "@angular/core";
import { RadioButtonComponent } from "../../forms/radio-button/radio-button.component";
import { MatExpansionPanel, MatAccordion } from "@angular/material/expansion";
import { LayoutService } from 'src/app/design-system/services/layout.service';
import { Globals } from "../../../globals";

@Component({
  selector: "app-collapse-link",
  templateUrl: "./collapse-link.component.html",
  styleUrls: ["./collapse-link.component.scss"],
  host: { class: "collapse-link" }
})
export class CollapseLinkComponent implements OnInit, AfterViewInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private globals: Globals,
    private layoutService: LayoutService
  ) {}

  @ViewChild("radioButton", { static: false }) radioButton: RadioButtonComponent;
  @ViewChild("expansionPanel", { static: true }) expansionPanel: MatExpansionPanel;

  @Input() accordion: MatAccordion;
  @Input() title: string;
  @Input() radioName?: string = null;
  @Input() value?: string;

  @Input() expanded: boolean = false;
  @Input() topLevelPanes: QueryList<CollapseLinkComponent>;

  @Output() radioSelectionEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() appTest?: string;

  pageLayout: any;

  openHandler() {
    this.updateView();
    this.expanded = this.expansionPanel.expanded;
    if (this.radioButton) {
      this.updateRadio(true);
      this.getRadioSelection();
    }

    this.globals.updateMasonry();
  }

  closeHandler() {
    this.updateView();
    this.expanded = this.expansionPanel.expanded;
    if (this.radioButton) {
      this.updateRadio(false);
      if (this.isAccordionClosed()) {
        this.expansionPanel.open();
      }
    }
    this.globals.updateMasonry();
  }

  updateView() {
    //const scrollTop = window.scrollY;
    setTimeout(() => {
      this.layoutService.initMasonry();
    }, 0);
    //setTimeout(window.scrollTo, 0, 0, scrollTop);
  }

  isAccordionClosed() {
    const isCLosed: boolean = this.topLevelPanes.toArray().every(item => {
      return item.expanded === false;
    });
    return isCLosed;
  }

  updateRadio(expanded) {
    if (!this.radioButton) return false;
    this.radioButton.checked = expanded;
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.expansionPanel.accordion = this.accordion;
    if (this.expanded) {
      this.updateRadio(this.expanded);
    }
  }

  getRadioSelection() {
    //console.log(this.radioButton.value);
    this.radioSelectionEmitter.emit(this.radioButton.value);
  }

}
