import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-alert-message",
  templateUrl: "./alert-message.component.html",
  styleUrls: ["./alert-message.component.scss"]
})
export class AlertMessageComponent implements OnInit {
  @Input() appTest: string;

  //named info in zeroheight
  // message, string to display
  // write rich html text directly between the tags
  @Input() message: string;

  //default icon : alert
  @Input() icon: string = "icon-circle-warning";
  @Input() colorClass: string = "color-accent";

  constructor() {}

  ngOnInit() {}
}
