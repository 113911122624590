import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-forgotten-password-form',
  templateUrl: './forgotten-password-form.component.html',
  styleUrls: ['./forgotten-password-form.component.sass']
})
export class ForgottenPasswordFormComponent extends AbstractComponent implements OnInit {

  public passwordForgottenForm: FormGroup;
  public backToLink = '/login';
  public error: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private usersService: UsersService) {
                super('forgotten-password-page');
              }

  ngOnInit() {
    this.passwordForgottenForm = this.fb.group({
      email: [''],
      recaptcha: ['']
    });
  }

  confirm() {
    const email = this.passwordForgottenForm.get('email').value;
    const recaptcha = this.passwordForgottenForm.get('recaptcha').value;
    this.usersService.passwordResetRequest(email, recaptcha).then(data => {
      this.router.navigate(['/forgotten-password/sent']);
    },
    err => {
      this.error = err;
    });
  }

  back() {
    this.router.navigate(['/login']);
  }
}
