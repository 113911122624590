import { Component, OnInit, Input, HostBinding } from "@angular/core";

@Component({
  selector: "app-pwa-chat-bar",
  templateUrl: "./pwa-chat-bar.component.html",
  styleUrls: ["./pwa-chat-bar.component.scss"]
})
export class PwaChatBarComponent implements OnInit {

  @Input() contactBlocksData: [];

  constructor() {}

  @HostBinding("class.top-page") @Input() isTopPage: boolean;

  ngOnInit() {}
}
