<div
  class="block-commercial"
  [ngClass]="[
    branding ? branding : '',
    edged ? 'edged' : '',
    withFlag ? ' with-flag' : '',
    isMajor ? 'major' : 'minor',
    visualLeft ? 'visual-left' : ''
  ]">

  <div class="bg-image-holder">
    <app-bg-image [bgImage]="image" [contain]="imageContain"></app-bg-image>
  </div>

  <div class="block-content-box">

    <h3 app-test="card-title">{{ title }}</h3>
    <app-flag-favorite
      class="flag"
      *ngIf="withFlag"
      (isFlagCheckedEmitter)="isFlagCheckedChangeHandler($event)"
      [isChecked]="isChecked">
    </app-flag-favorite>

    <h4 *ngIf="subtitle">{{ subtitle }}</h4>

    <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(this.text)"></p>

    <div class="link-container" *ngIf="isLink">
      <app-button
        [isLink]="isLink"
        [branding]="branding"
        (buttonClick)="buttonClickHandler($event)">
          {{ buttonLabel }}
        </app-button>
    </div>
  </div>
</div>
