import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AgreementsContentComponent } from './agreements-content/agreements-content.component';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';
import { AlertsService } from 'src/app/services/alerts/alerts.service';
import { CommonComponentsModule } from '../commons/common-components.module';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { AgreementContentComponent } from './agreement-content/agreement-content.component';
import { PaymentPlanComponent } from './payment-plan/payment-plan.component';
import { SettlementComponent } from './settlement/settlement.component';
import { SettlementService } from 'src/app/services/settlement/settlement.service';
import { StatementComponent } from './statement/statement.component';
import { SettlementSentComponent } from './settlement-sent/settlement-sent.component';
import { StatementSentComponent } from './statement-sent/statement-sent.component';
import { TypenscheinComponent } from './typenschein/typenschein.component';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { TypenscheinSentComponent } from './typenschein-sent/typenschein-sent.component';
import { ExtraCardsComponent } from '../commons/extra-cards/extra-cards.component';
import { SettlementPaymentInfoComponent } from './settlement-payment-info/settlement-payment-info.component';
import { StatementService } from 'src/app/services/statement/statement.service';
import { PaymentPlanService } from 'src/app/services/payment-plan/payment-plan.service';
import { HomeTabComponent } from '../commons/home-tab/home-tab.component';

const routes: Routes = [
  { path: 'home',
    redirectTo: '/home/agreements',
    pathMatch: 'full' },
  {
    path: 'home/agreements',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeTabComponent,
        children: [
          {
            path: '',
            component: AgreementsContentComponent,
            resolve: {
              agreements: AgreementsService,
              alerts: AlertsService
            },
          }
        ]
      },
      {
        path: '',
        component: ExtraCardsComponent,
        outlet : 'extras'
      }
    ]
  },
  {
    path: 'home/agreements/:id',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    resolve: {
      agreement : AgreementsService
    },
    children: [
      {
        path: '',
        component: AgreementContentComponent,
      },
      {
        path: '',
        component: ExtraCardsComponent,
        outlet : 'extras'
      }
    ]
  },
  {
    path: 'home/agreements/:id',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    resolve: {
      agreement : AgreementsService
    },
    children: [
      {
        path: 'payment-plan',
        component: PaymentPlanComponent,
        resolve: {
          paymentPlan : PaymentPlanService
        }
      },
      {
        path: 'statement/sent',
        component: StatementSentComponent,
        resolve: {
          requestSent : StatementService
        }
      },
      {
        path: 'settlement',
        canActivate: [AuthGuard],
        resolve: {
          settlement : SettlementService
        },
        children: [
          {
            path: '',
            component: SettlementComponent,
          },
          {
            path: 'sent',
            component: SettlementSentComponent,
            resolve: {
              requestSent : SettlementService
            }
          },
          {
            path: 'payment-info',
            component: SettlementPaymentInfoComponent
          }
        ]
      },
      {
        path: 'typenschein',
        canActivate: [AuthGuard],
        resolve: {
          customer : CustomerService
        },
        children: [
          {
            path: '',
            component: TypenscheinComponent,
          },
          {
            path: 'sent',
            component: TypenscheinSentComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    AgreementsContentComponent,
    AgreementContentComponent,
    PaymentPlanComponent,
    SettlementComponent,
    SettlementSentComponent,
    StatementComponent,
    StatementSentComponent,
    TypenscheinComponent,
    TypenscheinSentComponent,
    SettlementPaymentInfoComponent
    //add here all the required components
  ]
})
export class AgreementsModule { }

