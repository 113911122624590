<table>
  <caption>
    {{
      caption
    }}
  </caption>
  <tbody>
    <tr>
      <td colspan="2">
        <!-- <p [innerHTML]="infos">Purpose: {{ caption }}</p> -->
        <p>{{ purposeLabel }}: {{ purpose }}</p>
        <p>{{ retentionPeriodLabel }}: {{ period }}</p>
        <p>{{ descriptionLabel }}: {{ description }}</p>
      </td>
    </tr>
    <tr>
      <th>{{ usedNamesLabel }}</th>
      <th>{{ sharingLabel }}</th>
    </tr>
    <tr>
      <td>
        {{ usednames }}
      </td>
      <td>
        <a class="link-body-text" href="{{ sharingurl }}">{{
          privacyStatementLabel
        }}</a>
      </td>
    </tr>
  </tbody>
</table>
