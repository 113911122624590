import { NgModule } from "@angular/core";
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import { ButtonComponent } from '../components/navigation/button/button.component';
import { CardComponent } from '../components/blocks/card/card.component';
import { BgImageComponent } from '../components/media/bg-image/bg-image.component';
import { IconComponent } from '../components/icons/icon/icon.component';
import { MaterialModule } from './material.module';
import { IconButtonComponent } from '../components/icons/icon-button/icon-button.component';
import { QuickAccessComponent } from '../components/blocks/quick-access/quick-access.component';
import { BlockContractsComponent } from '../components/blocks/block-contracts/block-contracts.component';
import { HeaderComponent } from '../components/structure/header/header.component';
import { FooterComponent } from '../components/structure/footer/footer.component';
import { StepperComponent } from '../components/navigation/stepper/stepper.component';
import { TextfieldComponent } from '../components/forms/textfield/textfield.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from '../components/forms/select/select.component';
import { RadioButtonComponent } from '../components/forms/radio-button/radio-button.component';
import { RadioGroupComponent } from '../components/forms/radio-group/radio-group.component';
import { InputSearchComponent } from '../components/forms/input-search/input-search.component';
import { DatepickerComponent } from '../components/forms/datepicker/datepicker.component';
import { CheckboxGroupComponent } from '../components/forms/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from '../components/forms/checkbox/checkbox.component';
import { BackToComponent } from '../components/navigation/back-to/back-to.component';
import { ErrorMessageComponent } from '../components/forms/error-message/error-message.component';
import { AlertMessageComponent } from '../components/blocks/alert-message/alert-message.component';
import { PasswordKeyboardComponent } from '../components/forms/password-keyboard/password-keyboard.component';
import { IconFeedbackComponent } from '../components/icons/icon-feedback/icon-feedback.component';
import { LoginLayoutComponent } from '../components/structure/login-layout/login-layout.component';
import { DynamicListItemComponent } from '../components/forms/dynamic-list-item/dynamic-list-item.component';
import { DynamicListComponent } from '../components/forms/dynamic-list/dynamic-list.component';
import { RatingComponent } from '../components/forms/rating/rating.component';
import { TextareaComponent } from '../components/forms/textarea/textarea.component';
import { LoginHelpComponent } from '../components/blocks/login-help/login-help.component';
import { RegistrationHelpComponent } from '../components/blocks/registration-help/registration-help.component';
import { ContactButtonComponent } from '../components/navigation/contact-button/contact-button.component';
import { FormButtonsComponent } from '../components/forms/form-buttons/form-buttons.component';
import { VideoComponent } from '../components/media/video/video.component';
import { EmbedVideo } from 'ngx-embed-video';
import { ContactBlocksComponent } from '../components/modals/contact-blocks/contact-blocks.component';
import { ContactBlockComponent } from '../components/modals/contact-block/contact-block.component';
import { DialogComponent } from '../components/modals/dialog/dialog.component';
import { TermOfUseComponent } from '../components/modals/term-of-use/term-of-use.component';
import { UpdatePageSizeOnChangeDirective } from '../directives/update-page-size-on-change.directive';
import { HeaderConnectedComponent } from '../components/structure/header-connected/header-connected.component';
import { PageLayoutComponent } from '../components/structure/page-layout/page-layout.component';
import { NotificationButtonComponent } from '../components/navigation/notification-button/notification-button.component';
import { NavMenuComponent } from '../components/structure/nav-menu/nav-menu.component';
import { UserNameComponent } from '../components/blocks/user-name/user-name.component';
import { NavMenuMobileComponent } from '../components/structure/nav-menu-mobile/nav-menu-mobile.component';
import { SwitchContainerComponent } from '../components/navigation/switch-container/switch-container.component';
import { SwitchContainerItemComponent } from '../components/navigation/switch-container-item/switch-container-item.component';
import { ShowMoreButtonComponent } from '../components/navigation/show-more-button/show-more-button.component';
import { VehiculeEditionComponent } from '../components/forms/vehicule-edition/vehicule-edition.component';
import { BlockVehiculesComponent } from '../components/blocks/block-vehicules/block-vehicules.component';
import { LinkItemComponent } from '../components/lists/link-item/link-item.component';
import { NestedMasonryLayoutComponent } from '../components/structure/nested-masonry-layout/nested-masonry-layout.component';
import { BlockCommercialComponent } from '../components/blocks/block-commercial/block-commercial.component';
import { BlockVehiculeComponent } from '../components/blocks/block-vehicule/block-vehicule.component';
import { PushLinkComponent } from "../components/navigation/push-link/push-link.component";
import { FlagFavoriteComponent } from '../components/icons/flag-favorite/flag-favorite.component';
import { DropFilesBlockComponent } from '../components/forms/drop-files-block/drop-files-block.component';
import { LinkBodyTextComponent } from '../components/navigation/link-body-text/link-body-text.component';
import { TabsComponent } from '../components/navigation/tabs/tabs.component';
import { BlockTitleComponent } from '../components/blocks/block-title/block-title.component';
import { BlockRowComponent } from '../components/blocks/block-row/block-row.component';
import { CollapseLinkComponent } from '../components/navigation/collapse-link/collapse-link.component';
import { LinksListComponent } from '../components/lists/links-list/links-list.component';
import { TimelineComponent } from '../components/lists/timeline/timeline.component';
import { TimelineItemComponent } from '../components/lists/timeline-item/timeline-item.component';
import { BlockActionsComponent } from '../components/navigation/block-actions/block-actions.component';
import { AgreementAlertComponent } from '../components/blocks/agreement-alert/agreement-alert.component';
import { AgreementSyntheseComponent } from '../components/blocks/agreement-synthese/agreement-synthese.component';
import { AgreementCreditComponent } from '../components/blocks/agreement-credit/agreement-credit.component';
import { SyntheseListComponent } from '../components/lists/synthese-list/synthese-list.component';
import { ProgressBarComponent } from '../components/forms/progress-bar/progress-bar.component';
import { CheckListComponent } from '../components/lists/check-list/check-list.component';
import { ProgressTipComponent } from "../components/blocks/progress-tip/progress-tip.component";
import { ProgressCreditComponent } from '../components/blocks/progress-credit/progress-credit.component';
import { TableComponent } from '../components/tables/table/table.component';
import { ActionGroupComponent } from '../components/navigation/action-group/action-group.component';
import { AccordionComponent } from '../components/navigation/accordion/accordion.component';
import { EmphasisListComponent } from '../components/lists/emphasis-list/emphasis-list.component';
import { ProfileIntroComponent } from '../components/blocks/profile-intro/profile-intro.component';
import { PopInComponent } from '../components/modals/pop-in/pop-in.component';
import { FeedComponent } from '../components/modals/feed/feed.component';
import { FeedNotificationComponent } from '../components/modals/feed-notification/feed-notification.component';
import { FeedMessageComponent } from '../components/modals/feed-message/feed-message.component';
import { MessageToolComponent } from '../components/modals/message-tool/message-tool.component';
import { WelcomeComponent } from '../components/blocks/welcome/welcome.component';
import { BodyLinkComponent } from '../components/navigation/body-link/body-link.component';
import { AdditionalInformationComponent } from '../components/blocks/additional-information/additional-information.component';
import { CookieBannerComponent } from '../components/blocks/cookie-banner/cookie-banner.component';
import { CookieDetailsComponent } from '../components/blocks/cookie-details/cookie-details.component';
import { FabButtonComponent } from '../components/navigation/fab-button/fab-button.component';
import { PwaTabBarComponent } from '../components/structure/pwa-tab-bar/pwa-tab-bar.component';
import { PwaTabComponent } from '../components/structure/pwa-tab/pwa-tab.component';
import { PwaMenuMoreComponent } from '../components/structure/pwa-menu-more/pwa-menu-more.component';
import { PwaChatBarComponent } from '../components/structure/pwa-chat-bar/pwa-chat-bar.component';
import { TitleBarComponent } from '../components/blocks/title-bar/title-bar.component';
import { CloseButtonComponent } from '../components/modals/close-button/close-button.component';
import { PwaFormBackLinkComponent } from '../components/navigation/pwa-form-back-link/pwa-form-back-link.component';
import { FaqFormComponent } from '../components/forms/faq-form/faq-form.component';
import { FaqComponent } from '../components/blocks/faq/faq.component';
import { FaqListComponent } from '../components/lists/faq-list/faq-list.component';
import { FaqListItemComponent } from '../components/lists/faq-list-item/faq-list-item.component';
import { Globals } from '../globals';
import { LoaderComponent } from '../components/loaders/loader/loader.component';
import { OfflineAlertComponent } from '../components/alerts/offline-alert/offline-alert.component';
import { ProjectsFilterComponent } from '../components/blocks/projects-filter/projects-filter.component';
import { FilterListComponent } from '../components/lists/filter-list/filter-list.component';
import { BlockHelpComponent } from '../components/blocks/block-help/block-help.component';
import { ImageComponent } from '../components/media/image/image.component';
import { BlockPushPromoComponent } from '../components/blocks/block-push-promo/block-push-promo.component';
import { BannerImageComponent } from '../components/media/banner-image/banner-image.component';
import { SwitchTabsComponent } from '../components/navigation/switch-tabs/switch-tabs.component';
import { FileUploadComponent } from '../components/forms/file-upload/file-upload.component';
import { VehiculeEditionFormComponent } from '../components/forms/vehicule-edition-form/vehicule-edition-form.component';
import { PageNologLayoutComponent } from '../components/structure/page-nolog-layout/page-nolog-layout.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    EmbedVideo.forRoot()
  ],
  declarations: [
    ButtonComponent,
    CardComponent,
    BgImageComponent,
    IconComponent,
    IconButtonComponent,
    QuickAccessComponent,
    BlockContractsComponent,
    HeaderComponent,
    FooterComponent,
    StepperComponent,
    TextfieldComponent,
    SelectComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    CheckboxGroupComponent,
    CheckboxComponent,
    InputSearchComponent,
    DatepickerComponent,
    BackToComponent,
    ErrorMessageComponent,
    AlertMessageComponent,
    PasswordKeyboardComponent,
    IconFeedbackComponent,
    LoginLayoutComponent,
    DynamicListItemComponent,
    DynamicListComponent,
    RatingComponent,
    TextareaComponent,
    LoginHelpComponent,
    RegistrationHelpComponent,
    ContactButtonComponent,
    FormButtonsComponent,
    VideoComponent,
    ContactBlocksComponent,
    ContactBlockComponent,
    DialogComponent,
    TermOfUseComponent,
    UpdatePageSizeOnChangeDirective,
    HeaderConnectedComponent,
    PageLayoutComponent,
    NotificationButtonComponent,
    NavMenuComponent,
    UserNameComponent,
    NavMenuMobileComponent,
    SwitchContainerComponent,
    SwitchContainerItemComponent,
    ShowMoreButtonComponent,
    BlockVehiculesComponent,
    VehiculeEditionComponent,
    LinkItemComponent,
    NestedMasonryLayoutComponent,
    BlockCommercialComponent,
    BlockVehiculeComponent,
    PushLinkComponent,
    FlagFavoriteComponent,
    DropFilesBlockComponent,
    LinkBodyTextComponent,
    TabsComponent,
    ActionGroupComponent,
    BlockTitleComponent,
    BlockRowComponent,
    CollapseLinkComponent,
    LinksListComponent,
    TimelineComponent,
    TimelineItemComponent,
    BlockActionsComponent,
    AgreementAlertComponent,
    AgreementSyntheseComponent,
    AgreementCreditComponent,
    SyntheseListComponent,
    ProgressBarComponent,
    CheckListComponent,
    ProgressTipComponent,
    ProgressCreditComponent,
    TableComponent,
    AccordionComponent,
    EmphasisListComponent,
    ProfileIntroComponent,
    PopInComponent,
    FeedComponent,
    FeedNotificationComponent,
    FeedMessageComponent,
    MessageToolComponent,
    WelcomeComponent,
    BodyLinkComponent,
    AdditionalInformationComponent,
    CookieBannerComponent,
    CookieDetailsComponent,
    FabButtonComponent,
    PwaTabBarComponent,
    PwaTabComponent,
    PwaMenuMoreComponent,
    PwaChatBarComponent,
    TitleBarComponent,
    CloseButtonComponent,
    PwaFormBackLinkComponent,
    FaqFormComponent,
    FaqComponent,
    FaqListComponent,
    FaqListItemComponent,
    LoaderComponent,
    OfflineAlertComponent,
    ProjectsFilterComponent,
    FilterListComponent,
    BlockHelpComponent,
    ImageComponent,
    BlockPushPromoComponent,
    BannerImageComponent,
    SwitchTabsComponent,
    FileUploadComponent,
    VehiculeEditionFormComponent,
    PageNologLayoutComponent
  ],
  exports: [
    ButtonComponent,
    CardComponent,
    BgImageComponent,
    IconComponent,
    IconButtonComponent,
    QuickAccessComponent,
    BlockContractsComponent,
    HeaderComponent,
    FooterComponent,
    StepperComponent,
    TextfieldComponent,
    SelectComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    CheckboxGroupComponent,
    CheckboxComponent,
    InputSearchComponent,
    DatepickerComponent,
    BackToComponent,
    ErrorMessageComponent,
    AlertMessageComponent,
    PasswordKeyboardComponent,
    IconFeedbackComponent,
    LoginLayoutComponent,
    DynamicListItemComponent,
    DynamicListComponent,
    RatingComponent,
    TextareaComponent,
    LoginHelpComponent,
    RegistrationHelpComponent,
    ContactButtonComponent,
    FormButtonsComponent,
    VideoComponent,
    ContactBlocksComponent,
    ContactBlockComponent,
    DialogComponent,
    TermOfUseComponent,
    UpdatePageSizeOnChangeDirective,
    HeaderConnectedComponent,
    PageLayoutComponent,
    NotificationButtonComponent,
    NavMenuComponent,
    UserNameComponent,
    NavMenuMobileComponent,
    SwitchContainerComponent,
    SwitchContainerItemComponent,
    ShowMoreButtonComponent,
    BlockVehiculesComponent,
    VehiculeEditionComponent,
    LinkItemComponent,
    NestedMasonryLayoutComponent,
    BlockCommercialComponent,
    BlockVehiculeComponent,
    PushLinkComponent,
    FlagFavoriteComponent,
    DropFilesBlockComponent,
    LinkBodyTextComponent,
    TabsComponent,
    ActionGroupComponent,
    BlockTitleComponent,
    BlockRowComponent,
    CollapseLinkComponent,
    LinksListComponent,
    TimelineComponent,
    TimelineItemComponent,
    BlockActionsComponent,
    AgreementAlertComponent,
    AgreementSyntheseComponent,
    AgreementCreditComponent,
    SyntheseListComponent,
    ProgressBarComponent,
    CheckListComponent,
    ProgressTipComponent,
    ProgressCreditComponent,
    MaterialModule,
    TableComponent,
    AccordionComponent,
    EmphasisListComponent,
    ProfileIntroComponent,
    PopInComponent,
    FeedComponent,
    FeedNotificationComponent,
    FeedMessageComponent,
    MessageToolComponent,
    WelcomeComponent,
    BodyLinkComponent,
    AdditionalInformationComponent,
    CookieBannerComponent,
    CookieDetailsComponent,
    FabButtonComponent,
    PwaTabBarComponent,
    PwaTabComponent,
    PwaMenuMoreComponent,
    PwaChatBarComponent,
    TitleBarComponent,
    CloseButtonComponent,
    PwaFormBackLinkComponent,
    FaqFormComponent,
    FaqComponent,
    FaqListComponent,
    FaqListItemComponent,
    LoaderComponent,
    OfflineAlertComponent,
    ProjectsFilterComponent,
    FilterListComponent,
    BlockHelpComponent,
    ImageComponent,
    BlockPushPromoComponent,
    BannerImageComponent,
    SwitchTabsComponent,
    FileUploadComponent,
    VehiculeEditionFormComponent,
    PageNologLayoutComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Globals
  ]
})
export class DesignSystemModule {}
