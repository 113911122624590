import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActionData } from "../../navigation/action-group/action-group.component";

@Component({
  selector: "app-block-row",
  templateUrl: "./block-row.component.html",
  styleUrls: ["./block-row.component.scss"]
})

/* block-row components must be encapuslate in a div with class block-row-container*/
export class BlockRowComponent implements OnInit {
  constructor() {}

  @Input() label: string;
  @Input() value?: string;
  @Input() multiline ? = false; // true when app-block-row is used providing in input an ng-content
  @Input() actions?: ActionData[];
  @Input() appTest?: string;
  @Output() actionEvent: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {}
}
