import { Component, OnInit, Input } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";

@Component({
  selector: "app-term-of-use",
  templateUrl: "./term-of-use.component.html",
  styleUrls: ["./term-of-use.component.scss"]
})
export class TermOfUseComponent implements OnInit {
  
  @Input() appTest: string;

  @Input() title: string;
  @Input() text: string;
  @Input() buttonLabel?: string = 'close';

  constructor(private dialogService: DialogService) {}

  closeDialog() {
    this.dialogService.close();
  }

  ngOnInit() {}
}
