import { Component, OnInit, Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-faq-form",
  templateUrl: "./faq-form.component.html",
  styleUrls: ["./faq-form.component.scss"],
  host: { class: "faq-form" }
})
export class FaqFormComponent implements OnInit {
  constructor() {}

  @Input() subtitle: string = "Or ask your question below";
  @Input() searchHint: string =
    'Search examples: "how to access my personal online area?", "how to find my contracts details?"';
  @Input() searchPlaceholder: string =
    "Type your question here or search by keywords";
  @Input() buttonLabel: string = "ASK A QUESTION";
  
  @Output() onModelChange: EventEmitter<any> = new EventEmitter<any>();
  
  ngOnInit() {}
}
