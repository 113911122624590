import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.sass']
})
export class StatementComponent extends AbstractComponent implements OnDestroy {

  constructor(private router: Router,
              private dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) public data) {
    super('agreement-details-page');
  }

  sendStatementRequest() {
    this.dialogService.close();
    this.router.navigate(['/home/agreements/' + this.data.agreementId + '/statement/sent']);
  }

  ngOnDestroy(): void {
    this.dialogService.close();
  }

}
