<span app-test="edit-password-success-page">
  <div [editSlug]="slug">

    <app-message-block 
      [title]="fields.thank_you_title"
      [secondaryButton]="{
          label : fields.back_to_my_profile_button_label
      }"
      (secondaryButtonEmitter)="backToProfile()"
      appTest="customer-update-sent-success">
        <span app-test="message">
          {{fields.password_changed_label}}
          <br/>
          {{fields.password_changed_login_instruction_label}}
        </span>
    </app-message-block>

  </div>
</span>