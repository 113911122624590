import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-dynamic-list-item",
  templateUrl: "./dynamic-list-item.component.html",
  styleUrls: ["./dynamic-list-item.component.scss"]
})
export class DynamicListItemComponent implements OnInit {
  @Input() appTest: string;

  defaultIcon = "icon-dot-circle";

  @Input() text: string;
  @Input() iconWidth: string = "16px";
  @Input() iconHeight: string = "16px";

  private _icon: string = this.defaultIcon;
  public get icon(): string {
    return this._icon;
  }
  @Input()
  public set icon(value: string) {
    this._icon = value;
  }
  @Input() status: string = "pending";

  constructor() {}

  ngOnInit() {
    if (this.status === "ko") {
      this._icon = "icon-check-error";
    } else if (this.status === "ok") {
      this._icon = "icon-check-green";
    } else if (this.status === "pending") {
      this._icon = this.defaultIcon;
    }
  }
}
