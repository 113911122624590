<app-login-layout
  app-test="login-page"
  [cookiesAlert]="cookiesService.cookiesBannerConfig"
  (cookiesEmitter)="cookiesService.storeCookieFromPublicPage()"
  [footerLinks]="footerLinks"
  [copyright]="footerCopyright"
  [contactBlocksButton]="contactBlocksButton"
  [contactBlocksData]="contactBlocksData">

  <ng-template #formContentRef>
    <router-outlet></router-outlet>
  </ng-template>

  <ng-template #sideContentRef>
      <app-login-help
      [title]="title"
      [bgImage]="bgImage">
        <div [innerHTML]="postContent" [editSlug]="slug"></div>
      </app-login-help>
  </ng-template>

</app-login-layout>