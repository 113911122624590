import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  AfterContentChecked
} from "@angular/core";
import { SvgModule } from "../../../modules/svg.module";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"]
})
export class IconComponent implements OnInit, AfterContentChecked {
  @Input() appTest: string;

  @ViewChild("matIcon", { static: true }) matIcon: MatIcon;

  private _icon: string;
  public get icon(): string {
    return this._icon;
  }
  @Input()
  public set icon(value: string) {
    this._icon = value;
    this.svgModule.register(this._icon);
  }
  @Input() colorClass: string;
  @Input() iconWidth: string;
  @Input() iconHeight: string;
  @Input() description: string = "";

  //if you need to set the height of the icon by css, add in the html : iconWidth="auto"
  //you have an example as the header logo

  //we had to use a separate module SvgModule to register the svg files
  //and keep access to them in storybook

  constructor(private svgModule: SvgModule) {}
  ngOnInit() {
    //register the icon in svgModule
    this.svgModule.register(this._icon);
  }
  ngAfterContentChecked() {
    const svg: HTMLElement = (this.matIcon._elementRef
      .nativeElement as HTMLElement).firstElementChild as HTMLEmbedElement;
    if (svg) {
      (svg.firstChild as HTMLElement).innerHTML = this.description;
    }
  }
}
