<span app-test="bank-details-page">

  <div [editSlug]="slug">

    <div class="grid-item grid-item--fullsize">
      <div class="content-box">
        <app-table
        [caption]="fields.rci_bank_details_caption"
        [columnsData]="bankDetailsColumns"
        [tableData]="rciTableData"
        [has3Columns]="true"
        app-test="rci-bank-details-table">
        </app-table>
        <app-block-actions
        *ngIf="haveActiveAgreements && !havePersonalBankDetails"
        [actionList]="actions"
        (actionEmitter)="performAction($event)">
        </app-block-actions>
      </div>

      <div *ngIf="haveActiveAgreements && !havePersonalBankDetails" class="form-group">
        <app-alert-message app-test="missing-personal-bank-details-alert">
          {{fields.missing_personal_bank_detail_alert}}
        </app-alert-message>
      </div>
    </div>

    <div *ngIf="havePersonalBankDetails" class="grid-item grid-item--fullsize">
      <div class="content-box">
        <app-table
        [caption]="fields.my_bank_details_caption"
        [columnsData]="bankDetailsColumns"
        [tableData]="bankDetailsTableData"
        (rowActionEmitter)="editBankDetails($event)"
        (rowClickEmitter)="goToAgreementDetails($event)"
        [has4Columns]="true"
        app-test="personal-bank-details-table">
        </app-table>
        <app-block-actions
        [actionList]="actions"
        (actionEmitter)="performAction($event)">
        </app-block-actions>
      </div>
    </div>
    
  </div>

</span>
