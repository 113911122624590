import { Component, OnInit, Input } from "@angular/core";
//to do add layout classes to host
@Component({
  selector: "app-checkbox-group",
  templateUrl: "./checkbox-group.component.html",
  styleUrls: ["./checkbox-group.component.scss"],
  host: { class: "checkbox-group" }
})
export class CheckboxGroupComponent implements OnInit {
  @Input() appTest: string;

  @Input() layoutClass: string;
  constructor() {}

  ngOnInit() {}
}
