<app-pop-in
[title]="fields.warning_section_title"
icon="icon-circle-warning"
[closingButtonLabel]="fields.cancel_button_label"
[actionButtonLabel]="fields.action_statement_send_by_post"
actionButtonAppTest="statement-action-app-test"
closingButtonAppTest="statement-close-app-test"
[actionButtonLabel]="fields.action_statement_send_by_post"
app-test="statement-modal" (actionEmitter)="sendStatementRequest()">
	<div>{{fields.action_statement_request_message}}</div>
</app-pop-in>