<div class="layout-narrow">

  <h2 class="form-title">{{fields.success_title}}</h2>

  <app-icon-feedback
  icon="icon-circle-valid"
  colorClass="default-icon-color">
  </app-icon-feedback>

  <p app-test="message-mail-sent">{{fields.success_text}}</p>

  <div class="form-group">
    <app-alert-message>
      {{fields.success_alert_1}}
      <span class="text-bold" app-test="customer-email-lbl">{{customerEmail}}</span>
      <br/><br/>
      {{fields.success_alert_2}}
    </app-alert-message>
  </div>

  <div class="layout-xsmall">
    <app-form-buttons>
      <div>
        <app-button
        buttonType="secondary"
        [buttonLabel]="fields.login_button_label"
        (buttonClick)="back()">
        </app-button>
      </div>
    </app-form-buttons>
  </div>
</div>
