import {
  Component,
  OnInit,
  Inject,
  Input,
  HostListener,
  HostBinding,
  OnDestroy
} from "@angular/core";
import { DeviceService } from "src/app/design-system/services/device.service";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "app-contact-blocks",
  templateUrl: "./contact-blocks.component.html",
  styleUrls: ["./contact-blocks.component.scss"]
})
export class ContactBlocksComponent implements OnInit, OnDestroy {

  constructor(private deviceService: DeviceService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.isPWA = this.deviceService.isPWA || this.deviceService.isMobileSize();
  }
  @Input() appTest: string;

  @Input() title: string = "Contact";

  @HostBinding("class.pwa") @Input() isPWA: boolean = false;

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.updatePWA();
  }
  updatePWA() {
    if (window.innerWidth < 768) {
      this.isPWA = true;
    } else {
      this.isPWA = false || this.deviceService.isPWA;
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.deviceService.isIE) {
      const evt = document.createEvent('Event');
      evt.initEvent('scrollTop', true, false);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event('scrollTop'));
    }
  }
}
