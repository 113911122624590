import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-registration-validation',
  templateUrl: './registration-validation.component.html',
  styleUrls: ['./registration-validation.component.sass']
})
export class RegistrationValidationComponent extends AbstractComponent implements OnInit {

  private id: string;
  private code: string;

  public showError: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private signupService: SignupService) {
    super('registration-page');

  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.code = this.activatedRoute.snapshot.paramMap.get('code');
    this.signupService.validate(this.id, this.code).subscribe(
      data => {
        this.router.navigate(['/login', {fromValidation: "true"}]);
      },
      err => {
        this.showError = true;
      }
    );
  }

  back() {
    this.router.navigate(['/login']);
  }

}
