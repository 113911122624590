<span app-test="typenschein-page">
  <div [editSlug]="slug">
    <div class="grid-item grid-item--fullsize">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">

            <h2 *ngIf="!isPWA" class="form-title">
              {{fields.typenschein_title_label}}
            </h2>

            <span *ngIf="actualState === StateEnum.receiver">

              <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(this.fields.typenschein_description_text)"></div>

              <app-select
                [label]="fields.receiver_selection_label"
                [selectedOption]="selectedReceiverOption"
                [options]="receiverOptions"
                (selectionChange)="onReceiverChange($event)"
                app-test="receiver-selection-menu">
              </app-select>
              <mat-hint *ngIf="!selectedReceiverOption" class="mat-hint hint-error" style="color: #d20062 !important">
                <span app-test="receiver-selection-hint-lbl">{{fields.receiver_selection_hint}}</span>
              </mat-hint>

              <br/>
              <br/>

              <form [formGroup]="receiverFormMe"
                class="ng-untouched ng-pristine ng-valid"
                *ngIf="selectedReceiverOption && selectedReceiverOption.value === 'ME'"
                app-test="receiver-form-me">

                <div>
                  <h3 class="form-subtitle">{{fields.personal_details_check_title}}</h3>
                  <p>
                    {{fields.typenschein_details_text}}
                  </p>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.licence_plate_label"
                        [value]="agreement.vehicle.plate"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="license plate"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        *ngIf="customer.person.civilStatus"
                        [label]="fields.civilite_textfield_label"
                        [value]="customer.person.civilStatus"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="title"
                        >
                      </app-textfield>
                    </div>
                  </div>
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        *ngIf="customer.person.title"
                        [label]="fields.title_textfield_label"
                        [value]="customer.person.title"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest=""
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.name_textfield_label"
                        [value]="customer.person.firstName"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="first name"
                        >
                      </app-textfield>
                    </div>
                  </div>
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.surname_textfield_label"
                        [value]="customer.person.lastName"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="last name"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.birth_date_textfield_label"
                        [value]="dateFormatter.transform(customer.person.birthDate)"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="date of birth"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.address_textfield_label"
                        [value]="customer.addresses[0].street"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="street"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.zipcode_textfield_label"
                        [value]="customer.addresses[0].postCode"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="post code"
                        >
                      </app-textfield>
                    </div>
                  </div>
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.city_textfield_label"
                        [value]="customer.addresses[0].city"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="city"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

                <div class="rwd-row">
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.email_textfield_label"
                        [value]="customer.emails[0].value"
                        [disabled]="true"
                        [prefilled]="true"
                        appTest="e-mail"
                        >
                      </app-textfield>
                    </div>
                  </div>
                  <div class="rwd-col-50">
                    <div class="form-group">
                      <app-textfield
                        [label]="fields.phone_textfield_label"
                        [parentForm]="receiverFormMe"
                        controlName="phone"
                        [pattern]="patterns.phone.pattern"
                        [patternErrorMessage]="fields.invalid_error_txt"
                        appTest="phone"
                        >
                      </app-textfield>
                    </div>
                  </div>
                </div>

              </form>

              <form [formGroup]="receiverFormOther"
                class="ng-untouched ng-pristine ng-valid"
                *ngIf="selectedReceiverOption && (selectedReceiverOption.value === 'DEALER' || selectedReceiverOption.value === 'INSURANCE')"
                app-test="receiver-form-other">

                <div>
                  <h3 class="form-subtitle">{{fields.address_form_title}}</h3>
                </div>
                <p>{{fields.mandatory_label}}</p>

                <div >
                  <div class="form-group">
                    <app-textfield
                      [label]="fields.receiver_name_textfield_label"
                      [parentForm]="receiverFormOther"
                      controlName="receiverName"
                      [required]="true"
                      [requiredErrorMessage]="fields.required_error_txt"
                      appTest="receiver name"
                      >
                    </app-textfield>
                  </div>
                  <div class="form-group">
                    <app-textfield
                      [label]="fields.address_textfield_label"
                      [parentForm]="receiverFormOther"
                      controlName="street"
                      [required]="true"
                      [requiredErrorMessage]="fields.required_error_txt"
                      [pattern]="patterns.street.pattern"
                      [patternErrorMessage]="fields.invalid_error_txt"
                      appTest="street"
                      >
                    </app-textfield>
                  </div>
                  <div class="form-group">
                    <app-textfield
                      [label]="fields.zipcode_textfield_label"
                      [parentForm]="receiverFormOther"
                      controlName="postCode"
                      [required]="true"
                      [requiredErrorMessage]="fields.required_error_txt"
                      [pattern]="patterns.zipCode.pattern"
                      [patternErrorMessage]="fields.invalid_error_txt"
                      appTest="zip code">
                    </app-textfield>
                  </div>
                  <div class="form-group">
                    <app-textfield
                      [label]="fields.city_textfield_label"
                      [parentForm]="receiverFormOther"
                      controlName="city"
                      [required]="true"
                      [requiredErrorMessage]="fields.required_error_txt"
                      [pattern]="patterns.city.pattern"
                      [patternErrorMessage]="fields.invalid_error_txt"
                      appTest="city">
                    </app-textfield>
                  </div>
                </div>

              </form>

            </span>

            <span *ngIf="actualState === StateEnum.reason">

              <div>
                <h3 class="form-subtitle">{{fields.reason_selection_message}}</h3>
              </div>
  
              <app-select
                [label]="fields.reason_selection_label"
                [selectedOption]="selectedReasonOption"
                [options]="reasonOptions"
                (selectionChange)="onReasonChange($event)"
                app-test="reason-selection-menu">
              </app-select>
              <mat-hint *ngIf="!selectedReasonOption" class="mat-hint hint-error" style="color: #d20062 !important">
                <span app-test="reason-selection-hint-lbl">{{fields.reason_selection_hint}}</span>
              </mat-hint>

              <br/>
              <br/>

              <ng-container *ngIf="selectedReasonOption && selectedReasonOption.value === 'OTHER'">
                <app-textarea
                  [label]="fields.notes_textfield_label"
                  (onModelChange)="onTextareaChange($event)"
                  [value]="otherReasonText"
                  [required]="true"
                  [requiredErrorMessage]="fields.required_error_txt"
                  maxLength="250"
                  minRows="5"
                  [displayCharCount]="true"
                  app-test="other-reason-txt">
                </app-textarea>
              </ng-container>
              
            </span>

            <app-error-message
              *ngIf="errorOnRequest.serverError"
              [message]="fields.message_server_error"
              app-test="message-server-error">
            </app-error-message>
          
            <app-error-message
              *ngIf="errorOnRequest.badRequest"
              [message]="fields.message_bad_request_error"
              app-test="message-bad-request">
            </app-error-message>

            <app-form-buttons>
              <div>
                <app-button
                buttonType="secondary"
                [buttonLabel]="fields.cancel_button_label"
                (buttonClick)="cancel()"
                app-test="back-btn">
                </app-button>
                <app-button
                *ngIf="selectedReceiverOption"
                [buttonLabel]="fields.confirm_button_label"
                (buttonClick)="confirm($event)"
                [disabled]="isConfirmButtonDisabled()"
                app-test="confirm-btn">
                </app-button>
              </div>
            </app-form-buttons>

          </div>
        </div>
      </div>
    </div>
  </div>
</span>