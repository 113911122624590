import { Pipe, PipeTransform } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeAT from '@angular/common/locales/de-AT';
registerLocaleData(localeAT, 'de-AT');

@Pipe({
    name: 'integerFormatPipe',
})
export class IntegerFormatPipe implements PipeTransform {

    static readonly NUMBER_LOCALE = 'de-DE';

    transform(value: number): string | null {
        return this.integerGroupedBy(value, '.');
    }

    integerGroupedBy(value: number, groupSeparator: string): string {
        // splits the actual value in integer (parts[0]) and fraction parts (parts[1])
        const parts = value.toString().split(".");

        // groups the integer part every 3 digits starting from the end using the specified group separator
        /* The regex uses 2 lookahead assertions:
        a positive one to look for any point in the string that has a multiple of 3 digits in a row after it,
        and a negative assertion to make sure that point only has exactly a multiple of 3 digits.*/
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);

        // returns only the modified integer part
        return parts[0];
    }
}
