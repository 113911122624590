import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';

@Component({
  selector: 'app-term-of-use-modal',
  templateUrl: './term-of-use-modal.component.html',
  styleUrls: ['./term-of-use-modal.component.sass']
})
export class TermOfUseModalComponent extends AbstractComponent implements OnInit {

  public postContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
    super('registration-page');
  }

  ngOnInit() {
    this.postContent = this.sanitizer.bypassSecurityTrustHtml(this.fields.term_of_use_text);
  }

}
