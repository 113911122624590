import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { StepData } from 'src/app/design-system/components/navigation/stepper/stepper.component';
import { TermOfUseModalComponent } from '../term-of-use-modal/term-of-use-modal.component';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-update-user-form',
  templateUrl: './update-user-form.component.html',
  styleUrls: ['./update-user-form.component.sass']
})
export class UpdateUserFormComponent extends AbstractComponent implements OnInit {

  public isSuccess = true;

  public registrationSteps: StepData[];
  public readonly stepIndex = 2;

  public passwordForm: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder,
              private signupService: SignupService,
              private dialogService: DialogService) {
                super('registration-page');
              }

  ngOnInit() {
    if (!this.signupService.initialized) { this.router.navigate(['/login']); }

    this.passwordForm = this.fb.group({
      password: [''],
      confirmationPassword: [''],
      touCheckbox: false
    });

    this.registrationSteps = [
      {
        label: this.fields.step_1_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_2_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_3_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      }
    ];

  }

  passwordsMatch(): boolean {
    if (!this.passwordForm.controls.confirmationPassword.dirty) {
      return true;
    }

    if (this.passwordForm.value.password === this.passwordForm.value.confirmationPassword) {
      return true;
    } else {
      return false;
    }
  }

  back() {
    this.router.navigate(['/registration/update-customer']);
  }

  continue() {
    this.isSuccess = true;
    this.signupService.updateCustomer(this.passwordForm.value.password).subscribe(
      data => {
        console.log(data);
        this.router.navigate(['/registration/success']);
      },
      err => {
        console.log(err);
        this.isSuccess = false;
      }
    );
  }

  openTermOfUse() {
    this.passwordForm.patchValue({
      touCheckbox: !this.passwordForm.value.touCheckbox
    });
    this.dialogService.open('term-of-use', TermOfUseModalComponent, {...DialogService.DIALOG_CONFIG.termOfUse});
  }

}
