<span [formGroup]="parentForm">
  <mat-checkbox
    #matCheckbox
    [name]="name"
    [value]="value"
    [disabled]="disabled"
    [labelPosition]="labelPosition"
    (change)="onChange()"
    [checked]="checked"
    [indeterminate]="indeterminate"
    [aria-label]="ariaLabel"
    [ngClass]="colorClass"
    [formControlName]="controlName"
    tabindex="-1"
    attr.app-test="{{appTest}}"
  >
    {{ label }}
    <ng-content></ng-content>
  </mat-checkbox>
</span>
