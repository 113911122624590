import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockAction } from 'src/app/design-system/components/navigation/block-actions/block-actions.component';
import { SyntheseItem } from 'src/app/design-system/components/lists/synthese-list/synthese-list.component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { StatementComponent } from 'src/app/features/agreements/statement/statement.component';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { IntegerFormatPipe } from 'src/app/pipes/integer-format.pipe';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

@Component({
  selector: 'app-agreement-content',
  templateUrl: './agreement-content.component.html',
  styleUrls: ['./agreement-content.component.sass'],
  providers: [DateFormatPipe, CurrencyFormatPipe, IntegerFormatPipe]
})
export class AgreementContentComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public rciTableData: any[];
  public paymentPlan: BlockAction[];
  public agreement: any;
  public agreementSyntheseDetails: SyntheseItem[];
  public tabData: any[];
  public commercialCards: any[];

  public parentLink = '/home/agreements';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bankDetailsService: BankDetailsService,
              private dialogService: DialogService) {
        super('agreement-details-page');
  }

  ngOnInit() {
    this.commercialCards = WordpressService.commercialCards;

    this.agreement = this.route.snapshot.data.agreement;

    if (this.isInvalidAgreement(this.agreement)) {
      this.router.navigate([this.parentLink]);
    }

    console.log(this.agreement);

    if ( this.agreement.type !== 'BATTERY' ) {
      this.paymentPlan = [
      {
        label: this.fields.payment_plan,
        name: 'showPaymentPlan',
        appTest: 'show-payment-plan-action'
      }
    ];
      if ( !this.agreement.actions ) {
        this.tabData = [
          {
            label: this.fields.agreement_details_tab_label,
            fragment: 'agreementDetails'
          },
          {
            label: this.fields.payment_details_tab_label,
            fragment: 'paymentDetails'
          }
        ];
      } else {
        this.tabData = [
          {
            label: this.fields.agreement_details_tab_label,
            fragment: 'agreementDetails'
          },
          {
            label: this.fields.payment_details_tab_label,
            fragment: 'paymentDetails'
          },
          {
            label: this.fields.request_tab_label,
            fragment: 'requests'
          }];
      }
    } else {
      this.paymentPlan = undefined;
      this.tabData = [
        {
          label: this.fields.agreement_details_tab_label,
          fragment: 'agreementDetails'
        },
        {
          label: this.fields.payment_details_tab_label,
          fragment: 'paymentDetails'
        }
      ];
      if (this.agreement.duration === -1) {
        this.agreement.duration = this.fields.agreement_unlimited_duration_value;
      }
    }

    this.agreementSyntheseDetails = [
      {
        label: this.fields.agreement_synthese_number,
        value: this.agreement.label,
        isLink: false,
        link: ''
      },
      {
        label: this.fields.agreement_synthese_type,
        value: this.getTranslatedAgreementType(this.agreement.type),
        isLink: false,
        link: ''
      },
      {
        label: this.fields.agreement_synthese_model,
        value: this.agreement.vehicle.model ? this.agreement.vehicle.model.label : this.agreement.vehicle.plate,
        isLink: false,
        link: ''
      },
      {
        label: this.fields.agreement_synthese_status,
        value: this.getTranslatedAgreementStatus(this.agreement.status),
        isLink: false,
        link: ''
      }
    ];
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreements_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title : this.fields.agreement_synthese_title
    };
  }

  isInvalidAgreement(agreement: any): boolean {
    return (agreement.status === 'LEGAL_DPT' || agreement.status === 'DAMAGE' || agreement.status === 'CLOSED');
  }

  getTranslatedActionName(name: string): string {
    switch (name) {
      case 'CAR_CERTIFICATE':
        return this.fields.action_typenschein_request;
      case 'SETTLEMENT':
        if (this.agreement.type === 'CREDIT' || this.agreement.type === 'BALLOON') {
          return  this.fields.action_settlement_credit_balloon_request;
        } else {
          return  this.fields.action_settlement_leasing_request;
        }
      case 'STATEMENT':
        return this.fields.action_statement_request;
      default:
        return name;
    }
  }

  getTranslatedDueDateLabel(transferDueDate: string): string {
    switch (transferDueDate) {
      case 'LAST_DAY_OF_MONTH':
        return this.fields.transfer_due_date_ldom;
      case 'MID_MONTH':
        return this.fields.transfer_due_date_mm;
      default:
        return transferDueDate;
    }
  }

  getTranslatedAgreementType(type: string): string {
    switch (type) {
      case 'CREDIT':
        return this.fields.financing_agreement_label;
      case 'LEASING':
        return this.fields.leasing_agreement_label;
      case 'BATTERY':
        return this.fields.battery_rental_agreement_label;
      case 'BALLOON':
        return this.fields.credit_residual_value_agreement_label;
      default:
        return type;
    }
  }

  getTranslatedAgreementStatus(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return this.fields.agreement_details_status_active;
      case 'CLOSED':
        return this.fields.agreement_details_status_closed;
    }
  }

  goToPaymentMethod() {
    this.bankDetailsService.agreementIdList = [this.agreement.id];
    if (this.agreement.paymentMethod === 'DIRECT_DEBIT') {
      this.bankDetailsService.get(this.agreement.id).subscribe(
        data => {
          console.log(data);
          if (data.length > 0) {
            this.bankDetailsService.selectedIban = data[0].bankAccount.iban;
          } else  {
            this.bankDetailsService.selectedIban = undefined;
          }
          console.log(this.bankDetailsService.agreementIdList, this.bankDetailsService.selectedIban);
          this.router.navigate(['/payment-method']);
        },
        err => {
          console.log(err);
          this.bankDetailsService.selectedIban = undefined;
          this.router.navigate(['/payment-method']);
        }
      );
    } else {
      this.bankDetailsService.selectedIban = undefined;
      this.router.navigate(['/payment-method']);
    }
  }

  public showPaymentPlan() {
    this.router.navigate(['./payment-plan'], { relativeTo: this.route });
 }

  performAction(action: string) {
    switch (action) {
      case 'CAR_CERTIFICATE':
        this.router.navigate(['./typenschein'], { relativeTo: this.route });
        break;
      case 'SETTLEMENT':
        this.router.navigate(['./settlement'], { relativeTo: this.route });
        break;
      case 'STATEMENT':
        this.dialogService.open('popin', StatementComponent, {
          ...DialogService.DIALOG_CONFIG.popin,
          data: {
            agreementId : this.agreement.id
          }
        });
        break;
      case 'PAYMENT_PLAN':
        this.router.navigate(['./payment-plan'], { relativeTo: this.route });
        break;
    }
  }
}
