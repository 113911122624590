import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { ActivatedRoute } from '@angular/router';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.sass'],
  providers: [DateFormatPipe, CurrencyFormatPipe]
})
export class PaymentPlanComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  private paymentPlan: any;
  public paymentPlanTableData: any[];
  public paymentPlanTableCaption: string;

  constructor(private route: ActivatedRoute,
              private dateFormatter: DateFormatPipe,
              private currencyFormatter: CurrencyFormatPipe,
              private deviceService: DeviceService) {
    super('agreement-details-page');
  }

  ngOnInit() {
    this.paymentPlanTableCaption = this.deviceService.isPWA ? undefined : this.fields.payment_plan;
    this.paymentPlanTableData = [];
    this.paymentPlan = this.route.snapshot.data.paymentPlan;
    this.paymentPlan.payments.forEach((payment: any) => {
      let statusLabel = payment.status;
      switch ( payment.status ) {
        case 'PAID' :
          statusLabel = this.fields.payment_plan_status_paid;
          break;
        case 'UNPAID' :
            statusLabel = this.fields.payment_plan_status_unpaid;
            break;
        case 'INVOICED' :
            statusLabel = this.fields.payment_plan_status_invoiced;
            break;
        case 'NOT_INVOICED' :
            statusLabel = this.fields.payment_plan_status_not_invoiced;
            break;
      }

      const amount = payment.amount.toFixed(2).toString();

      this.paymentPlanTableData.push({
        amount : { text: this.currencyFormatter.transform(amount) },
        dueDate : { text: this.dateFormatter.transform(payment.dueDate) },
        status : { text: statusLabel },
        appTest : 'paymentPlanLine'
      });
    });
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.params.id,
      isSubPage : true,
      isHome : false,
      title : this.fields.payment_plan
    };
  }
}
