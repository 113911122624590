<div class="block-actions">
  <app-button
  class="block-action"
  *ngFor="let action of actionList"
  [buttonLabel]="action.label"
  [isLink]="true"
  (buttonClick)="handleActionClick(action)"
  attr.app-test="{{action.appTest}}">
  </app-button>
</div>
