<div class="layout-narrow">
  <h2 class="form-title">{{fields.form_title}}</h2>
  <p>{{fields.form_instruction}}</p>

  <div class="form-group">
    <app-stepper
    [selectedIndex]="stepIndex"
    [stepData]="registrationSteps"
    app-test="registration-stepper-1">
    </app-stepper>
  </div>

  <div class="layout-xsmall">
    <h2 class="form-subtitle">{{fields.step_2_title}}</h2>
    <p class="mention">{{fields.step_2_instruction_a}}</p>
    <p class="mention">{{fields.step_2_instruction_b}}</p>
    <form class="ng-untouched ng-pristine ng-valid"
    [formGroup]="personalInformationsForm"
    app-test="personal-informations-form">
      <div class="form-group">
        <app-textfield
        [label]="fields.zipcode_textfield_label"
        controlName="zipcode"
        [parentForm]="personalInformationsForm"
        [required]="true"
        [requiredErrorMessage]="fields.required_error_txt"
        [pattern]="patterns.zipCode.pattern"
        [patternErrorMessage]="fields.invalid_error_txt"
        app-test="zipcode-txt">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.city_textfield_label"
        controlName="city"
        [parentForm]="personalInformationsForm"
        [required]="true"
        [requiredErrorMessage]="fields.required_error_txt"
        [pattern]="patterns.city.pattern"
        [patternErrorMessage]="fields.invalid_error_txt"
        app-test="city-txt">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.address_textfield_label"
        controlName="address"
        [parentForm]="personalInformationsForm"
        [required]="true"
        [requiredErrorMessage]="fields.required_error_txt"
        [pattern]="patterns.street.pattern"
        [patternErrorMessage]="fields.invalid_error_txt"
        app-test="address-txt">
        </app-textfield>
      </div>
      <div class="form-group">
        <app-textfield
        [label]="fields.phone_textfield_label"
        controlName="phone"
        [parentForm]="personalInformationsForm"
        [pattern]="patterns.phone.pattern"
        [patternErrorMessage]="fields.invalid_error_phone_label"
        app-test="phone-txt">
        </app-textfield>
      </div>
      <app-form-buttons>
        <div>
          <app-button
          buttonType="secondary"
          [buttonLabel]="fields.back_button_label"
          (buttonClick)="back()"
          app-test="back-btn">
          </app-button>
          <app-button
          [buttonLabel]="fields.confirm_button_label"
          (buttonClick)="continue()"
          [disabled]="personalInformationsForm.invalid"
          app-test="confirm-personal-data-btn">
          </app-button>
        </div>
      </app-form-buttons>
    </form>
  </div>

</div>
