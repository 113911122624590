import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

export class Resource implements Resolve<any> {

  public manageError: boolean = false;

  constructor(protected http: HttpClient,
              protected url: string) {
  }

  list(): Observable<any[]> {
    return this.http
      .get<any[]>(this.url)
      .pipe(catchError(err => this.checkAlertUrl()));
  }

  checkAlertUrl(): Observable<any[]> {
    if (this.url.includes('/alerts')) {
      return of([]);
    } else {
      this.handleError<any[]>('list resource', []);
    }
  }

  get(id: string): Observable<any> {
    return this.http
      .get<any>(this.url + '/' + id)
      .pipe(catchError(this.handleError<any>('get resource', [])));
  }

  create(body: any): Observable<any> {
    return this.http
      .post<any>(this.url, body)
      .pipe(catchError(this.handleError<any>('create resource', [])));
  }

  update(id: string, body: any): Observable<any> {
    return this.http
      .put<any>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError<any>('update resource', [])));
  }

  /* create a method to handle hateoas links */

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ( route.params.id != null ) {
      return this.get(route.params.id);
    } else {
      return this.list();
    }
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //if ( this.manageError ) {
      //  console.error(`${operation} failed: ${error.message}`); // log to console instead
      //  return throwError(() => error);
      // Let the app keep running by returning an empty result.
      // return of(result as T);
      //}
      return throwError(() => error);
    };
  }

}
