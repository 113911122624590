import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';

@Component({
  selector: 'app-error-message-generic',
  templateUrl: './error-message-generic.component.html',
  styleUrls: ['./error-message-generic.component.sass']
})
export class ErrorMessageGenericComponent extends AbstractComponent implements OnInit{

  public successRequest: boolean = false;

  private parentLink = '/home';

  constructor( private router: Router ) {
    super('generic-error-page');
  }

  ngOnInit() {
  }

  backToHome() {
    this.router.navigate([this.parentLink]);
  }

}
