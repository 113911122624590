import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfigurablePage } from '../../commons/configurable-page';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DataProtectionComponent extends AbstractComponent implements OnInit, AfterViewInit, ConfigurablePage {

  private fragment: string;
  public pageContent1: SafeHtml;
  public pageContent2: SafeHtml;
  public pageContent3: SafeHtml;

  constructor(
    public sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private layoutService: LayoutService
    ) {
      super('data-protection-page');
      this.fragment = this.route.snapshot.fragment;
  }

  ngOnInit() {
      this.layoutService.updateMasonry(true);
      this. pageContent1 = this.sanitizer.bypassSecurityTrustHtml(this.fields.data_protection_page_content_1);
      this. pageContent2 = this.sanitizer.bypassSecurityTrustHtml(this.fields.data_protection_page_content_2);
      this. pageContent3 = this.sanitizer.bypassSecurityTrustHtml(this.fields.data_protection_page_content_3);
  }

  ngAfterViewInit() {
    this.viewportScroller.scrollToAnchor(this.fragment);
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_button_label,
      backToLink : '/',
      isSubPage : false,
      isHome : false
    };
  }
}
