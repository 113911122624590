import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-login-help",
  templateUrl: "./login-help.component.html",
  styleUrls: ["./login-help.component.scss"]
})
export class LoginHelpComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  @Input() bgImage: string;

  @Input() withOverlay?: boolean = true;
  @Input() overlayColor?: string = "#282832";
  @Input() overlayOpacity?: number = 0.6;

  @Input() title: string;
  @Input() text: string;

  ngOnInit() {}
}
