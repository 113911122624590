<span app-test="edit-email-success-page">
  <div [editSlug]="slug">

    <app-message-block 
      [title]="fields.thank_you_title"
      [secondaryButton]="{
          label : fields.back_to_my_profile_button_label
      }"
      (secondaryButtonEmitter)="backToProfile()"
      appTest="customer-update-sent-success">
      <span app-test="message">
        {{fields.confirmation_new_email_updated_label}}
        <br/>
        {{fields.notify_email_sent_to_confirm_new_username_label}}
      </span>
    </app-message-block>

  </div>
</span>