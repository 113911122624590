<app-login-layout
  [cookiesAlert]="cookiesService.cookiesBannerConfig"
  (cookiesEmitter)="cookiesService.storeCookieFromPublicPage()"
  [footerLinks]="footerLinks"
  [copyright]="footerCopyright"
  app-test="update-email-page"
  [contactBlocksButton]="contactBlocksButton"
  [contactBlocksData]="contactBlocksData">

  <ng-template #formContentRef>
    <div [editSlug]="slug">
      <div class="content-box-inner ng-star-inserted">
        <div class="layout-narrow">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #sideContentRef>
    <app-login-help [bgImage]="bgImage"></app-login-help>
  </ng-template>

</app-login-layout>
