import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SelectOptions } from 'src/app/design-system/components/forms/select/select.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact/contact.service';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { AbstractComponent } from '../../commons/abstract-component';
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-public-contact-us',
  templateUrl: './public-contact-us.component.html',
  styleUrls: ['./public-contact-us.component.sass']
})
export class PublicContactUsComponent extends AbstractComponent implements OnInit  {

  public publicContactUsForm: FormGroup;

  public messageTypeSelectOptions: Array<SelectOptions> = new Array<SelectOptions>();

  public selectedAgreement: SelectOptions;

  public selectedMessageType: SelectOptions;

  public customer: any;

  public errorFileMessage: string;

  public confirmButtonEl: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private contact: ContactService,
    private layoutService: LayoutService
  ) {
    super("contact-us-page");
  }

  ngOnInit() {
    this.layoutService.updateMasonry(true);

    this.customer = this.route.snapshot.data.customer;
    this.messageTypeSelectOptions = this.getMessageTypeOptions(this.fields.contact_us_type_list);

    this.publicContactUsForm = this.fb.group({
      agreementNumber: [null],
      customerName: [''],
      type: [''],
      email: [''],
      phone: [null],
      message: [''],
      recaptcha: ['']
    });
  }

  getMessageTypeOptions(messageTypeField: string) {
    messageTypeField.split("-").forEach(element => {
      if ( !this.isNullOrEmpty(element) ) {
        this.messageTypeSelectOptions.push({
          value: element.replace("\n", "").trim(),
          viewValue: element.replace("\n", "").trim(),
          hint: element.replace("\n", "").trim()
        });
      }
    });
    return this.messageTypeSelectOptions;
  }

  isNullOrEmpty( str: any ) {
    return !str || !str.trim();
  }

  setSelectedMessageType(event: SelectOptions) {
    this.selectedMessageType = event;
  }

  send(event: any) {
    const request = {
      agreementNumber: this.publicContactUsForm.get('agreementNumber').value,
      customerName: this.publicContactUsForm.get('customerName').value,
      messageType: this.selectedMessageType.value,
      email: this.publicContactUsForm.get('email').value,
      phone: this.publicContactUsForm.get('phone').value,
      message: this.publicContactUsForm.get('message').value,
    };

    this.confirmButtonEl = event.target.ownerDocument.activeElement;
    this.confirmButtonEl.disabled = true;

    this.contact.sendUnloggedRequest(request, this.publicContactUsForm.get('recaptcha').value).subscribe(
      data => {
        this.router.navigate(['./outcome'], { relativeTo: this.route });
      },
      error => {
        this.router.navigate(['./outcome'], { relativeTo: this.route });
      }
    );
  }

}
