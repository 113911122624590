<div class="login-page  page">
  <app-header 
    #header 
    [backToLink]="backToLink"
    [cookiesAlert]="cookiesAlert"
    (cookiesClickEmitter)="cookiesEmitter.emit($event)">
  </app-header>

  <div class="page-content login-content">
    <div class="container login-container">
      <div class="grid">
        <div class="grid-item grid-item--halfsize grid-item-left">
          <div class="content-box content-box--form">
            <ng-container>
              <ng-container *ngTemplateOutlet="formContentRef"></ng-container>
            </ng-container>
          </div>
        </div>
        <div class="grid-item grid-item--halfsize grid-item-right">
          <div class="content-box content-box--aside">
            <ng-container>
              <ng-container *ngTemplateOutlet="sideContentRef"></ng-container
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer
    #footer
    [copyright]="copyright"
    [withDownloadLogos]="false"
    [footerLinks]="footerLinks"
    [contactBlocksData]="contactBlocksData"
    [contactBlocksButton]="contactBlocksButton"
    [isPWA]="isPWA">
  </app-footer>
</div>
<app-pwa-chat-bar *ngIf="isPWA" [contactBlocksData]="contactBlocksData"></app-pwa-chat-bar>
