import { Component, OnInit } from "@angular/core";
import { AbstractComponent } from "../../commons/abstract-component";
import { LayoutService } from 'src/app/design-system/services/layout.service';

@Component({
  selector: 'app-contact-infos',
  templateUrl: './contact-infos.component.html',
  styleUrls: ['./contact-infos.component.scss']
})
export class ContactInfosComponent extends AbstractComponent implements OnInit {

    constructor(private layoutService: LayoutService) {
      super("contact-infos-page");
    }

    ngOnInit() {
      this.layoutService.updateMasonry(true);
    }
}
