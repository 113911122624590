import { Component, OnInit, Input } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";

export interface Filter {
  label: string;
  name: string;
  value: string;
}

@Component({
  selector: "app-filter-list",
  templateUrl: "./filter-list.component.html",
  styleUrls: ["./filter-list.component.scss"]
})
export class FilterListComponent implements OnInit {
  constructor() {}

  @Input() allText: string = "all";

  @Input() filters: Filter[] = [
    { label: "Car purchase", name: "", value: "" },
    { label: "Savings", name: "", value: "" },
    { label: "Insurance", name: "", value: "" },
    { label: "Face hardship", name: "", value: "" }
  ];

  ngOnInit() {}

  //SELECT

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numFilters = this.filters.length;
    return numSelected === numFilters;
  }

  noneSelected() {
    return this.selection.selected.length === 0;
  }

  /** Selects all checkboxes if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filters.forEach(filter => {
          this.selection.select(filter);
        });
  }
}
