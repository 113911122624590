<mat-tab-group
  #tabs
  [selectedIndex]="selectedTabIndex"
  [disableRipple]="true"
  [animationDuration]="5000"
  color="accent"
  [ngClass]="{
    'with-more': withMore,
    'is-not-desktop': !isDesktop,
    'is-desktop': isDesktop
  }"
  (selectedIndexChange)="changeHandler($event)"
>
  <mat-tab *ngFor="let tab of tabData" [label]="tab.label"> </mat-tab>
  <mat-tab
    *ngFor="let item of switchItems; let i = index"
    [label]="item.itemTitle"
  >
  </mat-tab>
</mat-tab-group>
