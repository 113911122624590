import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";

@Component({
  selector: "app-link-body-text",
  templateUrl: "./link-body-text.component.html",
  styleUrls: ["./link-body-text.component.scss"]
})
export class LinkBodyTextComponent implements OnInit {
  constructor() {}

  @Input() linkText: string;
  @Output() linkAction: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}
}
