import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError, empty } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { defer } from 'q';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
/* eslint-disable max-len */

const headers = new HttpHeaders()
  .set('Content-Type', 'application/json;charset=UTF-8');

@Injectable({
  providedIn: 'root'
})
export class SignupService implements Resolve<any> {

  private customerId: string;
  private customerSecret: string;
  private recaptcha: string;
  private signup: any;

  private _initialized = false;
  private _signupCreated = false;

  constructor(private httpClient: HttpClient,
              private router: Router) { }

  public get initialized() { return this._initialized; }
  public get signupCreated() { return this._signupCreated; }
  public get customer() { return Object.assign({}, this.signup.customer); }
  public get username() { return this.signup.username; }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.initialized) {
      if(route.paramMap.get('referrer')=="update-customer") return this.signup; 
      const deferred = defer<any>();
      this.checkCustomer().toPromise().then(
        data => { deferred.resolve(data); },
        err => { deferred.resolve(err); }
      );
      return deferred.promise;
    } else {
      this.router.navigate(['/login']);
    }

  }

  init(customerId: string, customerSecret: string, recaptcha: string) {
    this.customerId = customerId;
    this.customerSecret = customerSecret;
    this.recaptcha = recaptcha;
    this._initialized = true;
  }

  checkCustomer() {
    const jsonBody = {
      token: this.customerId,
      secret: this.customerSecret
    };

    return this.httpClient.post<any|void>(environment.restBase + '/signups?captcha=' + this.recaptcha, JSON.stringify(jsonBody), { headers })
      .pipe(
        map(res => {
          console.log(res);
          this.signup = res;
          this._signupCreated = true;
          return res;
        }),
        catchError(err => {
          console.log(err);
          const errors: any = {
            invalidSignupCredential: false,
            alredyRegisteredCustomer: false,
            alreadyExistingUsername: false,
            serverError: false
          };
          if (err.status === 404) {
            errors.invalidSignupCredential = true;
          } else if (err.status === 409) {
            errors.alredyRegisteredCustomer = true;
          } else if (err.status === 410) {
            errors.alreadyExistingUsername = true;
          } else {
            errors.serverError = true;
          }
          return throwError(errors);
        })
      );
  }

  updateSignup(address: any, phone: any) {
    Object.assign(this.signup.customer.addresses.filter((i: { type: string; }) => i.type === address.type)[0], address);
    Object.assign(this.signup.customer.phones.filter((i: { type: string; }) => i.type === phone.type)[0], phone);
    console.log(this.signup);
  }

  updateCustomer(password: string) {
    const jsonBody = this.signup;
    jsonBody.password = password;
    console.log(jsonBody);
    return this.httpClient.put<any|void>(environment.restBase + '/signups/' + jsonBody.id, JSON.stringify(jsonBody), { headers })
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(err => {
          console.log(err);
          return throwError(() => err);
        })
      );
  }

  validate(id: string, code: string) {
    return this.httpClient.post<any|void>(
      environment.restBase + '/signups/' + id + '?code=' + code, null, { headers })
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(err => {
          console.log(err);
          return throwError(() => err);
        })
      );
  }

}
