import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  EventEmitter,
  Output
} from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
import { DeviceService } from "../../../services/device.service";
import { MenuItems } from '../nav-menu/nav-menu.component';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, AfterViewInit {
  @Input() appTest: string;

  isIE11: boolean;

  @Input() isPWA: boolean = false;

  @Input() footerLinks: MenuItems[];

  @Input() copyright: string;
  // Far Austria, no download links, set prop withDownloadLogos as false
  @Input() withDownloadLogos: boolean = true;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("contactButton", { static: false })
  contactButtonElement: ElementRef;

  @ViewChild("footerElement", { static: true })
  footerElement: ElementRef;

  footer: HTMLElement;

  contactButton: HTMLElement;

  @Input() contactBlocksData: [];
  @Input() contactBlocksButton: string;

  constructor(
    private deviceService: DeviceService,
    private layoutService: LayoutService
  ) {}

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    if (!this.footer) return false;
    this.layoutService.setContactButtonPosition(this.footer);
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    if (!this.footer) return false;
    if (!this.contactButton) return false;
    this.layoutService.setContactButtonPosition(this.footer);
  }

  ngOnInit() {
    this.isIE11 = this.deviceService.isIE11 || this.deviceService.isEdge;
  }
  ngAfterViewInit() {
    this.footer = this.footerElement.nativeElement as HTMLElement;
    if (this.contactButtonElement) {
      this.contactButton = this.contactButtonElement
        .nativeElement as HTMLElement;
      LayoutService.CONTACT_BUTTON = this.contactButton;
      this.layoutService.triggerResize();
    }
  }
}
