import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class SvgModule {
  static REGISTRED_ICONS: string[] = new Array();
  iconPath: string = "app/design-system/assets/svg/";
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  register = (iconName: string) => {
    //register icons only once from IconCompoent
    //necessity to create a module to import the svg in Storybook
    //but in Storybook the redistry is emptied
    //so we must repeat the registration
    //uncomment the line beneath in your angular app
    //if (SvgModule.REGISTRED_ICONS.includes(iconName)) return true;

    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${this.iconPath}${iconName}.svg`
      )
    );
    SvgModule.REGISTRED_ICONS.push(iconName);
  };
}
