<mat-dialog-content>

  <app-nav-menu
  [menuItems]="menuItems"
  [isModal]="true"
  (onClick)="closePwaMenu()">
  </app-nav-menu>

  <app-nav-menu
  [menuItems]="footerLinks"
  [isFooterMenu]="true"
  [isModal]="true"
  (onClick)="closePwaMenu()">
  </app-nav-menu>

  <app-footer
  #footer
  [withDownloadLogos]="false"
  [copyright]="copyright"
  [isPWA]="isPWA">
  </app-footer>
  

</mat-dialog-content>
