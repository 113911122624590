<app-icon-button
  icon="icon-popin-close"
  iconHeight="16px"
  iconWidth="16px"
  colorClass="color-primary"
  (buttonClick)="closeWelcome()"
  ngClass="close-button"
></app-icon-button>
<div class="welcome-row">
  <div class="welcome-col-50">
    <div class="header">
      <app-icon
        class="illustration"
        icon="welcome"
        iconWidth="148px"
        iconHeight="109px"
      ></app-icon>
      <h2 class="welcome-title">Welcome to your online customer space</h2>
      <p>
        This is where you'll find all your agreements, quotes, best offers and
        all the online assistance you may need.
      </p>
    </div>
  </div>
  <div class="welcome-col-50">
    <app-video class="video" [videoUrl]="videoUrl"></app-video>
  </div>
</div>
