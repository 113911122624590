import { Component, OnInit, Input } from "@angular/core";
import { Title } from "@angular/platform-browser";

export interface TimelineData {
  status: string;
  date: string;
  title?: string;
  info: string;
  alert?: boolean;
}

@Component({
  selector: "app-timeline-item",
  templateUrl: "./timeline-item.component.html",
  styleUrls: ["./timeline-item.component.scss"]
})
export class TimelineItemComponent implements OnInit {
  constructor() {}

  @Input() itemData: TimelineData = {
    title: "Title",
    status: "success",
    date: "28/10/2015",
    info:
      "You have settled an overdue payment, your transaction has been successfully registered.",
    alert: true
  };
  @Input() buttonLabel: string = "Send document";

  getIcon(status) {
    let icon: string;
    switch (status) {
      case "pending":
        icon = "icon-circle-warning";
        break;
      case "success":
        icon = "icon-circle-valid";
        break;
      case "accepted":
        icon = "icon-circle-agreement-accepted";
        break;
      case "signed":
        icon = "icon-circle-agreement-signed";
        break;
      default:
        icon = "icon-circle-valid";
    }
    return icon;
  }

  ngOnInit() {
    console.log(this.itemData);
  }
}
