<button
  mat-fab
  [color]="themeColor"
  [ngStyle]="{ width: buttonWidth || '100%', height: buttonHeight || '100%' }"
  (click)="buttonClick.emit($event)"
>
  <app-icon
    [icon]="icon"
    [iconWidth]="iconWidth"
    [iconHeight]="iconHeight"
    [colorClass]="colorClass"
  >
  </app-icon>
</button>
