import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact/contact.service';
import { LayoutService } from 'src/app/design-system/services/layout.service';
import { Location } from '@angular/common';
import { ConfigurablePage } from '../../commons/configurable-page';

@Component({
  selector: 'app-contact-us-outcome',
  templateUrl: './contact-us-outcome.component.html',
  styleUrls: ['./contact-us-outcome.component.sass']
})
export class ContactUsOutcomeComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public successRequest: boolean;

  private parentLink = '/help/contact-us';

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private contactService: ContactService,
    private location: Location) {
    super('contact-us-page');
  }

  ngOnInit() {
    this.layoutService.updateMasonry(true);

    this.successRequest = this.contactService.hasSuccessRequest();
    if ( this.successRequest === undefined ) {
      this.router.navigate(["/home"]);
    }
  }

  backToContactUs() {
    //this.router.navigate([this.parentLink]);
    this.location.back();
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_button_label,
      backToLink : '/',
      isSubPage : false,
      isHome : false
    };
  }

}
