import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ContactBlocksComponent } from "../components/modals/contact-blocks/contact-blocks.component";

export interface DialogClasses {
  termOfUse: string;
  contact: string;
  feed: string;
  menu: string;
  pwaMenu: string;
  popin: string;
}
export interface DialogConfig {
  termOfUse: MatDialogConfig;
  contact: MatDialogConfig;
  feed: MatDialogConfig;
  menu: MatDialogConfig;
  pwaMenu: MatDialogConfig;
  popin: MatDialogConfig;
}

@Injectable({
  providedIn: "root"
})
export class DialogService {
  private static _DIALOG_REF: any;
  private static _DIALOG_TYPE: string;

  public static DIALOG_CLASSES: DialogClasses = {
    termOfUse: "term-of-use-style",
    contact: "contact-style",
    feed: "feed-style",
    menu: "menu-style",
    pwaMenu: "pwaMenu-style",
    popin: "popin-style"
  };

  private static COMMON_CONFIG: MatDialogConfig = {
    maxWidth: "inherit",
    autoFocus: false,
    restoreFocus: false
  };

  public static DIALOG_CONFIG: DialogConfig = {
    termOfUse: {
      ...DialogService.COMMON_CONFIG,
      width: "100%",

      /*maxHeight: "inherit",
      minWidth: "inherit",
      minHeight: "inherit",*/
      panelClass: DialogService.DIALOG_CLASSES.termOfUse
    },
    contact: {
      ...DialogService.COMMON_CONFIG,
      width: "100%",
      height: "254px",
      minWidth: "100%",
      position: { bottom: "0px", top: "auto" },
      panelClass: DialogService.DIALOG_CLASSES.contact
    },
    menu: {
      ...DialogService.COMMON_CONFIG,
      width: "320px",
      height: "100%",
      minWidth: "320px",
      position: { top: "0px", right: "0px" },
      panelClass: DialogService.DIALOG_CLASSES.menu
    },
    pwaMenu: {
      ...DialogService.COMMON_CONFIG,
      width: "100%",
      height: "100%",
      minWidth: "320px",
      position: { top: "0px", right: "0px" },
      panelClass: DialogService.DIALOG_CLASSES.pwaMenu
    },
    popin: {
      ...DialogService.COMMON_CONFIG,
      maxWidth: "490px",
      minWidth: "290px",
      width: "100%",
      panelClass: DialogService.DIALOG_CLASSES.popin
    },
    feed: {
      ...DialogService.COMMON_CONFIG,
      maxWidth: "320px",
      minWidth: "320px",
      position: { top: "0px", right: "0px" },
      panelClass: DialogService.DIALOG_CLASSES.feed
    }
  };

  constructor(private _dialog: MatDialog) {}

  open(dialogType: string, component, config: MatDialogConfig): void {
    const html: HTMLElement = document.querySelector("html");

    //put back the layer on top

    //prevent to open several Dialogs
    if (DialogService._DIALOG_REF) this.close();

    DialogService._DIALOG_TYPE = dialogType;

    //add classes for special dialog behaviour
    html.classList.add("dialog--" + dialogType);

    //prevent page scrolling
    html.style.overflow = "hidden";

    //open specific Dialog
    DialogService._DIALOG_REF = this._dialog.open(component, config);

    /*DialogService._DIALOG_REF.afterClosed().subscribe(() => {
     ...
    });*/
  }

  close(type: string = null) {
    if (!DialogService._DIALOG_REF) return true;
    //close only a specific modal - Used for the mobile - tablet menu on page resize
    if (type) {
      if (DialogService._DIALOG_TYPE === type) {
        DialogService._DIALOG_REF.close();
      }
      return true;
    }
    //Put back the main layer on top as the top style is changed on feed modal opening
    (document.querySelector(
      ".cdk-overlay-container"
    ) as HTMLElement).style.top = "0";

    DialogService._DIALOG_REF.close();
    DialogService._DIALOG_REF = null;

    const html: HTMLElement = document.querySelector("html");
    //remove classes for special dialog behaviour
    html.classList.remove("dialog--" + DialogService._DIALOG_TYPE);
    //enable page scrolling
    html.style.overflow = "auto";
    return true;
  }

  openContactdialog() {
    this.open("contact", ContactBlocksComponent, {
      ...DialogService.DIALOG_CONFIG.contact,
      data: {}
    });
  }
}
