import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    useHash: true,
    relativeLinkResolution: 'legacy'
}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
        console.log("router error #### " + error);
        this.router.navigate(['/generic-error']).then(
          isResolved => {
            if ( isResolved == null ) {
              return;
            }
            if ( !isResolved ) {
              this.router.navigate(['']);
            }
          },
          err => {
            console.error(err);
            this.router.navigate(['']);
          }
        );
      };
  }
}
