import { Component, OnInit, Input, Inject, AfterViewInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog.service";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"]
})
export class DialogComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService
  ) {}
  @Input() appTest: string;

  @Input() templateRef: Component;

  ngAfterViewInit() {
    if (this.data && this.data.component) {
      this.templateRef = this.data.component;
    }
  }
  ngOnInit() {}
  closeDialog() {
    this.dialogService.close();
  }
}
