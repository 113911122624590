import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-icon-feedback",
  templateUrl: "./icon-feedback.component.html",
  styleUrls: ["./icon-feedback.component.scss"]
})
export class IconFeedbackComponent implements OnInit {
  @Input() appTest: string;

  @Input() icon: string = "icon-circle-valid";
  @Input() colorClass: string = "color-primary";

  constructor() {}

  ngOnInit() {}
}
