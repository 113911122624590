import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ContactService  {

  constructor(private http: HttpClient) {}

  private successRequest: boolean;

  hasSuccessRequest() {
    return this.successRequest;
  }

  reset() {
    this.successRequest = undefined;
  }

  sendRequest(contact: any, attachment: File) {
    const url: string = environment.restBase + "/contacts";
    const formData = new FormData();
    formData.append("contact", new Blob([JSON.stringify(contact)], { type: 'application/json' } ));
    if ( attachment ) {
      formData.append("attachment", attachment, attachment.name);
    }
    return this.http
    .post<any>(url, formData)
    .pipe(
      map( res => {
        this.successRequest = true;
        return res;
      }),
      catchError(err => {
        this.successRequest = false;
        return throwError(() => err);
      })
    );
  }

  sendUnloggedRequest(contact: any, captcha: string) {
    const url: string = environment.restBase + "/logged-out-contacts/?captcha=" + captcha;
    return this.http
    .post<any>(url, contact)
    .pipe(
      map( res => {
        this.successRequest = true;
        return res;
      }),
      catchError(err => {
        this.successRequest = false;
        return throwError(() => err);
      })
    );
  }

}
