import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { DeviceService } from 'src/app/design-system/services/device.service';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.sass'],
  providers: [CurrencyFormatPipe]
})
export class SettlementComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public settlement: any;
  public agreement: any;

  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              public currencyFormatter: CurrencyFormatPipe,
              private deviceService: DeviceService) {
    super('action-settlement');
  }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    this.agreement = this.route.snapshot.parent.parent.data.agreement;
    this.settlement = this.route.snapshot.data.settlement;
  }

  getConfig() {
    const agreementType = this.route.snapshot.parent.parent.data.agreement.type;
    const settlementPageTitle = agreementType === 'LEASING' ? this.fields.settlement_title_leasing_label :
                                agreementType === 'CREDIT' ? this.fields.settlement_title_credit_ballon_label :
                                agreementType === 'BALLOON' ? this.fields.settlement_title_credit_ballon_label : undefined;
    return {
      backToLabel : this.fields.back_to_agreement_details_button_label,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.parent.params.id,
      isSubPage : true,
      isHome : false,
      title : settlementPageTitle
    };
  }

}
