<a
  href="#"
  [routerLink]="backToLink"
  class="back-button"
  *ngIf="isSubPage && isPWA"
  ><span class="chevron"></span
></a>
<h2
  class="title"
  [ngClass]="{ 'underlined-title': underlinedTitle, 'with-flag': withFlag }"
  *ngIf="title"
>
  <span *ngIf="title">{{ title }}</span>
  <ng-content *ngIf="!title"></ng-content>
  <app-flag-favorite
    class="flag"
    *ngIf="withFlag"
    (isFlagCheckedEmitter)="isFlagCheckedChangeHandler($event)"
    [isChecked]="isChecked"
  ></app-flag-favorite>
</h2>
