import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-switch-container-item",
  templateUrl: "./switch-container-item.component.html",
  styleUrls: ["./switch-container-item.component.scss"]
})
export class SwitchContainerItemComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;
  @Input() title: string;
  @Input() selected: boolean;
  @Input() index: number;

  ngOnInit() {}
}
