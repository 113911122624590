<div class="grid-item grid-item--fullsize">
  <div class="content-box" app-test="vehicle-saved-page">
    <div [editSlug]="slug">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-large">
            <br/>
            <br/>
            <h2 class="form-title">{{fields.success_title}}</h2>

            <app-icon-feedback
            icon="icon-circle-valid"
            colorClass="default-icon-color">
            </app-icon-feedback>

            <div class="layout-narrow">
              <app-form-buttons>
                <div>
                  <app-button
                  buttonType="secondary"
                  app-test="vehicles-back-btn"
                  [buttonLabel]="fields.navigation_button_label"
                  [routerLink]="parentLink">
                  </app-button>
                </div>
              </app-form-buttons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>