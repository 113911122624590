import {
  HttpClient,
  HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  // eslint-disable-next-line max-len
  readonly noAuthPattern = /^.*\b(\/oauth\/token|\/signups|\/users\/(.*)\/resets(.*)|\/users\/(.*)\/commit\/(.*)|\/signups\/(.*)|\/static\/(.*)|\/static\/patterns|\/static\/patterns|\/logged-out-contacts\/(.*))\b.*/;

  constructor( public http: HttpClient,
               private router: Router,
               public authService: JwtService,
               public tokenHelper: JwtHelperService) { }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
      return req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + this.authService.getAccessToken())
      });
    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler ): Observable<any> {

    if ( req.url.startsWith( environment.restBase ) && !this.noAuthPattern.test( req.url )) {

      return next.handle(this.addToken(req)).pipe(
          catchError(error => {
            if (req.url.includes('refresh_token')) {
              this.authService.logout();
              return of([]);
            }

            if (error instanceof HttpErrorResponse) {
              switch ((error as HttpErrorResponse).status) {
                case 400:
                  return this.handle400Error(error);
                case 401:
                  return this.handle401Error(req, next);
                case 504:
                  return this.handle504Error(req, next, error);
                default:
                  return throwError(error);
              }
            }
            return throwError(() => error);
          })
      );

    } else return next.handle(req);
  }

  handle504Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    return next.handle(this.addToken(req)).pipe(
      retry(2),
      catchError( err => {
        if ( error && error.status === 504 ) {
          this.router.navigate(['/generic-error']);
          return of([]);
        } else if ( req.url.includes("/alerts") ) {
          return of([]);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.authService.logout();
    }
    return throwError(() => error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    return this.refreshToken().pipe(
      switchMap(() => next.handle(this.addToken(req))),
      catchError(() => {
        this.authService.logout();
        return of([]);
      })
    );
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable( observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.authService.refreshToken().pipe(
        tap( () => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(() => {
          this.authService.logout();
          return of([]);
        })
      );
    }
  }

}
