<div [editSlug]="slug">
  <div class="grid-item grid-item--fullsize" app-test="settlement-payment-info-page">
    <div class="content-box content-box--form">
      <div class="content-box-inner">
        <div class="layout-narrow">
          <p [innerHTML]="fields.payment_info_text  | interpolate: { 
                                                        'validUntil' : settlement.validUntil | dateFormatPipe, 
                                                        'amountToPay' : settlement.terminationFigure | currencyFormatPipe, 
                                                        'customerId' :  customerId
                                                      }
                                                    | safe: 'html' "></p>
          <app-form-buttons>
            <app-button
              buttonType="secondary"
              [buttonLabel]="fields.back_to + ' ' + (agreement.type === 'LEASING' ? fields.settlement_title_leasing_label : fields.settlement_title_credit_ballon_label)"
              [routerLink]="['/home','agreements', agreement.id, 'settlement']">
            </app-button>
          </app-form-buttons>
        </div>
      </div>
    </div>
  </div>
</div>