import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              public tokenService: JwtService) { }

  // This should be used for routes where there are
  // no authenticated rest calls to redirect unauthenticated
  // users straight to login
  canActivate() {
    if (!this.tokenService.loggedIn) {
      console.log('you are not allowed to access this page');
      this.router.navigate(['/login']);
      return false;
    } else {
      console.log('you are being redirected to the requested page');
      return true;
    }
  }

}