import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/design-system/services/device.service';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'app-forgotten-password-page',
  templateUrl: './forgotten-password-page.component.html',
  styleUrls: ['./forgotten-password-page.component.sass']
})
export class ForgottenPasswordPageComponent extends AbstractComponent implements OnInit {

  public bgImage: string;
  public postContent: SafeHtml;

  public backLink = '/login';

  public isPWA = false;

  public footerLinks: MenuItems[];
  public footerCopyright: string;
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  constructor(private sanitizer: DomSanitizer,
              private deviceService: DeviceService,
              public cookiesService: CookiesService,
              private pageLayoutConfigService: PageLayoutConfigService) {
                super('forgotten-password-page');
              }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    this.bgImage = environment.contentUploads + '/2019/06/img_help_login-forgot_password.jpg';
    this.postContent = this.sanitizer.bypassSecurityTrustHtml(this.fields.side_help_text);
    this.footerLinks = this.pageLayoutConfigService.publicFooterLinks;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.publicContactBlocksData;
  }

}

