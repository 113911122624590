import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { StepData } from 'src/app/design-system/components/navigation/stepper/stepper.component';
import { SignupService } from 'src/app/services/signup/signup.service';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';

@Component({
  selector: 'app-check-customer-form',
  templateUrl: './check-customer-form.component.html',
  styleUrls: ['./check-customer-form.component.sass'],
  providers: [DateFormatPipe]
})
export class CheckCustomerFormComponent extends AbstractComponent implements OnInit {

  public registrationSteps: StepData[];
  public readonly stepIndex = 0;

  public identificationErrors: any;
  public identificationForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private signupService: SignupService,
              private dateFormatter: DateFormatPipe) {
                super('registration-page');
              }

  ngOnInit() {
    if (!this.signupService.initialized) { this.router.navigate(['/login']); }

    this.initializeIdentificationErrors(this.route.snapshot.data.signupRes);

    if (this.signupService.signupCreated) {
      this.identificationForm = this.fb.group({
        name: [this.signupService.customer.person.firstName],
        surname: [this.signupService.customer.person.lastName],
        birthDate: [this.dateFormatter.transform(this.signupService.customer.person.birthDate)]
      });
    }

    this.registrationSteps = [
      {
        label: this.fields.step_1_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_2_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      },
      {
        label: this.fields.step_3_stepper_text,
        formGroup: '',
        completed: false,
        editable: false
      }
    ];

  }

  initializeIdentificationErrors(error: any) {
    this.identificationErrors = {
      invalidSignupCredential: error.invalidSignupCredential,
      alredyRegisteredCustomer: error.alredyRegisteredCustomer,
      alreadyExistingUsername: error.alreadyExistingUsername,
      serverError: error.serverError
    };
  }

  haveServiceErrors(): boolean {
    return this.identificationErrors.invalidSignupCredential ||
    this.identificationErrors.alredyRegisteredCustomer ||
    this.identificationErrors.alreadyExistingUsername ||
    this.identificationErrors.serverError;
  }

  back() {
    this.router.navigate(['/login']);
  }

  continue() {
    this.router.navigate(['/registration/update-customer']);
  }

}
