<mat-horizontal-stepper
  [id]="id"
  labelPosition="bottom"
  #stepper
  [linear]="linear"
  [selectedIndex]="selectedIndex"
  (selectionChange)="onStepChange($event)"
>
  <mat-step
    *ngFor="let step of stepData"
    [stepControl]="step.formGroup"
    [editable]="step.editable"
    [completed]="step.completed"
    [ngClass]="{ 'done ': step.completed, 'editable ': step.editable }"
  >
    <ng-template matStepHeader> {{ step.label }}</ng-template>
    <ng-template matStepLabel> {{ step.label }}</ng-template>
    <ng-content select="step.formGroup"></ng-content>
  </mat-step>
</mat-horizontal-stepper>
