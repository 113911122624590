<div #navMenu>

  <mat-nav-list #matNNavList role="navigation" [disableRipple]="true" *ngIf="!isFooterMenu">
    <a mat-list-item
    *ngFor="let item of menuItems; let i = index"
    [routerLink]="item.routerLink"
    routerLinkActive="selected"
    [ngClass]="{ 'footer-link': item.isFooterLink}"
    (click)="onClick.emit($event)"
    [attr.app-test]="item.appTest">
      {{ item.itemTitle }}
    </a>
  </mat-nav-list>

  <mat-nav-list #matNNavList role="navigation" [disableRipple]="true" *ngIf="isFooterMenu">
    <a mat-list-item
    *ngFor="let item of menuItems; let i = index"
    [routerLink]="item.routerLink"
    class="dark-item"
    [ngClass]="{ 'footer-link': item.isFooterLink}"
    (click)="onClick.emit($event)"
    [attr.app-test]="item.appTest">
      {{ item.itemTitle }}
    </a>
  </mat-nav-list>

</div>
