import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass']
})
export class LoginFormComponent extends AbstractComponent implements OnInit {

  public loginForm: FormGroup;
  public loginErrors: any;
  public enableSignup: boolean;
  public loginAttempts: number;
  public noAgreementsHTMLMessage: any;
  public fromValidation: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder,
    private tokenService: JwtService,
    public agreementService: AgreementsService,
    private activatedRoute: ActivatedRoute) {
    super('login-page');
  }

  ngOnInit() {
    this.tokenService.logout();
    this.initializeLoginErrors();
    this.noAgreementsHTMLMessage = this.sanitizer.bypassSecurityTrustHtml( this.fields.message_no_agreement_error );

    this.loginForm = this.fb.group({
      username: [''],
      usernameCheckbox: false,
      password: [''],
      recaptcha: ['']
    });

    if (localStorage.getItem('userId')) {
      this.loginForm.patchValue({
        username: localStorage.getItem('userId'),
        usernameCheckbox: true
      });
    }
    this.fromValidation = this.activatedRoute.snapshot.paramMap.get('fromValidation') === "true";
    this.loginAttempts = 0;
  }

  /*
  enableConfirmButton(): boolean {
    if (this.captchaRequired()) {
      return this.loginForm.valid;
    } else {
      // manual validation: email and password required, password required
      return (
        new RegExp(this.patterns.email.pattern).test(this.loginForm.get('username').value) &&
        this.loginForm.value.username !== '' &&
        this.loginForm.value.password !== '');
    }
  }
  */
  login() {
    const captcha = this.captchaRequired() ? this.loginForm.value.recaptcha : undefined;
    this.initializeLoginErrors();
    this.tokenService.login(this.loginForm.value.username, this.loginForm.value.password, captcha).subscribe(
      data => {
        this.loginAttempts = 0;
        console.log(data);
        if (this.loginForm.value.usernameCheckbox) {
          localStorage.setItem('userId', this.loginForm.value.username);
        } else {
          localStorage.removeItem('userId');
        }
        this.router.navigate(['/home']);
      },
      err => {
        console.log(err);
        this.loginErrors = err;
        if ( this.loginErrors.invalidCredential ) {
          this.loginAttempts++;
        }
      }
    );
  }

  public captchaRequired() {
    return this.loginAttempts >= 3 || this.loginErrors.invalidCaptcha;
  }

  initializeLoginErrors() {
    this.loginErrors = {
      invalidCredential: false,
      invalidCaptcha: false,
      noAgreements: false,
      serverError: false
    };
  }

  eventKeyHandler(event: { keyCode: number; }) {
    // console.log(event);
    // event.keyCode === 13 detects ENTER key press
    if (event.keyCode === 13 && this.loginForm.valid) {
      this.login();
    }
 }

  signup() {
    this.router.navigate(['/registration/identify-customer']);
  }

}
