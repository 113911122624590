<div
  class="container--nested"
  [ngClass]="{ 'with-show-more-button': withShowMoreButton }"
>
  <div [class]="'content-box grid--' + nestedType" #nestedGrid>
    <div [class]="'grid-sizer--' + nestedType"></div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="withShowMoreButton" class="show-more-button-box">
    <app-show-more-button
      #showMoreButton
      [showMoreStatus]="showMoreStatus"
      [showMoreLabel]="showMoreLabel"
      [showLessLabel]="showLessLabel"
      (clickEmitter)="showHideMore($event)"
    >
    </app-show-more-button>
  </div>
</div>
