<div class="app-vehicule">
  <div class="bg-image-holder">
    <app-bg-image [bgImage]="image" app-test="vehicle-image"></app-bg-image>
    <div class="legend">
      <div class="legend-text">
        <h3 app-test="vehicle-name">{{ title }}</h3>
        <h4 app-test="vehicle-plate">{{ subtitle }}</h4>
      </div>
      <app-icon-button
        icon="icon-circle-edit"
        iconWidth="25px"
        iconHeight="25px"
        colorClass="color-white"
        ngClass="hover-white-strong"
        (click)="showVehicleDetails()"
        app-test="vehicle-details-btn"
      ></app-icon-button>
    </div>
  </div>

  <div class="vehcule-infos-list">
    <mat-list>
      <app-link-item
        attr.app-test="vehicle-agreement-{{info.appTest}}"
        *ngFor="let info of vehiculeInfos"
        [alert]="info.alert"
        [link]="info.link">
        {{ info.text }}
      </app-link-item>
    </mat-list>
  </div>
  <app-push-link
    *ngIf="commercialText"
    [title]="commercialText"
    app-test="vehicle-commercial-txt"
  ></app-push-link>
</div>
