<app-button
  *ngIf="!isPWA"
  [icon]="icon"
  [buttonLabel]="buttonLabel"
  ngClass="contact-button"
  iconHeight="30px"
  iconWidth="30px"
  appTest="contact-us-button"
  (buttonClick)="contactButtonEmitter.emit($event)"
></app-button>

<app-fab-button
  *ngIf="isPWA"
  (buttonClick)="contactButtonEmitter.emit($event)"
></app-fab-button>
