import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { VehiclesContentComponent } from './vehicles-content/vehicles-content.component';
import { VehicleContentComponent } from './vehicle-content/vehicle-content.component';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { CommonComponentsModule } from '../commons/common-components.module';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { SaveSuccessComponent } from './save-success/save-success.component';
import { ExtraCardsComponent } from '../commons/extra-cards/extra-cards.component';
import { HomeTabComponent } from '../commons/home-tab/home-tab.component';

const routes: Routes = [
  {
    path: 'home/vehicles',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeTabComponent,
        children: [
          {
            path: '',
            component: VehiclesContentComponent,
            resolve: {
              vehicles : VehiclesService
            },
          }
        ]
      },
      {
        path: '',
        component: ExtraCardsComponent,
        outlet : 'extras'
      }
    ]
  },
  {
    path: 'home/vehicles/:id',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    resolve: {
      vehicle : VehiclesService
    },
    children: [
      {
        path: '',
        component: VehicleContentComponent,
      },
      {
        path: '',
        component: ExtraCardsComponent,
        outlet : 'extras'
      }
    ]
  },
  {
    path: 'home/vehicles/:id/save-success',
    component: LoggedPageComponent,
    children: [
      {
        path: '',
        component: SaveSuccessComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    VehiclesContentComponent,
    VehicleContentComponent,
    SaveSuccessComponent
    //add here all the required components
  ]
})
export class VehiclesModule { }
