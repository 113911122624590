import { Component, OnInit, Inject, Input } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MenuItems } from '../nav-menu/nav-menu.component';

@Component({
  selector: "app-nav-menu-mobile",
  templateUrl: "./nav-menu-mobile.component.html",
  styleUrls: ["./nav-menu-mobile.component.scss"]
})

//Dialog
//contains the nav menu , the logo, a close button and the user name component
//opened from the burger
//the selected index of the menu can be  retrieved from a service or globally any other way
//it can be setted from the burger open action in the data object
//we used a static var in the NavMenuComponent for the demo
export class NavMenuMobileComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @Input() appTest: string;

  @Input() userName: string;

  @Input() menuItems: MenuItems[];

  @Input() welcomeText: string
;
  ngOnInit() {
    if (this.data) {
      this.userName = this.data.userName;
      this.menuItems = this.data.menuItems;
      this.welcomeText = this.data.welcomeText;
      this.appTest = this.data.appTest;
    }
  }
  closeMenu() {
    this.dialogService.close();
  }
}
