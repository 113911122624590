<span app-test="agreement-details-page">
  <div [editSlug]="slug">
    
    <div [ngClass]="[commercialCards && commercialCards.length > 0 ? 'grid-item grid-item--verybig' : 'grid-item grid-item--fullsize']">
      <div class="content-box">
        <app-agreement-synthese
          [syntheseList]="agreementSyntheseDetails"
          syntheseIcon="icon-circle-car">

          <app-agreement-credit
            *ngIf="!(agreement.type === 'BATTERY')"
            [label]= "fields.agreement_synthese_alert_label" 
            [agreement]="agreement" 
            [of]="fields.of"
            [date]="agreement.nextInstallmentDate | dateFormatPipe">
          </app-agreement-credit>
        </app-agreement-synthese>
        <app-agreement-alert *ngIf="agreement.alert" [message]="fields.overdue_payment"></app-agreement-alert>
      </div>
    </div>

    <div [ngClass]="[commercialCards && commercialCards.length > 0 ? 'grid-item grid-item--verybig' : 'grid-item grid-item--fullsize']">
      <div class="content-box">
        <app-tabs
          #appTabs
          [withMore]="false"
          [selectedTabIndex]="0"
          [tabData]="tabData">
        </app-tabs>

        <br/>

        <div class="block-section" id="agreementDetails">
          <app-block-title #agreementDetails [title]= "fields.agreement_details_tab_label"></app-block-title>
          <div class="block-row-container">
            <app-block-row
              *ngIf="agreement.startDate"
              [label]=fields.agreement_start_date
              [value]="agreement.startDate | dateFormatPipe"
              appTest="start date">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.endDate"
              [label]=fields.agreement_end_date
              [value]="agreement.endDate | dateFormatPipe"
              appTest="end date">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.duration"
              [label]=fields.agreement_duration
              [value]="agreement.duration"
              appTest="duration">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.financedAmount"
              [label]=fields.agreement_financed_amount
              [value]="agreement.financedAmount | currencyFormatPipe"
              appTest="financed amount">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.taxExpenses"
              [label]=fields.agreement_tax_expenses
              [value]="agreement.taxExpenses | currencyFormatPipe"
              appTest="tax expenses">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.handlingFee"
              [label]=fields.agreement_handling_fee
              [value]="agreement.handlingFee | currencyFormatPipe"
              appTest="handling fee">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.deposit"
              [label]=fields.agreement_leasing_deposit_label
              [value]="agreement.deposit | currencyFormatPipe"
              appTest="deposit">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.rentPrepayment"
              [label]=fields.agreement_leasing_deposit_label
              [value]="agreement.rentPrepayment | currencyFormatPipe"
              appTest="rent prepayment">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.depot"
              [label]=fields.agreement_depot
              [value]="agreement.depot | currencyFormatPipe"
              appTest="depot">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.mileageAtStart || agreement.mileageAtStart == '0'"
              [label]=fields.agreement_mileage_at_start
              [value]="agreement.mileageAtStart | integerFormatPipe"
              appTest="mileage at start">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.mileagePerYear"
              [label]=fields.agreement_mileage_per_year
              [value]="agreement.mileagePerYear | integerFormatPipe"
              appTest="mileage per year">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.totalMileage"
              [label]=fields.agreement_total_mileage
              [value]="agreement.totalMileage | integerFormatPipe"
              appTest="total mileage">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.residualValue"
              [label]=fields.agreement_residual_value_amount
              [value]="agreement.residualValue | currencyFormatPipe"
              appTest="residual value amount">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.residualValueDueDate"
              [label]=fields.agreement_residual_value_due_date
              [value]="agreement.residualValueDueDate | dateFormatPipe"
              appTest="residual value due date">
            </app-block-row>
          </div>
        </div>

        <br/><br/>

        <div class="block-section" id="paymentDetails">
          <app-block-title #paymentDetails [title]="fields.payment_details_tab_label"></app-block-title>
          <div class="block-row-container">
              <app-block-row
              *ngIf="agreement.monthlyInstallment"
              [label]=fields.payment_monthly_installment
              [value]="agreement.monthlyInstallment| currencyFormatPipe"
              appTest="monthly installment">
            </app-block-row>
              <app-block-row
              *ngIf="agreement.balloonInstallment"
              [label]=fields.agreement_residual_value_amount
              [value]="agreement.balloonInstallment| currencyFormatPipe"
              appTest="balloon installment">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.firstInstallmentDate"
              [label]=fields.payment_first_installment_date
              [value]="agreement.firstInstallmentDate | dateFormatPipe"
              appTest="first installment date">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.lastInstallmentDate"  
              [label]=fields.payment_last_installment_date
              [value]="agreement.lastInstallmentDate | dateFormatPipe"
              appTest="last installment date">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.lastInstallmentAmount"
              [label]=fields.agreement_last_installment_amount
              [value]="agreement.lastInstallmentAmount | currencyFormatPipe"
              appTest="amount of the last installement">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.paymentMethod && agreement.paymentMethod === 'PAYMENT_SLIP'"
              [label]=fields.way_of_payment
              [value]="fields.payment_strip_label"
              [actions]="[{ action: 'edit', label: fields.edit_label }]"
              appTest="payment-type-payment-slip"
              app-test="payment-type-payment-slip"
              (actionEvent)="goToPaymentMethod()">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.paymentMethod && agreement.paymentMethod === 'DIRECT_DEBIT'"
              [label]=fields.way_of_payment
              [value]="fields.direct_debit_label"
              [actions]="[{ action: 'edit', label: fields.edit_label }]"
              appTest="payment-type-direct-debit"
              app-test="payment-type-direct-debit"
              (actionEvent)="goToPaymentMethod()">
            </app-block-row>
            <app-block-row
              *ngIf="(agreement.mandateReferenceNumber || agreement.paymentMethod === 'DIRECT_DEBIT')"
              [label]=fields.mandate_reference_number
              [value]="agreement.mandateReferenceNumber"
              appTest="mandate reference number">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.transferDueDate"
              [label]=fields.transfer_due_date
              [value]="getTranslatedDueDateLabel(agreement.transferDueDate)"
              appTest="transfer due date">
            </app-block-row>
            <app-block-row
              *ngIf="agreement.type !== 'BATTERY'"
              [label]="fields.payment_plan"
              [actions]="[{ id: action, label: fields.view_label }]"
              (actionEvent)="showPaymentPlan()"
              app-test="PAYMENT_PLAN">
            </app-block-row>
          </div>
        </div>

        <br/><br/>

        <div class="block-section" id="requests" *ngIf="agreement.actions">
          <app-block-title #requests [title]="fields.request_tab_label"></app-block-title>
          <div class="block-row-container">
            <app-block-row
            *ngFor="let action of agreement.actions"
            [label]="getTranslatedActionName(action)" 
            [actions]="[{ id: action, label: fields.request_label }]"
            (actionEvent)="performAction($event)"
            attr.app-test="{{action}}">
            </app-block-row>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!(agreement.type === 'CREDIT' || agreement.type === 'BALLOON')" [ngClass]="[commercialCards && commercialCards.length > 0 ? 'grid-item grid-item--verybig' : 'grid-item grid-item--fullsize']">
      <div class="content-box content-box--form">
        <div class="content-box-inner">
          <div class="layout-narrow">
            <p>{{fields.tva_label}}</p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</span>
 