import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { LoggedPageComponent } from './logged-page/logged-page.component';
import { MessageBlockComponent } from './message-block/message-block.component';
import { CardCommercialComponent } from './card-commercial/card-commercial.component';
import { CardQuickAccessComponent } from './card-quick-access/card-quick-access.component';
import { ExtraCardsComponent } from './extra-cards/extra-cards.component';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { HomeTabComponent } from './home-tab/home-tab.component';
import { PublicPageComponent } from './public-page/public-page.component';

@NgModule({
  imports: [
    CommonModule,
    CmsManagerModule,
    DesignSystemModule
  ],
  exports: [
    CardCommercialComponent,
    CardQuickAccessComponent,
    ExtraCardsComponent,
    MessageBlockComponent
  ],
  declarations: [
    LoggedPageComponent,
    MessageBlockComponent,
    CardCommercialComponent,
    CardQuickAccessComponent,
    ExtraCardsComponent,
    HomeTabComponent,
    PublicPageComponent
  ]
})
export class CommonComponentsModule { }
