<label style="text-align: initial">{{ label }}</label>
<mat-form-field appearance="none" class="custom-textarea" [color]="'#000000'" [formGroup]="parentForm">
  <textarea
    #textarea
    matInput
    cdkTextareaAutosize
    [maxLength]="maxLength"
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows"
    [placeholder]="placeholder"
    [formControlName]="controlName"
    [required]="required"
    (keyup)="updateLayout($event)"
    (ngModelChange)="onModelChange.emit($event)"
    [required]="required"
    [attr.app-test]="appTest"
  ></textarea>
</mat-form-field>
<ng-container *ngIf="errorDetected()">
  <mat-hint [ngClass]="{ 'hint-error hint-left-side': errorDetected() }" >
    <span *ngIf="parentForm.controls[controlName].errors.required" app-test="validation-required-lbl">{{requiredErrorMessage}}</span>
  </mat-hint>
</ng-container>
<label *ngIf="displayCharCount" style="text-align: end">{{textarea.value.length}}/{{ maxLength }}</label>