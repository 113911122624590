<div
  class="app-page page no-log"
  [ngClass]="{
    'sub-page': isSubPage,
    'no-left-column': true
  }">

  <app-header 
    #header 
    [backToLink]="backToLink"
    [cookiesAlert]="cookiesAlert"
    (cookiesClickEmitter)="cookiesEmitter.emit($event)">
  </app-header>

  <div class="page-content">
    <app-back-to
      [label]="backToLabel"
      [link]="backToLink">
    </app-back-to>
    <div class="container">
      <div class="grid">
        <div class="grid-sizer"></div>
        <ng-container>
          <ng-container *ngTemplateOutlet="pageContentRef"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <app-footer
    #footer
    [footerLinks]="footerLinks"
    [withDownloadLogos]="false"
    [copyright]="copyright"
    [isPWA]="isPWA"
    [contactBlocksData]="contactBlocksData"
    [contactBlocksButton]="contactBlocksButton">
  </app-footer>

</div>

<app-pwa-chat-bar *ngIf="isPWA" [isTopPage]="!isSubPage" [contactBlocksData]="contactBlocksData"></app-pwa-chat-bar>
