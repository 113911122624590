import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';
import { ActivatedRoute, Router } from '@angular/router';
import { SettlementService } from 'src/app/services/settlement/settlement.service';
import { JwtService } from 'src/app/services/jwt/jwt.service';

@Component({
  selector: 'app-settlement-payment-info',
  templateUrl: './settlement-payment-info.component.html',
  styleUrls: ['./settlement-payment-info.component.sass']
})
export class SettlementPaymentInfoComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public agreement: any;
  public settlement: any;
  public customerId: string;

  constructor(private route: ActivatedRoute,
              private jwtService: JwtService,
              public service: SettlementService) {
      super('action-settlement');
    }

  ngOnInit() {
    this.agreement = this.route.snapshot.parent.parent.data.agreement;
    this.settlement = this.route.snapshot.parent.data.settlement;
    this.customerId = this.jwtService.UserStoredInformations.customerId;
  }

  getConfig() {
    const agreementType = this.route.snapshot.parent.parent.data.agreement.type;
    const backToLabelSuffix = agreementType === 'LEASING' ? this.fields.settlement_title_leasing_label :
                                agreementType === 'CREDIT' ? this.fields.settlement_title_credit_ballon_label :
                                agreementType === 'BALLOON' ? this.fields.settlement_title_credit_ballon_label : undefined;
    return {
      backToLabel : this.fields.back_to + ' ' + backToLabelSuffix,
      backToLink : '/home/agreements/' + this.route.snapshot.parent.parent.params.id + '/settlement',
      isSubPage : true,
      isHome : false,
      title: this.fields.payment_information_button
    };
  }
}
