import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth-guard';
import { DesignSystemModule } from 'src/app/design-system/modules/design-system.module';
import { CmsManagerModule } from 'src/app/cms-manager/cms-manager.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '../commons/common-components.module';
import { LoggedPageComponent } from '../commons/logged-page/logged-page.component';
import { BankDetailsContentComponent } from './bank-details-content/bank-details-content.component';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { AgreementsService } from 'src/app/services/agreements/agreements.service';

const routes: Routes = [
  { path: 'bank-details',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BankDetailsContentComponent,
        resolve: {
          bankDetails : BankDetailsService,
          agreements : AgreementsService
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    PipesModule,
    RouterModule.forChild(routes),
    CommonModule,
    DesignSystemModule,
    CmsManagerModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    BankDetailsContentComponent
    //add here all the required components
  ]
})
export class BankDetailsModule { }
