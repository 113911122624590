import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"]
})
export class IconButtonComponent implements OnInit {
  @Input() appTest: string;

  @Input() icon: string;
  @Input() iconWidth: string;
  @Input() iconHeight: string;
  @Input() colorClass: string;
  @Input() description: string = "";
  @Input() disabled: boolean;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
