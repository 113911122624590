<div class="cookie-banner-container negative">
  <div class="explanations">
    <span class="explanation-text">{{ explanationText }} </span>
    <a [href]="link" class="link explanation-link">{{ explanationLink }}</a>
  </div>
  <ul class="settings">
    <li *ngFor="let setting of settingsData">
      <app-checkbox
        [label]="setting.label"
        [name]="setting.name"
        [disabled]="setting.disabled"
        [checked]="setting.checked"
        colorClass="color-white"
      ></app-checkbox>
    </li>
  </ul>
  <app-button
    [buttonLabel]="buttonLabel"
    (buttonClick)="cookiePrefAction()"
  ></app-button>
</div>
