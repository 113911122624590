import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/design-system/services/dialog.service';
import { PaymentStripService } from 'src/app/services/payment-strip/payment-strip.service';

@Component({
  selector: 'app-edit-payment-method-modal',
  templateUrl: './edit-payment-method-modal.component.html',
  styleUrls: ['./edit-payment-method-modal.component.sass']
})
export class EditPaymentMethodModalComponent extends AbstractComponent implements OnInit, OnDestroy {

  public agreementLabels: string[];
  public paymentMethodType: string;
  private requestEditBody: any;

  public errorOnRequest: any;

  public elConfirmButton: any;

  constructor(private bankDetailsService: BankDetailsService,
              private paymentStripService: PaymentStripService,
              private router: Router,
              private dialogService: DialogService) {
    super('payment-method-page');
  }

  ngOnInit() {
    this.setErrorObject(false, false);
    this.agreementLabels = this.bankDetailsService.agreementLabelsNewDDM;
    this.paymentMethodType = this.bankDetailsService.selectedPaymentMethod;

    switch (this.paymentMethodType) {

      case 'directDebit':
        this.requestEditBody = this.bankDetailsService.newDDM;
        break;

      case 'paymentStrip':
        this.requestEditBody = this.bankDetailsService.agreementIdList;
        break;

      default:
        break;

    }
  }

  ngOnDestroy(): void {
    this.dialogService.close();
  }

  public updatePaymentMethod(event: any) {
    // disable the button after click
    this.elConfirmButton = event.target.ownerDocument.activeElement;
    this.elConfirmButton.disabled = true;

    this.setErrorObject(false, false);

    switch (this.paymentMethodType) {

      case 'directDebit':
        this.updateDirectDebit();
        break;

      case 'paymentStrip':
          this.updatePaymentStrip();
          break;

      default:
        break;

    }
  }

  private updateDirectDebit() {
    this.bankDetailsService.create(this.requestEditBody).subscribe(
      data => {
        // console.log(data);
        this.dialogService.close();
        this.router.navigate(['/payment-method/direct-debit/edit/success']);
      },
      err => {
        // console.log(err);
        if (err.status === 400) {
          this.setErrorObject(false, true);
        } else if (err.status === 500) {
          this.setErrorObject(true, false);
        } else {
          this.setErrorObject(true, false);
        }
        // enable back the button if an error is detected
        this.elConfirmButton.disabled = false;
      }
    );
  }

  private updatePaymentStrip() {
    this.paymentStripService.create(this.requestEditBody).subscribe(
      data => {
        // console.log(data);
        this.dialogService.close();
        this.router.navigate(['/payment-method/payment-slip/edit/success']);
      },
      err => {
        // console.log(err);
        if (err.status === 400) {
          this.setErrorObject(false, true);
        } else if (err.status === 500) {
          this.setErrorObject(true, false);
        } else {
          this.setErrorObject(true, false);
        }
        // enable back the button if an error is detected
        this.elConfirmButton.disabled = false;
      }
    );
  }

  private setErrorObject(serverErrorBool: boolean, badRequestBool: boolean) {
    this.errorOnRequest = {
        serverError: serverErrorBool,
        badRequest: badRequestBool
    };
  }
}
