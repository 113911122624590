import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurablePage } from '../../commons/configurable-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { DeviceService } from 'src/app/design-system/services/device.service';

/* eslint-disable max-len */

@Component({
  selector: 'app-edit-personal-details',
  templateUrl: './edit-personal-details.component.html',
  styleUrls: ['./edit-personal-details.component.sass']
})
export class EditPersonalDetailsComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public customer: any;
  public customerDetailsForm: FormGroup;

  private parentLink = '/customer';
  public isPWA: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private customerService: CustomerService,
              private deviceService: DeviceService) {
      super('customer-page');
  }

  ngOnInit() {
    this.isPWA = this.deviceService.isPWA;

    if (this.customerService.customer) {
      this.customer = this.customerService.customer;
      console.log(this.customerService.customer);
      console.log(this.customerService.indexEditableAddress);
      console.log(this.customerService.indexEditablePhone);
    } else {
      this.router.navigate([this.parentLink]);
    }

    this.customerDetailsForm = this.fb.group({
      street: [
        this.customerService.indexEditableAddress !== -1 ?
        this.customer.addresses[this.customerService.indexEditableAddress].street :
        ''
      ],
      city: [
        this.customerService.indexEditableAddress !== -1 ?
        this.customer.addresses[this.customerService.indexEditableAddress].city :
        ''
      ],
      postcode: [
        this.customerService.indexEditableAddress !== -1 ?
        this.customer.addresses[this.customerService.indexEditableAddress].postCode :
        ''
      ],
      country: [
        this.customerService.indexEditableAddress !== -1 ?
        this.customer.addresses[this.customerService.indexEditableAddress].country :
        ''
      ],
      phone: [
        this.customerService.indexEditablePhone !== -1 ?
        this.customer.phones[this.customerService.indexEditablePhone].value :
        ''
      ]
    });
  }

  getConfig() {
    return {
      backToLabel : this.fields.back_to_my_profile_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title: this.fields.edit_personal_details_label
    };
  }

  isPrefilledCountry(): boolean {
    return this.customerService.indexEditableAddress !== -1;
  }

  createNewCustomer() {
    if (this.customerService.indexEditableAddress !== -1) {
      this.customer.addresses[this.customerService.indexEditableAddress].street = this.customerDetailsForm.get('street').value;
      this.customer.addresses[this.customerService.indexEditableAddress].city = this.customerDetailsForm.get('city').value;
      this.customer.addresses[this.customerService.indexEditableAddress].postCode = this.customerDetailsForm.get('postcode').value;
      this.customer.addresses[this.customerService.indexEditableAddress].country = this.customerDetailsForm.get('country').value;
    } else {
      this.customer.addresses = [];
      this.customer.addresses.push(
        {
         street: this.customerDetailsForm.get('street').value,
         city: this.customerDetailsForm.get('city').value,
         postCode: this.customerDetailsForm.get('postcode').value,
         country: this.customerDetailsForm.get('country').value,
         type: 'home'
        }
      );
    }

    if (this.customerService.indexEditablePhone !== -1) {
      this.customer.phones[this.customerService.indexEditablePhone].value = this.customerDetailsForm.get('phone').value;
    } else {
      this.customer.phones = [];
      this.customer.phones.push(
        {
         value: this.customerDetailsForm.get('phone').value,
         type: 'home'
        }
      );
    }

    return this.customer;
  }

  confirm() {
    this.customer = this.createNewCustomer();
    console.log(this.customer);
    this.customerService.update('self', this.customer).subscribe(
      data => {
        this.customerService.customerUpdated = true;
        this.router.navigate(['./success'], { relativeTo: this.route });
      },
      err => {
        this.customerService.customerUpdated = false;
        this.router.navigate(['./success'], { relativeTo: this.route });
      }
    );
  }

  cancel() {
    this.router.navigate([this.parentLink]);
  }

}
