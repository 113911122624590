<p class="intro">
  <ng-content></ng-content>
</p>
<h3 class="form-subtitle">{{ subtitle }}</h3>
<form>
  <app-input-search app-test="faq-input-search"
    [hint]="searchHint" [placeholder]="searchPlaceholder"
    (onModelChange)="onModelChange.emit($event)"
  ></app-input-search>
 </form>
