import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditLinkDirective } from './edit-link.directive';
import { WordpressService } from './wordpress/wordpress.service';

@NgModule({
  declarations: [
    EditLinkDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EditLinkDirective
  ],
  providers: [WordpressService]
})
export class CmsManagerModule { }
