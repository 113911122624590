import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-quick-access",
  templateUrl: "./quick-access.component.html",
  styleUrls: ["./quick-access.component.scss"]
})
export class QuickAccessComponent implements OnInit {

  @Input() icon: string = "icon-circle-bell";
  @Input() title: string;

  constructor() {}

  ngOnInit() {}
}
