import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { ConfigurablePage } from '../../commons/configurable-page';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

@Component({
  selector: 'app-vehicle-content',
  templateUrl: './vehicle-content.component.html',
  styleUrls: ['./vehicle-content.component.sass'],
  providers: [DateFormatPipe]
})
export class VehicleContentComponent extends AbstractComponent implements OnInit, ConfigurablePage {

  public vehicle: any;
  private parentLink = '/home/vehicles';

  public commercialCards: any[];
  public errorFileMessage: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private vehiclesService: VehiclesService,
              public dateFormatter: DateFormatPipe) {
    super('vehicle-detail-page');
}

  ngOnInit() {
    this.commercialCards = WordpressService.commercialCards;

    this.vehicle = this.route.snapshot.data.vehicle;
  }

  getConfig() {
    return {
      backToLabel : this.fields.navigation_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false,
      title : this.fields.detail_card_title
    };
  }

  back() {
    this.router.navigateByUrl(this.parentLink);
  }

  public save(vehicle: any) {

    const promiseUpateName = this.vehiclesService.update(this.vehicle.id, {
      name : vehicle.name
    }).toPromise();

    if ( vehicle.imageToUpload  ) {
      console.log('save image : ' + vehicle.imageToUpload.name);

      promiseUpateName.then(res => {
        return this.vehiclesService.saveImage(this.vehicle.id, vehicle.imageToUpload ).toPromise();
      }).then( () => {
        return this.router.navigateByUrl('/home/vehicles/' + this.vehicle.id + '/save-success');
      }).catch( err => {
        return this.router.navigate(['/generic-error']);
      });
      // add errror
    } else {
      promiseUpateName.then(res => {
        return this.router.navigateByUrl('/home/vehicles/' + this.vehicle.id + '/save-success');
      }).catch( err => {
        return this.router.navigate(['/generic-error']);
      });
    }
  }
}
