import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-flag-favorite",
  templateUrl: "./flag-favorite.component.html",
  styleUrls: ["./flag-favorite.component.scss"]
})
export class FlagFavoriteComponent implements OnInit {
  constructor() {}

  @Input() iconOff: string = "";
  @Input() iconOn: string = "";
  @Input() isChecked: boolean = false;

  @Output() isFlagCheckedEmitter: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  clickHandler() {
    this.isChecked = !this.isChecked;
    this.isFlagCheckedEmitter.emit(this.isChecked);
  }
  ngOnInit() {}
}
