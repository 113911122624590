import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-block-push-promo",
  templateUrl: "./block-push-promo.component.html",
  styleUrls: ["./block-push-promo.component.scss"]
})
export class BlockPushPromoComponent implements OnInit {
  constructor() {}

  //set pushPromoLarge at true to display a block push promo large
  //push promo large is inserted in verybig blocks, and has a contact button;
  //push promo is a standalone block  inseted in a grid-item block
  @Input() pushPromoLarge: boolean;

  @Input() title: string = "Roadside service plus";
  @Input() fromText: string = "from";
  @Input() price: string = "9,99 € / year";
  @Input() secondaryButtonLabel?: string = "Contact us";
  @Input() primaryButtonLabel: string = "Purchase online";
  @Input() icon: string = "icon-circle-car";
  @Input() purchaseLink?: any;
  @Input() contactLink?: any;

  @Output() purchaseEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() contactEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {}
}
