import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../abstract-component';
import { WordpressService } from 'src/app/cms-manager/wordpress/wordpress.service';

@Component({
  selector: 'app-extra-cards',
  templateUrl: './extra-cards.component.html',
  styleUrls: ['./extra-cards.component.scss']
})
export class ExtraCardsComponent extends AbstractComponent implements OnInit {

  public commercialCards: any[];

  constructor() {
    super('quick-access-cards-page');
  }

  ngOnInit() {
    this.commercialCards = WordpressService.commercialCards;
    console.log(this.commercialCards);
  }

  haveCards(): boolean {
    return this.commercialCards && this.commercialCards.length > 0;
  }

  haveLink(card: any): boolean {
    return card.externalLinkLabel && card.externalLink ? true : false;
  }

}
