import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../resource';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends Resource {

  public updatableCustomer: any;

  public customerUpdated: boolean;

  public indexEditableAddress;

  public indexEditablePhone;

  constructor(http: HttpClient) {
    super(http, environment.restBase + '/customers');
  }

  set customer(updatableCustomer: any) {
    this.updatableCustomer = updatableCustomer;
  }

  get customer() {
    return this.updatableCustomer;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.self();
  }

  self() {
    return super.get('self');
  }

}
