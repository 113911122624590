import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-bg-image",
  templateUrl: "./bg-image.component.html",
  styleUrls: ["./bg-image.component.scss"]
})
export class BgImageComponent implements OnInit {
  //image as backgound with optional overlay
  //position absolute, spreading
  //add a background image in a block width position relative
  //it will cover all the block if the position if !contain
  //set contain as true to contain the image in the parent, ie to prevent the image to be cropped

  @Input() appTest: string;

  @Input() path?: string = "";
  @Input() bgImage: string;
  @Input() contain?: boolean = false;
  @Input() withOverlay?: boolean;
  @Input() overlayColor?: string = "#282832";
  @Input() overlayOpacity?: number = 0.6;

  ngOnInit() {}

  constructor() {}
}
