/* eslint-disable max-len */

import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { AbstractComponent } from 'src/app/features/commons/abstract-component';
import { environment } from 'src/environments/environment';
import { MenuItems } from 'src/app/design-system/components/structure/nav-menu/nav-menu.component';
import { PageLayoutConfigService } from 'src/app/services/page-layout-config/page-layout-config.service';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.sass']
})
export class LoginPageComponent extends AbstractComponent implements OnInit {

  public title: string;
  public bgImage: string;
  public postContent: SafeHtml;
  public footerLinks: MenuItems[];
  public footerCopyright: string;
  public contactBlocksData: Array<any>;
  public contactBlocksButton: string;

  constructor(
    private sanitizer: DomSanitizer,
    public cookiesService: CookiesService,
    private pageLayoutConfigService: PageLayoutConfigService,
    private activatedRoute: ActivatedRoute
  ) {
    super('login-page');
  }

  ngOnInit() {
    const fromValidation = this.activatedRoute.snapshot.paramMap.get('fromValidation') === "true";

    this.title = fromValidation ? this.fields.side_help_from_validation_title : this.fields.side_help_title;

    this.bgImage = fromValidation ?
                  environment.contentUploads + this.fields.side_help_from_validation_image.substring(this.fields.side_help_from_validation_image.indexOf('uploads') + 7) :
                  environment.contentUploads + this.fields.side_help_image.substring(this.fields.side_help_image.indexOf('uploads') + 7);

    const helpText = fromValidation ?  this.fields.side_help_from_validation_text : this.fields.side_help_text;
    this.postContent = this.sanitizer.bypassSecurityTrustHtml(helpText);

    this.footerLinks = this.pageLayoutConfigService.publicFooterLinks;
    this.footerCopyright = this.pageLayoutConfigService.footerCopyright;
    this.contactBlocksButton = this.pageLayoutConfigService.contactBlocksButtonLabel;
    this.contactBlocksData = this.pageLayoutConfigService.publicContactBlocksData;
  }

}
