<div class="app-footer" #footerElement>
  <div class="content-box">
    <div *ngIf="footerLinks"
      class="top-footer"
      [ngClass]="{ 'no-download-logos': !withDownloadLogos }"
    >
      <div class="content-box-inner-section">

        <ul class="footer-links-container" [ngClass]="isIE11 ? 'ie11' : 'not-ie11'">
          <li *ngFor="let link of footerLinks">
            <a [routerLink]="link.routerLink" class="footer-link" attr.app-test="{{link.appTest}}" (click)="onClick.emit($event)">
              {{ link.itemTitle }}
            </a>
          </li>
        </ul>

        <div class="footer-logos-container">
          <div *ngIf="withDownloadLogos" class="footer-logos">
            <app-icon-button
              icon="App_Store"
              iconHeight="40px"
              iconWidth="127px"
            ></app-icon-button>
            <app-icon-button
              icon="Google_Play"
              iconHeight="40px"
              iconWidth="127px"
            ></app-icon-button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box-inner bottom-footer">
      <div class="content-box-inner-section">
        <span>{{ copyright }}</span>
      </div>
    </div>
  </div>
  <div class="contact-link-box--fixed" #contactButton *ngIf="!isPWA">
    <app-contact-button 
      [contactBlocksData]="contactBlocksData" 
      [buttonLabel]="contactBlocksButton"
      icon="icon-circle-chat">
    </app-contact-button>
  </div>
</div>
