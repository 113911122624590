import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  constructor() {}
  @Input() appTest: string;

  alertIcon: string = "icon-alert-accent";

  @Input() icon: string = "icon-circle-insurance";
  @Input() alert?: boolean = false;
  @Input() expired?: boolean = false;
  @Input() pending?: boolean = false;
  @Input() title: string = "Title";
  @Input() subtitle: string = "Subtitle";
  @Input() info?: string;
  @Input() agreementNumber?: string;
  @Input() agreementNumberLabel?: string;
  @Input() startDate?: string;
  @Input() endDate?: string;
  @Input() buttonLabel?: string;
  @Input() hasButton?: boolean = false;
  @Input() buttonIsLink?: boolean = false;

  @Input() agreementsCount?: number;
  @Input() agreementsCountLabel?: string;

  @Output() cardButtonClick: EventEmitter<any> = new EventEmitter<any>();

  //cards with multi contracts - not used anymore, so far
  //@Input() multi?: boolean = false;

  displayAgreement: boolean;

  ngOnInit() {
    if (this.agreementNumber || this.startDate || this.endDate) {
      this.displayAgreement = true;
    }
  }
}
