import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnInit {
  @Input() appTest: string;

  @Input() buttonLabel: string;
  @Input() buttonType:
    | "mat-primary"
    | "mat-accent"
    | "secondary"
    | "link-button"
    | "default" = "mat-primary";
  @Input() isLink?: boolean;
  @Input() branding?: "renault";
  @Input() disabled?: boolean; //to add/remove attribute disabled in the nested button
  @Input() fullWidth?: boolean;
  @Input() icon?: string;
  @Input() iconHeight?: string = "20px";
  @Input() iconWidth?: string = "20px";
  @Input() colorClass?: string = "color-white"; //used for the icon in the button
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.isLink) {
      this.buttonType = "link-button";
    }
  }
}
