import { Component } from '@angular/core';
import { AbstractComponent } from '../../commons/abstract-component';
import { ConfigurablePage } from '../../commons/configurable-page';

@Component({
  selector: 'app-save-success',
  templateUrl: './save-success.component.html',
  styleUrls: ['./save-success.component.sass']
})
export class SaveSuccessComponent extends AbstractComponent implements ConfigurablePage {

  public parentLink = '/home/vehicles';

  constructor() {
    super('vehicle-detail-page');
  }

  getConfig() {
    return {
      backToLabel : this.fields.navigation_button_label,
      backToLink : this.parentLink,
      isSubPage : true,
      isHome : false
    };
  }

}
