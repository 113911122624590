import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TabData } from "../pwa-tab/pwa-tab.component";
import { MatTabNav } from "@angular/material/tabs";
import { DialogService } from "src/app/design-system/services/dialog.service";
import { PwaMenuMoreComponent } from "../pwa-menu-more/pwa-menu-more.component";
import { MenuItems } from '../nav-menu/nav-menu.component';

@Component({
  selector: "app-pwa-tab-bar",
  templateUrl: "./pwa-tab-bar.component.html",
  styleUrls: ["./pwa-tab-bar.component.scss"]
})
export class PwaTabBarComponent implements OnInit {
  constructor(private dialogService: DialogService) {}

  @ViewChild("matTabNav", { static: true }) matTabNav: MatTabNav;

  @Input() tabBarData: TabData[];
  @Input() activeLink: string;
  @Input() selectedIndex: number;
  @Input() menuItems: MenuItems;
  @Input() footerLinks: MenuItems[];
  @Input() copyright: string;
  @Input() tabBarMoreLabel: string;

  private isOpenDialog = false;

  ngOnInit() {
  }

  closePwaMenu() {
    this.dialogService.close();
    this.isOpenDialog = false;
  }

  openPwaMenu() {
    this.dialogService.open(
      "pwaMenu",
      PwaMenuMoreComponent,
      // DialogComponent,
      {
        ...DialogService.DIALOG_CONFIG.pwaMenu,
        data: {
          menuItems: this.menuItems,
          footerLinks: this.footerLinks,
          copyright: this.copyright
        }
      }
    );
    this.isOpenDialog = true;
  }

  togglePwaMenu() {
    if (!this.isOpenDialog) {
      this.openPwaMenu();
    } else {
      this.closePwaMenu();
    }
  }

}
